import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import Footer from "component/footer/Footer";
import HeaderNavigation from "component/header/HeaderNavigation";
import axios from "axios";
import DisplayType from "utils/displayType";
import Banner from "component/banner/Banner";
import { InsightMenu, NavigationMenu } from "admin/type/type";
import { EventCardProps, EventProps } from "admin/router/event";
import EventCard from "admin/router/event/component/EventCard";

import { useLocation, useNavigate } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import DefaultButton from "admin/component/button/DefaultButton";
import { sortingByOpenDate, updateTitle } from "utils/commonUtil";
import * as api from "../../api/api";
import InsightTabs from "./components/InsightTabs";
import evnetsBanner from "../../assets/event_banner.png";
import evnetsBanner1920 from "../../assets/event_banner_1920.png";
import evnetsBanner2048 from "../../assets/event_banner_2048.png";

export interface EventStateProps {
  scrollY: number;
  currentPage: number;
}

const Events = () => {
  const mode = DisplayType();
  const navigation = useNavigate();

  const isBack = useRef<boolean>(false);
  const [settingCurrentPage, updateSettingCurrentPate] =
    useState<boolean>(false);

  const countPerPage = useRef<number>(mode === "Mobile" ? 6 : 9);
  const [currentPage, updateCurrentPage] = useState<number>(1);
  const [totalPage, updateTotalPage] = useState<number>(0);

  const [allEvent, updateAllEvent] = useState<EventProps[]>([]);
  const today = useRef<Date>(new Date());
  const getEventList = async () => {
    try {
      const result = await axios.get(api.getEventList());
      if (result.status === 200) {
        const events = result.data
          .sort(sortingByOpenDate)
          .filter((item: any) => item.isActive)
          .map((item: any): EventCardProps => ({ ...item }));
        updateAllEvent(events);
        updateTotalPage(Math.ceil(events.length / countPerPage.current));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getServerTime = async () => {
    try {
      const result = await axios.get(api.getServerTime());
      today.current = new Date(result.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getStartIndex = () => {
    return mode === "Mobile" ? 0 : (currentPage - 1) * countPerPage.current;
  };

  const storeState = (page?: number) => {
    const state: EventStateProps = {
      scrollY: window.scrollY,
      currentPage: page || currentPage,
    };
    localStorage.setItem(document.location.pathname, JSON.stringify(state));
  };

  useEffect(() => {
    if (mode !== "Mobile" || !settingCurrentPage) return;

    const state = localStorage.getItem(document.location.pathname);
    if (state) {
      const beforeState = { ...JSON.parse(state) };
      window.scrollTo(0, beforeState.scrollY);
      localStorage.removeItem(document.location.pathname);
      localStorage.removeItem("goBack");
    }
  }, [settingCurrentPage]);

  useEffect(() => {
    if (mode !== "Mobile" || allEvent.length === 0) return;

    const goBack = localStorage.getItem("goBack");
    if (goBack) {
      isBack.current = JSON.parse(goBack).goBack;
    }
    if (isBack.current) {
      const state = localStorage.getItem(document.location.pathname);
      if (state) {
        const beforeState = { ...JSON.parse(state) };
        updateCurrentPage(beforeState.currentPage);
        updateSettingCurrentPate(true);
      }
    }
  }, [allEvent]);

  useEffect(() => {
    getServerTime();
    getEventList();
  }, []);

  useLayoutEffect(() => {
    updateTitle("Event");
  }, []);

  return (
    <Container>
      <Banner
        bannerImg={evnetsBanner}
        bannerImg1920={evnetsBanner1920}
        bannerImg2048={evnetsBanner2048}
        mode={mode}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: mode === "Mobile" ? "center" : "flex-start",
          }}
        >
          <div
            className={`${
              mode === "Mobile" ? "font-title-en" : "font-title-en"
            }`}
            style={{ color: "var(--white)" }}
          >
            EVENTS
          </div>
        </div>
      </Banner>
      <InsightTabs selectedTab={InsightMenu.EVENTS} />
      <InsightBodyContainer className="limit__body">
        <div className="event__list__container">
          {allEvent
            .slice(getStartIndex(), currentPage * countPerPage.current)
            .map((item) => {
              return (
                <EventCard
                  key={item.hpEventId}
                  item={item}
                  isViewer
                  today={today.current}
                  onClickItem={() => {
                    storeState();
                    navigation(`/insight/events/${item.hpEventId}`);
                  }}
                  callback={() => {
                    getEventList();
                  }}
                />
              );
            })}
        </div>
        {mode !== "Mobile" && totalPage > 0 && (
          <Pagination
            count={totalPage}
            shape="rounded"
            page={currentPage}
            onChange={(event, page) => {
              storeState(page);
              updateCurrentPage(page);
            }}
          />
        )}
        {mode === "Mobile" && totalPage > 0 && currentPage < totalPage && (
          <DefaultButton
            className="font-body3"
            type="outline"
            onClick={() => {
              storeState(currentPage + 1);
              updateCurrentPage(currentPage + 1);
            }}
          >
            이벤트 더보기
          </DefaultButton>
        )}
      </InsightBodyContainer>
      <Footer />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 768px) {
  }
`;
const InsightBodyContainer = styled.div`
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-top: 100px;
  padding-bottom: 160px;

  .event__list__container {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 24px;
    row-gap: 34px;
    margin-bottom: 40px;
  }

  @media only screen and (max-width: 768px) {
    padding-top: 40px;
    padding-bottom: 40px;
    .event__list__container {
      gap: 40px;
      margin-bottom: 64px;
    }
  }
`;
export default Events;
