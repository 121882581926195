import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";

import axios from "axios";
import { getYMD } from "utils/commonUtil";
import { NotionInput } from "admin/component/input/NotionInput";
import NotionInputSelecter from "admin/component/input/NotionInputSelecter";
import {
  Address,
  CompanyProjectType,
  HomepagePortfolioInfoType,
  imgCategory,
  IndustryTech,
  SelectData,
  stage,
  Stage,
} from "admin/type/type";
import { NotionTextarea } from "admin/component/input/NotionTextarea";
import { Button, Spinner } from "react-bootstrap";
import UploadCompanyImg from "../component/UploadCompanyImg";
import ProjectCard from "../component/ProjectCard";
import { PortfolioUpdateInfo } from "./PortfolioCompareModal";
import DaumModal from "../component/DaumModal";

interface Props {
  portfolioId: number;
  portfolioData: PortfolioUpdateInfo;
  onChangePortfolioData: (portfolioData: HomepagePortfolioInfoType) => void;
}
const PortfolioInfoByHomepage: React.FC<Props> = (props) => {
  const homepagePortfolio = props.portfolioData.homepagePortfolio;
  const [selectedStage, updateSelectedStage] = useState<Stage | undefined>(
    undefined
  );
  const [openDaumAddress, setOpenDaumAddress] = useState<boolean>(false);
  const [companyName, setCompanyName] = useState<string | undefined>(undefined);
  const [companyImgUrl, setCompanyImgUrl] = useState<string | undefined | null>(
    undefined
  );
  const [companyImgKey, setCompanyImgKey] = useState<string | undefined | null>(
    undefined
  );
  const [addr, setAddr] = useState<Address | undefined>(undefined);
  const [ceoName, setCeoName] = useState<string | undefined>(undefined);
  const [description, setDescription] = useState<string | undefined>(undefined);
  const [introduction, setIntroduction] = useState<string | undefined>(
    undefined
  );
  const [establishmentDay, setEstablishmentDay] = useState<string | undefined>(
    undefined
  );
  const [homepageUrl, setHomepageUrl] = useState<string | undefined | null>(
    undefined
  );
  const [hpPfIndustrialTech, setHpPfIndustrialTech] = useState<
    IndustryTech[] | undefined
  >([]);
  const [hpPfProject, setHpPfProject] = useState<
    CompanyProjectType[] | undefined
  >([]);

  useEffect(() => {
    const _homepagePortfolio = props.portfolioData.homepagePortfolio;
    updateSelectedStage(
      stage.find((item) => item.text === _homepagePortfolio?.stage)?.data
    );
    setCompanyName(_homepagePortfolio?.companyName);
    setCompanyImgUrl(_homepagePortfolio?.companyImgUrl);
    setCompanyImgKey(_homepagePortfolio?.companyImgKey);
    setAddr({
      addr1: _homepagePortfolio?.addr1,
      addr2: _homepagePortfolio?.addr2,
      addr3: _homepagePortfolio?.addr3,
      addrDetail: _homepagePortfolio?.addrDetail,
    });
    setCeoName(_homepagePortfolio?.ceoName);
    setDescription(_homepagePortfolio?.description);
    setIntroduction(_homepagePortfolio?.introduction);
    setEstablishmentDay(_homepagePortfolio?.establishmentDay);
    setHomepageUrl(_homepagePortfolio?.homepageUrl);
    setHpPfIndustrialTech(
      _homepagePortfolio?.hpPfIndustrialTech.map(
        (item) => item.hpIndustrialTech
      )
    );
    setHpPfProject(_homepagePortfolio?.hpPfProject);
  }, [
    props.portfolioData.homepagePortfolio?.companyName,
    props.portfolioData.homepagePortfolio?.companyImgUrl,
    props.portfolioData.homepagePortfolio?.companyImgKey,
    props.portfolioData.homepagePortfolio?.stage,
    props.portfolioData.homepagePortfolio?.addr1,
    props.portfolioData.homepagePortfolio?.addr2,
    props.portfolioData.homepagePortfolio?.addr3,
    props.portfolioData.homepagePortfolio?.addrDetail,
    props.portfolioData.homepagePortfolio?.ceoName,
    props.portfolioData.homepagePortfolio?.description,
    props.portfolioData.homepagePortfolio?.introduction,
    props.portfolioData.homepagePortfolio?.establishmentDay,
    props.portfolioData.homepagePortfolio?.homepageUrl,
    props.portfolioData.homepagePortfolio?.hpPfIndustrialTech,
    props.portfolioData.homepagePortfolio?.hpPfProject,
  ]);

  if (homepagePortfolio === undefined) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  return (
    <Container>
      <Row gap={24}>
        <UploadCompanyImg
          editable
          width={250}
          imgUrl={companyImgUrl}
          portfolioId={props.portfolioId}
          onChangeFile={(url, key) => {
            props.onChangePortfolioData({
              ...homepagePortfolio,
              companyImgKey: key,
              companyImgUrl: url,
            });
          }}
          category={imgCategory.COMPANY_IMG}
        />
        <Column gap={4}>
          <NotionInput
            editable
            hasUnderLine
            label="회사명"
            defaultValue={companyName}
            onChange={(e) => {
              setCompanyName(e);
            }}
            onBlur={(e: any) => {
              props.onChangePortfolioData({
                ...homepagePortfolio,
                companyName: companyName || "",
              });
            }}
          />
          <NotionInput
            editable
            hasUnderLine
            label="아이템 요약"
            defaultValue={description}
            onChange={(e) => {
              setDescription(e);
            }}
            onBlur={(e: any) => {
              props.onChangePortfolioData({
                ...homepagePortfolio,
                description: description || "",
              });
            }}
          />

          <NotionInputSelecter
            editable
            hasUnderLine
            label="상태"
            data={stage}
            selectedItem={stage.filter(
              (item) => item.text === selectedStage?.name
            )}
            onChange={(data: Stage[]) => {
              if (data.length > 0) {
                props.onChangePortfolioData({
                  ...homepagePortfolio,
                  stage: data[0].name,
                });
              }
            }}
            type="single"
          />
        </Column>
      </Row>
      <Row gap={24}>
        <NotionInput
          editable
          hasUnderLine
          className="flex1"
          label="대표자"
          defaultValue={ceoName}
          onChange={(e) => {
            setCeoName(e);
          }}
          onBlur={(e: any) => {
            props.onChangePortfolioData({
              ...homepagePortfolio,
              ceoName: ceoName || "",
            });
          }}
        />
        <NotionInput
          editable
          hasUnderLine
          className="flex1"
          label="홈페이지"
          defaultValue={homepageUrl}
          onChange={(e) => {
            setHomepageUrl(e);
          }}
          onBlur={(e: any) => {
            props.onChangePortfolioData({
              ...homepagePortfolio,
              homepageUrl: homepageUrl || "",
            });
          }}
        />
      </Row>
      <Row gap={24}>
        <NotionInput
          editable
          hasUnderLine
          textType="date"
          className="flex1"
          label="설립일"
          defaultValue={getYMD(establishmentDay || "")}
          onChange={(e) => {
            setEstablishmentDay(e);
          }}
          onBlur={(e: any) => {
            props.onChangePortfolioData({
              ...homepagePortfolio,
              establishmentDay: establishmentDay || "",
            });
          }}
        />
        <AddressContainer className="flex1">
          <span className="font-bold-14">소재지</span>
          <div
            className=" address__input font-regular-14"
            onClick={() => {
              setOpenDaumAddress(true);
            }}
          >{`${homepagePortfolio.addr1} ${homepagePortfolio.addr2} ${homepagePortfolio.addr3} ${homepagePortfolio.addrDetail}`}</div>
        </AddressContainer>
      </Row>
      <Row>
        <NotionTextarea
          editable
          label="기업 소개"
          defaultValue={introduction}
          onChange={(e) => {
            setIntroduction(e);
          }}
          onBlur={(e: any) => {
            props.onChangePortfolioData({
              ...homepagePortfolio,
              introduction: introduction || "",
            });
          }}
        />
      </Row>
      <Column gap={4}>
        <Row style={{ alignItems: "center", justifyContent: "space-between" }}>
          <span className="font-bold-14">기업 프로젝트</span>
        </Row>
        <Column gap={24} style={{ marginTop: 10 }}>
          {hpPfProject?.map((item) => {
            return (
              <ProjectCard
                key={item.pfProjectId}
                editable
                portfolioId={props.portfolioId}
                project={item}
                onChangeProjectData={(project) => {
                  const newProjects = hpPfProject.map((item) => {
                    if (item.pfProjectId === project.pfProjectId) {
                      return project;
                    }
                    return item;
                  });
                  props.onChangePortfolioData({
                    ...homepagePortfolio,
                    hpPfProject: newProjects,
                  });
                }}
                onDeleteProject={(projectId) => {
                  const index = hpPfProject.findIndex(
                    (item) => item.pfProjectId === projectId
                  );
                  hpPfProject.splice(index, 1);

                  const newProjects = hpPfProject.map((item) => {
                    return item;
                  });
                  props.onChangePortfolioData({
                    ...homepagePortfolio,
                    hpPfProject: newProjects,
                  });
                }}
              />
            );
          })}
        </Column>
      </Column>
      <DaumModal
        open={openDaumAddress}
        setValue={(address) => {
          props.onChangePortfolioData({
            ...homepagePortfolio,
            ...address,
          });
        }}
        toggle={() => {
          setOpenDaumAddress(false);
        }}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px;
  overflow: scroll;
  gap: 16px;
`;

const Row = styled.div<{ gap?: number }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: ${(props) => (props.gap ? `${props.gap}px` : "0px")};
  .flex1 {
    flex: 1;
  }
  .flex2 {
    flex: 2;
  }
  .flex3 {
    flex: 3;
  }
`;

const AddressContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 4px;

  .address__input {
    max-width: 100%;
    height: auto;
    padding: 5px 32px 5px 10px;
    border-bottom: 1px solid var(--grey-20);

    cursor: pointer;

    :hover {
      border-radius: 4px;
      background-color: var(--grey-10);
    }
  }
`;

const Column = styled.div<{ gap?: number }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.gap ? `${props.gap}px` : "0px")};
`;

export default PortfolioInfoByHomepage;
