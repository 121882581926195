import { useEffect, useState } from "react";

const getWindowDimensions = () => {
  const { innerWidth: width } = window;
  return width;
};
export const useContainerDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };
    window.addEventListener("resize", handleResize);
  }, []);
  return windowDimensions;
};
