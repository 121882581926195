import styled from "@emotion/styled";
import { PopupProps } from "admin/router/popup/PopupAdmin";
import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";

import PopupEditor from "./PopupEditor";
import PopupImage from "./PopupImage";

interface Props {
  popupList: PopupProps[];
  todayClose: () => void;
  close: () => void;
}

const PopupNotice: React.FC<Props> = (props) => {
  const [noticeList, updateNoticeList] = useState<PopupProps[]>(
    props.popupList
  );
  const [currentPosition, updateCurrentPosition] = useState<number>(0);

  const sliderRef = useRef<any>(null);
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    swipe: false,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useEffect(() => {}, [currentPosition]);

  return (
    <Container className="">
      <div className="notice__header">
        <span
          className="notice__header__title font-button3"
          style={{ color: "var(--grey-50)" }}
        >
          공지사항
        </span>
        <div className="notice__header__indicator">
          {props.popupList.map((item, index) => {
            return (
              <span
                className={`notice__header__indicator_dot ${
                  index === currentPosition ? "selected" : ""
                }`}
                onClick={() => {
                  if (!sliderRef) return;
                  sliderRef.current.slickGoTo(index);
                  updateCurrentPosition(index);
                }}
              />
            );
          })}
        </div>
      </div>
      <div className="notice__contents">
        <Slider
          ref={sliderRef}
          className="full"
          {...settings}
          afterChange={(cur) => {
            updateCurrentPosition(cur);
          }}
        >
          {noticeList.map((item) => {
            return item.isImg ? (
              <PopupImage popupData={item} />
            ) : (
              <PopupEditor initialValue={item.script || ""} />
            );
          })}
        </Slider>
      </div>

      <div className="notice__btn__container">
        <div className="notice__btn" onClick={props.todayClose}>
          1일간 보지 않기
        </div>
        <div className="notice__btn" onClick={props.close}>
          닫기
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  width: 500px;
  height: 740px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .notice__header {
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--grey-00);

    .notice__header__title {
      padding: 0 14px;
    }

    .notice__header__indicator {
      display: flex;
      flex-direction: row;
      gap: 10px;
      padding: 0 16px;

      .notice__header__indicator_dot {
        width: 10px;
        min-width: 10px;
        max-width: 10px;
        height: 10px;
        min-height: 10px;
        max-height: 10px;
        border-radius: 50%;
        background: var(--grey-00);
        transition: all 0.2s ease;
        cursor: pointer;
      }
      .selected {
        background: var(--primary);
      }
    }
  }

  .notice__contents {
    width: 100%;
    height: calc(740px - 100px);
    overflow: hidden;
    .full {
      width: 100%;
      height: calc(740px - 100px);
    }
  }

  .notice__btn__container {
    width: 100%;
    height: 60px;
    min-height: 60px;
    max-height: 60px;
    display: flex;
    flex-direction: row;
    border-top: 1px solid var(--grey-00);
    .notice__btn {
      flex: 1;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      user-select: none;
    }
    div:nth-of-type(1) {
      border-right: 1px solid var(--grey-00);
    }
  }

  @media (hover: hover) {
    .notice__header {
      .notice__header__indicator {
        .notice__header__indicator_dot {
          :hover {
            background: var(--primary);
          }
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    width: 300px;
    height: 540px;

    .notice__contents {
      width: 100%;
      height: calc(540px - 100px);
      .full {
        width: 100%;
        height: calc(540px - 100px);
      }
    }
  }
`;

export default PopupNotice;
