import React, { Dispatch, useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import styled from "@emotion/styled";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Mode } from "admin/type/type";
import DefaultButton from "admin/component/button/DefaultButton";
import News from "component/card/News";
import axios from "axios";
import { getYear, getYMD, sortingByOpenDate } from "utils/commonUtil";
import { NewsProps } from "admin/router/news";
import DisplayType from "utils/displayType";
import { Link } from "react-router-dom";
import GoTo from "component/go-list/GoTo";
import * as api from "../../api/api";

interface Props {}
const LastestNews: React.FC<Props> = (propss) => {
  const [loading, setLoading] = useState(true);
  const [newsList, updateNewsList] = useState<NewsProps[]>([]);
  const mode = DisplayType();
  useEffect(() => {}, []);

  const newsClick = (item: NewsProps) => {
    if (!item.linkUrl) return;

    const aTag = document.createElement("a");
    aTag.href = item.linkUrl;
    aTag.target = "_black";
    aTag.click();
  };

  const getNewsList = async () => {
    try {
      const result = await axios.get(api.getNewsList());
      if (result.status === 200) {
        const news = result.data
          .sort(sortingByOpenDate)
          .filter((item: any) => item.isActive)
          .map((item: any) => ({
            ...item,
            openDate: getYMD(item.openDate),
          }));
        updateNewsList(news.filter((item: any, index: number) => index < 3));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getNewsList();
  }, []);

  return (
    <Container>
      <NewsContainer>
        {newsList.map((item) => (
          <News
            key={item.hpNewsId}
            news={item}
            onClick={() => newsClick(item)}
          />
        ))}
      </NewsContainer>
      {mode !== "Mobile" && <GoTo to="/insight/news" toText="뉴스 전체보기" />}
      {mode === "Mobile" && (
        <div className="center" style={{ marginTop: 30 }}>
          <Link to={"/insight/news"} style={{ textDecoration: "none" }}>
            <DefaultButton className="font-button2" type="outline">
              뉴스 전체보기
            </DefaultButton>
          </Link>
        </div>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 24px;
  border-top: 1px solid var(--grey-00);

  @media only screen and (max-width: 768px) {
    padding-top: 0;
  }
`;

const NewsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
  @media only screen and (max-width: 768px) {
    gap: 0;
  }
`;

export default LastestNews;
