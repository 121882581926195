import React, { useRef, useState } from "react";
import DefaultButton from "admin/component/button/DefaultButton";
import DisplayType from "utils/displayType";
import ContactInput from "router/contact/components/ContactInput";
import { checkEmailForm } from "utils/commonUtil";
import axios from "axios";
import ModalLayout from "admin/component/modal/ModalLayout";
import ResultBlueletterModal from "component/success-modal/ResultBlueletterModal";
import PrivacyPolicyCheckBox from "component/agree-checkbox/PrivacyPolicyCheckBox";
import styled from "@emotion/styled";

import * as api from "../../api/api";
import { ReactComponent as Arrow } from "../../assets/blue_letter_apply_arrow.svg";

const BlueletterApplyNew = () => {
  const [checked, updateChecked] = useState<boolean>(false);
  const resultSuccess = useRef<boolean>(false);
  const [isResultModal, updateResultModal] = useState<boolean>(false);

  const [nameError, updateNameError] = useState<boolean>(false);
  const [emailError, updateEmailError] = useState<boolean>(false);
  const [checkedError, updateCheckedError] = useState<boolean>(false);

  const [name, updateName] = useState<string>("");
  const [email, updateEmail] = useState<string>("");
  const mode = DisplayType();

  const initValue = () => {
    updateNameError(false);
    updateEmailError(false);
    updateName("");
    updateEmail("");

    updateChecked(false);
  };

  const validationChecking = () => {
    const isValidName = name.length === 0;
    const isValidEmail = email.length === 0 || !checkEmailForm(email);
    const isValidChecked = !checked;

    updateNameError(isValidName);
    updateEmailError(isValidEmail);
    updateCheckedError(isValidChecked);

    return !isValidName && !isValidEmail && !isValidChecked;
  };

  const subscribeBlueletter = async () => {
    try {
      if (validationChecking()) {
        const data = {
          name,
          email,
        };
        const result = await axios.post(api.subscribeblueLetter(), data);

        if (result.status === 201) {
          updateResultModal(true);
          resultSuccess.current = true;
          initValue();
        }
      }
    } catch (error) {
      updateResultModal(true);
      resultSuccess.current = false;
      initValue();
    }
  };
  return (
    <Container>
      <BodyContainer className="limit__body">
        <LeftContainer>
          <span
            className={`blueletter__title ${
              mode === "Mobile" ? "font-sub-title-en" : "font-title-en"
            }`}
            style={{ color: "var(--black)" }}
          >
            BLUE LETTER
          </span>
          <p
            className={`blueletter__description ${
              mode === "Mobile" ? "font-body3" : "font-body3"
            }`}
          >
            다양한 투자 동향, 스타트업 활동,{mode === "Mobile" && <br />}신규
            포트폴리오 소식을 받아보세요.
          </p>
        </LeftContainer>
        <RightContainer>
          <div className="blueletter__row">
            <ContactInput
              className="blueletter__name"
              inputType="text"
              placeHolder="성함을 입력해주세요"
              isError={nameError}
              defaultValue={name}
              onFocus={() => updateNameError(false)}
              onChangeValue={(value) => updateName(value)}
            />
            <ContactInput
              className="blueletter__email"
              inputType="text"
              placeHolder="메일주소를 입력해주세요"
              isError={emailError}
              defaultValue={email}
              onFocus={() => updateEmailError(false)}
              onChangeValue={(value) => updateEmail(value)}
            />
          </div>
          <div className="check_container">
            <PrivacyPolicyCheckBox
              defaultCheck={checked}
              style={{ marginTop: 20 }}
              isError={checkedError}
              onChangeValue={(value) => {
                updateChecked(value);
                updateCheckedError(false);
              }}
            />
            <div className="apply__btn__wrapper">
              <ApplyBtn
                className={`${
                  mode === "Mobile" ? "font-button2" : "font-button1"
                }`}
                onClick={subscribeBlueletter}
              >
                구독하기
                {mode !== "Mobile" && <Arrow />}
              </ApplyBtn>
            </div>
          </div>
        </RightContainer>
        <ModalLayout
          isOpen={isResultModal}
          toggle={() => updateResultModal(false)}
        >
          <ResultBlueletterModal
            isSuccess={resultSuccess.current}
            close={() => updateResultModal(false)}
          />
        </ModalLayout>
      </BodyContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 320px;
  display: flex;
  background: var(--bg-02);

  @media only screen and (max-width: 768px) {
    height: fit-content;
  }
`;

const LeftContainer = styled.div`
  width: 300px;
  max-width: 300px;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;

  .blueletter__title {
    color: var(--primary);
  }
  .blueletter__description {
    color: var(--grey-50);
    margin: 0;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    max-width: 100%;
    height: 100%;
    align-items: center;

    .blueletter__description {
      text-align: center;
      margin-top: 6px;
    }
  }
`;
const RightContainer = styled.div`
  flex: 7;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;

  .input__wrapper {
    display: flex;
    flex-direction: column;
  }
  .blueletter__row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }

  .check_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .apply__btn__wrapper {
      width: fit-content;
    }
  }

  .blueletter__name {
    width: 240px;
  }
  .blueletter__email {
    flex-grow: 1;
  }

  .is__error {
    animation: shake 300ms;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    height: fit-content;
    .blueletter__row {
      width: 100%;
      flex-direction: column;
      gap: 30px;
    }

    .blueletter__name {
      width: 100%;
    }
    .blueletter__email {
      width: 100%;
    }

    .check_container {
      flex-direction: column;
      align-items: flex-start;

      .apply__btn__wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
`;
const BodyContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 100px;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
    padding-top: 56px;
    padding-bottom: 56px;
  }
`;

const ApplyBtn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  margin-top: 20px;
  color: var(--primary);
  cursor: pointer;

  @media only screen and (max-width: 768px) {
    justify-content: center;
    border: 1px solid var(--primary);
    width: 119px;
    height: 43px;
  }
`;

export default React.memo(BlueletterApplyNew);
