import { useRef, useEffect, useState, useLayoutEffect } from "react";

import Banner from "component/banner/Banner";
import HeaderNavigation from "component/header/HeaderNavigation";
import DisplayType from "utils/displayType";
import { NavigationMenu, Structure, UserInfo } from "admin/type/type";
import axios from "axios";
import { updateTitle } from "utils/commonUtil";
import { TeamContainer, TeamStaffContainer } from "./styles";
import Footer from "../../component/footer/Footer";
import * as api from "../../api/api";
import TeamSection, { TeamSectionProps } from "./organism/TeamSection";
import TeamSectionDepth1 from "./organism/TeamSectionDepth1";
import teamBanner from "../../assets/team_banner.png";
import teamBanner1920 from "../../assets/team_banner_1920.png";
import teamBanner2048 from "../../assets/team_banner_2048.png";

const Team = () => {
  const mode = DisplayType();
  const [structureData, setStructureData] = useState<TeamSectionProps[]>([]);

  const getStructureData = (
    allStructure: Structure[],
    allUser: UserInfo[],
    structureData: Structure
  ) => {
    const teamSection: TeamSectionProps = {
      structureId: structureData.hpStructureId,
      name: structureData.name,
      introduction: structureData.introduction,
      userList: allUser.filter(
        (user) =>
          user.hpStructure?.hpStructureId === structureData.hpStructureId
      ),
      structureChildren: [],
    };
    const children = allStructure.filter(
      (item) => item.parent === structureData.hpStructureId
    );
    if (children.length > 0) {
      teamSection.structureChildren = [
        ...children.map((child) => {
          return getStructureData(allStructure, allUser, child);
        }),
      ];
    }

    return teamSection;
  };
  const getHpStructure = async () => {
    try {
      const structureResult = await axios.get(api.getHomepageStructure());
      const userResult = await axios.get(api.getAllHomepageUser());
      if (structureResult.status === 200 && userResult.status === 200) {
        const hpUserList = userResult.data.map((item: any): UserInfo => {
          return { ...item };
        });
        const structureList = structureResult.data
          .map((item: any): Structure => {
            return { ...item, parent: item.parent === null ? 0 : item.parent };
          })
          .sort((a: any, b: any) =>
            a.structureIndex > b.structureIndex ? 1 : -1
          );
        const result = structureList
          .filter((item: any) => item.parent === 0)
          .map((item: any) => {
            return getStructureData(structureList, hpUserList, item);
          });
        setStructureData(result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useLayoutEffect(() => {
    updateTitle("팀 소개");
  }, []);

  useEffect(() => {
    getHpStructure();
  }, []);

  return (
    <TeamContainer>
      <Banner
        bannerImg={teamBanner}
        bannerImg1920={teamBanner1920}
        bannerImg2048={teamBanner2048}
        mode={mode}
        style={{ backgroundPosition: "center", backgroundPositionY: -150 }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: mode === "Mobile" ? "center" : "flex-start",
            paddingTop: mode === "Mobile" ? 70 : 0,
          }}
        >
          <div
            className={`${
              mode === "Mobile" ? "font-title-en" : "font-title-en"
            }`}
            style={{ color: "var(--white)" }}
          >
            TEAM
          </div>
          <div
            className={mode === "Mobile" ? "font-body3" : "font-body1"}
            style={{ color: "var(--white)", marginTop: 24 }}
          >
            우리는 유쾌한 상상력으로 새로운 투자 경험을 만들어 나가는 New-rule
            Maker 입니다.
            <br />각 분야의 전문가들이 세상을 바꿀 혁신가들의 든든한 파트너로서
            스타트업의 성장을 지원합니다.
          </div>
        </div>
      </Banner>
      <TeamStaffContainer className="limit__body">
        {structureData.map((item) => {
          // return <TeamSection key={item.structureId} item={item} />;
          return <TeamSectionDepth1 key={item.structureId} item={item} />;
        })}
      </TeamStaffContainer>
      <Footer />
    </TeamContainer>
  );
};
export default Team;
