import React, { useEffect, useRef, useState } from "react";
import ModalLayout from "admin/component/modal/ModalLayout";
import axios from "axios";
import { Button } from "react-bootstrap";
import styled from "@emotion/styled";
import SearchBar from "admin/component/search";
import { irArchiveType, IRDataProps } from "admin/type/type";
import { getYMD, getYMDHM } from "utils/commonUtil";
import ConfirmModal from "admin/component/modal/ConfirmModal";
import * as api from "../../../api/api";
import Navigation, { Pages } from "../../component/navigation";
import IRCard from "./component/IRCard";
import IRAnnouncementDetail from "./component/IRAnnouncementDetail";
import { ReactComponent as Delete } from "../../../assets/file_delete.svg";

export interface IRAnnouncementProps {
  hpAnnouncementId?: number;
  postingDate: string;
  title: string;
  content: string;
  hpAnnouncementFile: {
    hpAnnouncementFileId: number;
    fileKey: string;
    name: string;
    type: string;
  }[];
}

const IRAnnouncement = () => {
  const [allIRAnnouncement, updateAllIRAnnouncement] = useState<
    IRAnnouncementProps[]
  >([]);
  const announcementData = useRef<IRAnnouncementProps | undefined>(undefined);
  const [isOpenIrAnnouncement, setOpenIrAnnouncement] =
    useState<boolean>(false);
  const [isOpenDeleteModal, setOpenIsDeleteModal] = useState<boolean>(false);

  const openIrAnnouncement = (iaAnnouncementData?: IRAnnouncementProps) => {
    announcementData.current = iaAnnouncementData;
    setOpenIrAnnouncement(true);
  };

  const closeIrAnnouncement = () => {
    setOpenIrAnnouncement(false);
    announcementData.current = undefined;
    getIRAnnouncementList();
  };

  const openIrAnnouncementDelete = (
    iaAnnouncementData?: IRAnnouncementProps
  ) => {
    announcementData.current = iaAnnouncementData;
    setOpenIsDeleteModal(true);
  };

  const deleteIRAnnouncementItem = async (id: number) => {
    try {
      const result = await axios.delete(api.deleteIRAnnouncement(id));
      if (result.status === 200) {
        setOpenIsDeleteModal(false);
        announcementData.current = undefined;
        getIRAnnouncementList();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getIRAnnouncementList = async () => {
    try {
      const result = await axios.get(api.getIRAnnouncementAdmin());
      if (result.status === 200) {
        console.log(result);
        const _announcementData = result.data
          .sort((a: any, b: any) => (a.postingDate > b.postingDate ? -1 : 1)) // 내림차순
          .map(
            (item: any): IRAnnouncementProps => ({
              ...item,
              postingDate: getYMD(item.postingDate),
            })
          );
        updateAllIRAnnouncement([..._announcementData]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const init = async () => {
    try {
      if (await api.checkingToken()) {
        await getIRAnnouncementList();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    init();
  }, []);
  return (
    <Navigation activePageId={Pages[10].id}>
      <Container>
        <div className="announcement__header">
          <div />
          <Button
            variant="primary"
            onClick={() => {
              openIrAnnouncement(undefined);
            }}
          >
            공고 생성하기
          </Button>
        </div>
        <div className="announcement__body">
          <ItemRow
            className="font-sub-title4"
            style={{ borderTop: "1px solid var(--grey-20)", marginTop: 58 }}
          >
            <span className="item__row__title">{"제목"}</span>
            <span
              className="item__row__date"
              style={{ justifyContent: "flex-start" }}
            >
              {"공지일자"}
            </span>
          </ItemRow>
          {allIRAnnouncement.map((item) => {
            return (
              <ItemRow
                className="font-body2"
                onClick={(e) => {
                  openIrAnnouncement(item);
                }}
              >
                <span className="item__row__title">{item.title}</span>
                <div
                  className="item__row__date"
                  style={{ justifyContent: "flex-start" }}
                >
                  <span
                    className="font-button2 item__row__date__btn"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    {item.postingDate}
                  </span>
                </div>
                <Delete
                  onClick={(e) => {
                    e.stopPropagation();
                    openIrAnnouncementDelete(item);
                  }}
                />
              </ItemRow>
            );
          })}
        </div>
      </Container>

      <ModalLayout
        isOpen={isOpenIrAnnouncement}
        borderRadius={0}
        toggle={() => setOpenIrAnnouncement(false)}
      >
        <IRAnnouncementDetail
          announcementData={announcementData.current}
          callback={closeIrAnnouncement}
          close={closeIrAnnouncement}
        />
      </ModalLayout>

      <ModalLayout
        isOpen={isOpenDeleteModal}
        borderRadius={0}
        toggle={() => setOpenIrAnnouncement(false)}
      >
        <ConfirmModal
          message="해당 공고를 삭제하시겠습니까?"
          onClickNegativeBtn={() => {
            setOpenIsDeleteModal(false);
          }}
          onClickPositiveBtn={() => {
            if (
              announcementData.current &&
              announcementData.current.hpAnnouncementId
            ) {
              deleteIRAnnouncementItem(
                announcementData.current.hpAnnouncementId
              );
            }
          }}
        />
      </ModalLayout>
    </Navigation>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;

  .announcement__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    min-height: 60px;
    max-height: 60px;
    border-bottom: 1px solid grey;
    padding: 0 24px;
  }

  .announcement__body {
    width: 100%;
    height: calc(100vh - 60px);
    display: flex;
    flex-direction: column;
    padding: 24px;
    border-radius: 16px;
  }
`;

const ItemRow = styled.div`
  width: 100%;
  height: 80px;
  min-height: 80px;
  max-height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;

  border-bottom: 1px solid var(--grey-20);

  cursor: pointer;

  .item__row__title {
    flex-grow: 1;
  }
  .item__row__date {
    width: 140px;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    .item__row__date__btn {
      width: auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 10px 16px;
      line-height: 1;
      color: var(--black);
    }
  }

  .delete__btn {
  }

  @media only screen and (max-width: 768px) {
    .item__row__file {
      width: 125px;
      .item__row__file__btn {
        :hover {
          background-color: var(--white);
          color: var(--primary);
        }
      }
    }
  }
`;
export default IRAnnouncement;
