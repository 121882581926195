import React, { useEffect } from "react";
import styled from "@emotion/styled";

import { UserInfo } from "admin/type/type";
import { ReactComponent as DefaultUserImg } from "../../../../assets/default_user_img.svg";
// import { ReactComponent as Warnning } from "../../../../../../assets/image/ic_warning.svg";
import { ReactComponent as CheckboxOn } from "../../../../assets/checkbox_on.svg";
import { ReactComponent as CheckboxOff } from "../../../../assets/checkbox_off.svg";

interface Props {
  userInfo: UserInfo;
  structureName?: string;
  isChecked?: boolean;
  onClick?: () => void;
  selecteUser?: (user: UserInfo) => void;
  cancelSelecteUser?: (user: UserInfo) => void;
}

const UserRowItem: React.FC<Props> = (props) => {
  useEffect(() => {}, []);

  const onClick = () => {
    if (props.isChecked) {
      if (props.cancelSelecteUser) props.cancelSelecteUser(props.userInfo);
    } else if (props.selecteUser) props.selecteUser(props.userInfo);
  };

  return (
    <Container onClick={onClick}>
      <ProfileImageContainer>
        {props.userInfo.profileImgUrl ? (
          <img
            className="profile__img"
            alt="profile"
            src={props.userInfo.profileImgUrl}
          />
        ) : (
          <DefaultUserImg className="profile__img" />
        )}
      </ProfileImageContainer>
      <div className="content">
        <div className="font-regular-14">
          {props.userInfo.name}
          {props.userInfo.hpPosition?.name &&
            `/${props.userInfo.hpPosition?.name}`}
        </div>
        <span className="font-regular-12">
          {props.userInfo.hpStructure?.name || ""}
        </span>
      </div>
      {props.isChecked !== undefined && (
        <CheckboxContainer>
          {props.isChecked ? (
            <CheckboxOn className="checkbox" />
          ) : (
            <CheckboxOff className="checkbox" />
          )}
        </CheckboxContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: var(--white);
  transition: 0.1s;
  cursor: pointer;
  gap: 16px;
  padding: 8px;

  .content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
  }
  :hover {
    background-color: var(--secondary-2);
  }
`;
const ProfileImageContainer = styled.div`
  width: 40px;
  height: 40px;
  background-color: var(--grey-10);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  .profile__img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
`;

const CheckboxContainer = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  .checkbox {
    width: 50%;
    height: 50%;
  }
`;

export default React.memo(UserRowItem);
