import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import axios from "axios";
import { NotionInput } from "admin/component/input/NotionInput";
import NotionInputSelecter from "admin/component/input/NotionInputSelecter";
import DefaultButton from "admin/component/button/DefaultButton";
import { NewsCategory, SelectData } from "admin/type/type";
import { getYMD } from "utils/commonUtil";
import Chip from "component/chips/Chip";
import { NewsProps } from "..";
import NewsCard from "./NewsCard";
import * as api from "../../../../api/api";

interface Props {
  item?: NewsProps;
  close?: () => void;
  callback?: () => void;
}
export interface Portfolio {
  portfolioId: number;
  companyName: string;
}

const NewsDetail: React.FC<Props> = (props) => {
  const categoryList = [NewsCategory.BLUEPOINT, NewsCategory.BLUE_FAMILY];
  const [openDate, updateOpenDate] = useState<string | undefined>(
    getYMD(new Date())
  );
  const [linkUrl, updateLinkUrl] = useState<string | undefined>(undefined);
  const [portfolioList, updatePortfolioList] = useState<
    SelectData<Portfolio>[]
  >([]);
  const [selectPortfolio, updateSelectPortfolio] = useState<
    Portfolio | undefined
  >(undefined);
  const [category, updateCategory] = useState<NewsCategory | undefined>(
    undefined
  );
  const hpNewsId = useRef<number | undefined>(undefined);
  const isActive = useRef<boolean>(true);
  const getPortfolioList = async () => {
    try {
      const result = await axios.get(api.getHomepagePortfolioList(true));
      if (result.status === 200) {
        const list = result.data.map((item: any): SelectData<Portfolio> => {
          return {
            id: item.portfolioId,
            text: item.companyName,
            data: {
              portfolioId: item.portfolioId,
              companyName: item.companyName,
            },
          };
        });
        updatePortfolioList(list);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getContentDetail = () => {
    if (props.item === undefined) return;

    isActive.current = props.item.isActive;
    hpNewsId.current = props.item.hpNewsId;
    updateSelectPortfolio(props.item.hpPortfolio);
    updateOpenDate(getYMD(props.item.openDate));
    updateLinkUrl(props.item.linkUrl);
    updateCategory(props.item.category);
  };

  const updateNews = async () => {
    try {
      const param = {
        hpNewsId: hpNewsId.current,
        category,
        openDate,
        linkUrl,
        isActive: isActive.current,
        hpPortfolio: { portfolioId: selectPortfolio?.portfolioId },
      };

      axios.put(api.updateNews(), param).then((result) => {
        if (result.status === 200 && props.callback) {
          props.callback();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const createNews = async () => {
    try {
      const param = {
        category,
        openDate,
        linkUrl,
        isActive: isActive.current,
        hpPortfolio: { portfolioId: selectPortfolio?.portfolioId },
      };

      axios.post(api.createNews(), param).then((result) => {
        if (result.status === 201 && props.callback) {
          props.callback();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useLayoutEffect(() => {
    if (props.item !== undefined) {
      getContentDetail();
    }
  }, [props.item]);

  useEffect(() => {
    getPortfolioList();
  }, []);
  return (
    <Container>
      <div className="news__detail__header font-bold-18">
        <span />
        {props.item ? "뉴스 수정" : "뉴스 추가"}
        <DefaultButton
          disabled={!openDate || !linkUrl || !category}
          onClick={() => {
            if (props.item) {
              // 수정
              updateNews();
            } else {
              // 생성
              createNews();
            }
          }}
        >
          {props.item ? "수정하기" : "생성하기"}
        </DefaultButton>
      </div>
      <div className="news__detail__body">
        <div className="news__detail__body__row">
          <FlexColumn style={{ gap: 4 }}>
            <div className="font-bold-14">
              <span>카테고리</span>
            </div>
            <FlexRow style={{ gap: 12 }}>
              {categoryList.map((item) => {
                return (
                  <Chip
                    key={item}
                    isClickable
                    isActive={item === category}
                    onClickChip={() => {
                      updateCategory(item);
                    }}
                  >
                    {item}
                  </Chip>
                );
              })}
            </FlexRow>
          </FlexColumn>
        </div>
        {category === NewsCategory.BLUE_FAMILY && (
          <div className="news__detail__body__row">
            <NotionInputSelecter
              label="포트폴리오"
              hasUnderLine
              editable
              placeholder="포트폴리오를 선택하세요."
              type="single"
              data={portfolioList}
              maxHeight={300}
              selectedItem={portfolioList.filter(
                (item) => item.id === selectPortfolio?.portfolioId
              )}
              onChange={(item: Portfolio[]) => {
                if (item.length > 0) updateSelectPortfolio(item[0]);
              }}
            />
          </div>
        )}
        <div className="news__detail__body__row">
          <NotionInput
            label="게시일"
            hasUnderLine
            editable
            textType="date"
            max="9999-12-31"
            placeholder="연도. 월. 일"
            defaultValue={openDate}
            onChange={(e) => {
              updateOpenDate(e);
            }}
          />
        </div>
        <div className="news__detail__body__row">
          <NotionInput
            label="링크"
            hasUnderLine
            editable
            textType="text"
            defaultValue={linkUrl}
            onChange={(e) => {
              updateLinkUrl(e);
            }}
          />
        </div>
        {props.item && <NewsCard item={props.item} />}
      </div>
    </Container>
  );
};

const Container = styled.div`
  width: 100vw;
  max-width: 780px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  .news__detail__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px 24px;

    border-bottom: 1px solid var(--grey);
  }
  .news__detail__body {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 24px;
    gap: 10px;

    .news__detail__body__row {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;
export default NewsDetail;
