import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import DisplayType from "utils/displayType";

interface Props {
  className?: string;
  label?: string;
  defaultValue?: string;
  placeHolder?: string;
  isError?: boolean;
  errorMessage?: string;
  maxLength?: number;
  onFocus?: () => void;
  onChangeValue?: (value: string) => void;
}
const ContactTextArea: React.FC<Props> = (props) => {
  const mode = DisplayType();
  const [value, updateValue] = useState<string>("");

  const onChangeValue = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    updateValue(e.target.value);
  };

  const onFocus = (e: React.FocusEvent<HTMLTextAreaElement, Element>) => {
    if (props.onFocus) props.onFocus();
  };
  const onBlur = (e: React.FocusEvent<HTMLTextAreaElement, Element>) => {
    if (props.onChangeValue) {
      props.onChangeValue(e.target.value);
    }
  };

  useEffect(() => {
    if (props.defaultValue !== undefined) {
      updateValue(props.defaultValue);
    }
  }, [props.defaultValue]);

  return (
    <Container className={props.className}>
      {props.label && (
        <div
          className={`${
            mode === "Mobile" ? "font-sub-title4" : "font-sub-title4"
          }`}
          style={{ color: "var(--grey-50)" }}
        >
          {props.label}
        </div>
      )}
      <textarea
        className={`${mode === "Mobile" ? "font-body3" : "font-body3"} ${
          props.isError && "input_error"
        }`}
        placeholder={props.placeHolder || "내용을 입력해주세요"}
        value={value}
        maxLength={props.maxLength}
        onChange={onChangeValue}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      {props.isError && (
        <div
          className={`error__msg ${
            mode === "Mobile" ? "font-body4" : "font-body4"
          }`}
        >
          {props.errorMessage || "정확한 값을 입력해주세요."}
        </div>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  textarea {
    width: 100%;
    height: 240px;
    min-height: 240px;
    max-height: 240px;
    background-color: var(--bg-03);
    border: 1px solid var(--grey-00);
    border-radius: 4px;

    padding: 16px;
    color: var(--grey-60);
    resize: none;
    transition: all 0.1s ease;

    :invalid {
      border: 1px solid #e02020;
      background-color: #fdeded;
      animation: shake 300ms;
      :focus {
        outline: none;
      }
    }

    :focus {
      outline: 1px solid var(--primary);
    }

    :disabled {
      background-color: var(--grey-00);
      color: var(--grey-20);
    }

    ::placeholder {
      color: var(--grey-30);
    }
  }

  .input_error {
    border: 1px solid #e02020;
    background-color: #fdeded;
    animation: shake 300ms;
    :focus {
      outline: none;
    }
  }

  .error__msg {
    position: absolute;
    left: 10px;
    bottom: -24px;
    color: #e02020;
    :focus {
      outline: none;
    }
  }

  @media only screen and (max-width: 768px) {
    textarea {
      height: 320px;
      min-height: 320px;
      max-height: 320px;
    }
  }
`;

export default React.memo(ContactTextArea);
