import { useEffect, useLayoutEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import LatestPortfolio from "component/lastest-portfolio/portfolio/LatestPortfolio";
import Footer from "component/footer/Footer";
import HeaderNavigation from "component/header/HeaderNavigation";
import SearchBar from "component/search/SearchBar";
import axios from "axios";
import {
  IndustryTech,
  NavigationMenu,
  PortfolioProps,
  Stage,
} from "admin/type/type";
import PortfolioCard from "admin/router/portfolio/component/PortfolioCard";
import DisplayType from "utils/displayType";
import Banner from "component/banner/Banner";
import ModalLayout from "admin/component/modal/ModalLayout";
import { Pagination } from "@mui/material";
import DefaultButton from "admin/component/button/DefaultButton";
import { ContentStateProps } from "router/insight/Contents";
import { updateTitle } from "utils/commonUtil";
import LatestPortfolio2 from "component/lastest-portfolio/portfolio/LatestPortfolio2";
import * as api from "../../api/api";
import SectorList from "./organism/SectorList";
import StageList from "./organism/StageList";
import PortfolioModal from "./organism/PortfolioModal";

const Portfolio = () => {
  const mode = DisplayType();
  const selectedPortfolioId = useRef<number>(-1);
  const isSearched = useRef<boolean>(false);
  const [portfolioModalOpen, updatePortfolioModalOpen] =
    useState<boolean>(false);
  const [searchKeyword, updateSearchKeyword] = useState<string>("");
  const [selectedIndustryTech, updateSelectedIndustryTech] = useState<
    IndustryTech[]
  >([]);
  const [selectedStage, updateSelectedStage] = useState<Stage[]>([]);
  const [allPortfolioList, setAllPortfolioList] = useState<PortfolioProps[]>(
    []
  );

  const [headerPortfolioList, setHeaderPortfolioList] = useState<
    PortfolioProps[]
  >([]);

  const countPerPage = useRef<number>(mode === "Mobile" ? 8 : 15);
  const [currentPage, updateCurrentPage] = useState<number>(1);
  const [totalPage, updateTotalPage] = useState<number>(0);

  const [portfolioList, setPortfolioList] = useState<PortfolioProps[]>([]);

  const onClickPortfolio = (id: number) => {
    selectedPortfolioId.current = id;
    updatePortfolioModalOpen(true);
  };

  const onSearchPortfolio = () => {
    const filterIndustryId = selectedIndustryTech.map(
      (item) => item.acIndustrialTechId
    );
    const filterStageName = selectedStage.map((item) => item.name);
    let portfolio: PortfolioProps[] = [...allPortfolioList];

    if (filterIndustryId.length === 0) {
      portfolio = [];
    }

    if (filterStageName.length === 0) {
      portfolio = [];
    }

    portfolio = portfolio.filter((item) => {
      const sameIndustry = item.hpPfIndustrialTech.filter((data) =>
        filterIndustryId.includes(data.hpIndustrialTech.acIndustrialTechId)
      );
      return sameIndustry.length > 0;
    });

    portfolio = portfolio.filter((item) => {
      console.log(item);
      console.log(filterStageName);
      return filterStageName.includes(item.stage);
    });

    portfolio = portfolio.filter((item) => {
      return item.companyName
        .replace(/(\s*)/g, "")
        .toUpperCase()
        .includes(searchKeyword.replace(/(\s*)/g, "").toUpperCase());
    });

    setPortfolioList([...portfolio]);
    updateTotalPage(Math.ceil(portfolio.length / countPerPage.current));
  };

  const getStartIndex = () => {
    return mode === "Mobile" ? 0 : (currentPage - 1) * countPerPage.current;
  };

  const storeState = (page?: number) => {
    const state: ContentStateProps = {
      scrollY: window.scrollY,
      currentPage: page || currentPage,
    };
    localStorage.setItem(document.location.pathname, JSON.stringify(state));
  };

  const getPortflioList = async () => {
    try {
      const result = await axios.get(api.getHomepagePortfolioList());
      const headerResult = await axios.get(api.getHeaderPortfolio());
      if (result.status === 200) {
        const headerPortfolio = headerResult.data.map(
          (item: any) => item.portfolioId
        );
        const portfolios = result.data
          .map(
            (item: any): PortfolioProps => ({
              ...item,
            })
          )
          .reverse();

        setAllPortfolioList(portfolios);
        setHeaderPortfolioList(
          headerPortfolio.map((item: any) => {
            return portfolios.find((data: any) => data.portfolioId === item);
          })
        );
        setPortfolioList(portfolios);
        updateTotalPage(Math.ceil(portfolios.length / countPerPage.current));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (searchKeyword.length === 0 && isSearched.current) {
      isSearched.current = false;
      onSearchPortfolio();
    }
  }, [searchKeyword]);

  useEffect(() => {
    updateCurrentPage(1);
  }, [totalPage, portfolioList]);

  useEffect(() => {
    onSearchPortfolio();
  }, [selectedIndustryTech, selectedStage]);

  useEffect(() => {
    getPortflioList();
  }, []);

  useLayoutEffect(() => {
    updateTitle("포트폴리오 소개");
  }, []);

  return (
    <Container isModalOpen={portfolioModalOpen}>
      {mode === "Mobile" && (
        <Banner mode={mode}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div className="font-title-en" style={{ color: "var(--white)" }}>
              PORTFOLIO
            </div>
          </div>
        </Banner>
      )}
      <LastPortfolioContainer className="limit__body">
        <LatestPortfolio
          portfolioList={headerPortfolioList}
          onClickPortfolio={onClickPortfolio}
        />
      </LastPortfolioContainer>
      <PortfolioContainer>
        <PortfolioListContainer className="limit__body">
          <div
            className={`portfolio__label ${
              mode === "Mobile" ? "font-sub-title2-en" : "font-title"
            }`}
          >
            Portfolio
          </div>
          <div className="search">
            <SearchBar
              placeholder="회사명을 입력해주세요."
              onChage={(value) => {
                updateSearchKeyword(value);
              }}
              onSearching={() => {
                isSearched.current = true;
                onSearchPortfolio();
              }}
            />
          </div>
          <div className="sector">
            <SectorList
              allPortfolio={allPortfolioList}
              onChange={(data) => updateSelectedIndustryTech(data)}
            />
          </div>
          <div className="stage">
            <StageList onChange={(data) => updateSelectedStage(data)} />
          </div>

          <div className="portfolio__list">
            {portfolioList
              .slice(getStartIndex(), currentPage * countPerPage.current)
              .map((item) => {
                return (
                  <PortfolioCard
                    key={item.portfolioId}
                    portfolio={item}
                    isViewer
                    onClick={() => {
                      onClickPortfolio(item.portfolioId);
                    }}
                  />
                );
              })}
            {mode === "Mobile" && portfolioList.length % 2 === 1 && (
              <PortfolioCard />
            )}
            {portfolioList.length === 0 && (
              <SearchResultEmpty>
                {mode === "Mobile" ? (
                  <div className="font-body3" style={{ textAlign: "center" }}>
                    {searchKeyword.length > 0 && `"${searchKeyword}"에 대한 `}
                    <br />
                    {"검색 결과가 없습니다."}
                  </div>
                ) : (
                  <span className="font-body3">
                    {searchKeyword.length > 0 && `"${searchKeyword}"에 대한`}
                    {` 검색 결과가 없습니다.`}
                  </span>
                )}
              </SearchResultEmpty>
            )}
          </div>
          {mode !== "Mobile" && totalPage > 0 && (
            <div className="center">
              <Pagination
                count={totalPage}
                shape="rounded"
                page={currentPage}
                onChange={(event, page) => {
                  storeState(page);
                  updateCurrentPage(page);
                }}
              />
            </div>
          )}
        </PortfolioListContainer>
        {mode === "Mobile" && totalPage > 0 && currentPage < totalPage && (
          <div className="center">
            <DefaultButton
              className="font-body3"
              type="outline"
              onClick={() => {
                storeState(currentPage + 1);
                updateCurrentPage(currentPage + 1);
              }}
            >
              포트폴리오 더보기
            </DefaultButton>
          </div>
        )}
      </PortfolioContainer>
      <Footer />

      <ModalLayout isOpen={portfolioModalOpen} borderRadius={0}>
        <PortfolioModal
          portfolioId={selectedPortfolioId.current}
          close={() => {
            selectedPortfolioId.current = -1;
            updatePortfolioModalOpen(false);
          }}
        />
      </ModalLayout>
    </Container>
  );
};

const Container = styled.div<{ isModalOpen: boolean }>`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  padding-top: 80px;
  flex-direction: column;
  background-color: var(--white);
  overflow: ${(props) => (props.isModalOpen ? "hidden" : "scroll")};
  @media only screen and (max-width: 768px) {
    padding-top: 0px;
  }
`;

const LastPortfolioContainer = styled.div`
  width: 100%;
  margin-top: 100px;
  margin-bottom: 82px;

  @media only screen and (max-width: 768px) {
    margin-top: 40px;
    margin-bottom: 40px;
  }
`;

const PortfolioContainer = styled.div`
  flex-grow: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 118px;
  padding-bottom: 160px;
  background-color: var(--bg-02);

  .center {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    padding-bottom: 64px;
  }
  @media only screen and (max-width: 768px) {
    padding-top: 20px;
    padding-bottom: 0px;

    .center {
      background-color: var(--white);
    }
  }
`;

const PortfolioListContainer = styled.div`
  width: 100%;

  .portfolio__label {
    color: var(--black);
    text-transform: uppercase;
  }

  .search {
    margin-top: 64px;
  }
  .sector {
    margin-top: 56px;
  }
  .stage {
    margin-top: 32px;
  }

  .portfolio__list {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 40px;
    column-gap: 24px;

    margin-top: 56px;
    padding-bottom: 64px;
  }

  @media only screen and (max-width: 768px) {
    .search {
      margin-top: 8px;
    }
    .sector {
      margin-top: 40px;
    }
    .stage {
      margin-top: 24px;
    }
    .portfolio__list {
      width: calc(100% + 48px);
      display: flex;
      transform: translateX(-24px);
      padding: 40px 24px 40px 24px;
      background-color: var(--white);
      margin-top: 0px;
      justify-content: center;
      row-gap: 40px;
      column-gap: 7px;
    }
  }
`;

const SearchResultEmpty = styled.div`
  width: 100%;
  height: 100%;
  min-height: 276px;
  max-height: 276px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-top: 1px solid var(--grey-00);
  border-bottom: 1px solid var(--grey-00);
`;
export default Portfolio;
