import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import Footer from "component/footer/Footer";
import HeaderNavigation from "component/header/HeaderNavigation";
import axios from "axios";
import DisplayType from "utils/displayType";
import { EventProps } from "admin/router/event";
import { Link, useLocation } from "react-router-dom";
import { getYMD, updateTitle } from "utils/commonUtil";
import DocumentViewer from "admin/component/editor/DocumentViewer";
import { NavigationMenu } from "admin/type/type";
import GoList from "component/go-list/GoList";
import * as api from "../../api/api";
import { ReactComponent as Arrow } from "../../assets/title_arrow.svg";
import { ReactComponent as Back } from "../../assets/go_back.svg";

const EventDetail = () => {
  const mode = DisplayType();
  const eventId = useLocation().pathname.split("/")[3];
  const today = useRef<Date>(new Date());
  const [event, setEvent] = useState<EventProps | undefined>(undefined);

  const getEvent = async () => {
    try {
      const result = await axios.get(api.getEventDetail(eventId));
      if (result.status === 200) {
        setEvent({ ...result.data });
        updateTitle(result.data.title);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getServerTime = async () => {
    try {
      const result = await axios.get(api.getServerTime());
      today.current = new Date(result.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getServerTime();
    getEvent();
  }, []);

  if (event === undefined) return null;

  return (
    <Container>
      <BodyContainer className="limit__body">
        <span
          className={`open__date ${
            mode === "Mobile" ? "font-sub-title4" : "font-sub-title2"
          }`}
        >
          {getYMD(event?.openDate)}
        </span>
        <div className="event__detail__row">
          <div
            className={`row__left ${
              mode === "Mobile" ? "font-sub-title" : "font-title"
            }`}
          >
            {event.title}
          </div>
          <div className="row__right" />
        </div>
        <Divider />
        <div className="event__detail__row" style={{ flexGrow: 1 }}>
          <div className="row__left">
            <DocumentViewer initialValue={event.content} />
          </div>
          <div className="row__right">
            <div className="event__info">
              {event.eventDate && (
                <div className="event__info__row">
                  <div
                    className={`event__info__title ${
                      mode === "Mobile" ? "font-bold-16" : "font-sub-title4"
                    }`}
                  >
                    일시
                  </div>
                  <span
                    className={`event__info__content ${
                      mode === "Mobile" ? "font-regular-18" : "font-body2"
                    }`}
                  >
                    {getYMD(event.eventDate)}
                  </span>
                </div>
              )}
              {event.place && (
                <div className="event__info__row">
                  <div
                    className={`event__info__title ${
                      mode === "Mobile" ? "font-bold-16" : "font-sub-title4"
                    }`}
                  >
                    장소
                  </div>
                  <span
                    className={`event__info__content ${
                      mode === "Mobile" ? "font-regular-18" : "font-body2"
                    }`}
                  >
                    {event.place}
                  </span>
                </div>
              )}
              {(event.eventStartDate || event.eventEndDate) && (
                <div className="event__info__row">
                  <div
                    className={`event__info__title ${
                      mode === "Mobile" ? "font-bold-16" : "font-sub-title4"
                    }`}
                  >
                    기간
                  </div>
                  <span
                    className={`event__info__content ${
                      mode === "Mobile" ? "font-regular-18" : "font-body2"
                    }`}
                  >
                    {getYMD(event.eventStartDate)} ~{" "}
                    {getYMD(event.eventEndDate)}
                  </span>
                </div>
              )}
              {event.linkUrl && (
                <div className="event__info__row">
                  <div
                    className={`event__info__title ${
                      mode === "Mobile" ? "font-bold-16" : "font-sub-title4"
                    }`}
                  >
                    링크
                  </div>
                  <a
                    className={`event__info__content ${
                      mode === "Mobile" ? "font-regular-18" : "font-body2"
                    }`}
                    href={event.linkUrl}
                    style={{
                      textDecoration: "none",
                      color: "var(--primary)",
                    }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    바로가기
                    <Arrow style={{ transform: "rotate(-45deg)" }} />
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
        <Divider />
        <GoList to={"/insight/events"} />
      </BodyContainer>
      <Footer />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 80px;
  @media only screen and (max-width: 768px) {
    padding-top: 60px;
  }
`;
const BodyContainer = styled.div`
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 100px;
  padding-bottom: 100px;

  .open__date {
    color: var(--grey-20);
  }

  .event__detail__row {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 64px;

    .content {
      width: 100%;
      height: 100%;
    }

    .row__left {
      width: 100%;
      max-width: 776px;
    }
    .row__right {
      width: 100%;
      min-width: 336px;
      max-width: 336px;
    }
  }
  .event__info {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    .event__info__row {
      display: flex;
      flex-direction: row;
      .event__info__title {
        width: 80px;
        min-width: 80px;
        max-width: 80px;
        color: var(--grey-60);
      }
      .event__info__content {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: var(--grey-50);
      }
    }
  }

  @media only screen and (max-width: 768px) {
    padding-top: 36px;
    padding-bottom: 100px;
    .event__detail__row {
      flex-direction: column-reverse;
      gap: 24px;
    }
    .event__info {
      gap: 4px;
      .event__info__row {
        .event__info__title {
          width: 56px;
          min-width: 56px;
          max-width: 56px;
        }
      }
    }
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--grey-00);
  margin-top: 40px;
  margin-bottom: 40px;

  @media only screen and (max-width: 768px) {
    margin-top: 24px;
    margin-bottom: 24px;
  }
`;
export default EventDetail;
