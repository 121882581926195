import React, { useEffect, useRef, useState } from "react";
import ModalLayout from "admin/component/modal/ModalLayout";
import axios from "axios";
import { Button } from "react-bootstrap";
import styled from "@emotion/styled";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import * as api from "../../../api/api";
import Navigation, { Pages } from "../../component/navigation";
import PopupDetail from "./component/PopupDetail";
import PopupCardItem from "./component/PopupCardItem";

export interface PopupProps {
  isImg: boolean;
  idx: number;
  hpPopupId?: number;
  popupImgKey?: string;
  popupImgUrl?: string;
  popupUrl?: string;
  script?: string;
  description?: string;
  startDate?: string;
  endDate?: string;
}
interface PopupOrderProps {
  id: string;
  data: PopupProps;
}

// a little function to help us with reordering the result
const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const PopupAdmin = () => {
  const selectedPopup = useRef<PopupProps | undefined>(undefined);
  const lastIndex = useRef<number>(0);
  const [isOpenPopupDetail, setOpenPopupDetail] = useState<boolean>(false);
  const [isDragDisabled, updateDragDisabled] = useState<boolean>(true);
  const [Popups, updatePopups] = useState<PopupOrderProps[]>([]);

  const openPopupDetail = (Popup?: PopupProps) => {
    selectedPopup.current = Popup;
    setOpenPopupDetail(true);
  };

  const closePopupDetail = () => {
    setOpenPopupDetail(false);
    getPopup();
  };

  const getPopup = async () => {
    try {
      const result = await axios.get(api.getPopupListForAdmin());
      lastIndex.current = 0;

      if (result.status === 200) {
        console.log(result);
        const popupLIst = result.data
          .sort((a: any, b: any) => (a.idx > b.idx ? 1 : -1))
          .map((item: any, index: number) => ({
            id: `popup_${index}`,
            data: item,
          }));

        console.log(popupLIst);

        lastIndex.current = popupLIst[popupLIst.length - 1].data.idx;
        updatePopups([...popupLIst]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updatePopup = async () => {
    try {
      if (Popups.length === 0) return;

      const param = Popups.map((item, index) => ({ ...item.data, idx: index }));
      const result = await axios.put(api.updatePopupAll(), param);
      console.log(result);

      if (result.status === 200) {
        getPopup();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      Popups,
      result.source.index,
      result.destination.index
    );

    updatePopups([...items]);
  };

  const init = async () => {
    try {
      if (await api.checkingToken()) {
        await getPopup();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    init();
  }, []);
  return (
    <Navigation activePageId={Pages[11].id}>
      <Container>
        <div className="Popup__header">
          <div />
          <div>
            <Button
              style={{ marginRight: 20 }}
              variant="primary"
              onClick={() => {
                if (!isDragDisabled) {
                  updatePopup();
                }
                updateDragDisabled((prev) => !prev);
              }}
            >
              {!isDragDisabled ? "순서 저장하기" : "순서 변경하기"}
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                openPopupDetail(undefined);
              }}
            >
              팝업 생성하기
            </Button>
          </div>
        </div>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" direction="horizontal">
            {(provided, snapshot) => {
              return (
                <div
                  className="Popup__body"
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  <div
                    className="popup__body__list"
                    style={{
                      padding: 8,
                      backgroundColor: isDragDisabled ? "white" : "grey",
                    }}
                  >
                    {Popups.map((item, index) => {
                      return (
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                          isDragDisabled={isDragDisabled}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <PopupCardItem
                                key={item.data.hpPopupId}
                                item={item.data}
                                onClickItem={() => openPopupDetail(item.data)}
                                callback={() => closePopupDetail()}
                              />
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                  </div>
                </div>
              );
            }}
          </Droppable>
        </DragDropContext>
      </Container>

      {/* {isOpenPopupOrdering && (
        <ModalLayout
          isOpen={isOpenPopupOrdering}
          borderRadius={0}
          toggle={() => setOpenPopupOrdering(false)}
        >
          <PopupOrdering items={Popups} callback={() => closePopupOrdering()} />
        </ModalLayout>
      )} */}

      <ModalLayout
        isOpen={isOpenPopupDetail}
        borderRadius={0}
        toggle={() => setOpenPopupDetail(false)}
      >
        <PopupDetail
          lastIndex={lastIndex.current}
          item={selectedPopup.current}
          callback={() => closePopupDetail()}
        />
      </ModalLayout>
    </Navigation>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;

  .Popup__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    min-height: 60px;
    max-height: 60px;
    border-bottom: 1px solid grey;
    padding: 0 24px;
  }

  .Popup__body {
    width: 100%;
    height: calc(100vh - 60px);
    padding: 40px;
    overflow: scroll;
    .popup__body__list {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      gap: 10px;
    }
  }
`;
export default PopupAdmin;
