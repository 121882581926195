import React from "react";
import styled from "@emotion/styled";
import DisplayType from "utils/displayType";
import { PopupProps } from "admin/router/popup/PopupAdmin";
import poster from "../../../assets/blue_sync.jpg";

interface Props {
  popupData: PopupProps;
}

const PopupImage: React.FC<Props> = (props) => {
  const mode = DisplayType();

  return (
    <Container>
      <img
        className="poster__img"
        src={props.popupData.popupImgUrl}
        alt={props.popupData.popupUrl}
        style={{
          cursor:
            props.popupData.popupUrl && props.popupData.popupUrl !== ""
              ? "pointer"
              : "default",
        }}
        onClick={() => {
          if (props.popupData.popupUrl && props.popupData.popupUrl !== "") {
            window.open(props.popupData.popupUrl);
          }
        }}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 500px;
  height: calc(740px - 100px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .poster__img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @media only screen and (max-width: 768px) {
    width: 300px;
    height: calc(540px - 100px);
    .poster__img {
      width: 300px;
      height: calc(540px - 100px);
      object-fit: cover;
    }
  }
`;

export default PopupImage;
