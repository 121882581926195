import styled from "@emotion/styled";
import DisplayType from "utils/displayType";
import DefaultButton from "admin/component/button/DefaultButton";
import { useLayoutEffect } from "react";
import { updateTitle } from "utils/commonUtil";
import Footer from "../../component/footer/Footer";
import { ReactComponent as Logo } from "../../assets/logo_alumni.svg";
import logo from "../../assets/logo_alumni.jpg";

const Alumni = () => {
  const mode = DisplayType();

  useLayoutEffect(() => {
    updateTitle("동창");
  }, []);

  return (
    <Container>
      <HeaderContainer>
        {/* <Logo className="program__logo" /> */}
        <img className="program__logo" src={logo} alt="프로그램 로고" />
        <span
          className={`program__title ${
            mode === "Mobile" ? "font-bold-24" : "font-title"
          }`}
        >
          동창
        </span>
        <p
          className={`program__description ${
            mode === "Mobile" ? "font-body3" : "font-body2"
          }`}
        >
          그룹형 초기 스타트업 배치 프로그램으로, {mode === "Mobile" && <br />}
          선발된 팀들은{mode !== "Mobile" && <br />}
          서로가 {`'동창'`}이 되어{mode === "Mobile" && <br />} Peer-learning을
          통해 함께 성장합니다.
        </p>
      </HeaderContainer>
      <BodyContainer className="limit__body">
        <ProgramDescription>
          <div
            className={`program__description__title ${
              mode === "Mobile" ? "font-sub-title2" : "font-title"
            }`}
          >
            同創, 함께할 동, 시작할 창: 함께 시작하다
          </div>
          <p className={`${mode === "Mobile" ? "font-body3" : "font-body2"}`}>
            빠르게 변하는 시장에서 혼자 뛰는 스타트업은 쉽게 지치고 외롭습니다.
            동창은 스타트업 간 상호 경쟁이 아닌 Peer-learning(동료 간 학습)을
            통해 함께 성장하는 데 초점을 맞추며, 프로그램 전담팀이 스타트업의
            일원이 된 것처럼 문제를 푸는 여정을 함께합니다. <br />
            <br />
            오직 블루포인트 패밀리만을 위한 프로그램으로서 공통 교육과 오피스
            아워, 기술 및 산업 전문가 연결을 통한 사업성 검증, 사업 고도화에
            집중합니다. 과정 후반부에는 IR 데이, 투자사 네트워킹을 통해 후속투자
            유치를 지원합니다. 함께 선발된 팀들은 이름 그대로 서로가 {`'동창'`}
            이 되어 상호보완하며 성장해갈 수 있습니다.
          </p>
        </ProgramDescription>
        <ProgramProcess>
          <div
            className={`program__process__title ${
              mode === "Mobile" ? "font-bold-22" : "font-sub-title"
            }`}
          >
            프로그램 과정
          </div>
          <div className={`program__process__row`}>
            <span
              className={`${
                mode === "Mobile" ? "font-bold-16" : "font-bold-18"
              }`}
            >
              설명
            </span>
            <span
              className={`${mode === "Mobile" ? "font-body3" : "font-body2"}`}
            >
              초기 스타트업을 발굴해서 투자 및 지원하는 액셀러레이팅 프로그램
            </span>
          </div>
          <div className={`program__process__row`} style={{ marginTop: 8 }}>
            <span
              className={`${
                mode === "Mobile" ? "font-bold-16" : "font-bold-18"
              }`}
            >
              대상
            </span>
            <span
              className={`${mode === "Mobile" ? "font-body3" : "font-body2"}`}
            >
              예비/초기 단계의 스타트업 (사업분야 제한 없음)
            </span>
          </div>
          <div className="program__process__list">
            <ProgramProcessItem>
              <div className="program__process__item__column">
                <span
                  className={`${
                    mode === "Mobile" ? "font-sub-title2" : "font-sub-title"
                  }`}
                  style={{ color: "var(--primary)" }}
                >
                  Step. 1
                </span>
                <span
                  className={`${
                    mode === "Mobile" ? "font-body3" : "font-body2"
                  }`}
                  style={{ color: "var(--primary)" }}
                >
                  1달
                </span>
              </div>
              <div className="program__process__item__column">
                <span
                  className={`item__title ${
                    mode === "Mobile" ? "font-body3" : "font-body2"
                  }`}
                >
                  지원서 접수
                </span>
                <span
                  className={`item__descrition ${
                    mode === "Mobile" ? "font-body3" : "font-body2"
                  }`}
                >
                  팀 소개 영상, 블루포인트(홈페이지에 양식 有)
                </span>
              </div>
            </ProgramProcessItem>

            <ProgramProcessItem>
              <div className="program__process__item__column">
                <span
                  className={`${
                    mode === "Mobile" ? "font-sub-title2" : "font-sub-title"
                  }`}
                  style={{ color: "var(--primary)" }}
                >
                  Step. 2
                </span>
                <span
                  className={`${
                    mode === "Mobile" ? "font-body3" : "font-body2"
                  }`}
                  style={{ color: "var(--primary)" }}
                >
                  20일
                </span>
              </div>
              <div className="program__process__item__column">
                <span
                  className={`item__title ${
                    mode === "Mobile" ? "font-body3" : "font-body2"
                  }`}
                >
                  인터뷰
                </span>
              </div>
            </ProgramProcessItem>

            <ProgramProcessItem>
              <div className="program__process__item__column">
                <span
                  className={`${
                    mode === "Mobile" ? "font-sub-title2" : "font-sub-title"
                  }`}
                  style={{ color: "var(--primary)" }}
                >
                  Step. 3
                </span>
                <span
                  className={`${
                    mode === "Mobile" ? "font-body3" : "font-body2"
                  }`}
                  style={{ color: "var(--primary)" }}
                >
                  1일
                </span>
              </div>
              <div className="program__process__item__column">
                <span
                  className={`item__title ${
                    mode === "Mobile" ? "font-body3" : "font-body2"
                  }`}
                >
                  동창 찾기
                </span>
                <span
                  className={`item__descrition ${
                    mode === "Mobile" ? "font-body3" : "font-body2"
                  }`}
                >
                  면접전형 합격팀이 한자리에 모여 서로 소개하는 시간을 갖고
                  네트워킹 및 그룹 미션을 수행하는 최종 선발 전형{" "}
                </span>
              </div>
            </ProgramProcessItem>

            <ProgramProcessItem>
              <div className="program__process__item__column">
                <span
                  className={`${
                    mode === "Mobile" ? "font-sub-title2" : "font-sub-title"
                  }`}
                  style={{ color: "var(--primary)" }}
                >
                  Step. 4
                </span>
                <span
                  className={`${
                    mode === "Mobile" ? "font-body3" : "font-body2"
                  }`}
                  style={{ color: "var(--primary)" }}
                >
                  1달
                </span>
              </div>
              <div className="program__process__item__column">
                <span
                  className={`item__title ${
                    mode === "Mobile" ? "font-body3" : "font-body2"
                  }`}
                >
                  최종 선발 및 투자 계약
                </span>
              </div>
            </ProgramProcessItem>

            <ProgramProcessItem>
              <div className="program__process__item__column">
                <span
                  className={`${
                    mode === "Mobile" ? "font-sub-title2" : "font-sub-title"
                  }`}
                  style={{ color: "var(--primary)" }}
                >
                  Step. 5
                </span>
                <span
                  className={`${
                    mode === "Mobile" ? "font-body3" : "font-body2"
                  }`}
                  style={{ color: "var(--primary)" }}
                >
                  1년
                </span>
              </div>
              <div className="program__process__item__column">
                <span
                  className={`item__title ${
                    mode === "Mobile" ? "font-body3" : "font-body2"
                  }`}
                >
                  프로그램 참여
                </span>
                <span
                  className={`item__descrition ${
                    mode === "Mobile" ? "font-body3" : "font-body2"
                  }`}
                >
                  단체 교육, 오피스 아워, 네트워킹, IR 데이 등 12개월 과정
                </span>
              </div>
            </ProgramProcessItem>
          </div>
        </ProgramProcess>
        <ProgramProcess>
          <div
            className={`program__process__title ${
              mode === "Mobile" ? "font-bold-22" : "font-sub-title"
            }`}
          >
            지원 / 컨택 방법
          </div>
          <div
            className={`item__descrition ${
              mode === "Mobile" ? "font-body3" : "font-body2"
            }`}
            style={{ marginBottom: mode === "Mobile" ? 24 : 16 }}
          >
            모집시기 추후 홈페이지 공고 예정
          </div>
          <a
            href="https://www.dongchang.blue/"
            target={"_blank"}
            style={{ textDecoration: "none" }}
            rel="noreferrer"
          >
            <DefaultButton
              className="font-body3"
              type="outline"
              width={190}
              height={42}
              isHover
            >
              동창 홈페이지 바로가기
            </DefaultButton>
          </a>
        </ProgramProcess>
      </BodyContainer>
      <Footer />
    </Container>
  );
};
const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 80px;

  @media only screen and (max-width: 768px) {
    padding-top: 60px;
  }
`;

const HeaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--bg-02);
  padding-top: 80px;
  padding-bottom: 80px;

  .program__logo {
    width: 240px;
    min-width: 240px;
    max-width: 240px;
    height: 240px;
    min-width: 240px;
    max-width: 240px;
    border-radius: 50%;
    border: 1px solid #e5e5ea;

    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
  .program__title {
    color: var(--black);
    margin-top: 24px;
    margin-bottom: 16px;
  }
  .program__description {
    color: var(--grey-40);
    text-align: center;
  }

  @media only screen and (max-width: 768px) {
    padding-bottom: 60px;
    .program__logo {
      width: 117px;
      min-width: 117px;
      max-width: 117px;
      height: 117px;
      min-width: 117px;
      max-width: 117px;
    }
    .program__title {
      color: var(--black);
      margin-top: 24px;
      margin-bottom: 8px;
    }
  }
`;

const BodyContainer = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  margin-bottom: 160px;
`;

const ProgramDescription = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  margin-top: 100px;

  .program__description__title {
    color: var(--primary);
    margin-bottom: 24px;
  }

  @media only screen and (max-width: 768px) {
    margin-top: 40px;
    .program__description__title {
      margin-bottom: 16px;
    }
  }
`;

const ProgramProcess = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  margin-top: 100px;

  .program__process__title {
    color: var(--black);
    margin-bottom: 24px;
  }

  .program__process__row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
  }

  .program__process__list {
    width: 100%;
    border-top: 1px solid var(--grey-00);
    display: flex;
    flex-direction: column;
    margin-top: 24px;
  }
  @media only screen and (max-width: 768px) {
    margin-top: 40px;
    .program__process__title {
      margin-bottom: 16px;
    }
    .program__process__row {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
    }
  }
`;

const ProgramProcessItem = styled.div`
  width: 100%;
  padding: 32px;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid var(--grey-00);
  gap: 312px;
  .program__process__item__column {
    display: flex;
    flex-direction: column;
    min-width: 100px;
  }
  .item__title {
    color: var(--primary);
  }
  .item__descrition {
    color: var(--grey-60);
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    gap: 8px;
    padding: 24px 16px;
  }
`;

const EmailContainer = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  .email__icon {
    width: 32px;
    height: 32px;
  }
  .email__text {
    color: var(--primary);
  }
`;
export default Alumni;
