import React, { useEffect, useRef, useState } from "react";
import ModalLayout from "admin/component/modal/ModalLayout";
import axios from "axios";
import { Button } from "react-bootstrap";
import { PortfolioProps } from "admin/type/type";
import { Bounce, toast } from "react-toastify";
import { showCustomToast } from "utils/commonUtil";
import * as api from "../../../api/api";
import Navigation, { Pages } from "../../component/navigation";
import { PortfolioLayout } from "./style";
import PortfolioList from "./organism/PortfolioList";
import PortfolioCompareModal from "./organism/PortfolioCompareModal";

const PortfolioAdmin = () => {
  const selectedPortfolio = useRef<number[]>([]);
  const [hasUpdateInfo, setUpdateInfo] = useState<boolean>(false);
  const [isOpenPortfolioDetail, setOpenPortfolioDetail] =
    useState<boolean>(false);
  const [portfolios, updatePortfolio] = useState<PortfolioProps[]>([]);

  const openPortfolioDetail = (portfolioIds: number[]) => {
    selectedPortfolio.current = portfolioIds;
    setOpenPortfolioDetail(true);
  };

  const closePortfolioDetail = () => {
    setOpenPortfolioDetail(false);
    getHomepagePortfolio();
  };

  const getReLoadingLatticePortfolio = async () => {
    try {
      const result = await axios.put(api.reloadPortfolio());
      if (result.status === 200) {
        getHomepagePortfolio();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getHomepagePortfolio = async () => {
    try {
      const result = await axios.get(api.getHomepagePortfolioList(true));
      if (result.status === 200) {
        const homepagePOrtfolioList: PortfolioProps[] = result.data.map(
          (item: any) => {
            return { ...item };
          }
        );
        updatePortfolio([...homepagePOrtfolioList]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const portfolioChangeActive = async (id: number, active: boolean) => {
    try {
      const portfolio = portfolios.find((item) => item.portfolioId === id);

      if (!portfolio) return;

      const result = await axios.patch(
        api.patchHomepagePortfolioActiveStatus(portfolio.portfolioId),
        {
          active,
        }
      );
      console.log(result);
      if (result.status === 200) {
        showCustomToast({
          customElement: (
            <div>
              <div>{portfolio.companyName}을</div>
              <div>{active ? "활성화" : "비활성화"} 하였습니다.</div>
            </div>
          ),
          backgroundColor: "#61DFAE",
          color: "#FFFFFF",
          width: 300,
          height: 60,
          milliSec: 1500,
          transition: Bounce,
          position: "bottom-center",
          toastId: new Date().toString(),
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const init = async () => {
    try {
      if (await api.checkingToken()) {
        await getHomepagePortfolio();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    init();
  }, []);
  return (
    <Navigation activePageId={Pages[1].id}>
      <PortfolioLayout>
        <div className="portfolio__header">
          <span />
          <Button variant="primary" onClick={getReLoadingLatticePortfolio}>
            포트폴리오 갱신하기
          </Button>
        </div>
        <div className="portfolio__body">
          <div className="btn__container">
            <Button
              disabled={
                portfolios.filter(
                  (item) =>
                    item.preLatticePortfolioUpdatedAt === null ||
                    item.preLatticePortfolioUpdatedAt !==
                      item.curLatticePortfolioUpdatedAt
                ).length === 0
              }
              onClick={() => {
                const portfolioIds = portfolios
                  .filter(
                    (item) =>
                      item.preLatticePortfolioUpdatedAt === null ||
                      item.preLatticePortfolioUpdatedAt !==
                        item.curLatticePortfolioUpdatedAt
                  )
                  .map((item) => item.portfolioId);
                if (portfolioIds.length === 0) return;
                openPortfolioDetail(portfolioIds);
              }}
            >
              업데이트 모아보기
            </Button>
          </div>
          <PortfolioList
            portfolios={portfolios}
            openPortfolioDetail={openPortfolioDetail}
            onPortfolioChangeActive={portfolioChangeActive}
          />
        </div>
        <ModalLayout
          isOpen={isOpenPortfolioDetail}
          toggle={closePortfolioDetail}
        >
          <PortfolioCompareModal
            portfolioId={selectedPortfolio.current}
            close={closePortfolioDetail}
          />
        </ModalLayout>
      </PortfolioLayout>
    </Navigation>
  );
};
export default PortfolioAdmin;
