import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import DaumPostcode from "react-daum-postcode";
import { Address } from "admin/type/type";
import ModalLayout from "admin/component/modal/ModalLayout";
import { Button } from "react-bootstrap";

interface DaumModalProps {
  open: boolean;
  setValue: (value: Address) => void;
  toggle: () => void;
}
const DaumModal: React.FC<DaumModalProps> = ({ open, setValue, toggle }) => {
  const [address, setAddress] = useState<Address | undefined>(undefined);
  const [detailAddress, updateDetailAddress] = useState<string>("");
  const handleComplete = (data: any) => {
    console.log(data);

    if (data.sigungu.length > 0) {
      let fullAddress = data.address;
      let extraAddress = "";
      const addr3Index =
        fullAddress.indexOf(data.sigungu) + data.sigungu.length + 1;

      if (data.addressType === "R") {
        if (data.bname !== "") {
          extraAddress += data.bname;
        }
        if (data.buildingName !== "") {
          extraAddress +=
            extraAddress !== "" ? `${data.buildingName}` : data.buildingName;
        }
        fullAddress += extraAddress !== "" ? `(${extraAddress})` : "";
      }

      const addr: Address = {
        addr1: data.sido,
        addr2: data.sigungu,
        addr3: fullAddress.slice(addr3Index),
        fullAddr: fullAddress,
      };
      setAddress(addr);
    } else {
      const addr: Address = {
        addr1: data.address.split(" ")[0],
        addr2: data.address.split(" ")[1],
        addr3: data.address.split(" ").slice(2).join(" "),
        fullAddr: data.address,
      };
      setAddress(addr);
    }
  };

  const onSubmit = () => {
    const addr: Address = { ...address, addrDetail: detailAddress };
    setValue(addr);
    toggle();
  };

  useEffect(() => {
    setAddress(undefined);
    updateDetailAddress("");
  }, [open]);

  return (
    <ModalLayout isOpen={open} toggle={toggle}>
      <Container>
        <div className="daum__header">
          <span className="font-medium-20">주소를 입력해주세요.</span>
        </div>
        <div className="daum__body">
          {address === undefined ? (
            <DaumPostcode onComplete={handleComplete} />
          ) : (
            <div className="address__container">
              <span className="font-bold-14">주소</span>
              <span
                className="font-regular-14"
                style={{ marginTop: 10, paddingLeft: 10 }}
              >{`${address.addr1} ${address.addr2} ${address.addr3}`}</span>
              <input
                className="detail__address font-regular-14"
                type="text"
                placeholder="상세주소입력"
                value={detailAddress}
                onChange={(e) => updateDetailAddress(e.target.value)}
              />
            </div>
          )}
        </div>
        <div className="daum__footer">
          <Button onClick={toggle}>취소</Button>
          {address !== undefined && <Button onClick={onSubmit}>완료</Button>}
        </div>
      </Container>
    </ModalLayout>
  );
};
const Container = styled.div`
  width: 480px;
  height: 762px;
  display: flex;
  flex-direction: column;
  .daum__header {
    width: 100%;
    display: flex;
    align-items: center;
    height: 56px;
    max-height: 56px;
    min-height: 56px;
    padding: 0 24px;
    border-bottom: 1px solid var(--grey-20);
  }
  .daum__body {
    flex-grow: 1;
    .address__container {
      display: flex;
      flex-direction: column;
      padding: 24px;
      .detail__address {
        border-radius: 8px;
        margin-top: 4px;
        outline: none;
        padding: 6px 10px;
      }
    }
    div {
      height: 100% !important;
    }
  }
  .daum__footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding: 0 24px;
    gap: 24px;
    border-top: 1px solid var(--grey-20);

    height: 74px;
    max-height: 74px;
    min-height: 74px;
  }
`;
export default DaumModal;
