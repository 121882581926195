import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { getYMD } from "utils/commonUtil";
import axios from "axios";
import ModalLayout from "admin/component/modal/ModalLayout";
import ConfirmModal from "admin/component/modal/ConfirmModal";
import { ReactComponent as Trash } from "../../../../assets/trash_can.svg";
import Toggle from "../../../component/toggle";
import { PopupProps } from "../PopupAdmin";
import * as api from "../../../../api/api";

interface Props {
  item: PopupProps;
  showMenu?: boolean;
  onClickItem?: () => void;
  callback?: () => void;
}

const PopupCardItem: React.FC<Props> = (props) => {
  const [bannerItem, updateBannerItem] = useState<PopupProps>(props.item);
  const [deleteConfirmOpen, updateDeleteConfirmOpen] = useState<boolean>(false);
  const onClickContent = () => {
    if (props.onClickItem) {
      props.onClickItem();
    }
  };

  const onClickDelete = async () => {
    try {
      if (props.item.hpPopupId === undefined) return;

      const result = await axios.delete(api.deletePopup(props.item.hpPopupId));
      if (result.status === 200 && props.callback) {
        updateDeleteConfirmOpen(false);
        props.callback();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onClickToggle = (changeSate: boolean) => {};

  useEffect(() => {
    updateBannerItem(props.item);
  }, [props.item]);

  if (!bannerItem) return null;

  return (
    <Container onClick={onClickContent}>
      <div className="banner__content__container">
        <div className="banner__content__row">
          <div className="font-bold-12 banner__content__row__title">제목</div>
          <div className="font-regular-14 banner__content__row__content">
            {bannerItem.description}
          </div>
        </div>
        <div className="banner__content__row">
          <div className="font-bold-12 banner__content__row__title">시작일</div>
          <div className="font-regular-14 banner__content__row__content">
            {getYMD(bannerItem.startDate)}
          </div>
        </div>
        <div className="banner__content__row">
          <div className=" font-bold-12 banner__content__row__title">
            종료일
          </div>
          <div className="font-regular-14 banner__content__row__content">
            {getYMD(bannerItem.endDate)}
          </div>
        </div>
        <div className="banner__content__row">
          <div className="font-bold-12 banner__content__row__title">
            자료유형
          </div>
          <div className="font-regular-14 banner__content__row__content">
            {bannerItem.isImg ? "이미지" : "에디터"}
          </div>
        </div>

        <div className="banner__content__row">
          <div className=" font-bold-12 banner__content__row__title">
            연결링크
          </div>
          <div className="font-regular-14">{bannerItem.popupUrl || "없음"}</div>
        </div>
      </div>
      <MenuContainer className="menu__controller">
        <Trash
          className="icon"
          onClick={(e) => {
            e.stopPropagation();
            updateDeleteConfirmOpen(true);
          }}
        />
        <Toggle isEditable />
      </MenuContainer>

      <ModalLayout isOpen={deleteConfirmOpen}>
        <ConfirmModal
          type="confirm"
          message="선택하신 팝업을 삭제 하시겠습니까?"
          onClickNegativeBtn={() => updateDeleteConfirmOpen(false)}
          onClickPositiveBtn={onClickDelete}
        />
      </ModalLayout>
    </Container>
  );
};

const Container = styled.li`
  position: relative;
  width: auto;
  height: fit-content;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border: 1px solid rgba(55, 53, 47, 0.16);
  padding: 24px;
  background-color: white;
  .banner__content__container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    text-align: left;
    .banner__content__row {
      display: flex;
      flex-direction: column;
      gap: 4px;
      .banner__content__row__title {
        width: 100px;
        text-align: start;
      }
      .banner__content__row__content {
        text-align: end;
      }
    }

    .banner__link {
      margin-top: 6px;
      line-height: 16px;
      color: rgb(55, 53, 47);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  :hover {
    .menu__controller {
      display: flex;
    }
  }
`;

const MenuContainer = styled.div`
  position: absolute;
  min-width: 36px;
  height: 36px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  gap: 8px;
  padding: 0 8px;
  top: 0;
  right: 0;
  transform: translate(20%, -50%);
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid grey;
  z-index: 10;

  .icon {
    width: 20px;
    min-width: 20px;

    height: 20px;
    min-height: 20px;
    max-height: 20px;
    svg {
      fill: white;
    }
  }
`;
export default React.memo(PopupCardItem);
