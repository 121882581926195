import React, { useRef } from "react";
import styled from "@emotion/styled";
import { Editor } from "@tinymce/tinymce-react";
import axios from "axios";
import { imgCategory } from "admin/type/type";
import * as api from "../../../api/api";

interface Props {
  isEditable: boolean;
  className?: string;
  initialValue?: string;
  category: imgCategory;
  onEditorChange?: (html: string, text: string) => void;
}

const DocumentEditor: React.FC<Props> = (props) => {
  const { className = "" } = props;
  const editorRef = useRef<any>(null);
  const key = process.env.REACT_APP_TINY_MCE_KEY;

  return (
    <EditorContainer className={className}>
      <Editor
        ref={editorRef}
        apiKey={key}
        initialValue={props.initialValue}
        disabled={!props.isEditable}
        init={{
          language: "ko_KR",
          placeholder: "내용을 입력해주세요",
          height: "100%",
          skin: "borderless",
          menubar: false,
          statusbar: false,
          plugins: [
            "advlist autolink lists link image",
            "charmap print preview anchor help",
            "searchreplace visualblocks code",
            "insertdatetime media table paste wordcount",
          ],
          toolbar:
            "undo redo | bold italic underline strikethrough | \
            fontselect fontsizeselect | alignleft aligncenter alignright alignjustify | \
            outdent indent |  numlist bullist checklist | forecolor | charmap emoticons | preview | image media link | help",
          file_picker_types: "image",
          file_picker_callback: (cb: any, value: any, meta: any) => {
            if (meta.filetype === "image") {
              const input = document.createElement("input");
              input.setAttribute("type", "file");
              input.setAttribute("accept", "image/*");

              input.onchange = async (e) => {
                if (!input.files) return;

                const file = input.files[0];
                const formData = new FormData();
                formData.append("file", file);
                formData.append("category", props.category);

                const result = (
                  await axios.post(api.uploadAndGetUrl(), formData)
                ).data;
                if (result.length > 0) {
                  cb(result[0].url, { alt: file.name });
                }
              };
              input.click();
            }
          },

          images_upload_handler: async (
            blobInfo: any,
            success: any,
            failure: any
          ) => {
            try {
              const file = blobInfo.blob();
              const formData = new FormData();
              formData.append("file", file);
              formData.append("category", props.category);

              const result = (await axios.post(api.uploadAndGetUrl(), formData))
                .data;
              if (result.length > 0) {
                success(result[0].url);
              }
            } catch (error) {
              console.log(error);
            }
          },
          toolbar_mode: "floating",
        }}
        onEditorChange={(text: string, editor: any) => {
          if (props.onEditorChange) {
            props.onEditorChange(
              editor.getContent({ format: "html" }),
              editor.getContent({ format: "text" })
            );
          }
        }}
      />
    </EditorContainer>
  );
};

const EditorContainer = styled.div`
  width: 100%;
  height: 100%;

  .tox-tinymce {
    height: 100%;
    border: 1 solid black;
    border-radius: 10px;
    box-shadow: none;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    overflow: hidden;
    position: relative;
    visibility: inherit !important;
  }
`;

export default DocumentEditor;
