import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import Footer from "component/footer/Footer";
import axios from "axios";
import DisplayType from "utils/displayType";
import { useLocation } from "react-router-dom";
import { getYMD, updateTitle } from "utils/commonUtil";
import DocumentViewer from "admin/component/editor/DocumentViewer";
import { ContentProps } from "admin/router/content";
import GoList from "component/go-list/GoList";
import Label from "component/label/Label";
import * as api from "../../api/api";

const ContentsDetail = () => {
  const mode = DisplayType();
  const contentId = useLocation().pathname.split("/")[3];
  const [content, setContent] = useState<ContentProps | undefined>(undefined);

  const getContent = async () => {
    try {
      const result = await axios.get(api.getContentDetail(contentId));
      if (result.status === 200) {
        setContent({ ...result.data });
        updateTitle(result.data.title);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getContent();
  }, []);

  if (content === undefined) return null;

  return (
    <Container>
      <BodyContainer className="limit__body">
        <div className="contents__detail__title__wrapper">
          <span
            className={`${
              mode === "Mobile" ? "font-sub-title4-en" : "font-sub-title2"
            } open__date`}
          >
            {getYMD(content?.openDate)}
          </span>
          <div
            className={`contents__detail__title ${
              mode === "Mobile" ? "font-sub-title" : "font-title"
            }`}
          >
            {content.title}
          </div>
          <Label labelType="type2">{content.category}</Label>
          <Divider />
        </div>
        <div className="content__detail__row" style={{ flexGrow: 1 }}>
          <DocumentViewer initialValue={content.contents} />
        </div>
        <div className="contents__detail__title__wrapper">
          <Divider />
          <GoList to={"/insight/contents"} />
        </div>
      </BodyContainer>
      <Footer />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 80px;
  @media only screen and (max-width: 768px) {
    padding-top: 60px;
  }
`;
const BodyContainer = styled.div`
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 100px;
  padding-bottom: 100px;

  .contents__detail__title__wrapper {
    width: 100%;
    max-width: 816px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
  }

  .open__date {
    color: var(--grey-20);
  }

  .contents__detail__title {
    color: var(--black);
    margin-top: 4px;
    margin-bottom: 12px;
  }

  .content__detail__row {
    width: 100%;
    max-width: 816px;
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    gap: 64px;
  }

  @media only screen and (max-width: 768px) {
    padding-top: 36px;
    padding-bottom: 100px;
    .content__detail__row {
      gap: 24px;
    }
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--grey-00);
  margin-top: 40px;
  margin-bottom: 40px;

  @media only screen and (max-width: 768px) {
    margin-bottom: 24px;
  }
`;
export default ContentsDetail;
