import styled from "@emotion/styled";

export const PortfolioLayout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  .portfolio__header {
    width: 100%;
    height: 68px;
    min-height: 68px;
    max-height: 68px;
    padding: 0px 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--border-grey);
  }

  .portfolio__body {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .btn__container {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      padding: 24px;
    }
  }
`;
