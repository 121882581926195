import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import Search from "../../assets/search.svg";

interface Props {
  placeholder?: string;
  initValue?: string;
  onChage?: (value: string) => void;
  onSearching: () => void;
}
const SearchBar: React.FC<Props> = (props) => {
  const [value, updateValue] = useState<string>("");
  useEffect(() => {
    if (props.initValue && props.initValue.length > 0) {
      updateValue(props.initValue);
    }
  }, [props.initValue]);
  return (
    <Container>
      <input
        className="font-body2"
        type={"text"}
        placeholder={props.placeholder || "검색어를 입력해주세요."}
        value={value}
        onChange={(e) => {
          updateValue(e.target.value);
          if (props.onChage) props.onChage(e.target.value);
        }}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            props.onSearching();
            e.currentTarget.blur();
          }
        }}
      />
      <img
        className="icon"
        src={Search}
        alt="search icon"
        onClick={props.onSearching}
      />
      {/* <Search
        className="icon"
        width={32}
        height={32}
        onClick={props.onSearching}
      /> */}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 480px;
  max-height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 2px solid var(--primary);

  input {
    flex-grow: 1;
    height: 100%;
    margin-bottom: 8px;
    padding-right: 42px;
    border: 0;
    color: var(--black);
    background-color: transparent;
    :focus {
      outline: none;
    }
    ::placeholder {
      color: var(--grey-30);
    }
  }

  .icon {
    position: absolute;
    right: 0;
    width: 32px;
    height: 32px;
    margin-bottom: 8px;
    cursor: pointer;
  }
`;

export default SearchBar;
