import React, { useEffect, useRef, useState } from "react";

import { Button } from "react-bootstrap";
import { ContentCategory } from "admin/type/type";
import axios from "axios";
import { getYMD, sortingByOpenDate } from "utils/commonUtil";
import Navigation, { Pages } from "../../component/navigation";
import { ContentsLayout, ContentsList } from "./styles";
import ContentCard from "./component/ContentCard";
import SearchBar from "../../component/search";
import ModalLayout from "../../component/modal/ModalLayout";
import ContentDetail from "./component/ContentDetail";
import * as api from "../../../api/api";

export interface ContentProps {
  hpContentId?: number;
  thumbNailImgKey?: string;
  thumbNailImgUrl?: string;
  title?: string;
  openDate?: string;
  contents?: string;
  description?: string;
  category: ContentCategory;
  isActive: boolean;
}
const Contents = () => {
  const [contentsList, updateContentList] = useState<ContentProps[]>([]);
  const [isContentDetailModal, updateContentDetailModal] =
    useState<boolean>(false);
  const selectedContentData = useRef<ContentProps | undefined>(undefined);
  const [keyword, updateKeyword] = useState<string>("");

  const onShowContentDetail = (data?: ContentProps) => {
    selectedContentData.current = data;
    updateContentDetailModal(true);
  };

  const getContentList = async () => {
    try {
      const result = await axios.get(api.getContentList());
      if (result.status === 200) {
        updateContentList(
          result.data.sort(sortingByOpenDate).map(
            (item: any): ContentProps => ({
              ...item,
              openDate: getYMD(item.openDate),
            })
          )
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const init = async () => {
    try {
      if (await api.checkingToken()) {
        await getContentList();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <Navigation activePageId={Pages[4].id}>
      <ContentsLayout>
        <div className="contents__header">
          <SearchBar
            onChangeKeyword={(keyword) => {
              updateKeyword(keyword);
            }}
          />
          <Button
            variant="primary"
            onClick={() => onShowContentDetail(undefined)}
          >
            컨텐츠 생성하기
          </Button>
        </div>
        <div className="contents__body">
          <ContentsList>
            {contentsList
              .filter(
                (item) =>
                  item.title?.toUpperCase().includes(keyword.toUpperCase()) ||
                  item.description
                    ?.toUpperCase()
                    .includes(keyword.toUpperCase())
              )
              .map((item) => (
                <ContentCard
                  key={item.hpContentId}
                  item={item}
                  onClickItem={() => {
                    onShowContentDetail(item);
                  }}
                  callback={() => {
                    getContentList();
                  }}
                />
              ))}
          </ContentsList>
        </div>
        <ModalLayout
          isOpen={isContentDetailModal}
          toggle={() => updateContentDetailModal(false)}
        >
          <ContentDetail
            item={selectedContentData.current}
            callback={() => {
              updateContentDetailModal(false);
              getContentList();
            }}
          />
        </ModalLayout>
      </ContentsLayout>
    </Navigation>
  );
};
export default Contents;
