import React from "react";
import styled from "@emotion/styled";
import { CompanyProjectType } from "admin/type/type";
import DisplayType from "utils/displayType";

interface Props {
  project: CompanyProjectType;
}
const ProjectItem: React.FC<Props> = (props) => {
  const mode = DisplayType();
  return (
    <Container>
      <img
        className="thumbnail"
        src={props.project.projectImgUrl || ""}
        alt="project img"
      />
      <div className="project__infomation">
        <div
          className={`project__infomation__title ${
            mode === "Mobile" ? "font-sub-title2" : "font-sub-title4"
          }`}
        >
          {props.project.description}
        </div>
        <div
          className={`project__infomation__introduction ${
            mode === "Mobile" ? "font-body4" : "font-body2"
          }`}
        >
          {props.project.summary}
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  border: 1px solid var(--grey-00);

  .thumbnail {
    width: 100%;
    max-width: 280px;
    height: 100%;
    max-height: 212px;
  }
  .project__infomation {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 40px;
    border-left: 1px solid var(--grey-00);
    .project__infomation__title {
      color: var(--grey-60);
    }
    .project__infomation__introduction {
      margin-top: 16px;
      color: var(--grey-40);
    }
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    .thumbnail {
      width: 100%;
      max-width: 100%;
      aspect-ratio: 335/212;
      max-height: 100%;
    }
    .project__infomation {
      align-items: center;
      padding: 24px;

      .project__infomation__introduction {
        height: 110px;
        min-height: 110px;
        max-height: 110px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
      }
    }
  }
`;

export default React.memo(ProjectItem);
