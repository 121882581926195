import styled from "@emotion/styled";

export const ContentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--white);

  .main__column {
    display: flex;
    flex-direction: column;
  }

  .main__banner__img {
    width: 100%;
    height: 480px;
    object-fit: cover;
    object-position: top;
  }

  @media only screen and (max-width: 768px) {
    overflow: hidden;
  }
`;
