import React, { useLayoutEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import axios from "axios";
import { NotionInput } from "admin/component/input/NotionInput";
import DefaultButton from "admin/component/button/DefaultButton";
import UploadImg from "admin/component/upload-img/UploadImg";
import { imgCategory } from "admin/type/type";
import { getYMD } from "utils/commonUtil";
import DocumentEditor from "admin/component/editor/DocumentEditor";
import * as api from "../../../../api/api";

import { PopupProps } from "../PopupAdmin";
import { ReactComponent as RadioOn } from "../../../../assets/radio_on.svg";
import { ReactComponent as RadioOff } from "../../../../assets/radio_off.svg";

interface Props {
  lastIndex: number;
  item?: PopupProps;
  close?: () => void;
  callback?: () => void;
}

const PopupDetail: React.FC<Props> = (props) => {
  const [isImg, updateIsImg] = useState<boolean>(false);
  const [description, updateDescription] = useState<string | undefined>(
    undefined
  );
  const [startDate, updateStartDate] = useState<string | undefined>(
    getYMD(new Date())
  );
  const [endDate, updateEndDate] = useState<string | undefined>(
    getYMD(new Date())
  );
  const [popupUrl, updatePopupUrl] = useState<string | undefined>(undefined);
  const [popupImgKey, updatePopupImgKey] = useState<string | undefined>(
    undefined
  );
  const [popupImgUrl, updatePopupImgUrl] = useState<string | undefined>(
    undefined
  );
  const content = useRef<string>("");

  const getBlueletterDetail = () => {
    if (props.item === undefined) return;

    content.current = props.item.script || "";
    updateDescription(props.item.description);
    updateIsImg(props.item.isImg);
    updateStartDate(getYMD(props.item.startDate));
    updateEndDate(getYMD(props.item.endDate));
    updatePopupUrl(props.item.popupUrl);
    updatePopupImgKey(props.item.popupImgKey);
    updatePopupImgUrl(props.item.popupImgUrl);
  };

  const updatePopup = async () => {
    try {
      if (!props.item) return;

      let param: any = {
        hpPopupId: props.item?.hpPopupId,
        isImg,
      };
      if (isImg) {
        param = {
          ...param,
          description,
          popupImgKey,
          popupImgUrl,
          popupUrl,
          startDate: `${startDate} 00:00:00`,
          endDate: `${endDate} 23:59:59`,
          idx: props.item.idx,
        };
      } else {
        param = {
          ...param,
          description,
          startDate: `${startDate} 00:00:00`,
          endDate: `${endDate} 23:59:59`,
          script: content.current,
          idx: props.item.idx,
        };
      }
      const result = await axios.put(api.updatePopup(), param);
      console.log(result);

      if (result.status === 200 && props.callback) {
        props.callback();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const createPopup = async () => {
    try {
      let param: any = {
        isImg,
      };
      if (isImg) {
        param = {
          ...param,
          popupImgKey,
          popupImgUrl,
          popupUrl,
          startDate: `${startDate} 00:00:00`,
          endDate: `${endDate} 23:59:59`,
          description,
          idx: props.lastIndex + 1,
        };
      } else {
        param = {
          ...param,
          startDate: `${startDate} 00:00:00`,
          endDate: `${endDate} 23:59:59`,
          script: content.current,
          description,
          idx: props.lastIndex + 1,
        };
      }

      const result = await axios.post(api.createPopup(), param);
      console.log(result);

      if (result.status === 201 && props.callback) {
        props.callback();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useLayoutEffect(() => {
    if (props.item !== undefined) {
      getBlueletterDetail();
    }
  }, [props.item]);

  return (
    <Container>
      <div className="popup__detail__header font-bold-18">
        <span />
        {props.item ? "팝업 수정" : "팝업 추가"}
        <DefaultButton
          onClick={() => {
            if (
              !description ||
              description.length === 0 ||
              !startDate ||
              startDate.length === 0 ||
              !endDate ||
              endDate.length === 0
            )
              return alert("제목 과 게시일, 종료일은 필수 값입니다.");

            if (isImg) {
              if (!popupImgUrl || popupImgUrl.length === 0)
                return alert("이미지 파일이 없습니다.");
            } else if (!content.current || content.current.length === 0)
              return alert("에디터 내용이 없습니다. 채워주세요.");

            if (props.item) {
              // 수정
              updatePopup();
            } else {
              // 생성
              createPopup();
            }
          }}
        >
          {props.item ? "수정하기" : "생성하기"}
        </DefaultButton>
      </div>
      <div className="popup__detail__body">
        <div className="popup__detail__body__row">
          <NotionInput
            label="제목"
            hasUnderLine
            editable
            textType="text"
            placeholder="제목을 입력하세요."
            defaultValue={description}
            onChange={(e) => {
              updateDescription(e);
            }}
          />
        </div>
        <div className="popup__detail__body__row">
          <NotionInput
            label="게시일"
            hasUnderLine
            editable
            textType="date"
            max="9999-12-31"
            placeholder="연도. 월. 일"
            defaultValue={startDate}
            onChange={(e) => {
              updateStartDate(e);
              updateEndDate(e);
            }}
          />
          <NotionInput
            label="종료일"
            hasUnderLine
            editable
            textType="date"
            min={startDate}
            max="9999-12-31"
            placeholder="연도. 월. 일"
            defaultValue={endDate}
            onChange={(e) => {
              updateEndDate(e);
            }}
          />
        </div>
        <IRTypeContainer>
          <div className="font-bold-14">
            <span>{"자료유형"}</span>
          </div>

          <div className="ir__type__items">
            <div
              className={`${
                isImg ? "font-bold-14" : "font-regular-14"
              } ir__type__item`}
              onClick={() => updateIsImg(true)}
              style={{
                color: isImg ? "var(--black)" : "var(--grey-40)",
              }}
            >
              {isImg ? <RadioOn /> : <RadioOff />}
              이미지
            </div>
            <div
              className={`${
                !isImg ? "font-bold-14" : "font-regular-14"
              } ir__type__item`}
              onClick={() => updateIsImg(false)}
              style={{
                color: !isImg ? "var(--black)" : "var(--grey-40)",
              }}
            >
              {!isImg ? <RadioOn /> : <RadioOff />}
              에디터
            </div>
          </div>
        </IRTypeContainer>
        {isImg ? (
          <div
            className="popup__detail__body__row"
            style={{ alignItems: "flex-start" }}
          >
            <UploadImg
              editable
              category={imgCategory.POPUP}
              imgUrl={popupImgUrl}
              onChangeFile={(url, key) => {
                updatePopupImgKey(key);
                updatePopupImgUrl(url);
              }}
              width={500}
              height={640}
            />
            {/* <div style={{ border: "1px solid var(--grey-00)" }}>
            </div> */}
            <NotionInput
              label="링크"
              hasUnderLine
              editable
              textType="text"
              defaultValue={popupUrl}
              onChange={(e) => {
                updatePopupUrl(e);
              }}
            />
          </div>
        ) : (
          <div
            className="popup__detail__body__row"
            style={{ alignItems: "flex-start" }}
          >
            <div
              style={{
                width: 500,
                height: 640,
              }}
            >
              <DocumentEditor
                isEditable
                initialValue={content.current}
                category={imgCategory.POPUP}
                onEditorChange={(editor) => {
                  console.log(editor);
                  content.current = editor;
                }}
              />
            </div>
          </div>
        )}
      </div>
    </Container>
  );
};

const Container = styled.div`
  width: 100vw;
  max-width: 780px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  .popup__detail__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px 24px;

    border-bottom: 1px solid var(--grey);
  }
  .popup__detail__body {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 24px;
    gap: 10px;

    .popup__detail__body__row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 24px;
    }
  }
`;

const IRTypeContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  .ir__type__title {
    width: 100px;
  }

  .ir__type__items {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;

    .ir__type__item {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 4px;
      cursor: pointer;
    }
  }
`;
export default PopupDetail;
