import styled from "@emotion/styled";

import DisplayType from "utils/displayType";
import FaqSection from "router/contact/components/FaqSection";
import { useEffect, useState } from "react";
import { DropdownSelector } from "component/selector/DropdownSelector";
import Footer from "../../component/footer/Footer";
import Policy20220101 from "./policy_contents/Policy20220101";
import Policy20230601 from "./policy_contents/Policy20230601";

interface PrivatyPolicyVersion {
  title: string;
  policy: JSX.Element;
}

const PrivacyPolicy = () => {
  const mode = DisplayType();
  const version: PrivatyPolicyVersion[] = [
    { title: "2023.06.01", policy: <Policy20230601 /> },
    { title: "2022.01.01", policy: <Policy20220101 /> },
  ];

  const [selectedVersionId, updateSelectedVersionId] = useState<number>(0);
  const [selectedVersion, updateSelectedVersion] =
    useState<PrivatyPolicyVersion>(version[0]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
  }, []);
  return (
    <Container>
      <HeaderContainer
        className={`${mode === "Mobile" ? "font-sub-title2" : "font-title"}`}
      >
        <span style={{ color: "var(--black)" }}>개인정보 처리방침</span>
        <div
          className={`center ${
            mode === "Mobile" ? "font-body4" : "font-body2"
          }`}
          style={{
            color: "var(--grey-40)",
            textAlign: "center",
            padding: mode === "Mobile" ? "0 20px" : "0",
          }}
        >
          {`<주식회사 블루포인트파트너스>`}
          {mode === "Mobile" && <br />}
          {`('bluepoint.ac'이하 '회사')는`}
          {mode === "Mobile" && <br />} 「개인정보보호법」 제30조에 따라 <br />
          정보주체의 개인정보를 보호하고 이와 관련한 고충을
          {mode === "Mobile" && <br />} 신속하고 원활하게 처리할 수 있도록 하기
          위하여
          <br /> 다음과 같이 개인정보 처리방침을 수립·공개합니다.
        </div>
      </HeaderContainer>
      <div
        className="limit__body"
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
          marginTop: 80,
          gap: 16,
        }}
      >
        <div className={`${mode === "Mobile" ? "font-body4" : "font-body2"}`}>
          개인정보 처리 방침 Ver.
        </div>
        <DropdownSelector
          width={180}
          itemList={version.map((item, index) => ({
            id: index,
            text: item.title,
            data: item,
          }))}
          selectedId={selectedVersionId}
          onChangeItem={(item) => {
            if (item.data) {
              updateSelectedVersionId(item.id);
              updateSelectedVersion(item.data);
            }
          }}
        />
      </div>
      {selectedVersion.policy}
      <Footer />
    </Container>
  );
};
const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 80px;

  .center {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @media only screen and (max-width: 768px) {
    padding-top: 60px;
  }
`;

const HeaderContainer = styled.div`
  width: 100%;
  height: 298px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--bg-03);
  color: var(--grey-50);
  gap: 20px;
  margin: 0;
  .blue__point {
    color: var(--primary);
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    height: fit-content;
    text-align: center;
    padding-top: 60px;
    padding-bottom: 60px;
  }
`;
export default PrivacyPolicy;
