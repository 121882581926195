import styled from "@emotion/styled";

export const ContentsLayout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;

  .contents__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    min-height: 60px;
    max-height: 60px;
    border-bottom: 1px solid grey;
    padding: 0 24px;
  }
  .contents__body {
    width: 100%;
    height: calc(100vh - 60px);
    overflow: scroll;
  }
`;

export const ContentsList = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 0;
  padding: 24px;
`;
