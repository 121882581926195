import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";

import axios from "axios";
import { getYMD } from "utils/commonUtil";
import { NotionInput } from "admin/component/input/NotionInput";
import NotionInputSelecter from "admin/component/input/NotionInputSelecter";
import { imgCategory, LatticePortfolioInfoType } from "admin/type/type";
import { NotionTextarea } from "admin/component/input/NotionTextarea";
import { Spinner } from "react-bootstrap";
import UploadCompanyImg from "../component/UploadCompanyImg";
import ProjectCard from "../component/ProjectCard";
import SelectBox from "../component/SelectBox";

interface Props {
  portfolioId: number;
  latticePortfolioInfo?: LatticePortfolioInfoType;
  selectedObjectKeyLattice: string[];
  selectObject: (key: string) => void;
}
const PortfolioInfoByLattice: React.FC<Props> = (props) => {
  const getSelectObject = (key: string): boolean =>
    props.selectedObjectKeyLattice.includes(key);

  if (props.latticePortfolioInfo === undefined) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  return (
    <Container>
      <Row gap={24}>
        <SelectBox
          objectKey="companyImgKey,companyImgUrl"
          isSelect={getSelectObject("companyImgKey,companyImgUrl")}
          isFitContent
          onClick={props.selectObject}
        >
          <img
            className="logo__img"
            src={props.latticePortfolioInfo?.companyImgUrl || ""}
            alt="company_logo"
            style={{ width: 250, height: 250 }}
          />
        </SelectBox>
        <Column gap={4}>
          <SelectBox
            objectKey="companyName"
            isSelect={getSelectObject("companyName")}
            onClick={props.selectObject}
          >
            <NotionInput
              editable={false}
              label="회사명"
              defaultValue={props.latticePortfolioInfo?.companyName}
            />
          </SelectBox>
          <SelectBox
            objectKey="description"
            isSelect={getSelectObject("description")}
            onClick={props.selectObject}
          >
            <NotionInput
              editable={false}
              label="아이템 요약"
              defaultValue={props.latticePortfolioInfo?.description}
            />
          </SelectBox>
        </Column>
      </Row>
      <Row gap={24}>
        <SelectBox
          objectKey="ceoName"
          isSelect={getSelectObject("ceoName")}
          onClick={props.selectObject}
        >
          <NotionInput
            editable={false}
            label="대표자"
            defaultValue={props.latticePortfolioInfo.ceoName}
          />
        </SelectBox>
        <SelectBox
          objectKey="homepageUrl"
          isSelect={getSelectObject("homepageUrl")}
          onClick={props.selectObject}
        >
          <NotionInput
            editable={false}
            label="홈페이지"
            defaultValue={props.latticePortfolioInfo.homepageUrl}
          />
        </SelectBox>
      </Row>
      <Row gap={24}>
        <SelectBox
          objectKey="establishmentDay"
          isSelect={getSelectObject("establishmentDay")}
          onClick={props.selectObject}
        >
          <NotionInput
            editable={false}
            label="설립일"
            defaultValue={getYMD(props.latticePortfolioInfo.establishmentDay)}
          />
        </SelectBox>
        <div style={{ width: "100%", overflow: "hidden" }}>
          <SelectBox
            objectKey="addr1,addr2,addr3,addrDetail"
            isSelect={getSelectObject("addr1,addr2,addr3,addrDetail")}
            onClick={props.selectObject}
          >
            <AddressContainer>
              <span className="font-bold-14">소재지</span>
              <div className=" address__input font-regular-14">{`${props.latticePortfolioInfo.addr1} ${props.latticePortfolioInfo.addr2} ${props.latticePortfolioInfo.addr3} ${props.latticePortfolioInfo.addrDetail}`}</div>
            </AddressContainer>
          </SelectBox>
        </div>
      </Row>
      <Row>
        <SelectBox
          objectKey="introduction"
          isSelect={getSelectObject("introduction")}
          onClick={props.selectObject}
        >
          <NotionTextarea
            editable={false}
            label="기업 소개"
            defaultValue={props.latticePortfolioInfo.introduction}
          />
        </SelectBox>
      </Row>
      <Column gap={4}>
        <Row style={{ alignItems: "center", justifyContent: "space-between" }}>
          <span className="font-bold-14">기업 프로젝트</span>
        </Row>
        <Column gap={24} style={{ marginTop: 10 }}>
          {props.latticePortfolioInfo.pfProject.map((item, index) => {
            return (
              <SelectBox
                key={item.pfProjectId}
                objectKey={`pfProject_${item.pfProjectId}_${index}`}
                isSelect={getSelectObject(
                  `pfProject_${item.pfProjectId}_${index}`
                )}
                onClick={props.selectObject}
              >
                <ProjectCard editable={false} portfolioId={1} project={item} />
              </SelectBox>
            );
          })}
        </Column>
      </Column>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px;
  overflow: scroll;
  gap: 16px;
  .logo__img {
    border-radius: 10px;
    background-color: var(--white);
    border: 1px solid var(--grey_20);
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
    object-fit: contain;
  }
`;
const AddressContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 4px;

  .address__input {
    max-width: 100%;

    padding: 5px 32px 5px 10px;
  }
`;
const Row = styled.div<{ gap?: number }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: ${(props) => (props.gap ? `${props.gap}px` : "0px")};
  .flex1 {
    flex: 1;
  }
  .flex2 {
    flex: 2;
  }
  .flex3 {
    flex: 3;
  }
`;

const Column = styled.div<{ gap?: number }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.gap ? `${props.gap}px` : "0px")};
`;

export default React.memo(PortfolioInfoByLattice);
