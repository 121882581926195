import styled from "@emotion/styled";
import DisplayType from "utils/displayType";
import { useLayoutEffect } from "react";
import { updateTitle } from "utils/commonUtil";
import Footer from "../../component/footer/Footer";
import { ReactComponent as Logo } from "../../assets/logo_start_point.svg";
import StartingPointLogo from "../../assets/logo_start_point.png";
import StartingPointWord from "../../assets/starting_point_word.png";
import { ReactComponent as Email } from "../../assets/email__icon.svg";

const StartPoint = () => {
  const mode = DisplayType();

  useLayoutEffect(() => {
    updateTitle("스타팅포인트");
  }, []);

  return (
    <Container>
      <HeaderContainer>
        <img
          className="program__logo"
          src={StartingPointLogo}
          alt="startingPointLogo"
        />
        <span
          className={`program__title ${
            mode === "Mobile" ? "font-bold-24" : "font-title"
          }`}
        >
          스타팅포인트
        </span>
        <p
          className={`program__description ${
            mode === "Mobile" ? "font-body3" : "font-body2"
          }`}
        >
          다른 사람보다 조금은 더 자주 새로움에 {mode === "Mobile" && <br />}{" "}
          관심을 갖는 ‘반스푼 괴짜’를 위한 <br />
          작업, 네트워킹, 휴식의 공간이자,{mode === "Mobile" && <br />} 무엇이든
          몰입과 대화를 통해
          <br />
          자유자재로 바꿔나갈 수 있는 ‘외롭지 않은 공간’입니다.
        </p>
      </HeaderContainer>
      <BodyContainer className="limit__body">
        <ProgramDescription>
          <div
            className={`program__description__title ${
              mode === "Mobile" ? "font-sub-title2" : "font-title"
            }`}
          >
            실현하고 싶은 모든 사람들을 위한 커뮤니티 공간
          </div>
          <p className={`${mode === "Mobile" ? "font-body3" : "font-body2"}`}>
            스타트업 생태계에서 네트워킹의 힘은 실로 대단합니다. 스타트업을
            성장시키려면 자신의 비전을 믿어주고, 끌어주고, 힘을 실어주는
            사람들이 꼭 필요하기 때문입니다. 스타팅포인트는 블루포인트의
            자회사로서, 대전 등 비수도권 지역의 창업 커뮤니티를 활성화하여
            새로운 만남과 기회의 범위가 확장되기를 바랍니다.
            <br />
            <br /> 스타팅포인트는 ‘무엇인가 더 만들어 보고 싶은 마음’ 을 가진
            사람들이 서로 이야기를 나누고 마음 속에 품어두었던 가능성을 실현
            가능한 ‘무언가’로 만들 수 있는 커뮤니티 공간입니다. 이 곳에서 우리의
            세계는 무한히 확장합니다.
          </p>
        </ProgramDescription>
        <ProgramProcess>
          <div
            className={`program__process__title ${
              mode === "Mobile" ? "font-bold-22" : "font-sub-title"
            }`}
          >
            스타팅포인트 멤버십
          </div>
          <div className={`program__process__row`}>
            <span
              className={`${
                mode === "Mobile" ? "font-bold-16" : "font-bold-18"
              }`}
            >
              설명
            </span>
            <span
              className={`${mode === "Mobile" ? "font-body3" : "font-body2"}`}
            >
              실현하고 싶은 사람들을 위한 시작점, Starting Point.
            </span>
          </div>
          <div className={`program__process__row`} style={{ marginTop: 8 }}>
            <span
              className={`${
                mode === "Mobile" ? "font-bold-16" : "font-bold-18"
              }`}
            >
              대상
            </span>
            <span
              className={`${mode === "Mobile" ? "font-body3" : "font-body2"}`}
            >
              아래 단어 중 3개 이상 좋아한다면 누구나
            </span>
          </div>
          {mode === "Mobile" && (
            <img
              className="program__process__img"
              style={{ padding: 12 }}
              src={StartingPointWord}
              alt="startingpoint word img"
            />
          )}

          {mode !== "Mobile" && (
            <img
              className="program__process__img"
              style={{ width: 960, margin: "0 auto", marginTop: 72 }}
              src={StartingPointWord}
              alt="startingpoint word img"
            />
          )}
        </ProgramProcess>
        <ProgramProcess>
          <div
            className={`program__process__title ${
              mode === "Mobile" ? "font-bold-22" : "font-sub-title"
            }`}
          >
            문의처
          </div>
          <FlexRow
            className={`${
              mode === "Mobile" ? "font-regular-16" : "font-regulra-22"
            }`}
            style={{
              marginTop: 16,
              flexDirection: mode === "Mobile" ? "column" : "row",
              alignItems: mode === "Mobile" ? undefined : "center",
            }}
          >
            <span style={{ width: 80, fontWeight: 700 }}>이메일</span>
            <a
              href="mailto:contact@startingpoint.co.kr"
              style={{ textDecoration: "none" }}
              rel="noreferrer"
            >
              <EmailContainer>
                <span className={`email__text `}>
                  contact@startingpoint.co.kr
                </span>
              </EmailContainer>
            </a>
          </FlexRow>

          <FlexRow
            className={`${
              mode === "Mobile" ? "font-regular-16" : "font-regulra-22"
            }`}
            style={{
              marginTop: 12,
              flexDirection: mode === "Mobile" ? "column" : "row",
              alignItems: mode === "Mobile" ? undefined : "center",
            }}
          >
            <span style={{ width: 80, fontWeight: 700 }}>인스타</span>
            <a
              href="https://www.instagram.com/startingpoint_official/"
              style={{ textDecoration: "none" }}
              rel="noreferrer"
            >
              <EmailContainer>
                <span className={`email__text `}>
                  https://www.instagram.com/startingpoint_official/
                </span>
              </EmailContainer>
            </a>
          </FlexRow>

          <FlexRow
            className={`${
              mode === "Mobile" ? "font-regular-16" : "font-regulra-22"
            }`}
            style={{
              marginTop: 12,
              flexDirection: mode === "Mobile" ? "column" : "row",
              alignItems: mode === "Mobile" ? undefined : "center",
            }}
          >
            <span style={{ width: 80, fontWeight: 700 }}>링크드인</span>
            <a
              href="https://www.linkedin.com/in/starting-point-a32b30318/"
              style={{ textDecoration: "none" }}
              rel="noreferrer"
            >
              <EmailContainer>
                <span className={`email__text `}>
                  https://www.linkedin.com/in/starting-point-a32b30318/
                </span>
              </EmailContainer>
            </a>
          </FlexRow>
        </ProgramProcess>
      </BodyContainer>
      <Footer />
    </Container>
  );
};
const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 80px;

  @media only screen and (max-width: 768px) {
    padding-top: 60px;
  }
`;

const HeaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--bg-02);
  padding-top: 80px;
  padding-bottom: 80px;

  .program__logo {
    width: 240px;
    min-width: 240px;
    max-width: 240px;
    height: 240px;
    min-width: 240px;
    max-width: 240px;
    border-radius: 50%;
    border: 1px solid #e5e5ea;
    overflow: hidden;
    object-fit: contain;
    background-color: white;
  }
  .program__title {
    color: var(--black);
    margin-top: 24px;
    margin-bottom: 16px;
  }
  .program__description {
    color: var(--grey-40);
    text-align: center;
  }

  @media only screen and (max-width: 768px) {
    padding-bottom: 60px;
    .program__logo {
      width: 117px;
      min-width: 117px;
      max-width: 117px;
      height: 117px;
      min-width: 117px;
      max-width: 117px;
    }
    .program__title {
      color: var(--black);
      margin-top: 24px;
      margin-bottom: 8px;
    }
  }
`;

const BodyContainer = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  margin-bottom: 160px;
`;

const ProgramDescription = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  margin-top: 100px;

  .program__description__title {
    color: var(--primary);
    margin-bottom: 24px;
  }

  @media only screen and (max-width: 768px) {
    margin-top: 40px;
    .program__description__title {
      margin-bottom: 16px;
    }
  }
`;

const ProgramProcess = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  margin-top: 100px;

  .program__process__title {
    color: var(--black);
    margin-bottom: 24px;
  }

  .program__process__row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
  }

  .program__process__img {
    width: 100%;
    margin-top: 36px;
    /* border: 1px solid #e5e5ea; */
  }

  .program__process__list {
    width: 100%;
    border-top: 1px solid var(--grey-00);
    display: flex;
    flex-direction: column;
    margin-top: 24px;
  }
  @media only screen and (max-width: 768px) {
    margin-top: 40px;
    .program__process__title {
      margin-bottom: 16px;
    }
    .program__process__row {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
    }
  }
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const ProgramProcessItem = styled.div`
  width: 100%;
  padding: 32px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--grey-00);

  .item__title {
    color: var(--primary);
  }
  .item__descrition {
    color: var(--grey-60);
  }
  @media only screen and (max-width: 768px) {
    padding: 24px 16px;
  }
`;

const EmailContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--primary);
`;
export default StartPoint;
