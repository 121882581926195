import React, { useEffect, useRef, useState } from "react";
import Styled from "@emotion/styled";

import { NodeModel, useDragOver } from "@minoru/react-dnd-treeview";
import { toast } from "react-toastify";
import { ReactComponent as ArrowRightIcon } from "../../../../assets/arrow_right.svg";
import { ReactComponent as Complete } from "../../../../assets/edit_complete.svg";
import { ReactComponent as Cancel } from "../../../../assets/edit_cancel.svg";
import { ReactComponent as TrashCan } from "../../../../assets/trash_can.svg";
import { DataType } from "..";

type Props = {
  isStructure?: boolean;
  node: NodeModel<any>;
  depth: number;
  isOpen: boolean;
  hasChild: boolean;
  visibleInput: boolean;
  isSelectedStructure: boolean;
  canDelete?: boolean;
  isSelectedDeleteStructure?: boolean;
  existSameValue: (value: string) => boolean;
  onOpen: (id: NodeModel["id"]) => void;
  onToggle: (id: NodeModel["id"]) => void;
  onSelect: (node: NodeModel<DataType>) => void;
  onEditStructure: (id: NodeModel["id"]) => void;
  onEditCancelStructure: (id: NodeModel["id"]) => void;
  onTextChange: (id: NodeModel["id"], value: string) => void;
  deleteStructure: (data: NodeModel<DataType>) => void;
};

export const StructureNode: React.FC<Props> = (props) => {
  const { droppable, data } = props.node;
  const canDelete = props.canDelete || props.canDelete === undefined;
  const { id } = props.node;
  const indent = props.depth * 36;

  const inputRef = useRef<HTMLInputElement>(null);
  const [labelText, setLabelText] = useState(props.node.data.name);

  const handleToggle = (e: React.MouseEvent) => {
    e.stopPropagation();
    props.onToggle(props.node.id);
  };

  const handleSelect = () => {
    if (!props.isSelectedStructure) props.onSelect(props.node);

    if (!props.isSelectedStructure && !props.isOpen) {
      props.onToggle(props.node.id);
      return;
    }

    if (props.isSelectedStructure) {
      props.onToggle(props.node.id);
    }
  };

  const handleDelete = () => props.deleteStructure(props.node);

  const handleCancel = () => {
    props.onEditCancelStructure(id);
  };

  const handleChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLabelText(e.target.value);
  };

  const handleSubmit = () => {
    if (!props.existSameValue(labelText)) {
      props.onTextChange(id, labelText);
    } else {
      toast.error(`${labelText}가 이미 존재 합니다.`, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  const dragOverProps = useDragOver(id, props.isOpen, props.onToggle);

  useEffect(() => {
    if (props.visibleInput && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    } else {
      setLabelText(props.node.data.name);
    }
  }, [props.visibleInput]);

  return (
    <StructContainer
      className="tree-node root"
      onClick={handleSelect}
      onDoubleClick={(e) => {
        e.stopPropagation();
        props.onEditStructure(id);
      }}
      style={{
        marginInlineStart: indent,
        backgroundColor: props.isSelectedStructure
          ? "var(--grey-10)"
          : "var(--white)",
      }}
      {...dragOverProps}
    >
      {props.hasChild && (
        <div className={`expandIconWrapper ${props.isOpen ? "isOpen" : ""}`}>
          <div onClick={handleToggle}>
            <ArrowRightIcon />
          </div>
        </div>
      )}
      {props.visibleInput ? (
        <div className="font-medium-14 labelGridItem">
          <div className="inputWrapper">
            <div style={{ flexGrow: 1 }}>
              <input
                ref={inputRef}
                type="text"
                className="font-medium-14 "
                value={labelText}
                onChange={handleChangeText}
                onKeyDown={(e) => {
                  if (e.key === "Enter") handleSubmit();
                  if (e.key === "Escape") handleCancel();
                }}
              />
            </div>
            <button className="editIcon__wrapper">
              <Complete className="editIcon" onClick={handleSubmit} />
            </button>
            <button className="editIcon__wrapper">
              <Cancel className="editIcon" onClick={handleCancel} />
            </button>
          </div>
        </div>
      ) : (
        <div className="font-medium-14 labelGridItem basic">
          <div className="inputWrapper">
            {props.isSelectedDeleteStructure && (
              <TrashCan style={{ width: 16, height: 16 }} />
            )}
            {props.node.data.name}
          </div>
          {props.isSelectedStructure && canDelete && (
            <Cancel onClick={handleDelete} />
          )}
        </div>
      )}
    </StructContainer>
  );
};

const StructContainer = Styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  
  height: 36px;

  border-radius: 10px;
  padding: 0 12px;
  
  margin-bottom: 20px;

  border: 1px solid var(--border-grey);
  .expandIconWrapper {
    cursor: pointer;
    height: 24px;
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform linear 0.1s;
    transform: rotate(0deg);
  }

  .expandIconWrapper.isOpen {
    transform: rotate(90deg);
  }
  .basic { 
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .labelGridItem {
    position: relative;
    flex-grow: 1;
    padding-inline-start: 8px;

    color: var(--black);
    button {
      border: 0;
    }
    .inputWrapper{
      display:flex;
      width: 100%;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      input {
        width: 100%;
        border: 0;
        border-bottom: 1px solid var(--border-grey);
        background-color: transparent;
      }
      .editIcon__wrapper{
        width: 26px;
        height: 26px;
        min-width: 26px;
        min-height: 26px;
        border-radius: 50%;
        box-shadow: none;
        background-color: transparent;
        transition: 0.3s;
        padding:0;
        
        :hover {
          background-color: rgba(130, 130, 130, 0.2);
        }
      }
      .editIcon { 
        width: 24px;
        height: 24px;
        min-width: 24px;
        min-height: 24px;
      }
    }
  }
`;
