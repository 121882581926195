import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { Editor } from "@tinymce/tinymce-react";
import DisplayType from "utils/displayType";
import Spinner from "../../../component/Spinner/index";

interface Props {
  className?: string;
  initialValue: string;
}

const PopupEditor: React.FC<Props> = (props) => {
  const { className = "" } = props;
  const asdfRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (props.initialValue && asdfRef.current) {
      asdfRef.current.innerHTML = props.initialValue;
    }
  }, [props.initialValue]);

  return (
    <EditorContainer className={`scroll__invisible ${className}`}>
      <div ref={asdfRef} />
    </EditorContainer>
  );
};

const EditorContainer = styled.div`
  position: relative;
  width: calc(100%);
  height: calc(740px - 100px);
  padding: 32px;

  @media only screen and (max-width: 768px) {
    height: calc(540px - 100px);
    padding: 0;
    img {
      width: 100%;
      height: auto;
    }
  }
`;
export default PopupEditor;
