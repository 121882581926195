import React, { useEffect } from "react";
import styled from "@emotion/styled";

import { UserInfo } from "admin/type/type";
import { ReactComponent as DefaultUserImg } from "../../../../assets/default_user_img.svg";
// import { ReactComponent as Warnning } from "../../../../../../assets/image/ic_warning.svg";
import { ReactComponent as Cancel } from "../../../../assets/icon_x.svg";

interface Props {
  userInfo: UserInfo;
  structureName?: string;
  isChecked?: boolean;
  onClick?: () => void;
  selecteUser?: (user: UserInfo) => void;
  cancelSelecteUser?: (user: UserInfo) => void;
}

const UserCircleItem: React.FC<Props> = (props) => {
  useEffect(() => {}, []);

  const onClick = () => {
    if (props.isChecked) {
      if (props.cancelSelecteUser) props.cancelSelecteUser(props.userInfo);
    } else if (props.selecteUser) props.selecteUser(props.userInfo);
  };

  return (
    <Container onClick={onClick}>
      <ProfileImageContainer>
        {props.userInfo.profileImgUrl ? (
          <img
            className="profile__img"
            alt="profile"
            src={props.userInfo.profileImgUrl}
          />
        ) : (
          <DefaultUserImg className="profile__img" />
        )}
        <div className="delete__container">
          <Cancel className="delete__img" />
        </div>
      </ProfileImageContainer>
      <span className="font-regular-12">{props.userInfo.name}</span>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--white);
  transition: 0.1s;
  cursor: pointer;
  gap: 4px;
  padding: 8px;
`;
const ProfileImageContainer = styled.div`
  position: relative;
  width: 40px;
  height: 40px;
  background-color: var(--grey-10);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  .profile__img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
  .delete__container {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    top: 0;
    right: 0;
    border-radius: 50%;
    background-color: var(--grey-30);
    transform: translate(5px, -5px);
    svg {
      fill: white;
    }
    .delete__img {
      width: 70%;
      height: 70%;
    }
  }
`;

export default React.memo(UserCircleItem);
