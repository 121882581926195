import React, { useEffect, useRef, useState } from "react";

import { Button } from "react-bootstrap";
import { getServerTime } from "utils/commonApi";
import AccordionMenu from "admin/component/accordion/AccordionMenu";
import axios from "axios";
import { getRangeYear, getYear, sortingByOpenDate } from "utils/commonUtil";
import { NewsCategory } from "admin/type/type";
import Navigation, { Pages } from "../../component/navigation";
import { EventLayout, NewsList } from "./styles";
import SearchBar from "../../component/search";
import ModalLayout from "../../component/modal/ModalLayout";
import NewsCard from "./component/NewsCard";
import NewsDetail, { Portfolio } from "./component/NewsDetail";
import * as api from "../../../api/api";

export interface NewsProps {
  hpNewsId?: number; // 뉴스 id
  openDate?: string; // 뉴스가 홈페이지에 노출되는 시기
  linkUrl?: string; // 링크 url
  thumbNail?: string; // 뉴스 이미지 옵셔널
  title?: string; // 뉴스 제목
  category: NewsCategory; // 뉴스 카테고리
  description?: string; // 설명
  isActive: boolean; // show || hide
  createdAt?: string; // 생성 날짜
  hpPortfolio?: Portfolio;
}
interface GroupNewsProps {
  year: number;
  isDefaultOpen: boolean;
  newsList: NewsProps[];
}

const News = () => {
  const [isNewsDetailModal, updateNewsDetailModal] = useState<boolean>(false);
  const [newsGroup, setNewsGroup] = useState<GroupNewsProps[]>([]);
  const selectedNewsData = useRef<NewsProps | undefined>(undefined);
  const [changeList, updateChangeList] = useState<boolean>(false);
  const [today, updateToday] = useState<Date | undefined>(undefined);
  const [keyword, updateKeyword] = useState<string>("");

  const onShowEventDetail = (eventData?: NewsProps) => {
    selectedNewsData.current = eventData;
    updateNewsDetailModal(true);
  };
  const setCurrentTime = async () => {
    try {
      const currentTime = await getServerTime();
      updateToday(currentTime);
    } catch (error) {
      console.log(error);
    }
  };

  const getNewsList = async () => {
    try {
      const result = await axios.get(api.getNewsList());
      if (result.status === 200) {
        const currentYear = getYear(new Date()) || 2022;
        const yearRange = getRangeYear(2014, currentYear, 1).reverse();
        const newsGroupList = yearRange.map((year: number, index: number) => {
          const newsFilter = result.data
            .sort(sortingByOpenDate)
            .filter((item: any) => {
              const itemYear = getYear(item.openDate);
              return year === itemYear;
            });
          return {
            year,
            isDefaultOpen: index === 0,
            newsList: newsFilter,
          };
        });
        const list = newsGroupList.filter((item) => item.newsList.length > 0);
        setNewsGroup(list);
        updateChangeList(!changeList);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const init = async () => {
    try {
      if (await api.checkingToken()) {
        await getNewsList();
        await setCurrentTime();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    updateChangeList((prev) => !prev);
  }, [keyword]);

  useEffect(() => {
    init();
  }, []);
  return (
    <Navigation activePageId={Pages[5].id}>
      <EventLayout>
        <div className="contents__header">
          <SearchBar
            onChangeKeyword={(keyword) => {
              updateKeyword(keyword);
            }}
          />
          <Button
            variant="primary"
            onClick={() => onShowEventDetail(undefined)}
          >
            뉴스 생성하기
          </Button>
        </div>
        <div className="contents__body">
          {newsGroup.map((item) => {
            return (
              <AccordionMenu
                header={`${item.year}년 News`}
                isDefaultOpen
                headerStyle={{ width: "704px", margin: "0 auto" }}
                notifyChangeChild={changeList}
              >
                <NewsList>
                  {item.newsList
                    .filter(
                      (item) =>
                        item.hpPortfolio?.companyName
                          ?.toUpperCase()
                          .includes(keyword.toUpperCase()) ||
                        item.title
                          ?.toUpperCase()
                          .includes(keyword.toUpperCase()) ||
                        item.description
                          ?.toUpperCase()
                          .includes(keyword.toUpperCase())
                    )
                    .map((item) => {
                      return (
                        <NewsCard
                          key={item.hpNewsId}
                          item={item}
                          showMenu
                          onClickItem={() => onShowEventDetail(item)}
                          callback={() => {
                            getNewsList();
                          }}
                        />
                      );
                    })}
                </NewsList>
              </AccordionMenu>
            );
          })}
        </div>
        <ModalLayout
          isOpen={isNewsDetailModal}
          toggle={() => updateNewsDetailModal(false)}
        >
          <NewsDetail
            item={selectedNewsData.current}
            callback={() => {
              getNewsList();
              updateNewsDetailModal(false);
            }}
          />
        </ModalLayout>
      </EventLayout>
    </Navigation>
  );
};
export default News;
