import styled from "@emotion/styled";

export const TeamContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 768px) {
  }
`;

export const TeamStaffContainer = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 100px;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  gap: 160px;

  @media only screen and (max-width: 768px) {
    gap: 100px;
    padding-top: 64px;
    padding-bottom: 64px;
  }
`;
