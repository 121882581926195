import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";

interface Props {
  className?: string;
  portfolioId?: number;
  initialValue?: string;
}

const DocumentViewer: React.FC<Props> = (props) => {
  const asdfRef = useRef<HTMLDivElement>(null);
  const { className = "" } = props;

  useEffect(() => {
    if (props.initialValue && asdfRef.current) {
      asdfRef.current.innerHTML = props.initialValue;
    }
  }, [props.initialValue]);

  return (
    <EditorContainer className={className}>
      <div ref={asdfRef} />
    </EditorContainer>
  );
};

const EditorContainer = styled.div`
  position: relative;
  width: calc(100% + 2rem);
  height: calc(100% + 2rem);
  transform: translate(0, -1rem);

  @media only screen and (max-width: 768px) {
    img {
      width: 100%;
      height: auto;
    }
  }
`;
export default DocumentViewer;
