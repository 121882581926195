import React, { useEffect, useRef, useState } from "react";
import { NodeModel } from "@minoru/react-dnd-treeview";
import ModalLayout from "admin/component/modal/ModalLayout";
import {
  LatticeUserInfo,
  Position,
  SelectData,
  Structure,
  UserInfo,
} from "admin/type/type";
import axios from "axios";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import * as api from "../../../api/api";
import Navigation, { Pages } from "../../component/navigation";
import CreateUser from "./organism/create-user/CreateUser";
import TeamDetail from "./organism/team-detail";
import TeamStructure from "./organism/team-structure";
import { TeamLayout } from "./styles";

export interface DataType {
  isStruct?: boolean;
  name?: string;
  structInfo?: StructureInfo;
}

export interface StructureInfo extends Structure {
  isNew?: boolean;
  isChange?: boolean;
  isDelete?: boolean;
}

const TeamAdmin = () => {
  const [openCreateUser, updateOpenCreateUser] = useState<boolean>(false);

  const [structureData, setStructureData] = useState<NodeModel<DataType>[]>([]);
  const [allHpUser, setAllHpUser] = useState<UserInfo[]>([]);
  const [allPosition, setAllPosition] = useState<SelectData<Position>[]>([]);

  const [selectedStruct, setSelectedStruct] = useState<
    NodeModel<DataType> | undefined
  >(undefined);
  const selectedUserInfo = useRef<UserInfo | undefined>(undefined);

  const [deleteStructureId, setDeleteStructureId] = useState<number>(); // "삭제된 유저" structure id
  const openUserCreate = (userInfo: UserInfo | undefined) => {
    selectedUserInfo.current = userInfo;
    updateOpenCreateUser(true);
  };

  const onChangeStructureDescription = (description: string) => {
    if (selectedStruct === undefined) return;
    const newStructureData = structureData.map((item) => {
      if (item.id === selectedStruct.id) {
        return {
          ...item,
          data: {
            ...item.data,
            structInfo: {
              ...item.data?.structInfo,
              introduction: description,
              isChange: !item.data?.structInfo?.isNew,
            },
          },
        };
      }
      return { ...item };
    });
    setStructureData(newStructureData);
  };

  // 유저 불러오기
  const loadAndAddUser = async () => {
    const result = await axios.get(api.getAllLatticeUser());

    if (result.status === 200) {
      const existUserEmail = allHpUser.map((item) => item.email);
      const latticeUserList: LatticeUserInfo[] = result.data.map(
        (item: any) => ({
          ...item,
        })
      );

      const arr = latticeUserList
        .filter((item) => item.employmentStatus.employmentStatusId === 2)
        .filter((item: any) => !existUserEmail.includes(item.email));

      if (arr.length > 0) {
        const maxId = allHpUser.filter(
          (item) => typeof item.hpUserId === "string"
        ).length;
        let position = allPosition.find((item) => item.text === "없음")?.data;
        if (position === undefined) {
          position = {
            hpPositionId: "new_position_1",
            name: "없음",
          };
        }

        const newUsers = arr.map((user, index) => {
          const userInfo: UserInfo = {
            isNew: true,
            hpUserId: `user-${maxId + index}`,
            email: user.email,
            name: user.name,
            acUserId: user.acUserId,
            profileImgKey: user.profileImgKey,
            profileImgUrl: user.profileImgUrl,
            hpStructure: selectedStruct?.data?.structInfo,
            hpPosition: position,
            hpUserMajor: user?.acUserMajor.map((user) => {
              return { hpIndustrialTech: user.acIndustrialTech };
            }),
          };
          return userInfo;
        });

        const Tree = [...allHpUser, ...newUsers];
        setAllHpUser(Tree);
      }
    }
  };

  const onUserCreate = (newUser: UserInfo) => {
    const yn = allHpUser.filter((item) => item.hpUserId === newUser.hpUserId);
    if (yn.length > 0) {
      const newTree = allHpUser.map((item) => {
        if (item.hpUserId === newUser.hpUserId) {
          newUser.isChange = !newUser.isNew;
          return newUser;
        }
        return item;
      });
      setAllHpUser(newTree);
    } else {
      const maxId = `user-${
        allHpUser.filter((item) => typeof item.hpUserId === "string").length
      }`;
      newUser.hpUserId = maxId;
      const Tree = [...allHpUser, newUser];
      setAllHpUser(Tree);
    }
    updateOpenCreateUser(false);
  };

  const getHpStructure = async () => {
    try {
      const result = await axios.get(api.getHomepageStructure());
      if (result.status === 200) {
        const structureList = result.data.map((item: any): StructureInfo => {
          return { ...item, isNew: false, isChange: false, isDelete: false };
        });

        const newId =
          Math.max(...structureList.map((item: any) => item.hpStructureId)) + 1;
        console.log(newId);

        setDeleteStructureId(newId);

        const structureResult = structureList
          .concat({
            hpStructureId: newId,
            name: "삭제된 유저",
            structureIndex: 0,
            parent: 0,
            isNew: false,
            isChange: false,
            isDelete: false,
          })
          .sort((a: any, b: any) =>
            a.structureIndex > b.structureIndex ? 1 : -1
          )
          .map((item: any): NodeModel<DataType> => {
            return {
              id: item.hpStructureId,
              droppable: true,
              parent: item.parent === null ? 0 : item.parent,
              text: item.name,
              data: {
                name: item.name,
                isStruct: true,
                structInfo: item,
              },
            };
          });

        setStructureData(structureResult);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getAllHpUser = async () => {
    try {
      const result = await axios.get(api.getAllHomepageUser());
      if (result.status === 200) {
        const hpUserList = result.data.map((item: any): UserInfo => {
          return { ...item, isNew: false, isChange: false, isDelete: false };
        });

        setAllHpUser(hpUserList);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getAllPosition = async () => {
    try {
      const result = await axios.get(api.getAllPosition());
      if (result.status === 200) {
        const positionList = result.data.map(
          (item: any): SelectData<Position> => {
            return { id: item.hpPositionId, text: item.name, data: item };
          }
        );
        setAllPosition(positionList);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const orderingStructure = () => {
    if (structureData.length === 0) return;

    const orderedStructure: StructureInfo[] = [];

    const orderingChildren = (
      children: NodeModel<DataType>[],
      parentIndex: number
    ) => {
      if (children.length === 0) return;

      children.forEach((item, index) => {
        if (item.data && item.data.structInfo) {
          const structureIndex = parentIndex * 10 + (index + 1);
          orderedStructure.push({
            ...item.data.structInfo,
            structureIndex,
          });
          orderingChildren(
            structureData.filter((data) => data.parent === item.id),
            structureIndex
          );
        }
      });
      return orderedStructure;
    };

    return orderingChildren(
      structureData.filter((item) => item.parent === 0),
      0
    );
  };

  const onUpdateStructure = async () => {
    try {
      if (structureData.length === 0) return;

      const updateData = {
        hpStructure: orderingStructure(),
        hpUser: allHpUser,
      };

      const result = await axios.post(api.upsertAcStructure(), updateData);

      if (result.status === 201) {
        toast.success("저장성공", { position: toast.POSITION.BOTTOM_CENTER });
        init();
      } else if (result.status === 409) {
        toast.error("조직도 이름 중복", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (structureData.length > 0 && selectedStruct !== undefined) {
      setSelectedStruct(
        structureData.find((item) => item.id === selectedStruct.id)
      );
    }

    if (allHpUser.length > 0) {
      const newAllHpUser = allHpUser.map((item) => {
        const newUserStructure = structureData.find(
          (structure) => structure.id === item.hpStructure?.hpStructureId
        )?.data?.structInfo;
        return { ...item, hpStructure: newUserStructure };
      });
      setAllHpUser([...newAllHpUser]);
    }
  }, [structureData]);

  const init = async () => {
    try {
      if (await api.checkingToken()) {
        await getHpStructure();
        await getAllHpUser();
        await getAllPosition();
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    init();
  }, []);

  return (
    <Navigation activePageId={Pages[0].id}>
      <TeamLayout>
        <div className="team__header">
          <span />
          <Button variant="primary" onClick={onUpdateStructure}>
            조직원 정보 갱신
          </Button>
        </div>
        <div className="team__body">
          <TeamStructure
            structureData={structureData}
            setStructureData={setStructureData}
            selectedStruct={selectedStruct}
            setSelectedStruct={setSelectedStruct}
            allHpUser={allHpUser}
            deleteStructureId={deleteStructureId || 0}
          />
          <TeamDetail
            selectedStruct={selectedStruct}
            openUserCreate={openUserCreate}
            allHpUser={allHpUser}
            setAllHpUser={setAllHpUser}
            loadAndAddUser={loadAndAddUser}
            onChangeStructureDescription={onChangeStructureDescription}
            isSelectedDeleteStructure={
              selectedStruct
                ? selectedStruct.data?.structInfo?.hpStructureId ===
                  deleteStructureId
                : false
            }
          />
        </div>

        <ModalLayout
          isOpen={openCreateUser}
          toggle={() => {
            updateOpenCreateUser(false);
          }}
        >
          <CreateUser
            structure={structureData}
            selectedStructure={selectedStruct}
            userInfo={selectedUserInfo.current}
            allHpUser={allHpUser}
            checkDuplicateEmail={(email, userId) => {
              return false;
            }}
            onUserCreate={onUserCreate}
            allPosition={allPosition}
            setAllPosition={setAllPosition}
            cancelCreate={() => {
              updateOpenCreateUser(false);
            }}
            deleteStructureId={deleteStructureId || 0}
          />
        </ModalLayout>
      </TeamLayout>
    </Navigation>
  );
};
export default TeamAdmin;
