import styled from "@emotion/styled";
import axios from "axios";
import Chip from "component/chips/Chip";
import React, { useEffect, useRef, useState } from "react";
import DisplayType from "utils/displayType";
import { HomepagePortfolioInfoType, IndustryTech } from "admin/type/type";
import { NewsProps } from "admin/router/news";
import { NotionTextarea } from "admin/component/input/NotionTextarea";
import { getYMD } from "utils/commonUtil";
import Slider from "react-slick";
import News from "component/card/News";
import Label from "component/label/Label";
import NewsCard from "admin/router/news/component/NewsCard";
import * as api from "../../../api/api";
import { ReactComponent as CloseBtn } from "../../../assets/modal_close.svg";
import { ReactComponent as RollingLeft } from "../../../assets/rolling_left.svg";
import { ReactComponent as RollingRight } from "../../../assets/rolling_right.svg";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ProjectItem from "../component/ProjectItem";
import NewsItem from "../component/NewsItem";

interface Props {
  portfolioId: number;
  onChange?: (selectedSector: IndustryTech[]) => void;
  close: () => void;
}

const PortfolioModal: React.FC<Props> = (props) => {
  const mode = DisplayType();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const sliderRef = useRef<any>(null);
  const [portfolioInfo, updatePortfolioInfo] = useState<
    HomepagePortfolioInfoType | undefined
  >(undefined);
  const [portfolioNews, updatePortfolioNews] = useState<NewsProps[]>([]);

  const next = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };
  const prev = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const getPortfolioNews = async () => {
    try {
      const result = await axios.get(api.getNewsList(), {
        params: { portfolioId: props.portfolioId },
      });
      if (result.status === 200) {
        updatePortfolioNews(
          result.data
            .filter((item: any, index: number) => index < 2)
            .map((item: any): NewsProps => item)
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getPortfolioInfo = async () => {
    try {
      const result = await axios.get(
        api.getHomepagePortfolio(props.portfolioId)
      );
      if (result.status === 200) {
        updatePortfolioInfo({ ...result.data });
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getPortfolioInfo();
    getPortfolioNews();
  }, []);

  if (portfolioInfo === undefined) return null;

  return (
    <Container className="scroll__invisible">
      <CloseBtn className="close__btn" onClick={props.close} />
      <PortfolioHeader>
        <div className="portfolio__logo__container">
          {portfolioInfo.companyImgUrl ? (
            <img
              className="portfolio__logo"
              src={portfolioInfo.companyImgUrl}
              alt="logo"
            />
          ) : (
            <div
              className="font-sub-title2"
              style={{
                color: `var(--blue-01)`,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {portfolioInfo.companyName.split(" ").map((item, index) => {
                return <span key={index}>{item}</span>;
              })}
            </div>
          )}
        </div>

        <div className="portfolio__infomation">
          <div
            className={`portfolio__infomation__title ${
              mode === "Mobile" ? "font-sub-title" : "font-title-lx"
            }`}
          >
            {portfolioInfo.companyName}
          </div>
          <div
            className={`portfolio__infomation__introduction ${
              mode === "Mobile" ? "font-body3" : "font-body1"
            }`}
          >
            {portfolioInfo.description}
          </div>
          <div className="portfolio__infomation__chips__container">
            <Label labelType="type3">{portfolioInfo.stage}</Label>
            {portfolioInfo.hpPfIndustrialTech.map((item) => (
              <Label key={item.hpPfIndustrialTechId} labelType="type1">
                {item.hpIndustrialTech.name}
              </Label>
            ))}
          </div>
        </div>
      </PortfolioHeader>
      <Divider />
      <PortfolioBody>
        <NotionTextarea
          className={mode === "Mobile" ? "font-body3" : "font-body2"}
          editable={false}
          defaultValue={portfolioInfo.introduction}
        />
        <div className="portfolio__company__info">
          <div className="portfolio__company__info__row">
            <span
              className={`portfolio__company__info__title ${
                mode === "Mobile" ? "font-body4" : "font-body2"
              }`}
            >
              대표자
            </span>
            <span
              className={`${
                mode === "Mobile" ? "font-bold-14" : "font-bold-18"
              }`}
              style={{ color: "var(--grey-60)" }}
            >
              {portfolioInfo.ceoName}
            </span>
          </div>
          <div className="portfolio__company__info__row">
            <span
              className={`portfolio__company__info__title ${
                mode === "Mobile" ? "font-body4" : "font-body2"
              }`}
            >
              소재지
            </span>
            <span
              className={`${
                mode === "Mobile" ? "font-bold-14" : "font-bold-18"
              }`}
              style={{ color: "var(--grey-60)" }}
            >{`${portfolioInfo.addr1} ${portfolioInfo.addr2} ${portfolioInfo.addr3} ${portfolioInfo.addrDetail}`}</span>
          </div>
          <div className="portfolio__company__info__row">
            <span
              className={`portfolio__company__info__title ${
                mode === "Mobile" ? "font-body4" : "font-body2"
              }`}
            >
              설립일
            </span>
            <span
              className={`${
                mode === "Mobile" ? "font-bold-14" : "font-bold-18"
              }`}
              style={{ color: "var(--grey-60)" }}
            >
              {getYMD(portfolioInfo.establishmentDay)}
            </span>
          </div>
          {portfolioInfo.homepageUrl && (
            <div className="portfolio__company__info__row">
              <span
                className={`portfolio__company__info__title ${
                  mode === "Mobile" ? "font-body4" : "font-body2"
                }`}
              >
                홈페이지
              </span>
              <a
                className={`${
                  mode === "Mobile" ? "font-bold-14" : "font-bold-18"
                }`}
                style={{ color: "var(--primary)" }}
                href={portfolioInfo.homepageUrl}
                target={"_blank"}
                rel="noreferrer"
              >
                {portfolioInfo.companyName} 홈페이지
              </a>
            </div>
          )}
        </div>
        {portfolioInfo.hpPfProject.length > 0 && (
          <ProjectContainer>
            <div className=" header__container">
              <span
                className={
                  mode === "Mobile" ? "font-sub-title2" : "font-sub-title2"
                }
                style={{ textTransform: "uppercase" }}
              >
                Service / Product
              </span>
              <div className="controller">
                <RollingLeft className="rolling__icon" onClick={prev} />
                <RollingRight className="rolling__icon" onClick={next} />
              </div>
            </div>
            <Slider ref={sliderRef} className="full" {...settings}>
              {portfolioInfo.hpPfProject.map((item) => {
                return <ProjectItem key={item.pfProjectId} project={item} />;
              })}
            </Slider>
          </ProjectContainer>
        )}
        {portfolioNews.length > 0 && (
          <NewsContainer>
            <div
              className={`news__header__container ${
                mode === "Mobile" ? "font-sub-title2" : "font-sub-title2"
              }`}
              style={{ textTransform: "uppercase" }}
            >
              Related News
            </div>
            <div className="news__list">
              {portfolioNews.map((item) => (
                <a
                  key={item.hpNewsId}
                  href={item.linkUrl}
                  target={"_blank"}
                  style={{ textDecoration: "none" }}
                  rel="noreferrer"
                >
                  <News news={item} isViewer isPage />
                </a>
              ))}
            </div>
          </NewsContainer>
        )}
      </PortfolioBody>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 1060px;
  height: 90vh;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  background-color: var(--white);

  .close__btn {
    position: absolute;
    top: 24px;
    right: 24px;
    width: 48px;
    height: 48px;
    cursor: pointer;
    background-color: transparent;
    transition: background-color 0.2s ease-in;
    border-radius: 8px;
    :hover {
      background-color: var(--grey-00);
    }
  }

  @media only screen and (max-width: 768px) {
    width: 100vw;
    height: 100vh;

    .close__btn {
      top: 14px;
      right: 14px;
      width: 24px;
      height: 24px;
      :hover {
        background-color: transparent;
      }
    }
  }
`;
const Divider = styled.div`
  width: calc(100% - 160px);
  height: 1px;
  background-color: var(--grey-00);
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 40px;
`;
const PortfolioHeader = styled.div`
  width: 100%;
  height: fit-content;
  padding: 100px 80px 0px 80px;
  background-color: var(--white);

  display: flex;
  flex-direction: row;
  gap: 56px;

  .portfolio__logo__container {
    width: 100%;
    max-width: 220px;
    height: 100%;
    max-height: 220px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--white);
    border: 1px solid var(--grey-00);
    .portfolio__logo {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .portfolio__infomation {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    .portfolio__information__row {
      display: flex;
      flex-direction: row;
      gap: 8px;
      align-items: flex-end;
    }
    .portfolio__infomation__title {
      margin-top: 40px;
      color: var(--black);
    }
    .portfolio__infomation__introduction {
      margin-top: 8px;
      color: var(--grey-50);
    }
    .portfolio__infomation__chips__container {
      margin-top: 16px;
      display: flex;
      flex-direction: row;
      gap: 10px;
    }
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 80px 24px 40px 24px;
    gap: 0;

    .portfolio__logo__container {
      width: 100%;
      min-width: 167px;
      max-width: 167px;
      height: 100%;
      min-height: 167px;
      max-height: 167px;
    }

    .portfolio__infomation {
      align-items: center;
      .portfolio__infomation__title {
        margin-top: 40px;
      }
      .portfolio__infomation__introduction {
        margin-top: 4px;
      }
      .portfolio__infomation__chips__container {
        margin-top: 32px;
        display: flex;
        flex-direction: row;
        gap: 10px;
      }
    }
  }
`;

const PortfolioBody = styled.div`
  width: 100%;
  flex-grow: 1;
  padding: 0px 80px 100px 80px;
  display: flex;
  flex-direction: column;

  .portfolio__company__info {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 7px;
    .portfolio__company__info__row {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .portfolio__company__info__title {
      width: 120px;
      min-width: 120px;
      max-width: 120px;
      color: var(--grey-40);
    }
  }
  @media only screen and (max-width: 768px) {
    padding: 24px 24px 40px 24px;
    .portfolio__company__info {
      .portfolio__company__info__title {
        width: 64px;
        min-width: 64px;
        max-width: 64px;
      }
    }
  }
`;

const ProjectContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  gap: 24px;

  .full {
    width: 100%;
  }
  .header__container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .controller {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      .rolling__icon {
        width: 32px;
        height: 32px;
        cursor: pointer;
      }
    }
  }

  .slick-dots li.slick-active button:before {
    opacity: 0.75;
    color: var(--primary);
  }
  .slick-dots li {
    margin: 0;
  }
  .slick-dots {
    position: absolute;
    bottom: -34px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
  }

  @media only screen and (max-width: 768px) {
    margin-top: 80px;
  }
`;

const NewsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  .news__header__container {
    color: var(--black);
    border-bottom: 1px solid var(--grey-00);
    padding-bottom: 24px;
  }
  .news__list {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 24px;
    gap: 24px;
  }

  @media only screen and (max-width: 768px) {
    margin-top: 40px;
    gap: 12px;
    .news__header__container {
      border-bottom: none;
      padding-bottom: 0px;
    }
    .news__list {
      padding-top: 0;
      gap: 0;
    }
  }
`;

export default PortfolioModal;
