import React from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import DisplayType from "utils/displayType";
import { ReactComponent as Arrow } from "../../assets/title_arrow.svg";

interface Props {
  label: string;
  className?: string;
  style?: React.CSSProperties;
  link: string;
}

const Title: React.FC<Props> = (props) => {
  const mode = DisplayType();
  return (
    <TitleContainer className={props.className} style={props.style}>
      <div className="title__container">
        <span
          className={`title__title ${
            mode === "Mobile" ? "font-sub-title2" : "font-title"
          }`}
        >
          {props.label}
        </span>
        {/* {DisplayType() === "Mobile" && (
          <Link to={props.link}>
            <Arrow width={24} height={24} />
          </Link>
        )} */}
      </div>
      {props.children}
    </TitleContainer>
  );
};

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--white);

  .title__container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .title__title {
      flex-grow: 1;
      color: var(--black);
      text-transform: uppercase;
    }
  }

  @media only screen and (max-width: 768px) {
    .title__container {
      padding-bottom: 0px;
      margin-bottom: 16px;
      .title__title {
        width: 100%;
        text-align: center;
      }
    }
  }
`;

export default Title;
