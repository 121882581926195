import styled from "@emotion/styled";
import { allNewsCategory, Category } from "admin/type/type";
import Chip from "component/chips/Chip";
import React, { useEffect, useState } from "react";

interface Props {
  onChange?: (value: Category[]) => void;
}
const NewsCategoryList: React.FC<Props> = (props) => {
  const [isAllSelect, updateAllSelect] = useState<boolean>(true);
  const [category, setCategory] = useState<Category[]>([]);
  const [selectedCategory, updateSelectedCategory] = useState<Category[]>([]);

  const onClickAllSelect = () => {
    if (isAllSelect) {
      updateSelectedCategory([]);
      if (props.onChange) props.onChange([]);
    } else {
      updateSelectedCategory([...category]);
      if (props.onChange) props.onChange([...category]);
    }
    updateAllSelect(!isAllSelect);
  };

  const onSelectCategory = (item: Category) => {
    if (isAllSelect) {
      updateSelectedCategory([item]);
      updateAllSelect(false);
      if (props.onChange) props.onChange([item]);
    } else {
      const index = selectedCategory.findIndex(
        (category) => category.id === item.id
      );
      if (index !== -1) {
        selectedCategory.splice(index, 1);
        updateSelectedCategory([...selectedCategory]);
        updateAllSelect(selectedCategory.length === category.length);
        if (props.onChange) props.onChange([...selectedCategory]);
      } else {
        const newSector = [...selectedCategory, item];
        updateSelectedCategory(newSector);
        updateAllSelect(newSector.length === category.length);
        if (props.onChange) props.onChange(newSector);
      }
    }
  };

  const getAllCategory = () => {
    setCategory([...allNewsCategory]);
    updateSelectedCategory([...allNewsCategory]);
    updateAllSelect(true);
    if (props.onChange) props.onChange([...allNewsCategory]);
  };
  useEffect(() => {
    getAllCategory();
  }, []);

  return (
    <Container>
      <div className="category__list__container">
        <span className={`font-sub-title4`}>CATEGORY</span>
        <div className="category__list">
          <Chip
            isClickable
            isActive={isAllSelect}
            onClickChip={onClickAllSelect}
          >
            All
          </Chip>

          {category.map((item) => {
            return (
              <Chip
                key={item.id}
                isClickable
                isActive={selectedCategory
                  .map((category) => category.id)
                  .includes(item.id)}
                onClickChip={() => {
                  onSelectCategory(item);
                }}
              >
                {item.name}
              </Chip>
            );
          })}
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  .category__list__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 56px;
    gap: 24px;
    .category__list {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      gap: 10px;
    }
  }

  @media only screen and (max-width: 768px) {
    .category__list__container {
      flex-direction: column;
      align-items: flex-start;
      margin-top: 40px;
      gap: 16px;
    }
  }
`;

export default NewsCategoryList;
