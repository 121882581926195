import styled from "@emotion/styled";
import DisplayType from "utils/displayType";
import { useLayoutEffect } from "react";
import { updateTitle } from "utils/commonUtil";
import Footer from "../../component/footer/Footer";
import { ReactComponent as Logo } from "../../assets/logo_blue_labs.svg";
import { ReactComponent as Email } from "../../assets/email__icon.svg";
import { ReactComponent as Agenda } from "../../assets/bluelabs_agenda.svg";
import { ReactComponent as Excavation } from "../../assets/bluelabs_excavation.svg";
import { ReactComponent as Growth } from "../../assets/bluelabs_growth.svg";
import { ReactComponent as GrayDot } from "../../assets/bluelabs_gray_dot.svg";
import logoDifferentDoors from "../../assets/logo_different_doors.png";

const BlueLabs = () => {
  const mode = DisplayType();

  useLayoutEffect(() => {
    updateTitle("블루랩스");
  }, []);

  return (
    <Container>
      <HeaderContainer>
        <Logo className="program__logo" />
        <span
          className={`program__title ${
            mode === "Mobile" ? "font-bold-24" : "font-title"
          }`}
        >
          블루랩스
        </span>
        <p
          className={`program__description ${
            mode === "Mobile" ? "font-body3" : "font-body2"
          }`}
        >
          세상의 문제를 혁신가들과 함께 <br />
          &apos;창업&apos;으로 풀고자 하는 컴퍼니빌딩 프로그램입니다.
          {/* 회사 주도의 Top-down 기획창업 방식에서
          벗어나
          {mode === "Mobile" && <br />} 커뮤니티에서{" "}
          {mode !== "Mobile" && <br />}
          시작하여 창업으로 연결되는{mode === "Mobile" && <br />} Bottom-up
          형식의 컴퍼니빌딩 프로그램입니다. */}
        </p>
      </HeaderContainer>
      <BodyContainer className="limit__body">
        <ProgramDescription>
          <div
            className={`program__description__title ${
              mode === "Mobile" ? "font-sub-title2" : "font-title"
            }`}
            style={{
              marginTop: `${mode === "Mobile" ? "40px" : "100px"}`,
            }}
          >
            {`아젠다 중심의 창업 실험실`}
          </div>
          <p
            className={`${mode === "Mobile" ? "font-body3" : "font-body2"}`}
            style={{ wordBreak: "keep-all" }}
          >
            세상에는 아직 해결되지 않은 문제들이 많습니다.{" "}
            {mode === "Mobile" && <br />}저출산, 고령화, 주택난, 기후변화 등.
            <br />
            {mode === "Mobile" && <br />}
            블루랩스는 모두가 해결을 바라지만 쉽게 뛰어들지 못하고 있는, 그래서
            해결책을 내놓았을 때 시장에 미칠 영향이 큰 문제에 집중합니다.
            <br />
            {mode === "Mobile" && <br />}
            문제 의식을 갖고 해결 방법을 깊이 고민해온 혁신가들을 발굴해 본질을
            꿰뚫는 아이디어로 함께 근본적인 해결책을 찾아갑니다.
          </p>
          <div
            className={`program__description__title ${
              mode === "Mobile" ? "font-sub-title2" : "font-title"
            }`}
            style={{
              marginTop: "60px",
            }}
          >
            {`Zero stage에서 시작하는 혁신`}
          </div>
          <p
            className={`${mode === "Mobile" ? "font-body3" : "font-body2"}`}
            style={{ wordBreak: "keep-all" }}
          >
            블루랩스는 ‘시작 단계(Zero stage)’에서 혁신을 만들어갑니다.
            <br />
            {mode === "Mobile" && <br />}
            시장에서 선뜻 도전하지 못했던 아이템을 과감하게 선정하고 미션 중심의
            팀을 구성합니다.
            <br />
            {mode === "Mobile" && <br />}
            블루랩스의 자원과 노하우를 더함으로써 스타트업의 성공 가능성을
            높이고 빠른 성장을 유도합니다.
            <br />
            <br />
            창업을 통해 세상이 그동안 열망했지만 실현되지 못했던 큰 혁신을
            이뤄냅니다.
          </p>
        </ProgramDescription>
        <ProgramSolution
          style={{
            marginTop: "140px",
            padding: `${mode === "Mobile" ? "40px 10px" : "76px"}`,
          }}
        >
          <span
            className={`${
              mode === "Mobile" ? "font-bold-16" : "font-bold-20"
            } program__solution__title`}
            style={{
              marginBottom: `${mode === "Mobile" ? "28px" : "50px"}`,
            }}
          >
            블루랩스는 세상의 문제를 {mode === "Mobile" && <br />}혁신가들과
            함께 새로운 방식으로 해결합니다
          </span>
          <div
            className={`${
              mode === "Mobile"
                ? "program__solution__column"
                : "program__solution__row"
            }`}
            style={{
              gap: `${mode === "Mobile" && "30px"}`,
            }}
          >
            <div
              className={`program__solution__content ${
                mode === "Mobile"
                  ? "program__solution__row"
                  : "program__solution__column"
              }`}
              style={{
                textAlign: `${mode === "Mobile" ? "start" : "center"}`,
                justifyContent: `${
                  mode === "Mobile" ? "center" : "flex-start"
                }`,
              }}
            >
              <div
                className="svg-container"
                style={{
                  width: `${mode === "Mobile" ? "72px" : "136px"}`,
                  height: `${mode === "Mobile" ? "72px" : "136px"}`,
                }}
              >
                <Agenda
                  style={{
                    width: `${mode === "Mobile" && "67px"}`,
                    // height: `${mode === "Mobile" && "73px"}`,
                  }}
                />
              </div>
              <span
                className={`${
                  mode === "Mobile" ? "font-regular-14" : "font-regular-16"
                }`}
              >
                아젠다를 선정하고 <br />
                성장 가능성이 큰 아이템 기획
              </span>
            </div>
            <div
              className={`program__solution__content ${
                mode === "Mobile"
                  ? "program__solution__row"
                  : "program__solution__column"
              }`}
              style={{
                textAlign: `${mode === "Mobile" ? "start" : "center"}`,
                justifyContent: `${
                  mode === "Mobile" ? "center" : "flex-start"
                }`,
              }}
            >
              <div
                className="svg-container"
                style={{
                  width: `${mode === "Mobile" ? "72px" : "136px"}`,
                  height: `${mode === "Mobile" ? "72px" : "136px"}`,
                }}
              >
                <Excavation
                  style={{
                    width: `${mode === "Mobile" && "72px"}`,
                    // height: `${mode === "Mobile" && "73px"}`,
                  }}
                />
              </div>
              <span
                className={`${
                  mode === "Mobile" ? "font-regular-14" : "font-regular-16"
                }`}
              >
                문제의식과 실행력을 갖춘 <br />
                혁신가를 발굴
              </span>
            </div>
            <div
              className={`program__solution__content ${
                mode === "Mobile"
                  ? "program__solution__row"
                  : "program__solution__column"
              }`}
              style={{
                textAlign: `${mode === "Mobile" ? "start" : "center"}`,
                justifyContent: `${
                  mode === "Mobile" ? "center" : "flex-start"
                }`,
              }}
            >
              <div
                className="svg-container"
                style={{
                  width: `${mode === "Mobile" ? "72px" : "136px"}`,
                  height: `${mode === "Mobile" ? "72px" : "136px"}`,
                }}
              >
                <Growth
                  style={{
                    width: `${mode === "Mobile" && "51px"}`,
                    // height: `${mode === "Mobile" && "73px"}`,
                  }}
                />
              </div>
              <span
                className={`${
                  mode === "Mobile" ? "font-regular-14" : "font-regular-16"
                }`}
              >
                자원 투입과 사업 노하우 <br />
                공유 통해 빠른 성장 유도
              </span>
            </div>
          </div>
        </ProgramSolution>
        <ProgramProcess
          style={{ marginTop: `${mode === "Mobile" ? "100px" : "140px"}` }}
        >
          <div
            className={`program__process__title ${
              mode === "Mobile" ? "font-bold-22" : "font-sub-title"
            }`}
          >
            프로그램 과정
          </div>
          <div className={`program__process__row`}>
            <span
              className={`${
                mode === "Mobile" ? "font-bold-16" : "font-bold-18"
              }`}
            >
              대상
            </span>
            <span
              className={`${mode === "Mobile" ? "font-body3" : "font-body2"}`}
            >
              블루랩스의 문제의식과 선정한 아이템에 공감하는 잠재적 혁신가들
            </span>
          </div>

          <div className="program__process__list">
            <ProgramProcessItem>
              <div className={`program__process__item__column flex-2`}>
                <span
                  className={`${
                    mode === "Mobile" ? "font-bold-20" : "font-bold-28"
                  }`}
                  style={{
                    color: "var(--primary)",
                    textTransform: "uppercase",
                  }}
                >
                  Step. 1
                </span>
              </div>
              <div className="program__process__item__column  flex-4">
                <span
                  className={`program__process__item__title ${
                    mode === "Mobile" ? "font-body3" : "font-body2"
                  }`}
                  style={{
                    color: "var(--primary)",
                    marginBottom: `${mode === "Mobile" ? "8px" : "4px"}`,
                  }}
                >
                  아젠다 중심의 아이템 기획
                </span>
                <span
                  className={`item__descrition ${
                    mode === "Mobile" ? "font-body3" : "font-body2"
                  }`}
                >
                  풀기 어렵지만 해결했을 때 임팩트가 큰 문제(아젠다)를
                  선정합니다. 산업 분석, 시장 조사, 잠재고객 인터뷰 등을 통해
                  성장 가능성을 철저히 검증해 아이템을 발굴합니다.
                </span>
              </div>
            </ProgramProcessItem>
            <ProgramProcessItem>
              <div className={`program__process__item__column flex-2`}>
                <span
                  className={`${
                    mode === "Mobile" ? "font-bold-20" : "font-bold-28"
                  }`}
                  style={{
                    color: "var(--primary)",
                    textTransform: "uppercase",
                  }}
                >
                  Step. 2
                </span>
              </div>
              <div className="program__process__item__column  flex-4">
                <span
                  className={`program__process__item__title ${
                    mode === "Mobile" ? "font-body3" : "font-body2"
                  }`}
                  style={{
                    color: "var(--primary)",
                    marginBottom: `${mode === "Mobile" ? "8px" : "4px"}`,
                  }}
                >
                  미션 중심 창업멤버 구성
                </span>
                <span
                  className={`item__descrition ${
                    mode === "Mobile" ? "font-body3" : "font-body2"
                  }`}
                >
                  문제 의식과 해결의 방향성에 공감하는 이들을 중심으로 CEO 및
                  초기 창업 멤버를 구성합니다. 같은 미션을 가진 인재들을 모아
                  팀빌딩을 함으로써 빠르게 ‘원 팀(one-team)’ 으로 자리잡을 수
                  있게 합니다.
                </span>
              </div>
            </ProgramProcessItem>
            <ProgramProcessItem>
              <div className={`program__process__item__column flex-2`}>
                <span
                  className={`${
                    mode === "Mobile" ? "font-bold-20" : "font-bold-28"
                  }`}
                  style={{
                    color: "var(--primary)",
                    textTransform: "uppercase",
                  }}
                >
                  Step. 3
                </span>
              </div>
              <div className="program__process__item__column  flex-4">
                <span
                  className={`program__process__item__title ${
                    mode === "Mobile" ? "font-body3" : "font-body2"
                  }`}
                  style={{
                    color: "var(--primary)",
                    marginBottom: `${mode === "Mobile" ? "8px" : "4px"}`,
                  }}
                >
                  빠른 성장을 위한 스타트업 빌딩
                </span>
                <span
                  className={`item__descrition ${
                    mode === "Mobile" ? "font-body3" : "font-body2"
                  }`}
                >
                  초기 스타트업들은 필연적으로 시행착오를 겪습니다. 블루랩스는
                  시드 투자에서 Pre-A까지 초기 투자 유치에 적극적으로 참여하는
                  것은 물론, 단계별로 필요한 사항들을 제공해 창업팀이 안정적으로
                  운영될 수 있는 환경을 조성합니다. 비즈니스 모델 수립, MVP 검증
                  등에 블루포인트 전담 인력이 함께 함으로써 실패 가능성을 줄이고
                  빠르게 성장할 수 있도록 합니다.
                </span>
              </div>
            </ProgramProcessItem>
            <ProgramProcessItem>
              <div className={`program__process__item__column flex-2`}>
                <span
                  className={`${
                    mode === "Mobile" ? "font-bold-20" : "font-bold-28"
                  }`}
                  style={{
                    color: "var(--primary)",
                    textTransform: "uppercase",
                  }}
                >
                  Step. 4
                </span>
              </div>
              <div className="program__process__item__column  flex-4">
                <span
                  className={`program__process__item__title ${
                    mode === "Mobile" ? "font-body3" : "font-body2"
                  }`}
                  style={{
                    color: "var(--primary)",
                    marginBottom: `${mode === "Mobile" ? "8px" : "4px"}`,
                  }}
                >
                  스케일업을 위한 후속 지원
                </span>
                <span
                  className={`item__descrition ${
                    mode === "Mobile" ? "font-body3" : "font-body2"
                  }`}
                >
                  블루포인트의 엑셀러레이팅 노하우를 통해 스케일업을 이끕니다.
                  후속 투자 또는 투자 연결, 네트워크를 활용한 전문가 자문 제공,
                  멘토링 등을 통해 성장 동력이 이어질 수 있도록 지속적으로
                  지원합니다.
                </span>
              </div>
            </ProgramProcessItem>
          </div>
        </ProgramProcess>
        <ProgramProject
          style={{ marginTop: `${mode === "Mobile" ? "100px" : "140px"}` }}
        >
          <div
            className={`program__project__title ${
              mode === "Mobile" ? "font-bold-22" : "font-sub-title"
            }`}
          >
            프로젝트
          </div>
          <div
            className={`${
              mode === "Mobile"
                ? "program__project__column"
                : "program__project__row"
            }`}
            style={{
              gap: `${mode === "Mobile" ? "16px" : "40px"}`,
              alignItems: `${mode === "Mobile" && "center"}`,
            }}
          >
            <div
              className="logo-container"
              style={{
                width: `${mode === "Mobile" ? "335px" : "360px"}`,
                height: `${mode === "Mobile" ? "160px" : "180px"}`,
              }}
            >
              <div
                style={{
                  backgroundImage: `url(${logoDifferentDoors})`,
                  width: `${mode === "Mobile" ? "168px" : "176px"}`,
                  height: `${mode === "Mobile" ? "97px" : "101px"}`,
                }}
              />
            </div>
            <div className="program__project__contents">
              <div
                className={`company-name ${
                  mode === "Mobile" ? "font-bold-20" : "font-bold-24"
                }`}
              >
                디프런트도어즈
              </div>
              <div
                className={`company-item ${
                  mode === "Mobile" ? "font-regular-16" : "font-regular-18"
                }`}
                style={{
                  marginBottom: `${mode === "Mobile" ? "8px" : "16px"}`,
                }}
              >
                어린이 공간 중심 온<GrayDot style={{ margin: "0 2px 0 3px" }} />
                오프라인 통합 서비스
              </div>
              <span
                className={`company-description ${
                  mode === "Mobile" ? "font-regular-16" : "font-regular-18"
                }`}
              >
                어린이들의 동네 아지트를 만듭니다. <br />
                아이들은 언제든 와서 친구들과 다양한 활동을 즐기고, 부모는 앱을
                통해 아이들의 성장과 안전을 확인하는 온오프라인 통합 서비스를
                통해 아이와 부모 모두를 만족시키고자 합니다.
              </span>
            </div>
          </div>
        </ProgramProject>
        <ProgramProcess
          style={{ marginTop: `${mode === "Mobile" ? "100px" : "120px"}` }}
        >
          <div
            className={`program__process__title ${
              mode === "Mobile" ? "font-bold-22" : "font-sub-title"
            }`}
          >
            지원 / 컨택 방법
          </div>
          <a
            href="mailto:bluelabs@bluepoint.ac"
            style={{ textDecoration: "none" }}
          >
            <EmailContainer>
              <Email className="email__icon" />
              <span
                className={`email__text ${
                  mode === "Mobile" ? "font-regular-16" : "font-regulra-22"
                }`}
              >
                bluelabs@bluepoint.ac
              </span>
            </EmailContainer>
          </a>
        </ProgramProcess>
      </BodyContainer>
      <Footer />
    </Container>
  );
};
const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 80px;

  @media only screen and (max-width: 768px) {
    padding-top: 60px;
  }
`;

const HeaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--bg-02);
  padding-top: 80px;
  padding-bottom: 80px;

  .program__logo {
    width: 240px;
    min-width: 240px;
    max-width: 240px;
    height: 240px;
    min-width: 240px;
    max-width: 240px;
  }
  .program__title {
    color: var(--black);
    margin-top: 24px;
    margin-bottom: 16px;
  }
  .program__description {
    color: var(--grey-40);
    text-align: center;
  }

  @media only screen and (max-width: 768px) {
    padding-bottom: 60px;
    .program__logo {
      width: 117px;
      min-width: 117px;
      max-width: 117px;
      height: 117px;
      min-width: 117px;
      max-width: 117px;
    }
    .program__title {
      color: var(--black);
      margin-top: 24px;
      margin-bottom: 8px;
    }
  }
`;

const BodyContainer = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  margin-bottom: 160px;

  span {
    word-break: keep-all;
  }
`;

const ProgramDescription = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  .program__description__title {
    color: var(--primary);
    margin-bottom: 24px;
  }

  @media only screen and (max-width: 768px) {
    margin-top: 40px;
    .program__description__title {
      margin-bottom: 16px;
    }
  }
`;

const ProgramSolution = styled.div`
  height: fit-content;
  border: 1px solid #e5e5ea;
  display: flex;
  flex-direction: column;
  align-items: center;

  .program__solution__title {
    color: #7e91fe;
    text-align: center;
  }

  .program__solution__row {
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  .program__solution__column {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .program__solution__content {
    /* justify-content: flex-start; */
    align-items: center;
    flex: 1;
    color: #636366;

    .svg-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  @media only screen and (max-width: 768px) {
    .program__solution__content {
      span {
        min-width: 156px;
        margin-left: 20px;
      }
    }
  }
`;

const ProgramProcess = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  margin-top: 100px;

  .program__process__title {
    color: var(--black);
    margin-bottom: 24px;
  }

  .program__process__row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
  }

  .program__process__list {
    width: 100%;
    border-top: 1px solid var(--grey-00);
    display: flex;
    flex-direction: column;
    margin-top: 24px;
  }
  @media only screen and (max-width: 768px) {
    margin-top: 40px;
    .program__process__title {
      margin-bottom: 16px;
    }
    .program__process__row {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
    }
  }
`;

const ProgramProcessItem = styled.div`
  width: 100%;
  padding: 32px;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid var(--grey-00);
  .flex-2 {
    flex: 2;
  }
  .flex-4 {
    flex: 4;
  }
  .program__process__item__column {
    display: flex;
    flex-direction: column;
    min-width: 100px;
    justify-content: flex-start;
  }

  .program__process__item__row {
    display: flex;
    flex-direction: row;
    align-items: center;
    .program__process__item__title {
      width: 35px;
    }
  }
  .item__title {
    color: var(--primary);
  }
  .item__descrition {
    color: var(--grey-60);
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    gap: 8px;
    padding: 24px 16px;

    .program__process__item__row {
      .program__process__item__title {
        width: 24px;
      }
    }
  }
`;

const ProgramProject = styled.div`
  width: 100%;
  .program__project__title {
    margin-bottom: 20px;
  }

  .program__project__row {
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  .program__project__column {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .logo-container {
    border: 1px solid #e5e5ea;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .program__project__contents {
    display: flex;
    flex-direction: column;
    flex: 1;
    .company-name {
      margin-bottom: 4px;
    }
    .company-item {
      color: #636366;
    }
    .company-description {
    }
  }

  @media only screen and (max-width: 768px) {
    .program__project__contents {
      align-items: center;
      text-align: center;
    }
  }
`;

const EmailContainer = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  .email__icon {
    width: 32px;
    height: 32px;
  }
  .email__text {
    color: var(--primary);
  }
`;
export default BlueLabs;
