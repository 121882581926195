import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";

interface Props {
  header: string;
  headerStyle?: React.CSSProperties;
  isDefaultOpen?: boolean;
  notifyChangeChild: boolean;
}

const AccordionMenu: React.FC<Props> = (props) => {
  const bodyRef = useRef<HTMLDivElement>(null);
  const contenetRef = useRef<HTMLDivElement>(null);
  const [isCollapse, updateCollapse] = useState<boolean>(
    props.isDefaultOpen !== undefined ? props.isDefaultOpen : false
  );

  const handleToggle = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.stopPropagation();
      if (bodyRef.current === null || contenetRef.current === null) return;

      bodyRef.current.style.transition = "height 0.35s ease";
      if (bodyRef.current.clientHeight > 0) {
        bodyRef.current.style.height = "0px";
      } else {
        bodyRef.current.style.height = `${contenetRef.current.clientHeight}px`;
      }
      updateCollapse(!isCollapse);
    },
    [isCollapse]
  );

  useEffect(() => {
    if (bodyRef.current === null || contenetRef.current === null) return;
    if (isCollapse) {
      bodyRef.current.style.transition = "height 0.35s ease";
      bodyRef.current.style.height = `${contenetRef.current.clientHeight}px`;
    }
  }, [props.notifyChangeChild]);

  useEffect(() => {
    if (bodyRef.current === null || contenetRef.current === null) return;
    if (props.isDefaultOpen !== undefined && props.isDefaultOpen) {
      bodyRef.current.style.transition = "height 0s ease";
      bodyRef.current.style.height = `${contenetRef.current.clientHeight}px`;
    }
  }, []);

  return (
    <Container>
      <div
        className="arccordion__header"
        style={props.headerStyle}
        onClick={handleToggle}
      >
        <span className="font-bold-16">{props.header}</span>
      </div>
      <div ref={bodyRef} className="arccordion__body">
        <div ref={contenetRef}>{props.children}</div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .arccordion__header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .accordion__header__icon {
      width: 24px;
      height: 24px;
    }
  }
  .arccordion__body {
    width: 100%;
    height: 0px;
    overflow: hidden;
    transition: height 0.35s ease;
  }
`;

export default AccordionMenu;
