import React, { useEffect } from "react";
import styled from "@emotion/styled";

interface Props {
  children: any;
  isFitContent?: boolean;
  objectKey: string;
  isSelect: boolean;
  onClick?: (key: string) => void;
}

const SelectBox: React.FC<Props> = (props) => {
  useEffect(() => {}, []);

  return (
    <Container
      className={`${props.isSelect ? "selected" : ""}`}
      isFitContent={props.isFitContent}
      onClick={() => {
        if (props.onClick) props.onClick(props.objectKey);
      }}
    >
      {props.children}
    </Container>
  );
};

const Container = styled.div<{ isFitContent?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.isFitContent ? "fit-content" : "100%")};
  height: fit-content;

  background: #ffffff;
  border: 2px solid transparent;
  transition: all 0.1s ease;
  cursor: default;

  &.selected {
    border: 2px solid var(--primary);
  }

  :hover {
    border: 2px solid var(--primary);
  }
`;

export default React.memo(SelectBox);
