import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import HeaderNavigation from "component/header/HeaderNavigation";
import BlueletterApply from "component/blueletter-apply/BlueletterApply";
import Title from "component/title/Title";
import LastestContents from "component/lastest-contents/insight/LastestContents";
import DefaultButton from "admin/component/button/DefaultButton";
import LastestNews from "component/lastest-news/LastestNews";
import LatestPortfolio from "component/lastest-portfolio/main/LatestPortfolio";
import LatestPortfolioMobile from "component/lastest-portfolio/main/LatestPortfolioMobile";
import { Link } from "react-router-dom";
import { NavigationMenu } from "admin/type/type";
import styled from "@emotion/styled";
import BlueletterApplyNew from "component/blueletter-apply/BlueletterApplyNew";
import HeaderNavigationNew from "component/header/HeaderNavigationNew";
import ModalLayout from "admin/component/modal/ModalLayout";
import { getYMD, transformObjectToBase64, updateTitle } from "utils/commonUtil";
import { PopupProps } from "admin/router/popup/PopupAdmin";
import axios from "axios";

import { ContentsContainer } from "./styles";

import Footer from "../../component/footer/Footer";
import Video from "../../component/video";
import DisplayType from "../../utils/displayType";
import mainBanner from "../../assets/main_banner.png";
import mainBanner1920 from "../../assets/main_banner_1920.png";
import mainBanner2048 from "../../assets/main_banner_2048.png";
import PopupNotice from "./components/PopupNotice";
import * as api from "../../api/api";

const HeaderThemeArray = ["dark", "white"];

export type Mode = "Desktop" | "Tablet" | "Mobile";

function Main() {
  const [theme, setTheme] = useState(HeaderThemeArray[0]);
  const mode: Mode = DisplayType();
  const [isPopupNoticeModal, updatePopupNoticeModal] = useState<boolean>(false);
  const [noticeList, updateNoticeList] = useState<PopupProps[]>([]);
  const getPopupNotice = () => {
    const HAS_VISITED_BEFORE = localStorage.getItem("hasVisitedBefore");
    if (HAS_VISITED_BEFORE && HAS_VISITED_BEFORE > getYMD(new Date())) {
      updatePopupNoticeModal(false);
      return;
    }
    updatePopupNoticeModal(true);
  };

  const getPopup = async () => {
    try {
      const result = await axios.get(api.getPopupListForClient());
      if (result.status === 200) {
        console.log(result);
        const popupList = result.data.sort((a: any, b: any) =>
          a.idx > b.idx ? 1 : -1
        );
        updateNoticeList([...popupList]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPopup();
  }, []);

  useEffect(() => {
    getPopupNotice();
  }, []);

  useLayoutEffect(() => {
    console.log(transformObjectToBase64({ channelName: "새로운채널" }));
    updateTitle("Bluepoint 블루포인트");
  }, []);

  return (
    <ContentsContainer>
      {/* <HeaderNavigation
        isFillHeader={mode === "Mobile"}
        hasUnderLine
        selected={NavigationMenu.MAIN}
      /> */}
      <Video isMobile={mode === "Mobile"} />
      <HeaderContainer className="font-sub-title">
        <span
          className={mode === "Mobile" ? "font-bold-22" : "font-sub-title"}
          style={{ color: "var(--primary)", textAlign: "center" }}
        >
          시작하는 사람들을 위한{mode === "Mobile" && <br />} 모든 혁신의 시작점
        </span>
        <span
          className={mode === "Mobile" ? "font-body3" : "font-body1"}
          style={{
            marginTop: mode === "Mobile" ? 16 : 20,
            textAlign: "center",
          }}
        >
          세상을 바꿀 혁신가들의 순수한 이념이 {mode === "Mobile" && <br />}
          존중받는 사회, {mode !== "Mobile" && <br />}
          최초의 시도(First-in-Class)가{mode === "Mobile" && <br />} 활발하게
          이루어지는 사회를 꿈꿉니다.
        </span>
      </HeaderContainer>
      <div className="limit__body main__column">
        <Title
          label="Contents"
          link="/insight/contents"
          style={{ marginTop: mode === "Mobile" ? 40 : 120 }}
        >
          <LastestContents />
        </Title>
        <Title
          label="News"
          link="/insight/news"
          style={{ marginTop: mode === "Mobile" ? 160 : 120 }}
        >
          <LastestNews />
        </Title>
        <Title
          label="Portfolios"
          link="/portfolio"
          style={{
            marginTop: mode === "Mobile" ? 140 : 120,
            marginBottom: mode === "Mobile" ? 92 : 120,
          }}
        >
          <LatestPortfolio />
        </Title>
      </div>
      <div
        style={{
          width: "100%",
          height: mode === "Mobile" ? "auto" : 480,
          // aspectRatio: "1440/480",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background:
            mode === "Mobile"
              ? "linear-gradient(92.99deg, #4DBCEF 0%, #70A4F4 74.37%, #8F9DE9 100%)"
              : "var(--white)",
        }}
      >
        <div
          className="limit__body main__column"
          style={{
            zIndex: 1,
            alignItems: mode === "Mobile" ? "center" : "flex-start",
            paddingTop: mode === "Mobile" ? 48 : 0,
            paddingBottom: mode === "Mobile" ? 48 : 0,
          }}
        >
          {mode === "Mobile" ? (
            <pre
              className="font-bold-24"
              style={{
                color: "var(--white)",
                textAlign: "center",
                margin: 0,
              }}
            >
              블루포인트와 함께
              <br />
              세상을 바꿀 혁신가를 찾습니다
            </pre>
          ) : (
            <span className="font-regular-34" style={{ color: "var(--white)" }}>
              블루포인트와 함께 세상을 바꿀
              <br />
              <span className="font-bold-34">혁신가를 찾습니다.</span>
            </span>
          )}

          {mode === "Mobile" ? (
            <span
              className="font-body2"
              style={{
                color: "var(--white)",
                marginTop: 16,
                marginBottom: 24,
                textAlign: "center",
              }}
            >
              블루포인트는 창업가 중심의 혁신적인
              <br />
              액셀러레이팅 프로그램을 통해
              <br />
              초기 스타트업이 성장할 수 있도록 돕습니다.
            </span>
          ) : (
            <span
              className="font-body2"
              style={{ color: "var(--white)", marginTop: 24, marginBottom: 40 }}
            >
              블루포인트는 창업가 중심의 혁신적인 액셀러레이팅 프로그램을 통해
              <br />
              초기 스타트업이 성장할 수 있도록 돕습니다.
            </span>
          )}
          <Link
            to={`/contact/apply`}
            style={{ textDecoration: "none" }}
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            <ApplyBtn
              className={`${
                mode === "Mobile" ? "font-button2" : "font-button1"
              }`}
            >
              지원하기
            </ApplyBtn>
          </Link>
        </div>
        {mode !== "Mobile" && (
          <picture
            style={{ position: "absolute", width: "100%", height: "480px" }}
          >
            <source
              className="main__banner__img"
              srcSet={mainBanner}
              media="all and (max-width: 1440px)"
            />
            <source
              className="main__banner__img"
              srcSet={mainBanner1920}
              media="all and (max-width: 1920px)"
            />
            <img
              className="main__banner__img"
              src={mainBanner2048}
              alt="main banner img"
            />
          </picture>
        )}
      </div>
      {/* <BlueletterApply /> */}
      <BlueletterApplyNew />
      <Footer />
      {noticeList.length > 0 && (
        <ModalLayout
          isOpen={isPopupNoticeModal}
          borderRadius={0}
          toggle={() => {
            updatePopupNoticeModal(false);
          }}
        >
          <PopupNotice
            popupList={noticeList}
            todayClose={() => {
              updatePopupNoticeModal(false);
              const expires = new Date();
              expires.setDate(expires.getDate() + 1);
              localStorage.setItem("hasVisitedBefore", getYMD(expires));
            }}
            close={() => {
              updatePopupNoticeModal(false);
            }}
          />
        </ModalLayout>
      )}
    </ContentsContainer>
  );
}

const HeaderContainer = styled.div`
  width: 100%;
  height: 298px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--bg-03);
  color: var(--grey-50);
  margin: 0;
  .blue__point {
    color: var(--primary);
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    height: fit-content;
    padding-top: 50px;
    padding-bottom: 50px;
  }
`;

const ApplyBtn = styled.button`
  width: 190px;
  height: 54px;
  background-color: transparent;
  border: 1px solid var(--white);
  color: var(--white);

  transition: all 0.3s ease-in-out;

  :hover {
    background-color: var(--white);
    color: var(--primary);
  }
  @media only screen and (max-width: 768px) {
    width: 119px;
    height: 43px;
  }
`;
export default Main;
