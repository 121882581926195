import React, { useEffect, useState } from "react";
import { NodeModel } from "@minoru/react-dnd-treeview";
import { UserInfo } from "admin/type/type";
import { toast } from "react-toastify";
import Structure from "../../component/Structure";
import { TeamStructureLayout } from "./styles";
import { DataType } from "../..";

interface Props {
  structureData: NodeModel<DataType>[];
  setStructureData: React.Dispatch<React.SetStateAction<NodeModel<DataType>[]>>;
  selectedStruct: NodeModel<DataType> | undefined;
  setSelectedStruct: React.Dispatch<
    React.SetStateAction<NodeModel<DataType> | undefined>
  >;
  allHpUser: UserInfo[];
  deleteStructureId: number;
}

const TeamStructure: React.FC<Props> = (props) => {
  const [isChange, setIsChange] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(true);

  const reloading = () => setReload((preState) => !preState);

  const onChangeStructure = () => setIsChange(true);
  const addStructure = () => {
    const filter = props.structureData.filter(
      (item) => item.droppable && item.data?.structInfo?.isNew
    );
    const nameFilter = props.structureData.filter((item) =>
      item.text.includes("새 그룹")
    );
    const maxId = `structure-${filter.length}`;
    const name = `새 그룹${
      nameFilter.length > 0 ? `(${nameFilter.length})` : ""
    }`;
    const newData: NodeModel<DataType> = {
      id: maxId,
      droppable: true,
      parent: 0,
      text: name,
      data: {
        name,
        isStruct: true,
        structInfo: {
          isNew: true,
          isChange: false,
          isDelete: false,
          hpStructureId: maxId,
          parent: 0,
          name,
        },
      },
    };
    const struct = Object.assign([], props.structureData);
    struct.push(newData);
    props.setStructureData(struct);
    if (!props.selectedStruct) props.setSelectedStruct(newData);
    onChangeStructure();
  };

  const deleteStructure = (data: NodeModel<DataType>) => {
    if (
      props.allHpUser.filter(
        (item) => item.hpStructure?.hpStructureId === data.id
      ).length > 0 ||
      props.structureData.filter((item) => item.parent === data.id).length > 0
    ) {
      toast.error("하위 그룹 및 유저를 옮겨주세요", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      if (data.data?.structInfo?.isNew) {
        const index = props.structureData.findIndex(
          (item) => item.data && item.data.structInfo && item.id === data.id
        );
        props.structureData.splice(index, 1);
      } else {
        props.structureData.forEach((item) => {
          if (item.data && item.data.structInfo && item.id === data.id)
            item.data.structInfo.isDelete = true;
        });
      }
      props.setStructureData(Object.assign([], props.structureData));
      props.setSelectedStruct(undefined);
    }

    onChangeStructure();
  };

  return (
    <TeamStructureLayout>
      <Structure
        data={props.structureData}
        selectedStruct={props.selectedStruct}
        setData={props.setStructureData}
        reloading={reloading}
        setSelectedStruct={props.setSelectedStruct}
        onAddStructure={addStructure}
        deleteStructure={deleteStructure}
        onChangeStructure={onChangeStructure}
        deleteStructureId={props.deleteStructureId}
        allHpUser={props.allHpUser}
      />
    </TeamStructureLayout>
  );
};
export default TeamStructure;
