import React from "react";
import { RingLoader } from "react-spinners";
import { Mode } from "../../router/main";
import DisplayType from "../../utils/displayType";

interface SpinnerProps {
  loading: boolean;
  size?: number;
}
const Spinner: React.FC<SpinnerProps> = ({ loading, size }) => {
  const mode: Mode = DisplayType();
  return (
    <RingLoader
      size={size || mode !== "Mobile" ? 80 : 24}
      color="var(--primary)"
      loading={loading}
      css={`
        display: flex;
        align-self: center;
      `}
    />
  );
};
export default Spinner;
