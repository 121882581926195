import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import styled from "@emotion/styled";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import DisplayType from "utils/displayType";
import { HomepagePortfolioInfoType, Mode } from "admin/type/type";
import axios from "axios";
import { Link } from "react-router-dom";
import Label from "component/label/Label";
import * as api from "../../../api/api";

import { ReactComponent as RollingLeft } from "../../../assets/rolling_left.svg";
import { ReactComponent as RollingRight } from "../../../assets/rolling_right.svg";

interface Props {}
interface PortfolioList {
  id: number;
  name: string;
  portfolio?: HomepagePortfolioInfoType;
}
const LatestPortfolio: React.FC<Props> = (propss) => {
  const [loading, setLoading] = useState(true);
  const [portfolioList, setPortfolioList] = useState<PortfolioList[]>([]);
  const sliderRef = useRef<any>(null);
  const mode = DisplayType();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: mode === "Mobile" ? 1 : 3,
    slidesToScroll: mode === "Mobile" ? 1 : 3,
  };

  const PortfolioItem = ({ item }: { item: PortfolioList }) => {
    return (
      <PortfolioItemContainer>
        <div className="portfolio__wrapper">
          <div className="portfolio__thumbnail">
            {item.portfolio?.companyImgUrl ? (
              <img
                className="portfolio__logo"
                src={item.portfolio.companyImgUrl}
                alt="logo"
              />
            ) : (
              <div
                className="font-sub-title2"
                style={{
                  color: `var(--blue-01)`,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {item.portfolio?.companyName.split(" ").map((item, index) => {
                  return <span key={index}>{item}</span>;
                })}
              </div>
            )}
            <div className="portfolio__label">
              <Label labelType="type1">{item.portfolio?.stage || ""}</Label>
            </div>
          </div>
          <InfoContainer className="bottom">
            <span className="font-sub-title4" style={{ color: "var(--black)" }}>
              {item.portfolio?.companyName}
            </span>
            {mode !== "Mobile" && (
              <div className="chips__container">
                {item.portfolio?.hpPfIndustrialTech.map((item) => {
                  return (
                    <Label key={item.hpPfIndustrialTechId} labelType="type2">
                      {item.hpIndustrialTech.name}
                    </Label>
                  );
                })}
              </div>
            )}
            <p
              className={`portfolio__introduction ${
                mode === "Mobile" ? "font-body3" : "font-body3"
              }`}
            >
              {item.portfolio?.introduction}
            </p>
            <p
              className={`portfolio__description ${
                mode === "Mobile" ? "font-body4" : "font-body4"
              }`}
            >
              {item.portfolio?.description}
            </p>
            {mode === "Mobile" && (
              <div className="chips__container">
                {item.portfolio?.hpPfIndustrialTech.map((item) => {
                  return (
                    <Label key={item.hpPfIndustrialTechId} labelType="type2">
                      {item.hpIndustrialTech.name}
                    </Label>
                  );
                })}
              </div>
            )}
          </InfoContainer>
        </div>
      </PortfolioItemContainer>
    );
  };

  const getPortflioList = async () => {
    try {
      const result = await axios.get(api.getHomepagePortfolioList());
      if (result.status === 200) {
        const portfolios = result.data.reverse().map((item: any) => ({
          ...item,
        }));
        const filteredPortfolioList: PortfolioList[] = [];
        const transPortfolio = portfolios.filter(
          (item: any, index: number) => index < 5
        );

        for (const item of transPortfolio) {
          const portfolioDetail = (
            await axios.get(api.getHomepagePortfolio(item.portfolioId))
          ).data;
          filteredPortfolioList.push({
            id: item.portfolioId,
            name: item.companyName,
            portfolio: portfolioDetail,
          });
        }

        setPortfolioList(filteredPortfolioList);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const next = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };
  const prev = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  useEffect(() => {
    getPortflioList();
  }, []);

  return (
    <Container>
      {mode !== "Mobile" && (
        <Controller>
          <RollingLeft className="rolling__icon" onClick={prev} />
          <RollingRight className="rolling__icon" onClick={next} />
        </Controller>
      )}
      <Slider ref={sliderRef} className="full" {...settings}>
        {portfolioList
          .filter((item, index) => index < 9)
          .map((item) => (
            <div key={item.id}>
              <Link
                to={`/portfolio/${item.portfolio?.portfolioId}`}
                style={{ textDecoration: "none" }}
              >
                <PortfolioItem item={item} />
              </Link>
            </div>
          ))}
      </Slider>
    </Container>
  );
};

const Controller = styled.div`
  position: absolute;
  right: 24px;
  top: -60px;
  display: flex;
  flex-direction: row;
  gap: 24px;

  .rolling__icon {
    cursor: pointer;
    :hover {
      path {
        stroke: var(--primary);
      }
    }
  }
  @media only screen and (max-width: 768px) {
    right: 0;
    top: -45px;
  }
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 24px;
  .full {
    width: calc(100%);
  }
  .slick-dots li.slick-active button:before {
    opacity: 0.75;
    color: var(--primary);
  }
  .slick-dots li {
    margin: 0;
  }
  .slick-dots li button:before {
    font-family: "slick";
    font-size: 10px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 15px;
    height: 15px;
    content: "•";
    text-align: center;
    opacity: 0.25;
    color: black;
    -webkit-font-smoothing: antialiased;
  }
  .slick-dots {
    position: absolute;
    bottom: -34px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
  }

  .slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    margin-left: 0;
    margin-right: 0;
  }

  @media only screen and (max-width: 768px) {
  }
`;

const PortfolioItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  width: calc(1176px / 3 + 24px);
  height: 452px;
  padding-right: 24px;

  .portfolio__wrapper {
    width: calc(100% - 24px);
    height: 100%;
    display: flex;
    flex-direction: column;
    transition: all 0.3s ease;
    border: 1px solid var(--grey-00);
    .portfolio__thumbnail {
      position: relative;
      width: 100%;
      height: 100%;
      min-height: 202px;
      max-height: 202px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--white);

      .portfolio__logo {
        width: 200px;
        height: 200px;
        object-fit: contain;
      }

      .portfolio__label {
        position: absolute;
        top: 16px;
        left: 16px;
      }
    }
    :hover {
      border-color: var(--primary);
    }
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    height: auto;
    flex-direction: column;
    margin-top: 0;
    gap: 0px;
    padding-right: 0px;
    .portfolio__wrapper {
      width: 100%;
      .portfolio__thumbnail {
        width: 100%;
        min-width: 100%;
        min-height: 100%;
        height: auto;
        min-height: auto;
        max-height: auto;
        aspect-ratio: 355 / 240;

        .portfolio__label {
          position: absolute;
          top: 8px;
          left: 8px;
        }
      }
    }
  }
`;

const InfoContainer = styled.div`
  width: calc(100%);
  flex-grow: 1;
  padding: 32px;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;

  border-top: 1px solid var(--grey-00);

  .chips__container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 4px;
    max-height: 64px;
    overflow: hidden;
    margin: 0;
    margin-top: 16px;
  }

  .portfolio__introduction {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    margin: 0;
    margin-top: 24px;
    color: var(--black);
  }

  .portfolio__description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin: 0;
    margin-top: 8px;
    color: var(--grey-40);
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    .portfolio__description {
      -webkit-line-clamp: 1;
    }

    .portfolio__introduction {
      display: none;
      -webkit-line-clamp: 2;
    }
  }
`;

export default LatestPortfolio;
