import { useEffect, useLayoutEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import Footer from "component/footer/Footer";
import axios from "axios";
import DisplayType from "utils/displayType";
import Banner from "component/banner/Banner";
import { ContactMenu, NavigationMenu } from "admin/type/type";
import DefaultButton from "admin/component/button/DefaultButton";
import { checkEmailForm, updateTitle } from "utils/commonUtil";
import ModalLayout from "admin/component/modal/ModalLayout";
import PrivacyPolicyCheckBox from "component/agree-checkbox/PrivacyPolicyCheckBox";
import { Link } from "react-router-dom";
import Spinner from "component/Spinner";
import * as api from "../../api/api";
import ContactTabs from "./components/ContactTabs";
import { ReactComponent as Arrow } from "../../assets/title_arrow.svg";
import ContactInput from "./components/ContactInput";
import ContactTextArea from "./components/ContactTextArea";
import SectorList from "./components/SectorList";
import ContactFileUpload from "./components/ContactFileUpload";
import SuccessApplyModal from "../../component/success-modal/SuccessApplyModal";

import bg from "../../assets/contact_bg.png";
import ContactChecked from "./components/ContactChecked";

const Apply = () => {
  const mode = DisplayType();

  const bodyRef = useRef<HTMLDivElement>(null);
  const section1 = useRef<HTMLDivElement>(null);
  const section2 = useRef<HTMLDivElement>(null);
  const section3 = useRef<HTMLDivElement>(null);
  const section4 = useRef<HTMLDivElement>(null);
  const section5 = useRef<HTMLDivElement>(null);
  const section6 = useRef<HTMLDivElement>(null);
  const section7 = useRef<HTMLDivElement>(null);
  const section8 = useRef<HTMLDivElement>(null);
  const section9 = useRef<HTMLDivElement>(null);
  const section10 = useRef<HTMLDivElement>(null);

  const [isSuccessApplyModal, updateSuccessApplyModal] =
    useState<boolean>(false);

  const [isProgress, updateProgress] = useState<boolean>(false);

  const [isQnaModal, updateQnaModal] = useState<boolean>(false);

  const [ceoNameError, updateCeoNameError] = useState<boolean>(false);
  const [companyNameError, updateCompanyNameError] = useState<boolean>(false);
  const [ceoTelError, updateCeoTelError] = useState<boolean>(false);
  const [ceoEmailError, updateCeoEmailError] = useState<boolean>(false);
  const [descriptionError, updateDescriptionError] = useState<boolean>(false);
  const [problemError, updateProblemError] = useState<boolean>(false);
  const [solutionError, updateSolutionError] = useState<boolean>(false);
  const [pfIndustrialTechError, updatePfIndustrialTechError] =
    useState<boolean>(false);
  const [fileError, updateFileError] = useState<boolean>(false);
  const [ApplyPathError, updateApplyPathError] = useState<boolean>(false);
  const [checkedError, updateCheckedError] = useState<boolean>(false);

  const [ceoName, updateCeoName] = useState<string>("");
  const [companyName, updateCompanyName] = useState<string>("");
  const [ceoTel, updateCeoTel] = useState<string>("");
  const [ceoEmail, updateCeoEmail] = useState<string>("");
  const [description, updateDescription] = useState<string>("");
  const [problem, updateProblem] = useState<string>("");
  const [solution, updateSolution] = useState<string>("");
  const [file, updateFile] = useState<any | undefined>(undefined);
  const [additionalInformation, updateAdditionalInformation] =
    useState<string>("");
  const [pfIndustrialTech, updatePfIndustrialTech] = useState<
    {
      isMain: boolean;
      acIndustrialTech: {
        acIndustrialTechId: number;
        name: string;
      };
    }[]
  >([]);
  const [applyPath, updateApplyPath] = useState<number[]>([]);
  const [applyExtraMsg, updateApplyExtraMsg] = useState<string>("");
  const [checked, updateChecked] = useState<boolean>(false);

  const [validationChecked, updateValidationChekced] = useState(false);

  const initValue = () => {
    updateCeoNameError(false);
    updateCompanyNameError(false);
    updateCeoTelError(false);
    updateCeoEmailError(false);
    updateDescriptionError(false);
    updateProblemError(false);
    updateSolutionError(false);
    updatePfIndustrialTechError(false);
    updateFileError(false);
    updateCheckedError(false);
    updateApplyPathError(false);

    updateCeoName("");
    updateCompanyName("");
    updateCeoTel("");
    updateCeoEmail("");
    updateDescription("");
    updateProblem("");
    updateSolution("");
    updateFile(undefined);
    updateAdditionalInformation("");
    updatePfIndustrialTech([]);
    updateChecked(false);
    updateApplyPath([]);
    updateApplyExtraMsg("");
  };

  const validationChecking = () => {
    const isValidCeoName = ceoName.length === 0;
    const isValidCompanyName = companyName.length === 0;
    const isValidCeoTel = ceoTel.length === 0;
    const isValidCeoEmail = ceoEmail.length === 0 || !checkEmailForm(ceoEmail);
    const isValidDescription = description.length === 0;
    const isValidProblem = problem.length === 0;
    const isValidSolution = solution.length === 0;
    const isValidPfIndustrialTech = pfIndustrialTech.length === 0;
    const isValidFile = file === undefined;
    const isValidApplyPath = applyPath.length === 0;
    const isValidChecked = !checked;

    updateCeoNameError(isValidCeoName);
    updateCompanyNameError(isValidCompanyName);
    updateCeoTelError(isValidCeoTel);
    updateCeoEmailError(isValidCeoEmail);
    updateDescriptionError(isValidDescription);
    updateProblemError(isValidProblem);
    updateSolutionError(isValidSolution);
    updatePfIndustrialTechError(isValidPfIndustrialTech);
    updateFileError(isValidFile);
    updateApplyPathError(isValidApplyPath);
    updateCheckedError(isValidChecked);

    updateValidationChekced((prev) => !prev);

    return (
      !isValidCeoName &&
      !isValidCompanyName &&
      !isValidCeoTel &&
      !isValidCeoEmail &&
      !isValidDescription &&
      !isValidProblem &&
      !isValidSolution &&
      !isValidPfIndustrialTech &&
      !isValidFile &&
      !isValidApplyPath &&
      !isValidChecked
    );
  };
  const sendPortfolioInfo = async () => {
    const getPath = (id: number) => {
      switch (id) {
        case 1:
          return { id, name: "지인 소개" };
        case 2:
          return { id, name: "언론보도" };
        case 3:
          return { id, name: "SNS" };
        case 4:
          return { id, name: "인터넷 검색" };
        default:
          return { id, name: "기타" };
      }
    };

    try {
      if (validationChecking()) {
        updateProgress(true);
        const formData = new FormData();
        formData.append("file", file);

        const fileUploadResult = await axios.post(
          api.uploadBusinessPlanFile(),
          formData
        );
        const fileUploadGoogleResult = await axios.post(
          api.uploadGoogleBusinessPlanFile(),
          formData
        );
        if (
          fileUploadResult.status === 201 &&
          fileUploadGoogleResult.status === 201
        ) {
          const data = {
            ceoName,
            companyName,
            ceoTell: ceoTel,
            ceoEmail,
            description,
            problem,
            solution,
            additionalInformation,
            pfIndustrialTech,
            businessPlanFile: fileUploadResult.data[0],
            googleDrive: fileUploadGoogleResult.data,
            applyPathList: applyPath.map((item) => ({
              applyPath: getPath(item),
              extra: item === 5 ? applyExtraMsg : undefined,
            })),
          };

          const result = await axios.post(api.sendApply(), data);
          if (result.status === 201) {
            updateSuccessApplyModal(true);
            updateProgress(false);
            initValue();
          }
        }
      }
    } catch (error) {
      console.log(error);
      updateProgress(false);
    }
  };

  useEffect(() => {
    if (
      !bodyRef.current ||
      !section1.current ||
      !section2.current ||
      !section3.current ||
      !section4.current ||
      !section5.current ||
      !section6.current ||
      !section7.current ||
      !section8.current ||
      !section9.current ||
      !section10.current
    ) {
      return;
    }
    // let scrollTop = bodyRef.current.offsetTop - (mode === "Mobile" ? 60 : 80);
    let scrollTop = mode === "Mobile" ? -60 : -80;
    switch (true) {
      case ceoNameError || companyNameError:
        scrollTop += section1.current.offsetTop;
        window.scrollTo({ top: scrollTop, behavior: "smooth" });
        break;
      case ceoTelError || ceoEmailError:
        scrollTop += section2.current.offsetTop;
        window.scrollTo({ top: scrollTop, behavior: "smooth" });
        break;
      case descriptionError:
        scrollTop += section3.current.offsetTop;
        window.scrollTo({ top: scrollTop, behavior: "smooth" });
        break;
      case pfIndustrialTechError:
        scrollTop += section4.current.offsetTop;
        window.scrollTo({ top: scrollTop, behavior: "smooth" });
        break;
      case problemError:
        scrollTop += section5.current.offsetTop;
        window.scrollTo({ top: scrollTop, behavior: "smooth" });
        break;
      case solutionError:
        scrollTop += section6.current.offsetTop;
        window.scrollTo({ top: scrollTop, behavior: "smooth" });
        break;
      case fileError:
        scrollTop += section7.current.offsetTop;
        window.scrollTo({ top: scrollTop, behavior: "smooth" });
        break;
      case ApplyPathError:
        scrollTop += section8.current.offsetTop;
        window.scrollTo({ top: scrollTop, behavior: "smooth" });
        break;
      case checkedError:
        scrollTop += section9.current.offsetTop + section10.current.offsetTop;
        window.scrollTo({ top: scrollTop, behavior: "smooth" });
        break;
      default: {
        console.log("");
      }
    }
  }, [validationChecked]);
  useLayoutEffect(() => {
    updateTitle("Apply");
  }, []);
  return (
    <Container>
      <Banner bannerImg2048={bg} mode={mode}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: mode === "Mobile" ? "center" : "flex-start",
          }}
        >
          <div className="font-title-en" style={{ color: "var(--white)" }}>
            CONTACT
          </div>
        </div>
      </Banner>
      <ContactTabs selectedTab={ContactMenu.APPLY} />
      <ApplyBodyContainer className="limit__body" ref={bodyRef}>
        {mode === "Mobile" ? (
          <p className="font-body1">
            블루포인트와 함께
            <br />
            새로운 룰을 만들어갈
            <br />
            스타트업을 찾습니다!
          </p>
        ) : (
          <p className="font-body">
            블루포인트와 함께 새로운 룰을 만들어갈
            <br />
            스타트업을 찾습니다!
          </p>
        )}
        <Link to={`/contact/faq`} style={{ textDecoration: "none" }}>
          <span className="font-body3 qna" style={{ color: "var(--primary)" }}>
            자주 묻는 질문
            <Arrow className="qna__arrow" width={16} height={16} />
          </span>
        </Link>

        <ApplySection style={{ marginTop: mode === "Mobile" ? 80 : 100 }}>
          <div className="section__title__container">
            <span
              className={`section__title ${
                mode === "Mobile" ? "font-sub-title2" : "font-sub-title"
              }`}
            >
              기본 정보
            </span>
            <span
              className={`section__description ${
                mode === "Mobile" ? "font-body4" : "font-body3"
              }`}
            >
              기본 정보를 입력해주세요.
            </span>
          </div>
          <div className="section__row" ref={section1}>
            <ContactInput
              className="section__flex2"
              label="이름"
              inputType="text"
              placeHolder="대표자 성함을 입력해주세요"
              isError={ceoNameError}
              defaultValue={ceoName}
              onFocus={() => updateCeoNameError(false)}
              onChangeValue={(value) => updateCeoName(value)}
            />
            <ContactInput
              className="section__flex4"
              label="기업명"
              inputType="text"
              placeHolder="기업명을 입력해주세요."
              isError={companyNameError}
              defaultValue={companyName}
              onFocus={() => updateCompanyNameError(false)}
              onChangeValue={(value) => updateCompanyName(value)}
            />
          </div>
          <div className="section__row" ref={section2}>
            <ContactInput
              className="section__flex2"
              label="전화번호"
              inputType="phone"
              placeHolder="대표자 전화번호를 입력해주세요."
              isError={ceoTelError}
              defaultValue={ceoTel}
              onFocus={() => updateCeoTelError(false)}
              onChangeValue={(value) => updateCeoTel(value)}
            />
            <ContactInput
              className="section__flex4"
              label="이메일"
              inputType="text"
              placeHolder="대표자 이메일을 입력해주세요"
              isError={ceoEmailError}
              defaultValue={ceoEmail}
              onFocus={() => updateCeoEmailError(false)}
              onChangeValue={(value) => updateCeoEmail(value)}
            />
          </div>
          <div className="section__row" ref={section3}>
            <ContactTextArea
              label="아이템 요약(50자 이내)"
              maxLength={50}
              isError={descriptionError}
              defaultValue={description}
              onFocus={() => updateDescriptionError(false)}
              onChangeValue={(value) => updateDescription(value)}
            />
          </div>
        </ApplySection>

        <ApplySection style={{ marginTop: mode === "Mobile" ? 40 : 100 }}>
          <div className="section__title__container">
            <span
              className={`section__title ${
                mode === "Mobile" ? "font-sub-title2" : "font-sub-title"
              }`}
            >
              산업기술 분야
            </span>
          </div>
          <div className="section__row" ref={section4}>
            <SectorList
              isError={pfIndustrialTechError}
              defaultValue={pfIndustrialTech.map(
                (item) => item.acIndustrialTech
              )}
              onChange={(value) => {
                const sector = value.map((item, index) => {
                  return {
                    isMain: index === 0,
                    acIndustrialTech: {
                      acIndustrialTechId: item.acIndustrialTechId,
                      name: item.name,
                    },
                  };
                });
                updatePfIndustrialTechError(false);
                updatePfIndustrialTech(sector);
              }}
            />
          </div>
        </ApplySection>

        <ApplySection style={{ marginTop: 40 }}>
          <div className="section__title__container">
            <span
              className={`section__title ${
                mode === "Mobile" ? "font-sub-title2" : "font-sub-title"
              }`}
            >
              세부 내용
            </span>
            <span
              className={`section__description ${
                mode === "Mobile" ? "font-body4" : "font-body3"
              }`}
            >
              각 항목에 맞는 세부 내용을 기술해주세요.
            </span>
          </div>
          <div className="section__row" ref={section5}>
            <ContactTextArea
              label="해결하고자 하는 문제에 대해 설명해주세요. (500자 이내)"
              maxLength={500}
              isError={problemError}
              defaultValue={problem}
              onFocus={() => updateProblemError(false)}
              onChangeValue={(value) => {
                updateProblem(value);
              }}
            />
          </div>
          <div className="section__row" ref={section6}>
            <ContactTextArea
              label="그 문제를 해결한 방법에 대해 설명해주세요. (500자 이내)"
              maxLength={500}
              isError={solutionError}
              defaultValue={solution}
              onFocus={() => updateSolutionError(false)}
              onChangeValue={(value) => {
                updateSolution(value);
              }}
            />
          </div>
        </ApplySection>

        <ApplySection style={{ marginTop: mode === "Mobile" ? 40 : 100 }}>
          <div className="section__title__container">
            <span
              className={`section__title ${
                mode === "Mobile" ? "font-sub-title2" : "font-sub-title"
              }`}
            >
              사업계획서 첨부
            </span>
            <span
              className={`section__description ${
                mode === "Mobile" ? "font-body4" : "font-body3"
              }`}
            >
              자유양식으로 30MB 미만의 파일을 첨부해주세요.
            </span>
          </div>
          <div className="section__row" ref={section7}>
            <ContactFileUpload
              editable
              width={776}
              isError={fileError}
              defaultValue={file}
              onChangeFile={(file) => {
                updateFile(file);
                updateFileError(false);
              }}
            />
          </div>
        </ApplySection>

        <ApplySection style={{ marginTop: mode === "Mobile" ? 40 : 100 }}>
          <div className="section__title__container">
            <span
              className={`section__title ${
                mode === "Mobile" ? "font-sub-title2" : "font-sub-title"
              }`}
            >
              추가 정보
            </span>
            <span
              className={`section__description ${
                mode === "Mobile" ? "font-body4" : "font-body3"
              }`}
            >
              추가 정보가 있다면 기재해주세요. (500자 이내)
            </span>
          </div>
          <div className="section__row" ref={section8}>
            <ContactChecked
              label="블루포인트를 알게 된 경로"
              isError={ApplyPathError}
              checkedItem={applyPath}
              updateCheckedItem={updateApplyPath}
              extraMsg={applyExtraMsg}
              updateExtraMsg={updateApplyExtraMsg}
              onChangeValue={() => updateApplyPathError(false)}
            />
          </div>
          <div className="section__row" ref={section9}>
            <ContactTextArea
              label="추가 정보가 있다면 기재해 주세요. (500자 이내)"
              maxLength={500}
              defaultValue={additionalInformation}
              onChangeValue={(value) => updateAdditionalInformation(value)}
            />
          </div>
        </ApplySection>
        <div ref={section10}>
          <PrivacyPolicyCheckBox
            defaultCheck={checked}
            style={{ marginTop: 30 }}
            isError={checkedError}
            onChangeValue={(value) => {
              updateChecked(value);
              updateCheckedError(false);
            }}
          />
        </div>
        <SubmitButtonContainer>
          <DefaultButton
            className="font-sub-title4"
            type="submit"
            onClick={sendPortfolioInfo}
            width={194}
            height={60}
          >
            제출하기
          </DefaultButton>
        </SubmitButtonContainer>

        <ModalLayout
          isOpen={isSuccessApplyModal}
          borderRadius={0}
          toggle={() => updateSuccessApplyModal(false)}
        >
          <SuccessApplyModal
            close={() => {
              updateSuccessApplyModal(false);
            }}
          />
        </ModalLayout>
        <ModalLayout isOpen={isProgress} borderRadius={0} isProgress>
          <Spinner loading={isProgress} size={80} />
        </ModalLayout>
      </ApplyBodyContainer>
      <Footer />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 768px) {
  }
`;
const ApplyBodyContainer = styled.div`
  flex-grow: 1;
  width: 100%;
  display: flex;
  padding-top: 100px;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;

  .qna {
    cursor: pointer;
    .qna__arrow {
      transform: rotate(-45deg);
      path {
        fill: var(--primary);
      }
    }
  }

  .is__error {
    animation: shake 300ms;
  }
  @media only screen and (max-width: 768px) {
    padding-top: 40px;
    padding-bottom: 40px;
    .news__list {
      margin-top: 40px;
    }
  }
`;

const ApplySection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  .section__title__container {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: flex-end;

    border-bottom: 1px solid var(--grey-00);
    padding-bottom: 16px;

    .section__title {
      color: var(--grey-60);
    }

    .section__description {
      padding-bottom: 6px;
      color: var(--grey-30);
    }
  }

  .section__row {
    display: flex;
    flex-direction: row;
    gap: 24px;

    .section__flex1 {
      flex: 1;
    }
    .section__flex2 {
      flex: 2;
    }
    .section__flex3 {
      flex: 3;
    }
    .section__flex4 {
      flex: 4;
    }
  }

  @media only screen and (max-width: 768px) {
    .section__title__container {
      flex-direction: column;
      align-items: flex-start;
      gap: 0px;
    }

    .section__row {
      flex-direction: column;
      gap: 40px;
    }
  }
`;
const CheckBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;
const SubmitButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 160px;

  @media only screen and (max-width: 768px) {
    align-items: center;
    justify-content: center;
    margin-top: 80px;
  }
`;
export default Apply;
