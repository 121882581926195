import React from "react";
import styled from "@emotion/styled";
import DisplayType from "utils/displayType";
import { ReactComponent as SuccessCheck } from "../../assets/success_apply.svg";
import { ReactComponent as FailCheck } from "../../assets/fail_icon.svg";
import { ReactComponent as CloseBtn } from "../../assets/modal_close.svg";

interface Props {
  isSuccess: boolean;
  close: () => void;
}
const ResultBlueletterModal: React.FC<Props> = (props) => {
  const mode = DisplayType();
  const successMessage =
    "블루레터를 신청해주셔서 감사합니다.\n앞으로 매월 신규 블루레터를 보내드리겠습니다. ";
  const failMessage = "이미 구독 중입니다. 감사합니다.";
  return (
    <Container>
      <ContentsContainer>
        {mode !== "Mobile" && (
          <CloseBtn className="close__btn" onClick={props.close} />
        )}
        {props.isSuccess ? <SuccessCheck /> : <FailCheck />}
        <div
          className={`success__apply__title ${
            mode === "Mobile" ? "font-sub-title2" : "font-sub-title"
          }`}
        >
          {props.isSuccess
            ? "구독 신청이 완료되었습니다!"
            : "구독 신청에 실패했습니다."}
        </div>
        <p
          className={`success__apply__content ${
            mode === "Mobile" ? "font-body4" : "font-body3"
          }`}
        >
          {props.isSuccess ? successMessage : failMessage}
        </p>
      </ContentsContainer>
      {mode === "Mobile" && (
        <div className="mobile__close" onClick={props.close}>
          확인
        </div>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;

  .mobile__close {
    width: 100%;
    height: 56px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--grey-00);
    color: var(--primary);
  }
`;

const ContentsContainer = styled.div`
  position: relative;
  width: 716px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  padding: 80px;

  .close__btn {
    position: absolute;
    top: 24px;
    right: 24px;
    width: 48px;
    height: 48px;
    cursor: pointer;
    background-color: transparent;
    transition: background-color 0.2s ease-in;
    border-radius: 8px;
    :hover {
      background-color: var(--grey-00);
    }
  }

  .success__apply__title {
    text-align: center;
  }
  .success__apply__content {
    text-align: center;
  }

  @media only screen and (max-width: 768px) {
    width: calc(100vw - 64px);
    padding: 40px 24px;

    .success__apply__title {
      text-align: center;
    }
    .success__apply__content {
      text-align: center;
      width: 100%;
    }
  }
`;

export default React.memo(ResultBlueletterModal);
