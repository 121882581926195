import React, { useEffect, useRef } from "react";
import styled from "@emotion/styled";
import YouTube, { Options } from "react-youtube";
import DisplayType from "utils/displayType";
import { Mode } from "admin/type/type";
import BallBounceAnim from "router/main/components/BallBounceAnim";
import { Vision } from "./styles";
import { ReactComponent as ScrollDown } from "../../assets/scroll_down.svg";
import mainVideo from "../../assets/media/main_video_1080.mp4";
import mobileVideoMain from "../../assets/m_video_main.png";

interface Props {
  isMobile: boolean;
}
const Video: React.FC<Props> = () => {
  const visionText = `세상을 바꾸는\n새로운 방법`;
  const ref = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const videoRef = useRef<any>(null);
  const mode = DisplayType();
  return (
    <VideoContainer ref={containerRef} mode={mode}>
      {mode !== "Mobile" && (
        <video
          className="youtube"
          src={mainVideo}
          width={"100vw"}
          height={"100vh"}
          muted
          autoPlay
          loop
        />
      )}
      {/* <Vision ref={ref} imgUrl={mobileVideoMain}>
        <pre className="font-title" style={{ color: "var(--white)" }}>
          {visionText}
        </pre>
      </Vision> */}
      <MovileVistion>
        <div className="video__text">
          <span className="font-light-48">모든</span>
          <span className="font-light-48">혁신의</span>
          <strong>
            <span className="font-bold-48" style={{ lineHeight: 1 }}>
              시작점
            </span>
          </strong>
        </div>
        <BallBounceAnim />
      </MovileVistion>
      <ScrollDown
        className="scroll__down"
        width={64}
        height={64}
        onClick={() => {
          if (containerRef.current) {
            window.scrollTo({
              top:
                containerRef.current.offsetHeight -
                (mode === "Mobile" ? 60 : 80),
              behavior: "smooth",
            });
          }
        }}
      />
    </VideoContainer>
  );
};

const VideoContainer = styled.div<{ mode: Mode }>`
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  .youtube {
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
  }
  .scroll__down {
    position: absolute;
    bottom: 48px;
    left: calc(50% - 32px);
    cursor: pointer;
    animation: arrowMove 1.6s infinite;

    ${(props) =>
      props.mode !== "Mobile" &&
      `
      :hover {
        path {
          stroke: var(--primary);
        }
        line {
          stroke: var(--primary);
        }
      }
    `}

    @media screen and (max-width: 768px) {
      bottom: 24px;
      path {
        stroke: var(--black);
      }
      line {
        stroke: var(--black);
      }
      :hover {
        path {
          stroke: var(--white);
        }
        line {
          stroke: var(--white);
        }
      }
    }
  }

  @keyframes arrowMove {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(8px);
    }
    100% {
      transform: translateY(0);
    }
  }
`;

const MovileVistion = styled.div`
  display: none;
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;

    .video__text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 12px;
    }
  } ;
`;
export default Video;
