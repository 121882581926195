import React, { useEffect, useRef, useState } from "react";
import Styled from "@emotion/styled";

import axios from "axios";
import { imgCategory } from "admin/type/type";
import { ReactComponent as Delete } from "../../../../assets/cancel_red.svg";
import * as api from "../../../../api/api";

interface Props {
  className?: string;
  profile?: string;
  callback?: (url: string, key: string) => void;
  deleteImage?: () => void;
}
export const ProfileUpload: React.FC<Props> = (props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [render, setRender] = useState<boolean>(false);
  const reRender = () => setRender((prevState) => !prevState);

  const uploadFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (!e.target.files || e.target.files.length === 0) return false;
    try {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      formData.append("category", imgCategory.USER_IMG);
      const result = (await axios.post(api.uploadAndGetUrl(), formData)).data;
      if (result.length > 0) {
        if (props.callback) props.callback(result[0].url, result[0].key);
      }
      e.target.value = "";
    } catch (error) {
      console.log(error);
    }
  };

  const deleteImage = () => {
    if (props.deleteImage) props.deleteImage();
  };

  useEffect(() => {
    reRender();
  }, [props.profile]);
  return (
    <Container>
      <div className="profile__container">
        {props.profile ? (
          <div className="profile__img__container">
            <div
              className="overlay"
              style={{ backgroundImage: `url(${props.profile})` }}
            />
            <div className="blur">
              <Delete className="delete" onClick={deleteImage} />
            </div>
            <div
              className="profile"
              style={{ backgroundImage: `url(${props.profile})` }}
            />
          </div>
        ) : (
          <div
            className="empty"
            onClick={() => {
              try {
                if (inputRef.current) {
                  inputRef.current.click();
                }
              } catch (error) {
                console.log(error);
              }
            }}
          >
            비어있음
          </div>
        )}
      </div>
      <input
        type="file"
        style={{ display: "none" }}
        ref={inputRef}
        accept="image/*"
        onChange={uploadFile}
      />
    </Container>
  );
};

const Container = Styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 24px;

  .profile__container {
    position: relative;
    width: 100%;
    height: 100%;

    border: 1px solid var(--black);
    box-sizing: border-box;
    border-radius: 10px;
    .empty {
      display: flex;
      align-items: center;
      justify-content: center;
      width: calc(100% - 1px);
      height: calc(100% - 1px);
      cursor: pointer;
    }

    .profile__img__container {
      position: relative;
      width: 100%;
      height: 100%;
      .profile {
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        background-size: cover;
        background-position: center center;
        clip-path: circle(45% at 50% 50%);
      }

      .blur {
        position: absolute;
        display: none;
        flex-direction: row;
        justify-content: flex-end;
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        border-radius: 10px;
        background-color: rgba(0, 0, 0, 0.2);
        padding: 10px;
        .delete {
          cursor: pointer;
        }
      }

      .overlay {
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        border-radius: 10px;
        background-color: var(--white);
        background-size: cover;
        background-position: center center;
      }

      :hover {
        .blur {
          display: flex;
        }
        .profile {
          background-color: var(--white);
        }
      }
    }
  }
  `;
