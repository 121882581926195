import React from "react";
import styled from "@emotion/styled";
import DefaultButton from "../button/DefaultButton";

interface Props {
  type?: "confirm" | "alert";
  title?: string;
  message: string;
  positiveTitle?: string;
  negativeTitle?: string;
  onClickPositiveBtn?: () => void;
  onClickNegativeBtn?: () => void;
}
const ConfirmModal: React.FC<Props> = ({
  type = "confirm",
  title = "확인하기",
  message,
  positiveTitle = "확인",
  negativeTitle = "취소",
  onClickPositiveBtn,
  onClickNegativeBtn,
}) => {
  return (
    <Container>
      <Header>
        <div className="font-bold-20">{title}</div>
      </Header>
      <Body className="font-regular-14" style={{ textAlign: "center" }}>
        {message}
      </Body>
      <Footer>
        {type === "confirm" ? (
          <>
            <DefaultButton onClick={onClickNegativeBtn} type="text">
              {negativeTitle}
            </DefaultButton>
            <DefaultButton onClick={onClickPositiveBtn} type="submit">
              {positiveTitle}
            </DefaultButton>
          </>
        ) : (
          <DefaultButton onClick={onClickPositiveBtn} type="submit">
            {positiveTitle}
          </DefaultButton>
        )}
      </Footer>
    </Container>
  );
};

const Container = styled.div`
  width: 480px;
  height: 200px;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  width: 100%;
  height: 56px;
  min-height: 56px;
  max-height: 56px;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 24px;

  border-bottom: 1px solid var(--grey-20);
`;
const Body = styled.div`
  flex-grow: 1;
  padding: 16px;
  gap: 16px;
  white-space: pre-wrap;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
const Footer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
  padding: 16px 24px;
`;
export default ConfirmModal;
