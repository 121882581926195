import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { NotionInput } from "admin/component/input/NotionInput";
import DefaultButton from "admin/component/button/DefaultButton";
import { imgCategory } from "admin/type/type";
import { getYMD } from "utils/commonUtil";
import DocumentEditor from "admin/component/editor/DocumentEditor";
import axios from "axios";
import { IRAnnouncementProps } from "../IRAnnouncement";
import FileUploaderDND, { DNDFileType } from "./FileUploaderDND";
import * as api from "../../../../api/api";

interface Props {
  announcementData?: IRAnnouncementProps;
  close?: () => void;
  callback?: () => void;
}

const IRAnnouncementDetail: React.FC<Props> = (props) => {
  const [title, updateTitle] = useState<string>("");
  const [postingDate, updatePostingDate] = useState<string>("");
  const [file, updateFile] = useState<DNDFileType[] | undefined>(undefined);
  const content = useRef<string>("");

  const validationChecking = (): boolean => {
    return false;
  };

  const createAnnouncementPost = async () => {
    // if (!file) return alert("파일을 선택해주세요.");
    // if (!validationChecking()) return;
    let uploadedFiles: any[] = [];

    try {
      if (file) {
        const formData = new FormData();
        file
          .filter((item) => !item.isUploaded)
          .forEach((item) => {
            formData.append("file", item.file as File);
          });
        const fileUploadResult = await axios.post(
          api.uploadIRAnnouncementFile(),
          formData
        );
        console.log(fileUploadResult);
        if (fileUploadResult.status === 201) {
          const files = fileUploadResult.data.map((item: any) => {
            const _file = file.find(
              (fileItem) => fileItem.file.name === item.name
            );
            return {
              fileKey: item.fileKey,
              name: item.name,
              type: _file?.type,
            };
          });
          uploadedFiles = [
            ...file.filter((item) => item.isUploaded).map((item) => item.file),
            ...files,
          ];
        }
      }
      const param = {
        postingDate,
        title,
        content: content.current,
        hpAnnouncementFile: [...uploadedFiles],
      };

      if (props.announcementData) {
        const result = await axios.put(api.updateIRAnnouncement(), {
          hpAnnouncementId: props.announcementData.hpAnnouncementId,
          ...param,
        });
        console.log(result);
        if (result.status === 200 && props.callback) {
          props.callback();
        }
      } else {
        const result = await axios.post(api.createIRAnnouncement(), param);
        console.log(result);
        if (result.status === 201 && props.callback) {
          props.callback();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAnnouncementDetailData = () => {
    if (props.announcementData === undefined) return;

    content.current = props.announcementData.content;
    updatePostingDate(getYMD(props.announcementData.postingDate));
    updateTitle(props.announcementData.title);
    updateFile([
      ...props.announcementData.hpAnnouncementFile.map((item, index) => {
        return {
          id: index,
          isUploaded: true,
          type: item.type,
          file: item,
        };
      }),
    ]);
  };

  useLayoutEffect(() => {
    if (props.announcementData !== undefined) {
      getAnnouncementDetailData();
    }
  }, [props.announcementData]);

  return (
    <Container className="scroll__invisible">
      <div className="ir__announcement__detail__header font-bold-18">
        <span>공고자료</span>
        <DefaultButton
          onClick={() => {
            createAnnouncementPost();
          }}
        >
          {props.announcementData ? "수정하기" : "생성하기"}
        </DefaultButton>
      </div>
      <div className="ir__announcement__detail__body">
        <div className="ir__announcement__detail__body__row">
          <div style={{ flex: 2 }}>
            <NotionInput
              label="제목"
              hasUnderLine
              editable
              textType="text"
              defaultValue={title}
              onChange={(e) => {
                updateTitle(e);
              }}
            />
          </div>
          <div style={{ flex: 1 }}>
            <NotionInput
              label="게시일"
              hasUnderLine
              editable
              textType="date"
              max="9999-12-31"
              placeholder="연도. 월. 일"
              defaultValue={postingDate}
              onChange={(e) => {
                updatePostingDate(e);
              }}
            />
          </div>
        </div>

        <div className="ir__announcement__detail__body__row">
          <FileUploaderDND
            title="첨부서류"
            filesDirection="row"
            maxFile={3}
            multiple
            files={file}
            editable
            fileuploadCallback={(files) => {
              console.log(files);
              updateFile([...files]);
            }}
          />
        </div>

        <div
          className="ir__announcement__detail__body__row"
          style={{ height: 500 }}
        >
          <DocumentEditor
            isEditable
            initialValue={content.current}
            category={imgCategory.ANNOUNCEMENT}
            onEditorChange={(editor) => {
              content.current = editor;
            }}
          />
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  width: 100vw;
  max-width: 780px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  .ir__announcement__detail__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px 24px;

    border-bottom: 1px solid var(--grey);
  }
  .ir__announcement__detail__body {
    width: 100%;

    display: flex;
    flex-direction: column;
    padding: 24px;
    gap: 24px;

    padding-bottom: 80px;

    .ir__announcement__detail__body__row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 24px;
    }
  }
`;

const IRTypeContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  .ir__type__title {
    width: 100px;
  }

  .ir__type__items {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;

    .ir__type__item {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 4px;
      cursor: pointer;
    }
  }
`;

const IRDateContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 16px;
`;
export default IRAnnouncementDetail;
