import { Logout } from "api/api";
import React from "react";
import { Accordion } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { NavigationLayout } from "./styles";

interface Props {
  activePageId: number;
}

export interface PageProps {
  id: number;
  icon?: string;
  name: string;
  link?: string;
}

export const Pages: PageProps[] = [
  { id: 1, name: "Team", link: "/dash/team" },
  { id: 2, name: "Portfolio", link: "/dash/portfolio" },
  // { id: 3, name: "PortfolioHeaderList", link: "/dash/portfolio/header-list" },
  { id: 3, name: "Insight" },
  { id: 4, name: "Event", link: "/dash/event" },
  { id: 5, name: "Content", link: "/dash/contents" },
  { id: 6, name: "News", link: "/dash/news" },
  { id: 7, name: "BlueLetter", link: "/dash/blueletter" },
  { id: 8, name: "Banner", link: "/dash/banner" },
  { id: 9, name: "IR" },
  { id: 10, name: "IR자료실", link: "/dash/ir-archives" },
  { id: 11, name: "공고", link: "/dash/announcement" },
  { id: 12, name: "Popup", link: "/dash/popup" },
  { id: 12, name: "Logout" },
];

const Navigation: React.FC<Props> = (props) => {
  const navigate = useNavigate();

  const NavItem: React.FC<{
    navItem: PageProps;
    isActive: boolean;
    isLink: boolean;
    onClick?: () => void;
  }> = (navProps) => {
    return (
      <div
        className={`nav__item ${
          navProps.isActive ? "font-bold-24" : "font-regular-20"
        }`}
        onClick={navProps.onClick}
        onKeyDown={(e) => {}}
      >
        {navProps.navItem.name}
      </div>
    );
  };

  const goToLink = (link?: string) => {
    if (!link) return;

    navigate(link);
  };
  return (
    <NavigationLayout>
      <div className="navgation__container scroll__invisible">
        <div className="navigation">
          <div className="nav__title font-bold-28">블루포인트 파트너스</div>
          <div className="divider" />
          <div className="nav__group">
            <NavItem
              navItem={Pages[0]}
              isLink
              isActive={props.activePageId === Pages[0].id}
              onClick={() => {
                goToLink(Pages[0].link);
              }}
            />
            <NavItem
              navItem={Pages[1]}
              isLink
              isActive={props.activePageId === Pages[1].id}
              onClick={() => {
                goToLink(Pages[1].link);
              }}
            />
          </div>
          <div className="divider" />
          <div className="nav__group">
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header as="div">
                  <NavItem
                    navItem={Pages[2]}
                    isLink={false}
                    isActive={
                      props.activePageId >= Pages[3].id &&
                      props.activePageId <= Pages[7].id
                    }
                  />
                </Accordion.Header>
                <Accordion.Body>
                  <NavItem
                    navItem={Pages[3]}
                    isLink
                    isActive={props.activePageId === Pages[3].id}
                    onClick={() => {
                      goToLink(Pages[3].link);
                    }}
                  />
                  <NavItem
                    navItem={Pages[4]}
                    isLink
                    isActive={props.activePageId === Pages[4].id}
                    onClick={() => {
                      goToLink(Pages[4].link);
                    }}
                  />
                  <NavItem
                    navItem={Pages[5]}
                    isLink
                    isActive={props.activePageId === Pages[5].id}
                    onClick={() => {
                      goToLink(Pages[5].link);
                    }}
                  />
                  <NavItem
                    navItem={Pages[6]}
                    isLink
                    isActive={props.activePageId === Pages[6].id}
                    onClick={() => {
                      goToLink(Pages[6].link);
                    }}
                  />
                  <NavItem
                    navItem={Pages[7]}
                    isLink
                    isActive={props.activePageId === Pages[7].id}
                    onClick={() => {
                      goToLink(Pages[7].link);
                    }}
                  />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
          <div className="divider" />
          <div className="nav__group">
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header as="div">
                  <NavItem
                    navItem={Pages[8]}
                    isLink={false}
                    isActive={
                      props.activePageId >= Pages[9].id &&
                      props.activePageId <= Pages[10].id
                    }
                  />
                </Accordion.Header>
                <Accordion.Body>
                  <NavItem
                    navItem={Pages[9]}
                    isLink
                    isActive={props.activePageId === Pages[9].id}
                    onClick={() => {
                      goToLink(Pages[9].link);
                    }}
                  />
                  <NavItem
                    navItem={Pages[10]}
                    isLink
                    isActive={props.activePageId === Pages[10].id}
                    onClick={() => {
                      goToLink(Pages[10].link);
                    }}
                  />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
          <div className="divider" />

          <NavItem
            navItem={Pages[11]}
            isLink
            isActive={props.activePageId === Pages[11].id}
            onClick={() => {
              goToLink(Pages[11].link);
            }}
          />

          <div className="divider" />
          <NavItem
            navItem={Pages[12]}
            isLink={false}
            isActive={false}
            onClick={() => {
              Logout();
            }}
          />
        </div>
      </div>
      <div className="contents__container">{props.children}</div>
    </NavigationLayout>
  );
};
export default Navigation;
