import React from "react";
import styled from "@emotion/styled";
import DisplayType from "utils/displayType";

interface Props {
  className?: string;
  labelType?: "type1" | "type2" | "type3" | "type4";
  children: string;
  onClickChip?: () => void;
}
const Label: React.FC<Props> = (props) => {
  const mode = DisplayType();
  const { labelType = "type1" } = props;
  const getTypeStyle = (): React.CSSProperties => {
    if (labelType === "type2") {
      return {
        color: "var(--blue-01)",
        background: "var(--blue-03)",
        border: `1px solid var(--blue-03)`,
      };
    }

    if (labelType === "type3") {
      return {
        color: "var(--white)",
        background: "var(--green-01)",
        border: `1px solid var(--green-01)`,
      };
    }

    if (labelType === "type4") {
      return {
        color: "var(--green-01)",
        background: "var(--green-03)",
        border: `1px solid var(--green-03)`,
      };
    }

    return {
      color: "var(--primary)",
      background: "var(--white)",
      border: `1px solid var(--primary)`,
    };
  };
  return (
    <Container
      className={`${props.className || ""} ${
        mode === "Mobile" ? "font-button3" : "font-button3"
      }`}
      style={getTypeStyle()}
    >
      {props.children}
    </Container>
  );
};

const Container = styled.div<{
  mainColor?: string;
}>`
  padding: 3px 15px 2px 15px;
  width: fit-content;
  height: fit-content;
  white-space: nowrap;
  cursor: default;
  background-color: var(--white);
`;

export default React.memo(Label);
