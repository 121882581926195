import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Button } from "react-bootstrap";
import {
  CompanyProjectType,
  HomepagePortfolioInfoType,
  LatticePortfolioInfoType,
} from "admin/type/type";
import axios from "axios";
import * as api from "../../../../api/api";
import { ReactComponent as PrevArrow } from "../../../../assets/angle_left.svg";
import { ReactComponent as NextArrow } from "../../../../assets/angle_right.svg";
import { ReactComponent as Copy } from "../../../../assets/copy.svg";
import PortfolioInfoByHomepage from "./PortfolioInfoByHomepage";
import PortfolioInfoByLattice from "./PortfolioInfoByLattice";

interface Props {
  portfolioId: number[];
  close?: () => void;
}
export interface PortfolioUpdateInfo {
  portfolioId: number;
  latticePortfolio?: LatticePortfolioInfoType;
  selectedObjectKeyLattice: string[];
  homepagePortfolio?: HomepagePortfolioInfoType;
}
const PortfolioCompareModal: React.FC<Props> = (props) => {
  const [portfolioData, updatePortfolioData] = useState<PortfolioUpdateInfo[]>(
    []
  );
  const [reload, setReload] = useState<boolean>(false);
  const [currentIndex, updateCurrentIndex] = useState<number>(-1);
  const [hasPrev, updateHasPrev] = useState<boolean>(false);
  const [hasNext, updateHasNext] = useState<boolean>(false);

  const reloading = () => setReload((prevState) => !prevState);

  const onChangeHomepagePortfolioData = (data: HomepagePortfolioInfoType) => {
    const newData = portfolioData.map((item, index) => {
      if (index === currentIndex) {
        return { ...item, homepagePortfolio: { ...data } };
      }
      return { ...item };
    });
    updatePortfolioData([...newData]);
  };
  const copyAndPasteData = () => {
    const curPortfolio = portfolioData[currentIndex];
    curPortfolio.selectedObjectKeyLattice.forEach((key: string) => {
      if (curPortfolio.homepagePortfolio && curPortfolio.latticePortfolio) {
        if (key === "companyImgKey,companyImgUrl") {
          curPortfolio.homepagePortfolio.companyImgKey =
            curPortfolio.latticePortfolio.companyImgKey;
          curPortfolio.homepagePortfolio.companyImgUrl =
            curPortfolio.latticePortfolio.companyImgUrl;
        } else if (key === "addr1,addr2,addr3,addrDetail") {
          curPortfolio.homepagePortfolio.addr1 =
            curPortfolio.latticePortfolio.addr1;
          curPortfolio.homepagePortfolio.addr2 =
            curPortfolio.latticePortfolio.addr2;
          curPortfolio.homepagePortfolio.addr3 =
            curPortfolio.latticePortfolio.addr3;
          curPortfolio.homepagePortfolio.addrDetail =
            curPortfolio.latticePortfolio.addrDetail;
        } else if (key.includes("pfProject")) {
          const projectId = parseInt(key.split("_")[1], 10);
          const projectIndex = parseInt(key.split("_")[2], 10);
          const latticeProject = curPortfolio.latticePortfolio.pfProject.find(
            (item) => item.pfProjectId === projectId
          );

          if (latticeProject === undefined) return;

          const homepageProject =
            curPortfolio.homepagePortfolio.hpPfProject.find(
              (item) => item.pfProjectId === projectId
            );

          if (homepageProject !== undefined) {
            const newProject: CompanyProjectType[] =
              curPortfolio.homepagePortfolio.hpPfProject.map(
                (item): CompanyProjectType => {
                  if (item.pfProjectId === projectId) {
                    return { ...latticeProject };
                  }
                  return { ...item };
                }
              );
            curPortfolio.homepagePortfolio.hpPfProject = newProject;
          } else {
            curPortfolio.homepagePortfolio.hpPfProject.splice(
              projectIndex,
              0,
              latticeProject
            );
          }
        } else {
          curPortfolio.homepagePortfolio[key] =
            curPortfolio.latticePortfolio[key];
        }
      }
    });
    curPortfolio.selectedObjectKeyLattice = [];

    const newData = portfolioData.map((item) => {
      return item;
    });

    updatePortfolioData([...newData]);
  };

  const getPortfolio = async () => {
    try {
      const latticeResult = await axios.get(
        api.getLatticePortfolio(portfolioData[currentIndex].portfolioId)
      );
      const homepageResult = await axios.get(
        api.getHomepagePortfolio(portfolioData[currentIndex].portfolioId)
      );

      if (latticeResult.status === 200 && homepageResult.status === 200) {
        const newData = portfolioData.map((item, index) => {
          if (index === currentIndex) {
            return {
              ...item,
              latticePortfolio: latticeResult.data,
              homepagePortfolio: homepageResult.data,
            };
          }
          return item;
        });

        updatePortfolioData([...newData]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const selectObject = (key: string) => {
    const newData = portfolioData.map((item, index) => {
      if (index === currentIndex) {
        const newSelectedObjectKey: string[] = [
          ...item.selectedObjectKeyLattice,
        ];
        if (newSelectedObjectKey.includes(key)) {
          const index = newSelectedObjectKey.findIndex((item) => item === key);
          newSelectedObjectKey.splice(index, 1);
        } else {
          newSelectedObjectKey.push(key);
        }

        return { ...item, selectedObjectKeyLattice: newSelectedObjectKey };
      }
      return item;
    });
    updatePortfolioData([...newData]);
  };

  const savePortfolioInfo = async () => {
    try {
      const result = await axios.post(
        api.upsertHomepagePortfolio(),
        portfolioData[currentIndex].homepagePortfolio
      );
      if (result.status === 201) {
        if (portfolioData.length === 1) {
          // 문닫자
          if (props.close) props.close();
        } else if (currentIndex + 1 > portfolioData.length - 1) {
          updateCurrentIndex(0);
          portfolioData.splice(currentIndex, 1);
        } else {
          // 다음 주소로 가기
          portfolioData.splice(currentIndex, 1);
          updatePortfolioData([...portfolioData]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (currentIndex === -1) return;
    if (
      portfolioData[currentIndex].latticePortfolio === undefined ||
      portfolioData[currentIndex].homepagePortfolio === undefined
    ) {
      getPortfolio();
    }

    updateHasPrev(portfolioData.length !== 1 && currentIndex !== 0);
    updateHasNext(
      portfolioData.length !== 1 && currentIndex !== portfolioData.length - 1
    );
  }, [currentIndex, portfolioData]);

  useEffect(() => {
    const portfolioList: PortfolioUpdateInfo[] = props.portfolioId.map(
      (item): PortfolioUpdateInfo => {
        return { portfolioId: item, selectedObjectKeyLattice: [] };
      }
    );
    updatePortfolioData(portfolioList);
    updateCurrentIndex(0);
  }, []);
  return (
    <Container>
      <Header>
        <div className={hasPrev ? "arrow__container" : ""}>
          {hasPrev && (
            <PrevArrow
              className="icon"
              onClick={() => {
                updateCurrentIndex(currentIndex - 1);
              }}
            />
          )}
        </div>
        <div className="font-bold-18">
          {currentIndex !== -1
            ? portfolioData[currentIndex].homepagePortfolio?.companyName
            : ""}
        </div>
        <div className={hasNext ? "arrow__container" : ""}>
          {hasNext && (
            <NextArrow
              className="icon"
              onClick={() => {
                updateCurrentIndex(currentIndex + 1);
              }}
            />
          )}
        </div>
      </Header>
      <BtnContainer>
        <Button
          onClick={() => {
            savePortfolioInfo();
          }}
        >
          완료
        </Button>
      </BtnContainer>
      <Body>
        <div className="portfolio__container">
          {portfolioData.length > 0 && (
            <PortfolioInfoByLattice
              portfolioId={portfolioData[currentIndex].portfolioId}
              selectedObjectKeyLattice={
                portfolioData[currentIndex].selectedObjectKeyLattice
              }
              latticePortfolioInfo={
                portfolioData[currentIndex].latticePortfolio
              }
              selectObject={selectObject}
            />
          )}
        </div>
        <div className="copy__btn__contaeinr">
          <div className="copy" onClick={copyAndPasteData}>
            <Copy width={24} height={24} />
          </div>
        </div>
        <div className="portfolio__container">
          {portfolioData.length > 0 && (
            <PortfolioInfoByHomepage
              portfolioId={portfolioData[currentIndex].portfolioId}
              portfolioData={portfolioData[currentIndex]}
              onChangePortfolioData={(data) => {
                onChangeHomepagePortfolioData(data);
              }}
            />
          )}
        </div>
      </Body>
    </Container>
  );
};

const Container = styled.div`
  width: 80vw;
  height: 90vh;
  display: flex;
  flex-direction: column;
  background-color: var(--grey-10);
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: var(--white);
  padding: 10px 24px;
  border-bottom: 1px solid var(--grey-10);
  .arrow__container {
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0px;
    background-color: var(--white);
    transition: all 0.1s ease-in;

    .icon {
      width: 50%;
      height: 50%;
    }
    :hover {
      background-color: var(--grey-00);
      border-radius: 8px;
    }
  }
`;
const BtnContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
  padding-right: 24px;
  gap: 24px;
`;
const Body = styled.div`
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 24px 24px 24px;
  gap: 24px;
  overflow: scroll;
  .portfolio__container {
    flex: 1;
    height: 100%;
    background-color: var(--white);
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
  }
  .copy__btn__contaeinr {
    width: 50px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .copy {
      width: 48px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      background-color: var(--white);
      transition: all 0.1s ease-in;
      cursor: pointer;
      :hover {
        background-color: var(--grey-00);
        border-radius: 8px;
      }
    }
  }
`;

export default React.memo(PortfolioCompareModal);
