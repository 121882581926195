import React, {
  ChangeEvent,
  useCallback,
  useRef,
  useState,
  useEffect,
  CSSProperties,
} from "react";
import styled from "@emotion/styled";
import axios from "axios";
import { MoonLoader } from "react-spinners";
import { getExtention } from "utils/commonUtil";
import { ReactComponent as Docs } from "../../../../assets/large_docs.svg";
import { ReactComponent as File } from "../../../../assets/large_file.svg";
import { ReactComponent as Jpg } from "../../../../assets/large_jpg.svg";
import { ReactComponent as Pdf } from "../../../../assets/large_pdf.svg";
import { ReactComponent as Png } from "../../../../assets/large_png.svg";
import { ReactComponent as Xls } from "../../../../assets/large_xls.svg";
import { ReactComponent as Zip } from "../../../../assets/large_zip.svg";
import { ReactComponent as Empty } from "../../../../assets/large_empty_file.svg";
import { ReactComponent as Delete } from "../../../../assets/file_delete.svg";

export interface DNDFileType {
  id: number;
  isUploaded: boolean;
  type: string;
  file:
    | File
    | {
        hpAnnouncementFileId: number;
        fileKey: string;
        name: string;
        type?: string;
      };
}

interface Props {
  files?: DNDFileType[];
  filesDirection?: "column" | "row";
  title?: string;
  multiple?: boolean;
  editable?: boolean;
  maxFile?: number;
  onlyDownload?: boolean;
  style?: CSSProperties;
  fileuploadCallback: (files: any) => void;
}

const FileUploaderDND: React.FC<Props> = (props) => {
  const { maxFile = 20 } = props;
  const { filesDirection = "column" } = props;
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [files, setFiles] = useState<DNDFileType[]>([]);
  const [fileUploading, updateFileUploading] = useState<boolean>(false);

  const dragRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const fileId = useRef<number>(0);

  const getTypeIcon = (type: string | undefined) => {
    if (type === undefined) return null;
    switch (type) {
      case "pdf":
        return <Pdf className="file__icon" />;
      case "xls":
        return <Xls className="file__icon" />;
      case "docs":
        return <Docs className="file__icon" />;
      case "jpg":
        return <Jpg className="file__icon" />;
      case "png":
        return <Png className="file__icon" />;
      case "zip":
        return <Zip className="file__icon" />;
      default:
        return <File className="file__icon" />;
    }
  };

  const onChangeFiles = useCallback(
    async (e: ChangeEvent<HTMLInputElement> | any) => {
      try {
        let selectFiles: File[] = [];
        const maxSize = 10 * 1024 * 1024; // 10MB

        if (e.type === "drop") {
          selectFiles = e.dataTransfer.files;
        } else {
          selectFiles = e.target.files;
        }

        if (files.length + selectFiles.length > maxFile) {
          return alert(`업로드 파일이 최대 ${maxFile}개를 넘길 수 없습니다.`);
        }

        let selectedFileStartId = Math.max(...files.map((item) => item.id));

        console.log(files);
        console.log(selectFiles);

        const newFiles = [...files];
        for (const _file of selectFiles) {
          newFiles.push({
            id: selectedFileStartId++,
            isUploaded: false,
            type: getExtention(_file.type),
            file: _file,
          });
        }

        props.fileuploadCallback(newFiles);
      } catch (error) {
        console.log(error);
      }
    },
    [files]
  );

  const deleteFile = (index: number) => {
    if (!files || files.length === 0) return;

    files.splice(index, 1);
    props.fileuploadCallback(files);
  };

  const handleDragIn = useCallback((e: DragEvent): void => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDragOut = useCallback((e: DragEvent): void => {
    e.preventDefault();
    e.stopPropagation();

    setIsDragging(false);
  }, []);

  const handleDragOver = useCallback((e: DragEvent): void => {
    e.preventDefault();
    e.stopPropagation();

    if (e.dataTransfer!.files) {
      setIsDragging(true);
    }
  }, []);

  const handleDrop = useCallback(
    (e: DragEvent): void => {
      e.preventDefault();
      e.stopPropagation();

      onChangeFiles(e);
      setIsDragging(false);
    },
    [onChangeFiles]
  );

  const initDragEvents = useCallback((): void => {
    if (dragRef.current !== null) {
      dragRef.current.addEventListener("dragenter", handleDragIn);
      dragRef.current.addEventListener("dragleave", handleDragOut);
      dragRef.current.addEventListener("dragover", handleDragOver);
      dragRef.current.addEventListener("drop", handleDrop);
    }
  }, [handleDragIn, handleDragOut, handleDragOver, handleDrop]);

  const resetDragEvents = useCallback((): void => {
    if (dragRef.current !== null) {
      dragRef.current.removeEventListener("dragenter", handleDragIn);
      dragRef.current.removeEventListener("dragleave", handleDragOut);
      dragRef.current.removeEventListener("dragover", handleDragOver);
      dragRef.current.removeEventListener("drop", handleDrop);
    }
  }, [handleDragIn, handleDragOut, handleDragOver, handleDrop]);

  useEffect(() => {
    initDragEvents();

    return () => resetDragEvents();
  }, [initDragEvents, resetDragEvents]);

  useEffect(() => {
    setFiles([...(props.files || [])]);
  }, [props.files]);

  return (
    <Container className="DragDrop" ref={dragRef} style={props.style}>
      {props.title && (
        <DNDTitle className="font-bold-14">{props.title}</DNDTitle>
      )}
      <Files
        className="DragDrop-Files"
        style={{ flexDirection: filesDirection }}
      >
        {files.length > 0 &&
          files.map((file: any, index) => {
            const name = file.file.name;
            return (
              <FileItem key={index}>
                <a className="file__content" href={"/"}>
                  {getTypeIcon(file.type)}
                  <span className="file__title">{name}</span>
                </a>
                {props.editable && (
                  <Delete
                    className="file__delete"
                    onClick={() => {
                      deleteFile(index);
                    }}
                  />
                )}
              </FileItem>
            );
          })}
        {props.editable && maxFile > files.length ? (
          <DragDropFileSmall
            className={isDragging ? "dragging" : ""}
            // htmlFor="fileUpload"
            onClick={() => {
              if (inputRef.current) {
                inputRef.current.click();
              }
            }}
          >
            {fileUploading ? (
              <MoonLoader
                color="var(--primary)"
                loading
                size={16}
                speedMultiplier={1}
              />
            ) : (
              <Empty width={20} height={20} />
            )}
            {fileUploading ? (
              <div>업로드 중....</div>
            ) : (
              <div>파일을 업로드 해주세요.</div>
            )}
          </DragDropFileSmall>
        ) : (
          <div
            style={{
              height: "36px",
            }}
          />
        )}
      </Files>
      <input
        ref={inputRef}
        type="file"
        id="fileUpload"
        disabled={props.onlyDownload}
        style={{ display: "none" }}
        multiple={props.multiple}
        onChange={onChangeFiles}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

const DNDTitle = styled.div`
  width: 100%;
`;

const Files = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const FileItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  .file__delete {
    width: 16px;
    height: 16px;
    min-width: 16px;
    min-height: 16px;
    max-width: 16px;
    max-height: 16px;
    cursor: pointer;
  }
  .file__content {
    width: 214px;
    display: flex;
    flex-direction: row;
    align-items: center;

    text-decoration: none;

    padding: 8px 12px;
    background: #ffffff;
    border: 1px solid #828282;
    border-radius: 4px;

    cursor: pointer;

    .file__icon {
      width: 20px;
      height: 20px;
      min-width: 20px;
      min-height: 20px;
      max-width: 20px;
      max-width: 20px;
    }
    .file__title {
      font-weight: 400;
      font-size: 12px;
      line-height: 1;
      letter-spacing: 0.1px;
      color: #828282;

      margin-left: 8px;
      margin-right: 12px;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;

const DragDropFileSmall = styled.label`
  width: 214px;
  height: 36px;

  cursor: pointer;
  transition: 0.12s ease-in;
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  -ms-flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  background: #ffffff;
  border: 1px dashed #dcdcdc;
  border-radius: 4px;

  gap: 8px;

  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  letter-spacing: 0.1px;
  color: #828282;

  padding: 8px 12px;

  &.dragging {
    background: #f0f0f0;
  }
`;

export default FileUploaderDND;
