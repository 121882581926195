import React, { forwardRef, ReactNode } from "react";
import styled from "@emotion/styled";
import DisplayType from "utils/displayType";

interface Props {
  sectionId?: string;
  headerTitle: string;
  children?: ReactNode;
}
const FaqSection = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const mode = DisplayType();
  return (
    <Container ref={ref} id={props.sectionId}>
      <div
        className={`faq__section__title ${
          mode === "Mobile" ? "font-sub-title4" : "font-sub-title4"
        }`}
      >
        {props.headerTitle}
      </div>
      <div className={`faq__section__body`}>{props.children}</div>
    </Container>
  );
});

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  .faq__section__title {
    padding: 30px 0;
    color: var(--black);
    border-bottom: 1px solid var(--grey-00);
  }
  .faq__section__body {
    padding: 30px 0;
    border-bottom: 1px solid var(--grey-00);
  }
  p {
    margin: 0;
    ul {
      margin-bottom: 0;
    }
  }

  ol,
  ul {
    margin: 0;
    padding-left: 1rem;
  }
  .dashed {
    list-style-image: url("data:image/gif;base64,R0lGODlhBQAKAIABAAAAAP///yH5BAEAAAEALAAAAAAFAAoAAAIIjI+ZwKwPUQEAOw==");
  }

  @media only screen and (max-width: 768px) {
    .faq__section__title {
      padding: 20px 0;
    }
    .faq__section__body {
      padding: 20px 0;
    }
  }
`;

export default React.memo(FaqSection);
