import React, { useEffect } from "react";
import styled from "@emotion/styled";
import DisplayType from "utils/displayType";
import { BlueLetterProps } from "admin/router/blueletter";

interface Props {
  blueletter: BlueLetterProps;
  isViewer?: boolean;
  onClick?: () => void;
}

const Blueletter: React.FC<Props> = (props) => {
  const { isViewer = false } = props;
  const mode = DisplayType();
  useEffect(() => {}, []);

  return (
    <Container
      href={props.blueletter.linkUrl}
      target="_blank"
      onClick={props.onClick}
    >
      <img className="thumbNail" src={props.blueletter.thumbNail} alt="logo" />
      <InfoContainer>
        <span
          className={`blueletter__title ${
            mode === "Mobile" ? "font-sub-title4" : "font-sub-title2"
          }`}
        >
          {props.blueletter.title}
        </span>
        <p
          className={`blueletter__description ${
            mode === "Mobile" ? "font-body4" : "font-body2"
          }`}
        >
          {props.blueletter.description}
        </p>
      </InfoContainer>
    </Container>
  );
};

const Container = styled.a`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: fit-content;
  text-decoration: none;
  border: 1px solid var(--grey-00);

  transition: all 0.1s ease;
  cursor: pointer;

  .thumbNail {
    width: 318px;
    min-width: 318px;
    max-width: 318px;
    height: 189px;
    min-height: 189px;
    max-height: 189px;
    border-right: 1px solid var(--grey-00);

    object-fit: cover;
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;

    .thumbNail {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      height: 160px;
      min-height: 160px;
      max-height: 160px;

      object-fit: cover;
      border: 0px;
      border-bottom: 1px solid var(--grey-00);
    }
  }
`;

const InfoContainer = styled.div`
  flex-grow: 1;
  height: 100%;
  padding: 10px 32px 10px 32px;
  display: flex;
  flex-direction: column;
  .blueletter__portfolio {
    color: var(--grey-20);
  }
  .blueletter__title {
    max-height: 52px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    margin: 0;
    color: var(--black);
  }

  .blueletter__description {
    height: 54px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin: 0;
    margin-top: 8px;
    color: var(--grey-40);
  }
  .blueletter__date {
    margin-top: 16px;
    color: var(--grey-30);
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 24px;
    justify-content: flex-start;
    .blueletter__title {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      margin: 0;
      color: var(--black);
    }
    .blueletter__description {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      margin: 0;
      margin-top: 10px;
      color: var(--grey-40);
    }
    .blueletter__date {
      margin-top: 6px;
      color: var(--grey-30);
    }
  }
`;
export default React.memo(Blueletter);
