import React, { Dispatch, useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import styled from "@emotion/styled";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import DefaultButton from "admin/component/button/DefaultButton";
import axios from "axios";
import { getYMD, sortingByOpenDate } from "utils/commonUtil";

import DisplayType from "utils/displayType";
import { BlueLetterProps } from "admin/router/blueletter";
import Blueletter from "component/card/Blueletter";
import { Link } from "react-router-dom";
import GoTo from "component/go-list/GoTo";
import * as api from "../../api/api";

interface Props {}
const LastestBlueletter: React.FC<Props> = (propss) => {
  const [loading, setLoading] = useState(true);
  const [blueletterList, updateBlueletterList] = useState<BlueLetterProps[]>(
    []
  );
  const mode = DisplayType();

  const blueLetterClick = (item: BlueLetterProps) => {
    if (!item.linkUrl) return;

    const aTag = document.createElement("a");
    aTag.href = item.linkUrl;
    aTag.target = "_black";
    aTag.click();
  };

  const getBlueletterList = async () => {
    try {
      const result = await axios.get(api.getBlueLetterList());
      if (result.status === 200) {
        const blueletter = result.data
          .sort(sortingByOpenDate)
          .filter((item: any) => item.isActive)
          .map((item: any) => ({
            ...item,
            openDate: getYMD(item.openDate),
          }));
        updateBlueletterList(
          blueletter.filter((item: any, index: number) => index < 1)
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBlueletterList();
  }, []);

  return (
    <Container>
      <BlueletterContainer>
        {blueletterList.map((item) => (
          <Blueletter
            key={item.hpBlueLetterId}
            blueletter={item}
            onClick={() => {
              blueLetterClick(item);
            }}
          />
        ))}
      </BlueletterContainer>
      {mode !== "Mobile" && (
        <GoTo to="/insight/blueletter" toText="블루레터 전체보기" />
      )}
      {mode === "Mobile" && (
        <div className="center" style={{ marginTop: 30 }}>
          <Link to={"/insight/blueletter"} style={{ textDecoration: "none" }}>
            <DefaultButton className="font-button2" type="outline">
              블루레터 전체보기
            </DefaultButton>
          </Link>
        </div>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BlueletterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
  @media only screen and (max-width: 768px) {
    gap: 0;
  }
`;

export default LastestBlueletter;
