import React, {
  createRef,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import AboutBluePoint from "router/about/AboutBluePoint";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import styled from "@emotion/styled";
import { padding, width } from "@mui/system";
import dog from "./assets/dog.jpeg";

interface PortfolioInfo {
  companyName: string;
  description: string;
  introductionCompany: string;
  ceoName: string;
  establishDate: string;
  industrialTech: string[];
  problem: string;
  solutions: string;
  technology: string;
  advantages: string;
  productStatus: string;
  businessModel: string;
}

const PDFTestView = () => {
  const data: PortfolioInfo[] = [
    {
      companyName: "주식회사 스케치소프트",
      description: "디자이너를 위한 3D 스케치북",
      introductionCompany: `스케치소프트는 '가장 쉽고 빠른 3차원 스케치북'을 모토로 2020년 설립된 기술 스타트업이다. 창업자인 김용관 대표(29)는 KAIST 산업디자인학과 스케치랩 박사 및 연구원 출신으로, 3D 스케치를 위한 인간-컴퓨터 상호작용 기술의 상용화를 목표하고 있다.`,
      ceoName: "이용관",
      establishDate: "2020.02.13",
      industrialTech: ["ICT", "SW"],
      problem: `디자이너에게 창작의 과정은 스케칭이지만, 이보다 변환 작업인 3D모델링에 50배 이상의 많은 시간을 쓰고 있음 
수 많은 아이디어들이 검토되지 못한 채 폐기되고 창의적인 활동을 할 인원들이 모델링 작업에 투입됨 `,
      solutions: `3D 스케칭 솔루션
2D 스케칭처럼 빠르면서도 3D 모델링의 3차원 정보를 충분히 표현 가능`,
      technology: `사용자의 펜 입력을 컴퓨터가 수학적으로 해석하여 3차원 형상을 생성`,
      advantages: `디자인의 효율 개선을 넘어 디자인 프로세스의 혁신을 가져올 수 있음`,
      productStatus: `• 프로토타입 제작 완료
• 고객 테스트 진행`,
      businessModel: `인당 과금 모델`,
    },
    {
      companyName: "주식회사 스케치소프트",
      description: "디자이너를 위한 3D 스케치북",
      introductionCompany: `스케치소프트는 '가장 쉽고 빠른 3차원 스케치북'을 모토로 2020년 설립된 기술 스타트업이다. 창업자인 김용관 대표(29)는 KAIST 산업디자인학과 스케치랩 박사 및 연구원 출신으로, 3D 스케치를 위한 인간-컴퓨터 상호작용 기술의 상용화를 목표하고 있다.`,
      ceoName: "이용관",
      establishDate: "2020.02.13",
      industrialTech: ["ICT", "SW"],
      problem: `디자이너에게 창작의 과정은 스케칭이지만, 이보다 변환 작업인 3D모델링에 50배 이상의 많은 시간을 쓰고 있음 
수 많은 아이디어들이 검토되지 못한 채 폐기되고 창의적인 활동을 할 인원들이 모델링 작업에 투입됨 `,
      solutions: `3D 스케칭 솔루션
2D 스케칭처럼 빠르면서도 3D 모델링의 3차원 정보를 충분히 표현 가능`,
      technology: `사용자의 펜 입력을 컴퓨터가 수학적으로 해석하여 3차원 형상을 생성`,
      advantages: `디자인의 효율 개선을 넘어 디자인 프로세스의 혁신을 가져올 수 있음`,
      productStatus: `• 프로토타입 제작 완료
• 고객 테스트 진행`,
      businessModel: `인당 과금 모델`,
    },
    {
      companyName: "주식회사 스케치소프트",
      description: "디자이너를 위한 3D 스케치북",
      introductionCompany: `스케치소프트는 '가장 쉽고 빠른 3차원 스케치북'을 모토로 2020년 설립된 기술 스타트업이다. 창업자인 김용관 대표(29)는 KAIST 산업디자인학과 스케치랩 박사 및 연구원 출신으로, 3D 스케치를 위한 인간-컴퓨터 상호작용 기술의 상용화를 목표하고 있다.`,
      ceoName: "이용관",
      establishDate: "2020.02.13",
      industrialTech: ["ICT", "SW"],
      problem: `디자이너에게 창작의 과정은 스케칭이지만, 이보다 변환 작업인 3D모델링에 50배 이상의 많은 시간을 쓰고 있음 
수 많은 아이디어들이 검토되지 못한 채 폐기되고 창의적인 활동을 할 인원들이 모델링 작업에 투입됨 `,
      solutions: `3D 스케칭 솔루션
2D 스케칭처럼 빠르면서도 3D 모델링의 3차원 정보를 충분히 표현 가능`,
      technology: `사용자의 펜 입력을 컴퓨터가 수학적으로 해석하여 3차원 형상을 생성`,
      advantages: `디자인의 효율 개선을 넘어 디자인 프로세스의 혁신을 가져올 수 있음`,
      productStatus: `• 프로토타입 제작 완료
• 고객 테스트 진행`,
      businessModel: `인당 과금 모델`,
    },
  ];
  const [arrayRef, updateArrayRef] = useState<
    React.RefObject<HTMLDivElement>[]
  >([]);

  useLayoutEffect(() => {
    updateArrayRef(
      data.map((i) => {
        return createRef<HTMLDivElement>();
      })
    );
  }, []);

  const handleDownloadPdf = async () => {
    try {
      if (!arrayRef[0].current) return;
      const firstCanvas = await html2canvas(arrayRef[0].current);

      const firstData = firstCanvas.toDataURL("image/png", 1.0);
      const pdf = new jsPDF("p", "mm", "a4");
      pdf.addImage(firstData, "PNG", 0, 0, 210, 297);
      for (let i = 1; i < arrayRef.length; i++) {
        const element = arrayRef[i].current;
        if (element) {
          const canvas = await html2canvas(arrayRef[0].current);
          const data = canvas.toDataURL("image/png", 1.0);
          pdf.addPage();
          pdf.addImage(data, "PNG", 0, 0, 210, 297);
        }
      }

      pdf.save("print.pdf", { returnPromise: true }).then((response) => {
        console.log("complet");
        console.log(response);
      });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Container>
      <button style={{ marginTop: 200 }} onClick={handleDownloadPdf}>
        pdf 출력
      </button>
      <A4ContaeinrList style={{ marginTop: 100 }}>
        {data.map((item, index) => {
          return (
            <A4Contaeinr ref={arrayRef[index]}>
              <div className="pdf__header">
                <div className="pdf__header__infomation">
                  <div className="font-bold-30">{item.companyName}</div>
                  <div className="font-regular-16">{item.description}</div>
                  <div className="font-regular-8" style={{ marginTop: 8 }}>
                    {item.introductionCompany}
                  </div>
                  <div style={{ marginTop: 32 }}>
                    <span
                      className="font-bold-12"
                      style={{
                        color: "black",
                        opacity: 0.4,
                        width: 62,
                        display: "inline-block",
                      }}
                    >
                      대표자
                    </span>
                    <span className="font-bold-12" style={{ color: "black" }}>
                      {item.ceoName}
                    </span>
                  </div>
                  <div>
                    <span
                      className="font-bold-12"
                      style={{
                        color: "black",
                        opacity: 0.4,
                        width: 62,
                        display: "inline-block",
                      }}
                    >
                      설립일
                    </span>
                    <span className="font-bold-12" style={{ color: "black" }}>
                      {item.establishDate}
                    </span>
                  </div>
                  <div>
                    <span
                      className="font-bold-12"
                      style={{
                        color: "black",
                        opacity: 0.4,
                        width: 62,
                        display: "inline-block",
                      }}
                    >
                      산업분류
                    </span>
                    <span className="font-bold-12" style={{ color: "black" }}>
                      {item.industrialTech.join(",")}
                    </span>
                  </div>
                </div>
                {/* <img className="pdf__logo__img" src={dog} alt="개" />
                 */}
                {/* <img
                  src={`${"https://www.ui4u.go.kr/depart/img/content/sub03/img_con03030100_01.jpg"}?${new Date().getTime()}`}
                  className="pdf__logo__img"
                  crossOrigin="anonymous"
                  onLoad={() => {}}
                  alt="image"
                /> */}
              </div>
              <div className="pdf__contents__section">
                <div className="contents__section__title">{`Problem & Solutions`}</div>
                <div
                  className="contents__section__item"
                  style={{ marginTop: 20 }}
                >
                  <div className="contents__section__item__title">Problem</div>
                  <pre className="contents__section__item__text">
                    {item.problem}
                  </pre>
                </div>
                <div
                  className="contents__section__item"
                  style={{ marginTop: 14 }}
                >
                  <div className="contents__section__item__title">
                    Solutions
                  </div>
                  <pre className="contents__section__item__text">
                    {item.solutions}
                  </pre>
                </div>
              </div>
              <div className="pdf__contents__section">
                <div className="contents__section__title">{`Technology & Advantages`}</div>
                <div
                  className="contents__section__item"
                  style={{ marginTop: 20 }}
                >
                  <div className="contents__section__item__title">
                    Technology
                  </div>
                  <pre className="contents__section__item__text">
                    {item.technology}
                  </pre>
                </div>
                <div
                  className="contents__section__item"
                  style={{ marginTop: 14 }}
                >
                  <div className="contents__section__item__title">
                    Advantages
                  </div>
                  <pre className="contents__section__item__text">
                    {item.advantages}
                  </pre>
                </div>
              </div>
              <div className="pdf__contents__section">
                <div className="contents__section__title">{`Product Status & Business Model`}</div>
                <div
                  className="contents__section__item"
                  style={{ marginTop: 20 }}
                >
                  <div className="contents__section__item__title">
                    Product Status
                  </div>
                  <pre className="contents__section__item__text">
                    {item.productStatus}
                  </pre>
                </div>
                <div
                  className="contents__section__item"
                  style={{ marginTop: 14 }}
                >
                  <div className="contents__section__item__title">
                    Business Model
                  </div>
                  <pre className="contents__section__item__text">
                    {item.businessModel}
                  </pre>
                </div>
              </div>
            </A4Contaeinr>
          );
        })}
      </A4ContaeinrList>
    </Container>
  );
};
const Container = styled.div`
  width: 100vw;
  background: var(--grey-00);
`;

const A4ContaeinrList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: fit-content;
  margin: 0 auto;
`;

const A4Contaeinr = styled.div`
  width: 595px;
  height: 842px;
  border: 1px solid black;
  background: var(--white);
  display: flex;
  flex-direction: column;
  padding: 50px 40px 40px 40px;

  .pdf__header {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 45px;

    .pdf__header__information {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
    }

    .pdf__logo__img {
      width: 120px;
      min-width: 120px;
      max-width: 120px;
      height: 120px;
      min-height: 120px;
      max-height: 120px;

      background-size: cover;
    }
  }

  .pdf__contents__section {
    width: 100%;
    margin-top: 40px;
    display: flex;
    flex-direction: column;

    .contents__section__title {
      width: 100%;
      height: 18px;
      font-weight: 700;
      font-size: 14px;
      line-height: 14px;
      color: black;
      padding-bottom: 15px;
      border-bottom: 1px solid black;
    }

    .contents__section__item {
      width: 100%;
      display: flex;
      flex-direction: column;
      .contents__section__item__title {
        font-family: "Bpp Bold";
        font-size: 12px;
        letter-spacing: -0.02em;
        line-height: 1;
      }
      .contents__section__item__text {
        font-family: "Bpp Regular";
        font-size: 10px;
        letter-spacing: 0;
        line-height: 1.25;
        margin-top: 6px;
        margin-bottom: 0;
      }
    }
  }
`;

export default PDFTestView;
