import React, { useEffect } from "react";
import Styled from "@emotion/styled";

import { UserInfo } from "admin/type/type";
import { ReactComponent as UserPlus } from "../../../../assets/user_plus.svg";
// import { ReactComponent as Warnning } from "../../../../../../assets/image/ic_warning.svg";

interface Props {
  onClick?: () => void;
}

const AddUserCard: React.FC<Props> = (props) => {
  useEffect(() => {}, []);

  return (
    <Container onClick={props.onClick}>
      <ProfileImageContainer>
        <UserPlus className="profile__img" />
      </ProfileImageContainer>
      <div className="font-bold-20">유저생성</div>
    </Container>
  );
};

const Container = Styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 216px;
    height: 240px;

    background: #ffffff;
    border: 1px solid transparent;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
    border-radius: 10px;

    transition: 0.1s;

    cursor: pointer;
    :hover {
      border: 1px solid var(--primary);
      width: 215px;
      height: 237px;
    }
  `;
// const Overlay = Styled.div`
//     position: absolute;
//     width: 100%;
//     height: 100%;
//     left: 0;
//     top: 0;
//     border-radius: 10px;
//     background-color: rgba(255, 255, 255, 0.75);
//     .warnning {
//       display: flex;
//       flex-direction: row;
//       align-items: center;
//       color: #ffc800;
//       margin: 4px 0 0 4px;
//     }
//   `;
const ProfileImageContainer = Styled.div`
    width: 120px;
    height: 120px;

    border-radius: 50%;
    margin-top: 16px;

    display: flex;
    align-items: center;
    justify-content: center;
    .profile__img {
      width: 60%;
      height: 60%;
      
    }
  `;

export default React.memo(AddUserCard);
