import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import Footer from "component/footer/Footer";
import HeaderNavigation from "component/header/HeaderNavigation";
import axios from "axios";
import DisplayType from "utils/displayType";
import Banner from "component/banner/Banner";
import {
  Category,
  InsightMenu,
  NavigationMenu,
  NewsCategory,
} from "admin/type/type";
import SearchBar from "component/search/SearchBar";
import { NewsProps } from "admin/router/news";
import { Pagination } from "@mui/material";
import DefaultButton from "admin/component/button/DefaultButton";
import { sortingByOpenDate, updateTitle } from "utils/commonUtil";
import * as api from "../../api/api";
import InsightTabs from "./components/InsightTabs";
import NewsCard from "../../component/card/News";
import { ContentStateProps } from "./Contents";
import newsBanner from "../../assets/news_banner.png";
import newsBanner1920 from "../../assets/news_banner_1920.png";
import newsBanner2048 from "../../assets/news_banner_2048.png";
import NewsCategoryList from "./organism/NewsCategoryList";

const News = () => {
  const mode = DisplayType();

  const isBack = useRef<boolean>(false);
  const [settingCurrentPage, updateSettingCurrentPate] =
    useState<boolean>(false);

  const countPerPage = useRef<number>(mode === "Mobile" ? 8 : 12);
  const [currentPage, updateCurrentPage] = useState<number>(1);
  const [totalPage, updateTotalPage] = useState<number>(0);

  const [allNews, updateAllNews] = useState<NewsProps[]>([]);
  const [news, updateNews] = useState<NewsProps[]>([]);
  const [searchKeyword, updateSearchKeyword] = useState<string>("");
  const [selectedCategory, updateSelectedCategory] = useState<Category[]>([]);

  const onSearchNews = () => {
    let newsList: NewsProps[] = [...allNews];
    newsList = newsList
      .filter((item) => {
        return selectedCategory
          .map((category) => category.name)
          .includes(item.category);
      })
      .filter((item) => {
        const keyword = searchKeyword.replace(/(\s*)/g, "").toUpperCase();
        return (
          item.title?.replace(/(\s*)/g, "").toUpperCase().includes(keyword) ||
          item.description
            ?.replace(/(\s*)/g, "")
            .toUpperCase()
            .includes(keyword) ||
          item.hpPortfolio?.companyName
            ?.replace(/(\s*)/g, "")
            .toUpperCase()
            .includes(keyword)
        );
      });

    updateNews([...newsList]);
    updateTotalPage(Math.ceil(newsList.length / countPerPage.current));
    updateCurrentPage(1);
  };

  const getAllNews = async () => {
    try {
      const result = await axios.get(api.getNewsList());
      if (result.status === 200) {
        const news = result.data
          .sort(sortingByOpenDate)
          .filter((item: any) => item.isActive)
          .map((item: any): NewsProps => ({ ...item }));
        updateAllNews(news);
        updateNews(news);
        updateTotalPage(Math.ceil(news.length / countPerPage.current));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getStartIndex = () => {
    return mode === "Mobile" ? 0 : (currentPage - 1) * countPerPage.current;
  };

  const storeState = (page?: number) => {
    const state: ContentStateProps = {
      scrollY: window.scrollY,
      currentPage: page || currentPage,
    };
    localStorage.setItem(document.location.pathname, JSON.stringify(state));
  };

  useEffect(() => {
    if (!settingCurrentPage) return;

    const state = localStorage.getItem(document.location.pathname);
    if (state) {
      const beforeState = { ...JSON.parse(state) };
      window.scrollTo(0, beforeState.scrollY);
      localStorage.removeItem(document.location.pathname);
      localStorage.removeItem("goBack");
    }
  }, [settingCurrentPage]);

  useEffect(() => {
    if (allNews.length === 0) return;

    const goBack = localStorage.getItem("goBack");
    if (goBack) {
      isBack.current = JSON.parse(goBack).goBack;
    }
    if (isBack.current) {
      const state = localStorage.getItem(document.location.pathname);
      if (state) {
        const beforeState = { ...JSON.parse(state) };
        updateCurrentPage(beforeState.currentPage);
        updateSettingCurrentPate(true);
      }
    }
  }, [allNews]);

  useEffect(() => {
    if (searchKeyword.length === 0) {
      onSearchNews();
    }
  }, [searchKeyword]);

  useEffect(() => {
    onSearchNews();
  }, [selectedCategory]);

  useEffect(() => {
    getAllNews();
  }, []);

  useLayoutEffect(() => {
    updateTitle("News");
  }, []);

  return (
    <Container>
      <Banner
        bannerImg={newsBanner}
        bannerImg1920={newsBanner1920}
        bannerImg2048={newsBanner2048}
        mode={mode}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: mode === "Mobile" ? "center" : "flex-start",
          }}
        >
          <div
            className={`${
              mode === "Mobile" ? "font-title-en" : "font-title-en"
            }`}
            style={{ color: "var(--white)" }}
          >
            NEWS
          </div>
        </div>
      </Banner>
      <InsightTabs selectedTab={InsightMenu.NEWS} />
      <InsightBodyContainer className="limit__body">
        <SearchBar
          onChage={(value) => {
            updateSearchKeyword(value);
          }}
          onSearching={onSearchNews}
        />
        <NewsCategoryList
          onChange={(value) => {
            updateSelectedCategory(value);
          }}
        />
        <div className="news__list">
          {news
            .slice(getStartIndex(), currentPage * countPerPage.current)
            .map((item) => {
              return (
                <NewsCard
                  key={item.hpNewsId}
                  news={item}
                  onClick={() => {
                    storeState();
                  }}
                />
              );
            })}
          {news.length === 0 && (
            <SearchResultEmpty>
              {mode === "Mobile" ? (
                <div className="font-body3" style={{ textAlign: "center" }}>
                  {searchKeyword.length > 0 && `"${searchKeyword}"에 대한 `}
                  <br />
                  {"검색 결과가 없습니다."}
                </div>
              ) : (
                <span className="font-body3">
                  {searchKeyword.length > 0 && `"${searchKeyword}"에 대한`}
                  {` 검색 결과가 없습니다.`}
                </span>
              )}
            </SearchResultEmpty>
          )}
        </div>
        {mode !== "Mobile" && totalPage > 0 && (
          <div className="center">
            <Pagination
              count={totalPage}
              shape="rounded"
              page={currentPage}
              onChange={(event, page) => {
                storeState(page);
                updateCurrentPage(page);
              }}
            />
          </div>
        )}
        {mode === "Mobile" && totalPage > 0 && currentPage < totalPage && (
          <div className="center">
            <DefaultButton
              className="font-body3"
              type="outline"
              onClick={() => {
                storeState(currentPage + 1);
                updateCurrentPage(currentPage + 1);
              }}
            >
              뉴스 더보기
            </DefaultButton>
          </div>
        )}
      </InsightBodyContainer>
      <Footer />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 768px) {
  }
`;
const InsightBodyContainer = styled.div`
  flex-grow: 1;
  width: 100%;
  display: flex;
  padding-top: 100px;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;

  .news__list {
    display: flex;
    flex-direction: column;
    margin-top: 56px;
    margin-bottom: 56px;
    gap: 24px;
    padding-top: 24px;
    border-top: 1px solid var(--grey-00);
  }
  .center {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media only screen and (max-width: 768px) {
    padding-top: 40px;
    padding-bottom: 40px;
    .news__list {
      margin-top: 40px;
      gap: 0;
    }
  }
`;
const SearchResultEmpty = styled.div`
  width: 100%;
  height: 100%;
  min-height: 276px;
  max-height: 276px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-bottom: 1px solid var(--grey-00);
`;
export default News;
