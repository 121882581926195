import styled from "@emotion/styled";

export const FooterContainer = styled.div`
  width: 100%;
  padding-top: 60px;
  padding-bottom: 60px;
  background-color: var(--grey-60);
`;

export const FooterContentContainer = styled.div`
  display: flex;
  flex-direction: column;

  .footer__content__row {
    display: flex;
    flex-direction: row;
  }
  .footer__content__section {
    flex: 1;
    .sns__link {
      height: fit-content;
      background-color: white;
      border-radius: 50%;
      :hover {
        svg {
          opacity: 0.7;
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .footer__content__row {
      gap: 24px;
      flex-direction: column;
    }
  }
`;

export const FooterText = styled.div`
  display: flex;
  flex-direction: column;
  .footer__text__title {
    color: var(--grey-00);
    margin-bottom: 10px;
  }
  .footer__text__subtitle {
    color: var(--grey-30);
  }

  .footer__text__row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #444444;
`;
