import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import DisplayType from "utils/displayType";
import { text } from "stream/consumers";

interface Props {
  label?: string;
  isRequired?: boolean;
  className?: string;
  defaultValue?: string;
  placeholder?: string;
  editable: boolean;
  tabIndex?: number;
  fontColor?: string;
  onChange?: (e: string) => void;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
}

export const NotionTextarea: React.FC<Props> = (props) => {
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const [focus, setFocus] = useState<boolean>(false);
  const [value, setValue] = useState<string>(props.defaultValue || "");
  const [isEdit, setEdit] = useState<boolean>(false);

  const [render, setRender] = useState<boolean>(true);

  const reRender = () => setRender((prevState) => !prevState);

  const onFocus = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    setEdit(true);
  };
  const onBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    setEdit(false);
    if (props.onBlur) props.onBlur(e);
  };

  const onChangeInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
    if (props.onChange) props.onChange(e.target.value);
  };

  const handleResize = () => {
    if (textareaRef && textareaRef.current) {
      textareaRef.current.value = value || "";
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${
        textareaRef.current.scrollHeight + 5
      }px`;
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setValue(props.defaultValue || "");
  }, [props.defaultValue]);

  useEffect(() => {
    if (isEdit && textareaRef && textareaRef.current) {
      textareaRef.current.value = value || "";
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${
        textareaRef.current.scrollHeight + 5
      }px`;
      textareaRef.current.style.color = `${
        props.fontColor || "var(--black)"
      } !important`;
      textareaRef.current.focus();
    }
  }, [isEdit]);

  useEffect(() => {
    if (textareaRef && textareaRef.current) {
      textareaRef.current.value = value || "";
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${
        textareaRef.current.scrollHeight + 5
      }px`;
      textareaRef.current.style.color = `${
        props.fontColor || "var(--black)"
      } !important`;
    }
  }, [value]);

  return (
    <Container className={props.className || ""}>
      {props.label && (
        <div className="font-button3">
          <span>{props.label}</span>
          {props.isRequired && (
            <span
              className="font-button3"
              style={{ color: "var(--red)", marginLeft: 8 }}
            >
              *
            </span>
          )}
        </div>
      )}
      <TextContainer
        className="scroll__invisible"
        editable={props.editable}
        tabIndex={props.tabIndex}
        onFocus={() => {
          if (!props.editable) return;
          setEdit(true);
        }}
      >
        <textarea
          ref={textareaRef}
          className={`scroll__invisible ${
            props.className ||
            `${DisplayType() === "Mobile" ? "font-body3" : "font-body2"}`
          }`}
          style={{ color: `${props.fontColor || "var(--black)"} !important` }}
          autoComplete="off"
          wrap="on"
          placeholder={props.placeholder || ""}
          disabled={!props.editable}
          value={value}
          onBlur={onBlur}
          onChange={(e) => onChangeInput(e)}
          onInput={(e) => {
            if (!textareaRef || !textareaRef.current) return;
            textareaRef.current.style.height = "auto";
            textareaRef.current.style.height = `${
              textareaRef.current.scrollHeight + 5
            }px`;
          }}
        />
      </TextContainer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 4px;

  .required {
    color: var(--red);
  }
`;

const TextContainer = styled.div<{ editable: boolean }>`
  position: relative;
  width: 100%;
  textarea {
    width: 100%;
    height: 400px;

    ${(props) => props.editable && `padding: 5px 10px 7px 10px`};
    border-radius: 4px;
    background: transparent;
    border: ${(props) =>
      props.editable ? "1px solid var(--grey-20)" : "1px solid transparent"};
    overflow: scroll;
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-word;
    resize: none;
    appearance: none;
    :disabled {
      -webkit-text-fill-color: var(--grey-60);
      opacity: 1;
    }
    :hover {
      ${(props) => props.editable && "background-color: var(--grey-10)"};
    }
    :focus {
      outline: none;
      border: 1px solid var(--grey-20);
      :hover {
        ${(props) => props.editable && "background-color: var(--white)"};
      }
    }
    ::placeholder {
      color: var(--grey-20);
    }
  }
`;
