import { UserInfo } from "admin/type/type";
import React, { useEffect, useRef, useState } from "react";
import axios from "axios";

import { NodeModel } from "@minoru/react-dnd-treeview";
import { Button } from "react-bootstrap";
import { NotionInput } from "admin/component/input/NotionInput";
import { ItemContainer } from "component/gallery/styles";
import ModalLayout from "admin/component/modal/ModalLayout";
import ConfirmModal from "admin/component/modal/ConfirmModal";
import UserCard from "../../component/UserCard";
import {
  AllUserContainer,
  AllUserList,
  Divider,
  EmptyView,
  StructureContainer,
  StructureUserList,
  TeamDetailLayout,
} from "./styles";

import * as api from "../../../../../api/api";
import AddUserCard from "../../component/\bAddUserCard";
import UserRowItem from "../../component/UserRowItem";
import UserCircleItem from "../../component/UserCircleItem";
import { DataType } from "../..";
import { ReactComponent as Cancel } from "../../../../../assets/edit_cancel.svg";

interface Props {
  selectedStruct: NodeModel<DataType> | undefined;
  allHpUser: UserInfo[];
  loadAndAddUser: () => void;
  setAllHpUser: React.Dispatch<React.SetStateAction<UserInfo[]>>;
  openUserCreate: (userInfo: UserInfo | undefined) => void;
  onChangeStructureDescription: (description: string) => void;
  isSelectedDeleteStructure: boolean;
}
const TeamDetail: React.FC<Props> = (props) => {
  const selectedUsersRef = useRef<HTMLDivElement>(null);
  const [searchName, updateSearchName] = useState<string>("");
  const [addUserOpen, updateAddUserOpen] = useState<boolean>(false);
  const [usersInStructure, updateUsersInStructure] = useState<UserInfo[]>([]);
  const [selectedUsers, updateSelectedUsers] = useState<UserInfo[]>([]);
  const [deletedUser, setDeletedUser] = useState<UserInfo>();
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openRestoreModal, setOpenRestoreModal] = useState<boolean>(false);

  const selecteUser = (user: UserInfo) => {
    updateSelectedUsers([...selectedUsers, user]);
  };

  const cancelSelecteUser = (user: UserInfo) => {
    const index = selectedUsers.findIndex(
      (item) => item.hpUserId === user.hpUserId
    );
    selectedUsers.splice(index, 1);
    updateSelectedUsers([...selectedUsers]);
  };

  const moveUserToSelectedStructure = () => {
    if (selectedUsers.length > 0) {
      const selectedUsersId = selectedUsers.map((item) => item.hpUserId);
      const newAllHpUser = props.allHpUser.map((item) => {
        if (selectedUsersId.includes(item.hpUserId)) {
          return {
            ...item,
            isChange: !item.isNew,
            hpStructure: props.selectedStruct?.data?.structInfo,
          };
        }
        return { ...item };
      });
      props.setAllHpUser([...newAllHpUser]);
    }
  };

  useEffect(() => {
    if (selectedUsersRef.current) {
      selectedUsersRef.current.style.height =
        selectedUsers.length > 0 ? "75px" : "0px";
    }
  }, [selectedUsers]);

  useEffect(() => {
    if (props.selectedStruct !== undefined) {
      updateUsersInStructure(
        props.allHpUser.filter(
          (user) => user.hpStructure?.hpStructureId === props.selectedStruct?.id
        )
      );
      updateAddUserOpen(false);
      updateSelectedUsers([]);
    }
  }, [props.selectedStruct, props.allHpUser]);

  if (props.selectedStruct === undefined) {
    return (
      <EmptyView className="font-bold-20">
        선택된 조직이 없습니다.
        <br />
        조직을 선택해주세요.
      </EmptyView>
    );
  }

  return (
    <TeamDetailLayout>
      <StructureContainer className={addUserOpen ? "structure__reduce" : ""}>
        <div className="structure__users__header">
          <div className="structure__users__header__row">
            <span className="font-bold-20">{props.selectedStruct.text}</span>
            <div style={{ display: "flex", flexDirection: "row", gap: 20 }}>
              <Button onClick={props.loadAndAddUser}>조직원 불러오기</Button>
              {!props.isSelectedDeleteStructure && (
                <Button
                  onClick={() => {
                    updateAddUserOpen((prevState) => !prevState);
                  }}
                >
                  조직원 이동
                </Button>
              )}
            </div>
          </div>
          {!props.isSelectedDeleteStructure && (
            <NotionInput
              className="structure__description"
              editable
              hasUnderLine
              defaultValue={props.selectedStruct.data?.structInfo?.introduction}
              onBlur={(e) => {
                const description = e.target.value;
                props.onChangeStructureDescription(description);
              }}
              placeholder="조직의 설명을 적어주세요."
            />
          )}
        </div>
        <StructureUserList className="scroll__invisible">
          {props.isSelectedDeleteStructure
            ? props.allHpUser
                .filter((item) => item.isDelete)
                .map((userInfo) => {
                  return (
                    <UserCard
                      key={userInfo.hpUserId}
                      userInfo={userInfo}
                      onClick={() => {
                        props.openUserCreate(userInfo);
                      }}
                      onClickRestore={(userInfo) => {
                        setOpenRestoreModal(true);
                        setDeletedUser(userInfo);
                      }}
                    />
                  );
                })
            : usersInStructure
                .filter((item) => !item.isDelete)
                .map((userInfo) => {
                  return (
                    <UserCard
                      key={userInfo.hpUserId}
                      userInfo={userInfo}
                      onClick={() => {
                        props.openUserCreate(userInfo);
                      }}
                      onClickDelete={(userInfo) => {
                        setOpenDeleteModal(true);
                        setDeletedUser(userInfo);
                      }}
                    />
                  );
                })}
          {!props.isSelectedDeleteStructure && (
            <AddUserCard
              onClick={() => {
                props.openUserCreate(undefined);
              }}
            />
          )}
        </StructureUserList>
      </StructureContainer>
      <AllUserContainer className={addUserOpen ? "alluser__increse" : ""}>
        <div className="alluser__header">
          <div className="alluser__header__header">
            <div className="alluser__header__header__row">
              <Cancel
                width={26}
                height={26}
                style={{ cursor: "pointer" }}
                onClick={() => updateAddUserOpen(false)}
              />
              <span className="font-bold-18">조직원 이동</span>
            </div>
            <Button onClick={moveUserToSelectedStructure}>확인</Button>
          </div>
          <div
            ref={selectedUsersRef}
            className="selected__user__container scroll__invisible"
          >
            {selectedUsers.map((item) => {
              return (
                <UserCircleItem
                  key={item.hpUserId}
                  userInfo={item}
                  isChecked={selectedUsers
                    .map((item) => item.hpUserId)
                    .includes(item.hpUserId)}
                  cancelSelecteUser={cancelSelecteUser}
                />
              );
            })}
          </div>
        </div>
        <AllUserList className="scroll__invisible">
          <input
            className="alluser__search font-regular-12"
            placeholder="이름으로 검색"
            onChange={(e) => {
              updateSearchName(e.target.value);
            }}
          />
          <Divider style={{ marginTop: "18px", marginBottom: "18px" }} />
          {props.allHpUser
            .filter((item) => !item.isDelete)
            .filter((item) => item.name?.includes(searchName))
            .map((userInfo) => {
              return (
                <UserRowItem
                  key={userInfo.hpUserId}
                  userInfo={userInfo}
                  isChecked={selectedUsers
                    .map((item) => item.hpUserId)
                    .includes(userInfo.hpUserId)}
                  selecteUser={selecteUser}
                  cancelSelecteUser={cancelSelecteUser}
                />
              );
            })}
        </AllUserList>
      </AllUserContainer>
      <ModalLayout
        isOpen={openDeleteModal}
        toggle={() => setOpenDeleteModal(false)}
      >
        <ConfirmModal
          message={`${deletedUser?.name} 유저를 삭제하시겠습니까?`}
          title={"유저 삭제하기"}
          type={"confirm"}
          negativeTitle={"취소"}
          positiveTitle={"삭제하기"}
          onClickPositiveBtn={() => {
            if (deletedUser) {
              props.setAllHpUser((prev) => {
                const updatedList = [...prev];
                const updatedUser = updatedList.find(
                  (item) => item.hpUserId === deletedUser.hpUserId
                );
                if (updatedUser) {
                  updatedUser.isDelete = true;
                }
                return updatedList;
              });
            }

            setOpenDeleteModal(false);
          }}
          onClickNegativeBtn={() => setOpenDeleteModal(false)}
        />
      </ModalLayout>
      <ModalLayout
        isOpen={openRestoreModal}
        toggle={() => setOpenRestoreModal(false)}
      >
        <ConfirmModal
          message={`${deletedUser?.name} 유저를 복구하시겠습니까?`}
          title={"유저 복구하기"}
          type={"confirm"}
          negativeTitle={"취소"}
          positiveTitle={"복구하기"}
          onClickPositiveBtn={() => {
            if (deletedUser) {
              props.setAllHpUser((prev) => {
                const updatedList = [...prev];
                const updatedUser = updatedList.find(
                  (item) => item.acUserId === deletedUser.acUserId
                );
                if (updatedUser) {
                  updatedUser.isDelete = false;
                }
                return updatedList;
              });
            }
            setOpenRestoreModal(false);
          }}
          onClickNegativeBtn={() => setOpenRestoreModal(false)}
        />
      </ModalLayout>
    </TeamDetailLayout>
  );
};
export default TeamDetail;
