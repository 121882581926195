import React, { useEffect, useRef, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
import * as api from "../../../api/api";
import { LoginContainer, LoginBox } from "./styles";
import { ReactComponent as Logo } from "../../../assets/logo.svg";

const LogIn = () => {
  const navigation = useNavigate();
  const idRef = useRef<HTMLInputElement>(null);
  const pwRef = useRef<HTMLInputElement>(null);
  const [email, updateEmail] = useState<string>("");
  const [password, updatePassword] = useState<string>("");
  const [isSaveId, updateSaveId] = useState<boolean>(false);
  const [cookies, setCookie, removeCookie] = useCookies();

  const isLoginAble = () => {
    if (!idRef.current || !pwRef.current) return true;
    if (idRef.current.value.length === 0) return true;
    if (pwRef.current.value.length === 0) return true;
    return false;
  };

  const submitLogin = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    try {
      e.preventDefault();
      const param = {
        email,
        password,
      };
      const result = await axios.post(api.adminLogin(), param, {
        withCredentials: true,
      });
      removeCookie("access_token");
      setCookie("sessing", true);
      setCookie("access_token", result.data.access_token);
      await axios.put(api.updateIndustrialTech());
      if (result.status === 200) {
        if (isSaveId) {
          window.localStorage.setItem("email", email);
        } else window.localStorage.removeItem("email");
        onSuccessLogin();
      }
    } catch (error) {
      toast.error(`${error}`, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  const onSuccessLogin = (accessToken?: string) => {
    navigation("/dash/team");
  };

  useEffect(() => {}, []);

  return (
    <LoginContainer>
      <div className="background" />
      <LoginBox>
        <div className="left__container">
          <Logo className="logo" />
          <span>세상을 바꾸는 새로운 기술</span>
        </div>
        <div className="right__container">
          <Form>
            <Form.Group style={{ width: "300px" }}>
              <Form.Label style={{ color: "white" }}>ID</Form.Label>
              <Form.Control
                ref={idRef}
                type="text"
                placeholder="Admin Id"
                value={email}
                autoComplete="off"
                onChange={(e) => {
                  updateEmail(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label style={{ color: "white" }}>Password</Form.Label>
              <Form.Control
                ref={pwRef}
                type="password"
                placeholder="Password"
                value={password}
                autoComplete="off"
                onChange={(e) => {
                  updatePassword(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check
                type="checkbox"
                style={{ color: "white" }}
                label="아이디 기억하기"
              />
            </Form.Group>
            <div className="login__btn">
              <Button
                style={{ width: "100%" }}
                variant="primary"
                type="submit"
                size="sm"
                disabled={isLoginAble()}
                onClick={submitLogin}
              >
                Login
              </Button>
            </div>
          </Form>
        </div>
      </LoginBox>
    </LoginContainer>
  );
};
export default LogIn;
