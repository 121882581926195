import { useContainerDimensions } from "./containerDimensions";

const DisplayType = () => {
  const windowWidth = useContainerDimensions();
  if (windowWidth > 1023) {
    return "Desktop";
  }
  if (windowWidth < 774) {
    return "Mobile";
  }
  return "Tablet";
};
export default DisplayType;
