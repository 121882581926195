import styled from "@emotion/styled";
import Footer from "component/footer/Footer";
import DisplayType from "utils/displayType";

import { ReactComponent as NewLogo } from "../../assets/new_logo.svg";
import commingSoon from "../../assets/comming_soon.png";

const Maintainance = () => {
  const mode = DisplayType();

  return (
    <Container>
      <div className="maintainance__body">
        <img
          className="commin__soon"
          src={commingSoon}
          alt="점검 페이지 이미지"
        />
        <div
          className={`main__title ${
            mode === "Mobile" ? "font-sub-title2" : "font-sub-title"
          }`}
        >
          서비스 준비중입니다.
        </div>
        <div
          className={`maintainance__body__msg ${
            mode === "Mobile" ? "" : "font-body2"
          }`}
        >
          <div>현재 홈페이지는 업데이트 중입니다.</div>
          <div>
            <strong>점검시간(17:00 ~ 17:20)</strong>까지 완료 후 <br /> 서비스를
            {mode === "Mobile" && <br />} 제공할 수 있도록 노력하겠습니다.
          </div>
          <div>감사합니다.</div>
        </div>
      </div>
      <NewLogo className="logo" />
      <Footer />
    </Container>
  );
};
const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .maintainance__body {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .main__title {
      margin-top: 50px;
    }
    .maintainance__body__msg {
      margin-top: 16px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }

  .commin__soon {
    width: 424px;
    height: 276px;
  }

  .logo {
    position: absolute;
    top: 0;
    left: 100px;
  }

  @media only screen and (max-width: 768px) {
    .maintainance__body {
      min-height: 100vh;
      .main__title {
        margin-top: 24px;
      }
      .maintainance__body__msg {
        margin-top: 8px;
      }
    }

    .commin__soon {
      width: 307px;
      height: 202px;
    }

    .logo {
      position: absolute;
      top: 20px;
      left: 32px;
      width: 100px;
      height: 60px;
    }
  }
`;

export default Maintainance;
