import styled from "@emotion/styled";
import DisplayType from "utils/displayType";
import { useLayoutEffect } from "react";
import { updateTitle } from "utils/commonUtil";
import Footer from "../../component/footer/Footer";
import { ReactComponent as Logo } from "../../assets/logo_blue_school.svg";

const BlueSchool = () => {
  const mode = DisplayType();

  useLayoutEffect(() => {
    updateTitle("블루스쿨");
  }, []);

  return (
    <Container>
      <HeaderContainer>
        <Logo className="program__logo" />
        <span
          className={`program__title ${
            mode === "Mobile" ? "font-bold-24" : "font-title"
          }`}
        >
          블루스쿨
        </span>
        <p
          className={`program__description ${
            mode === "Mobile" ? "font-body3" : "font-body2"
          }`}
        >
          스타트업의 핵심 성장지표 분석을 통해
          <br />
          실질적 밸류업을 달성하도록 블루포인트가{mode === "Mobile" && (
            <br />
          )}{" "}
          집중 케어합니다.
        </p>
      </HeaderContainer>
      <BodyContainer className="limit__body">
        <ProgramDescription>
          <div
            className={`program__description__title ${
              mode === "Mobile" ? "font-sub-title2" : "font-title-en"
            }`}
          >
            No unicorn is born,
            <br />
            It will only be made
          </div>
          <p className={`${mode === "Mobile" ? "font-body3" : "font-body2"}`}>
            블루포인트 포트폴리오사를 대상으로 진행하는 부트캠프 형식의 집중
            성장 프로그램입니다. 스타트업은 극초기 시기를 지나면 다음 단계로의
            도약이 필요한 구간을 만나게 됩니다. 블루스쿨은 여기에 포커스를 두고,
            참가 기업들의 핵심 지표와 내재가치를 실질적으로 끌어올리기 위해
            심사역들이 밀착 지원합니다.
            <br />
            <br />각 사의 비즈니스모델, 서비스∙제품 등에 따라 목표를 정하고 해당
            영역의 전문가들이 집중 멘토링을 제공하며, KPI(핵심성과지표) 또는
            OKR(조직과 개인의 목표를 설정하고 결과를 확인하는 성과관리기법)
            세팅을 통해 핵심 지표를 개선합니다.
          </p>
        </ProgramDescription>
        <ProgramProcess>
          <div
            className={`program__process__title ${
              mode === "Mobile" ? "font-bold-22" : "font-sub-title"
            }`}
          >
            프로그램 과정
          </div>
          <div className={`program__process__row`}>
            <span
              className={`${
                mode === "Mobile" ? "font-bold-16" : "font-bold-18"
              }`}
            >
              설명
            </span>
            <span
              className={`${mode === "Mobile" ? "font-body3" : "font-body2"}`}
            >
              패밀리 기업의 핵심 성장 지표를 개선할 수 있도록 돕는 집중 멘토링
              프로그램
            </span>
          </div>
          <div className={`program__process__row`} style={{ marginTop: 8 }}>
            <span
              className={`${
                mode === "Mobile" ? "font-bold-16" : "font-bold-18"
              }`}
            >
              대상
            </span>
            <span
              className={`${mode === "Mobile" ? "font-body3" : "font-body2"}`}
            >
              블루포인트 포트폴리오
            </span>
          </div>
          <div className="program__process__list">
            <ProgramProcessItem>
              <span
                className={`item__title ${
                  mode === "Mobile" ? "font-body3" : "font-body2"
                }`}
              >
                참가기업
              </span>
              <span
                className={`item__descrition ${
                  mode === "Mobile" ? "font-body3" : "font-body2"
                }`}
              >
                최근 약 1년 내 블루포인트가 투자한 기업 중 서비스플랫폼 기업
                위주로 3~4개 선정
              </span>
            </ProgramProcessItem>
            <ProgramProcessItem>
              <span
                className={`item__title ${
                  mode === "Mobile" ? "font-body3" : "font-body2"
                }`}
              >
                커리큘럼
              </span>
              <span
                className={`item__descrition ${
                  mode === "Mobile" ? "font-body3" : "font-body2"
                }`}
              >
                각 포트폴리오사의 핵심 지표 현황, 비즈니스모델, 성장 단계 등에
                대해 사전 인터뷰와 데이터 분석을 실시해 각 사에 맞는 개선
                목표치를 설정
              </span>
            </ProgramProcessItem>
            <ProgramProcessItem>
              <span
                className={`item__title ${
                  mode === "Mobile" ? "font-body3" : "font-body2"
                }`}
              >
                멘토
              </span>
              <span
                className={`item__descrition ${
                  mode === "Mobile" ? "font-body3" : "font-body2"
                }`}
              >
                최소 10년 이상 해당 분야 경험 또는 스타트업 멘토링 경험을 보유한
                국내외 전문가들을 멘토로 초대
              </span>
            </ProgramProcessItem>
            <ProgramProcessItem>
              <span
                className={`item__title ${
                  mode === "Mobile" ? "font-body3" : "font-body2"
                }`}
              >
                멘토링
              </span>
              <span
                className={`item__descrition ${
                  mode === "Mobile" ? "font-body3" : "font-body2"
                }`}
              >
                지표 성장 관련 수 주간에 걸친 집중 멘토링 제공
              </span>
            </ProgramProcessItem>
          </div>
        </ProgramProcess>
      </BodyContainer>
      <Footer />
    </Container>
  );
};
const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 80px;

  @media only screen and (max-width: 768px) {
    padding-top: 60px;
  }
`;

const HeaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--bg-02);
  padding-top: 80px;
  padding-bottom: 80px;

  .program__logo {
    width: 240px;
    min-width: 240px;
    max-width: 240px;
    height: 240px;
    min-width: 240px;
    max-width: 240px;
  }
  .program__title {
    color: var(--black);
    margin-top: 24px;
    margin-bottom: 16px;
  }
  .program__description {
    color: var(--grey-40);
    text-align: center;
  }

  @media only screen and (max-width: 768px) {
    padding-bottom: 60px;
    .program__logo {
      width: 117px;
      min-width: 117px;
      max-width: 117px;
      height: 117px;
      min-width: 117px;
      max-width: 117px;
    }
    .program__title {
      color: var(--black);
      margin-top: 24px;
      margin-bottom: 8px;
    }
  }
`;

const BodyContainer = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  margin-bottom: 160px;
`;

const ProgramDescription = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  margin-top: 100px;

  .program__description__title {
    color: var(--primary);
    margin-bottom: 24px;
  }

  @media only screen and (max-width: 768px) {
    margin-top: 40px;
    .program__description__title {
      margin-bottom: 16px;
    }
  }
`;

const ProgramProcess = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  margin-top: 100px;

  .program__process__title {
    color: var(--black);
    margin-bottom: 24px;
  }

  .program__process__row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
  }

  .program__process__list {
    width: 100%;
    border-top: 1px solid var(--grey-00);
    display: flex;
    flex-direction: column;
    margin-top: 24px;
  }
  @media only screen and (max-width: 768px) {
    margin-top: 40px;
    .program__process__title {
      margin-bottom: 16px;
    }
    .program__process__row {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
    }
  }
`;

const ProgramProcessItem = styled.div`
  width: 100%;
  padding: 32px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--grey-00);

  .item__title {
    color: var(--primary);
  }
  .item__descrition {
    color: var(--grey-60);
  }
  @media only screen and (max-width: 768px) {
    padding: 24px 16px;
  }
`;

const EmailContainer = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  .email__icon {
    width: 32px;
    height: 32px;
  }
  .email__text {
    color: var(--primary);
  }
`;
export default BlueSchool;
