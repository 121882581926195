import React, { useState, useEffect } from "react";
import { Container } from "./styles";

interface Props {
  label?: string;
  defaultChecked?: boolean;
  isEditable: boolean;
  onChange?: (changeSate: boolean) => void;
}
const Toggle: React.FC<Props> = (props) => {
  const [checked, setChecked] = useState<boolean>(
    props.defaultChecked || false
  );
  const [render, setRender] = useState<boolean>(true);

  const toggeRequired = () => {
    if (!props.isEditable) return;
    const updateState = !checked;
    setChecked(updateState);
    if (props.onChange !== undefined) {
      props.onChange(updateState);
    }
  };
  useEffect(() => {
    setChecked(props.defaultChecked || false);
  }, [props.defaultChecked]);
  return (
    <Container
      onClick={(e) => {
        e.stopPropagation();
        toggeRequired();
      }}
    >
      {props.label && <span className="font-regular-14">{props.label}</span>}
      <div className={`require__wrapper ${checked && "active"}`}>
        <span className={`require__thumb ${checked && "require"}`} />
      </div>
    </Container>
  );
};

export default Toggle;
