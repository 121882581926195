import React, { Dispatch, useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import styled from "@emotion/styled";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Mode } from "admin/type/type";
import DefaultButton from "admin/component/button/DefaultButton";
import axios from "axios";
import { ContentProps } from "admin/router/content";
import DisplayType from "utils/displayType";
import Chip from "component/chips/Chip";
import { Link, useNavigate } from "react-router-dom";
import { getYMD, sortingByOpenDate } from "utils/commonUtil";
import { Hidden } from "@mui/material";
import Label from "component/label/Label";
import * as api from "../../../api/api";

import { ReactComponent as RollingLeft } from "../../../assets/rolling_left.svg";
import { ReactComponent as RollingRight } from "../../../assets/rolling_right.svg";

interface Props {}
const LastestContents: React.FC<Props> = (props) => {
  const [loading, setLoading] = useState(true);
  const sliderRef = useRef<any>(null);
  const mode = DisplayType();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: mode === "Mobile" ? 1 : 3,
    slidesToScroll: mode === "Mobile" ? 1 : 3,
  };

  const navigate = useNavigate();
  const [contents, updateContents] = useState<ContentProps[]>([]);

  const getContentsList = async () => {
    try {
      const result = await axios.get(api.getContentList());
      if (result.status === 200) {
        if (result.data.length > 0) {
          updateContents(
            result.data
              .sort(sortingByOpenDate)
              .filter((item: any) => item.isActive)
              .map((item: any): ContentProps => ({ ...item }))
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const next = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };
  const prev = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  useEffect(() => {
    getContentsList();
  }, []);

  return (
    <Container>
      {mode !== "Mobile" && (
        <Controller>
          <RollingLeft className="rolling__icon" onClick={prev} />
          <RollingRight className="rolling__icon" onClick={next} />
        </Controller>
      )}
      <Slider ref={sliderRef} className="full" {...settings}>
        {contents
          .filter((item, index) => index < 9)
          .map((item) => (
            <div key={item.hpContentId}>
              <Link
                to={`/insight/contents/${item.hpContentId}`}
                style={{ textDecoration: "none" }}
              >
                <ContentsContainer
                  contentsImg={item.thumbNailImgUrl}
                  style={{ textDecoration: "none" }}
                >
                  <div className="contents__wrapper">
                    <div className="contents__thumbnail">
                      <div className="content__label">
                        <Label labelType="type1">{item.category}</Label>
                      </div>
                    </div>
                    <div className="contents__info">
                      <span
                        className={`content__title ${
                          mode === "Mobile"
                            ? "font-sub-title4"
                            : "font-sub-title2"
                        }`}
                      >
                        {item?.title}
                      </span>
                      <p
                        className={`contents__description ${
                          mode === "Mobile" ? "font-body4" : "font-body2"
                        }`}
                      >
                        {item?.description}
                      </p>
                      <span className="font-body4-en open__date">
                        {getYMD(item.openDate)}
                      </span>
                    </div>
                  </div>
                </ContentsContainer>
              </Link>
            </div>
          ))}
      </Slider>
    </Container>
  );
};

const Controller = styled.div`
  position: absolute;
  right: 24px;
  top: -60px;
  display: flex;
  flex-direction: row;
  gap: 24px;
  .rolling__icon {
    cursor: pointer;
    :hover {
      path {
        stroke: var(--primary);
      }
    }
  }
  @media only screen and (max-width: 768px) {
    right: 0;
    top: -45px;
  }
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 24px;
  .full {
    width: calc(100%);
  }
  .slick-dots li.slick-active button:before {
    opacity: 0.75;
    color: var(--primary);
  }

  .slick-dots li {
    margin: 0;
  }
  .slick-dots li button:before {
    font-family: "slick";
    font-size: 10px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 15px;
    height: 15px;
    content: "•";
    text-align: center;
    opacity: 0.25;
    color: black;
    -webkit-font-smoothing: antialiased;
  }

  .slick_arrow .slick-prev {
    display: none;
  }

  .slick-dots {
    position: absolute;
    bottom: -55px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
  }

  .slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    margin-left: 0;
    margin-right: 0;
  }

  @media only screen and (max-width: 768px) {
    .slick-dots {
      bottom: -45px;
    }
  }
`;

const ContentsContainer = styled.div<{ contentsImg?: string }>`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  /* width: calc(1176px / 3 + 24px); */

  .contents__wrapper {
    width: calc(100% - 24px);
    height: 100%;
    display: flex;
    flex-direction: column;
    transition: all 0.3s ease;
    border: 1px solid var(--grey-00);

    .contents__thumbnail {
      position: relative;
      width: calc(100%);
      height: 100%;
      min-height: 240px;
      max-height: 240px;

      /* The image used */
      background-image: url(${(props) => props.contentsImg || ""});

      /* Center and scale the image nicely */
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      .content__label {
        position: absolute;
        top: 16px;
        left: 16px;
      }
    }
    .contents__info {
      width: calc(100%);
      display: flex;
      flex-direction: column;
      padding: 32px;
      border-top: 1px solid var(--grey-00);
      .content__title {
        height: 68px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        margin: 0;
        color: var(--black);
      }
      .contents__description {
        height: 75px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        margin: 0;
        margin-top: 24px;
        color: var(--grey-40);
      }
      .open__date {
        margin-top: 32px;
        color: var(--grey-40);
      }
    }
    :hover {
      border-color: var(--primary);
    }
  }

  @media only screen and (max-width: 768px) {
    .contents__wrapper {
      width: 100%;
      height: auto;
      flex-direction: column;
      margin-top: 0;
      gap: 0px;
      margin-right: 0px;
      .contents__thumbnail {
        width: 100%;
        min-width: 100%;
        min-height: 100%;
        height: auto;
        min-height: auto;
        max-height: auto;
        aspect-ratio: 355 / 240;
      }
      .contents__info {
        width: 100%;
        padding: 24px;
        background-color: var(--bg-02);

        .content__title {
          height: 56px;
        }
        .contents__description {
          height: 66px;
          margin-top: 10px;
        }
      }
    }
  }
`;

export default React.memo(LastestContents);
