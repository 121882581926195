import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import Footer from "component/footer/Footer";
import HeaderNavigation from "component/header/HeaderNavigation";
import axios from "axios";
import DisplayType from "utils/displayType";
import Banner from "component/banner/Banner";
import { InsightMenu, NavigationMenu } from "admin/type/type";

import { BlueLetterProps } from "admin/router/blueletter";
import { Pagination } from "@mui/material";
import DefaultButton from "admin/component/button/DefaultButton";
import { sortingByOpenDate, updateTitle } from "utils/commonUtil";
import * as api from "../../api/api";
import InsightTabs from "./components/InsightTabs";
import BlueletterCard from "../../component/card/Blueletter";
import { ContentStateProps } from "./Contents";
import blueletterBanner from "../../assets/blueletter_banner.png";
import blueletterBanner1920 from "../../assets/blueletter_banner_1920.png";
import blueletterBanner2048 from "../../assets/blueletter_banner_2048.png";

const Blueletter = () => {
  const mode = DisplayType();

  const isBack = useRef<boolean>(false);
  const [settingCurrentPage, updateSettingCurrentPate] =
    useState<boolean>(false);

  const countPerPage = useRef<number>(mode === "Mobile" ? 8 : 12);
  const [currentPage, updateCurrentPage] = useState<number>(1);
  const [totalPage, updateTotalPage] = useState<number>(0);

  const [blueletter, updateBlueletter] = useState<BlueLetterProps[]>([]);

  const getAllBlueletter = async () => {
    try {
      const result = await axios.get(api.getBlueLetterList());
      if (result.status === 200) {
        const blueletters = result.data
          .sort(sortingByOpenDate)
          .filter((item: any) => item.isActive)
          .map((item: any): BlueLetterProps => ({ ...item }));

        updateBlueletter(blueletters);
        updateTotalPage(Math.ceil(blueletters.length / countPerPage.current));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getStartIndex = () => {
    return mode === "Mobile" ? 0 : (currentPage - 1) * countPerPage.current;
  };

  const storeState = (page?: number) => {
    const state: ContentStateProps = {
      scrollY: window.scrollY,
      currentPage: page || currentPage,
    };
    localStorage.setItem(document.location.pathname, JSON.stringify(state));
  };

  useEffect(() => {
    if (!settingCurrentPage) return;

    const state = localStorage.getItem(document.location.pathname);
    if (state) {
      const beforeState = { ...JSON.parse(state) };
      window.scrollTo(0, beforeState.scrollY);
      localStorage.removeItem(document.location.pathname);
      localStorage.removeItem("goBack");
    }
  }, [settingCurrentPage]);

  useEffect(() => {
    if (blueletter.length === 0) return;

    const goBack = localStorage.getItem("goBack");
    if (goBack) {
      isBack.current = JSON.parse(goBack).goBack;
    }
    if (isBack.current) {
      const state = localStorage.getItem(document.location.pathname);
      if (state) {
        const beforeState = { ...JSON.parse(state) };
        updateCurrentPage(beforeState.currentPage);
        updateSettingCurrentPate(true);
      }
    }
  }, [blueletter]);

  useEffect(() => {
    getAllBlueletter();
  }, []);

  useLayoutEffect(() => {
    updateTitle("Blue Letter");
  }, []);

  return (
    <Container>
      <Banner
        bannerImg={blueletterBanner}
        bannerImg1920={blueletterBanner1920}
        bannerImg2048={blueletterBanner2048}
        mode={mode}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: mode === "Mobile" ? "center" : "flex-start",
          }}
        >
          <div
            className={`${
              mode === "Mobile" ? "font-title-en" : "font-title-en"
            }`}
            style={{ color: "var(--white)" }}
          >
            BLUE LETTER
          </div>
        </div>
      </Banner>
      <InsightTabs selectedTab={InsightMenu.BLUELETTER} />
      <InsightBodyContainer className="limit__body">
        <div className="blueletter__list">
          {blueletter
            .slice(getStartIndex(), currentPage * countPerPage.current)
            .map((item) => (
              <BlueletterCard key={item.hpBlueLetterId} blueletter={item} />
            ))}
        </div>
        {mode !== "Mobile" && totalPage > 0 && (
          <div className="center">
            <Pagination
              count={totalPage}
              shape="rounded"
              page={currentPage}
              onChange={(event, page) => {
                storeState(page);
                updateCurrentPage(page);
              }}
            />
          </div>
        )}
        {mode === "Mobile" && totalPage > 0 && currentPage < totalPage && (
          <div className="center">
            <DefaultButton
              className="font-body3"
              type="outline"
              onClick={() => {
                storeState(currentPage + 1);
                updateCurrentPage(currentPage + 1);
              }}
            >
              블루레터 더보기
            </DefaultButton>
          </div>
        )}
      </InsightBodyContainer>
      <Footer />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 768px) {
  }
`;
const InsightBodyContainer = styled.div`
  flex-grow: 1;
  width: 100%;
  display: flex;
  padding-top: 100px;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;

  .blueletter__list {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 56px;
  }
  .center {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media only screen and (max-width: 768px) {
    padding-top: 40px;
    padding-bottom: 40px;
    .blueletter__list {
      gap: 24px;
    }
  }
`;
export default Blueletter;
