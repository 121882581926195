import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import axios from "axios";
import ModalLayout from "admin/component/modal/ModalLayout";
import ConfirmModal from "admin/component/modal/ConfirmModal";
import { IRDataProps } from "admin/type/type";
import { ReactComponent as Delete } from "../../../../assets/file_delete.svg";
import * as api from "../../../../api/api";
import { IRDataAdmin } from "../IRArchive";

interface Props {
  item: IRDataAdmin;
  showMenu?: boolean;
  onClickItem?: () => void;
  callback?: () => void;
}

const IRCard: React.FC<Props> = (props) => {
  const [deleteConfirmOpen, updateDeleteConfirmOpen] = useState<boolean>(false);
  const selectedItem = useRef<IRDataProps | undefined>(undefined);

  const onClickDelete = async (id: number) => {
    try {
      const result = await axios.delete(api.deleteIRArchives(), {
        params: { hpIrId: id },
      });
      if (result.status === 200 && props.callback) {
        updateDeleteConfirmOpen(false);
        props.callback();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fileDownload = async (key: string) => {
    try {
      const result = await axios.get(api.downloadByKey(), {
        params: { key },
      });
      const a = document.createElement("a");
      a.href = result.data;
      a.click();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // updateIrArchiveData(props.item);
  }, [props.item]);

  if (!props.item) return null;

  return (
    <Container>
      <div className="font-bold-20 ir__card__title">
        {props.item.type.title}
      </div>
      <ListContainer className="scroll__invisible">
        {props.item.data.map((item) => {
          return (
            <ListItem>
              <div className="font-medium-14">{item.title}</div>
              <div style={{ textAlign: "end" }}>
                <span
                  className="file__download font-button3"
                  onClick={(e) => {
                    e.stopPropagation();
                    fileDownload(item.fileKey);
                  }}
                >{`PDF 다운로드`}</span>
              </div>
              <Delete
                className="item__remove"
                onClick={(e) => {
                  e.stopPropagation();
                  selectedItem.current = item;
                  updateDeleteConfirmOpen(true);
                }}
              />
            </ListItem>
          );
        })}
      </ListContainer>

      <ModalLayout isOpen={deleteConfirmOpen}>
        <ConfirmModal
          type="confirm"
          message={`선택하신 "${selectedItem.current?.title}" 파일을 삭제 하시겠습니까?`}
          onClickNegativeBtn={() => {
            updateDeleteConfirmOpen(false);
            selectedItem.current = undefined;
          }}
          onClickPositiveBtn={() => {
            if (!selectedItem.current) return;
            onClickDelete(selectedItem.current?.id);
          }}
        />
      </ModalLayout>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background-color: var(--grey-00);
  .ir__card__title {
    padding: 24px 16px 16px 16px;
  }
`;

const ListContainer = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
  overflow: scroll;
`;

const ListItem = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: white;
  gap: 16px;
  padding: 12px;

  .file__download {
    border: 1px solid black;
    padding: 4px 8px;
    cursor: pointer;
    transition: all 0.1s ease-in;
    :hover {
      border-color: var(--primary);
      color: var(--primary);
    }
  }

  .item__remove {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
`;

export default React.memo(IRCard);
