import React from "react";
import styled from "@emotion/styled";

import { Link } from "react-router-dom";
import DisplayType from "../../utils/displayType";
import { ReactComponent as Back } from "../../assets/go_back.svg";

interface Props {
  to: string;
}

const GoList: React.FC<Props> = (props) => {
  const mode = DisplayType();
  return (
    <Container>
      <Link className="font-button1 go__list__link" to={props.to}>
        <Back />
        <span>목록으로</span>
      </Link>
    </Container>
  );
};
const Container = styled.div`
  .go__list__link {
    text-decoration: none;
    color: var(--primary);
    path {
      fill: var(--primary);
    }

    :hover {
      color: var(--black);
      path {
        fill: var(--black);
      }
    }
  }
`;
export default GoList;
