import styled from "@emotion/styled";

import DisplayType from "utils/displayType";
import FaqSection from "router/contact/components/FaqSection";
import { useEffect } from "react";
import Footer from "../../../component/footer/Footer";

const Policy20230601 = () => {
  const mode = DisplayType();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
  }, []);
  return (
    <BodyContainer className="limit__body">
      <div
        style={{ width: "100%", height: 1, backgroundColor: "var(--black)" }}
      />
      <FaqSection headerTitle={`1. 개인정보의 처리목적`}>
        <div className={`${mode === "Mobile" ? "font-body3" : "font-body3"}`}>
          <ol style={{ display: "flex", flexDirection: "column", gap: 6 }}>
            <li>
              회사는 아래와 같은 업무처리 목적을 위하여 개인정보를 처리합니다.
              처리하고 있는 개인정보는 아래의 목적 이외의 용도로는 사용하지
              않습니다.
              <ul className="dashed">
                <li>투자 제안･업무 제휴 등 접수 및 처리결과 회신</li>
                <li>투자 동향 및 포트폴리오 소식 등 뉴스레터 발송</li>
                <li>포트폴리오사 주요 정보(블루카드) 제공</li>
                <li>회사의 개인정보 처리업무에 따른 처리목적</li>
              </ul>
            </li>
            <li>
              이용 목적이 변경되는 경우에는 개인정보보호법 제18조에 따라 별도의
              동의를 받는 등 관련 법령에 따른 필요한 조치를 이행할 예정입니다.
            </li>
          </ol>
        </div>
      </FaqSection>
      <FaqSection headerTitle={`2. 개인정보의 처리 및 보유기간`}>
        <div className={`${mode === "Mobile" ? "font-body3" : "font-body3"}`}>
          <ol style={{ display: "flex", flexDirection: "column", gap: 6 }}>
            <li>
              회사는 법령에 따른 개인정보 보유, 이용기간 또는 정보주체로부터
              개인정보 수집 시에 동의받은 개인정보 보유, 이용기간 내에서
              개인정보를 처리하고 보유합니다.
            </li>
            <li>
              다만, 뉴스레터 발송의 경우 개인정보 보유기간은 다음과 같습니다.
              <ul className="dashed">
                <li>
                  뉴스레터 발송 : 뉴스레터 구독 취소시까지 (탈퇴요청시 삭제)
                </li>
              </ul>
            </li>
          </ol>
        </div>
      </FaqSection>
      <FaqSection headerTitle={`3. 처리하는 개인정보 항목`}>
        <div className={`${mode === "Mobile" ? "font-body3" : "font-body3"}`}>
          <ol style={{ display: "flex", flexDirection: "column", gap: 6 }}>
            <li>
              회사는 투자 및 업무 제휴 제안 접수 등을 위해 아래와 같은
              개인정보를 수집하고 있습니다.
              <ul className="dashed">
                <li>성명, 기업명, 전화번호, 이메일 주소</li>
              </ul>
            </li>
            <li>
              회사는 투자 동향 및 포트폴리오 소식 등 뉴스레터 발송을 위한
              목적으로 아래와 같은 개인정보를 수집하고 있습니다.
              <ul className="dashed">
                <li>성명, 이메일 주소</li>
              </ul>
            </li>

            <li>
              회사는 포트폴리오사의 주요 정보 등 블루카드 제공을 위한 목적으로
              아래와 같은 개인정보를 수집하고 있습니다.
              <ul className="dashed">
                <li>성명, 기업명, 직급, 이메일 주소</li>
              </ul>
            </li>
          </ol>
        </div>
      </FaqSection>
      <FaqSection headerTitle={`4. 개인정보의 파기 절차 및 방법`}>
        <div className={`${mode === "Mobile" ? "font-body3" : "font-body3"}`}>
          <ol style={{ display: "flex", flexDirection: "column", gap: 6 }}>
            <li>
              회사는 원칙적으로 개인정보의 처리목적이 달성된 경우 등 그
              개인정보가 불필요하게 되었을 때에는 지체 없이 해당 개인정보를
              파기합니다.
            </li>
            <li>
              다만, 관계법령의 규정에 따라 개인정보를 파기하지 아니하고
              보존하여야 하는 경우에는 해당 개인정보 또는 개인정보파일을 다른
              개인정보와 분리해서 저장·관리 합니다.
            </li>
            <li>
              개인정보의 파기 방법은 아래와 같습니다.
              <ul className="dashed">
                <li>
                  전자적 파일형태로 저장된 개인정보: 기록을 재생할 수 없는
                  기술적 방법을 사용하여 삭제
                </li>
                <li>
                  종이에 출력된 개인정보: 분쇄기로 분쇄하거나 소각을 통하여 파기
                </li>
              </ul>
            </li>
          </ol>
        </div>
      </FaqSection>
      <FaqSection
        headerTitle={`5. 정보주체와 법정대리인의 권리·의무 및 그 행사방법에 관한 사항`}
      >
        <div className={`${mode === "Mobile" ? "font-body3" : "font-body3"}`}>
          <ol style={{ display: "flex", flexDirection: "column", gap: 6 }}>
            <li>
              정보주체는 회사에 대해 언제든지 개인정보의 열람･정정･삭제･처리정지
              등의 권리를 요구할 수 있습니다.
            </li>
            <li>
              정보주체는 회사에 대해 개인정보 보호법 시행규칙 별지 제8호 서식에
              따라 서면, 전자우편등을 통하여 하실 수 있으며 회사는 이에 대해
              지체없이 조치하겠습니다.
            </li>
            <li>
              정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우,
              회사는 해당 개인정보의 정정 또는 삭제를 완료할 때까지 해당
              개인정보를 이용하거나 제공하지 않습니다.
            </li>
            <li>
              권리행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을
              통하여 하실 수 있습니다. 이 경우 개인정보보호법 시행규칙 별지
              제11호 서식에 따른 위임장을 제출하셔야 합니다.
            </li>
          </ol>
        </div>
      </FaqSection>
      <FaqSection headerTitle={`6. 개인정보의 안전성 확보조치에 관한 사항`}>
        <div className={`${mode === "Mobile" ? "font-body3" : "font-body3"}`}>
          {" "}
          <ul
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 6,
              listStyleType: "none",
            }}
          >
            <li>
              회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고
              있습니다.
              <ul className="dashed">
                <li>
                  관리적 조치 : 내부관리계획 수립･시행, 정기적 직원 교육 등
                </li>
                <li>
                  기술적 조치 : 개인정보처리시스템의 접근권한 관리, 접근통제
                  시스템 및 보안프로그램 설치
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </FaqSection>
      <FaqSection headerTitle={`7. 개인정보의 제3자 제공`}>
        <div className={`${mode === "Mobile" ? "font-body3" : "font-body3"}`}>
          <ul
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 6,
              listStyleType: "none",
            }}
          >
            <li>
              회사는 개인정보를 {`"개인정보의 처리목적"`}에서 고지한 범위내에서
              사용하며, 정보주체의 사전 동의 없이는 동 범위를 초과하여
              이용하거나 원칙적으로 개인정보를 외부에 공개하지 않습니다. 다만,
              아래의 경우에는 예외로 합니다.
              <ul className="dashed">
                <li>정보주체로부터 별도의 동의를 받는 경우</li>
                <li>개인정보보호법 및 다른 법률에 특별한 규정이 있는 경우</li>
              </ul>
            </li>
          </ul>
        </div>
      </FaqSection>
      <FaqSection headerTitle={`8. 개인정보 처리업무의 위탁`}>
        <div className={`${mode === "Mobile" ? "font-body3" : "font-body3"}`}>
          <ol
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 6,
            }}
          >
            <li>
              회사는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보
              처리업무를 위탁하고 있습니다.
              <ul className="dashed">
                <li>
                  뉴스레터 발송
                  <ul
                    style={{
                      listStyleType: "square",
                      listStyleImage: "none",
                    }}
                  >
                    <li>위탁받는 자 (수탁자) : (주)스티비</li>
                    <li>위탁하는 업무의 내용 : 뉴스레터 발송</li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              위탁업무의 내용이나 수탁자가 변경될 경우에는 지체 없이 본 개인정보
              처리방침을 통하여 공개하도록 하겠습니다.
            </li>
            <li>
              회사는 위탁계약 체결시 위탁업무 수행목적 외 개인정보 처리금지,
              기술적․관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리․감독,
              손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가
              개인정보를 안전하게 처리하는지를 감독하고 있습니다.
            </li>
          </ol>
        </div>
      </FaqSection>
      <FaqSection
        headerTitle={`9. 개인정보 자동 수집 장치의 설치･운영 및 그 거부에 관한 사항`}
      >
        <div className={`${mode === "Mobile" ? "font-body3" : "font-body3"}`}>
          <ol
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 6,
              listStyleType: "none",
            }}
          >
            <li>
              회사는 정보주체의 이용정보를 저장하고 수시로 불러오는
              ‘쿠키(cookie)’를 사용하지 않습니다.
            </li>
          </ol>
        </div>
      </FaqSection>
      <FaqSection headerTitle={`10. 개인정보 보호책임자에 관한 사항`}>
        <div className={`${mode === "Mobile" ? "font-body3" : "font-body3"}`}>
          <ol
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 6,
            }}
          >
            <li>
              회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보
              처리와 관련한 이용자의 불만처리 및 피해구제 등을 위하여 아래와
              같이 개인정보 보호책임자를 지정·운영하고 있습니다.
              <ul className="dashed">
                <li>
                  개인정보 보호책임자 및 연락처
                  <ul
                    style={{
                      listStyleType: "square",
                      listStyleImage: "none",
                    }}
                  >
                    <li>개인정보보호 담당부서 : 기업법무팀</li>
                    <li>개인정보 보호책임자 : 이현우 익스퍼트 매니저</li>
                    <li>연락처 : hwlee@bluepoint.ac</li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              이용자는 회사의 서비스(또는 사업)을 이용하시면서 발생한 모든
              개인정보 보호 관련 문의, 불만처리, 피해구제, 개인정보 열람청구
              등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수
              있습니다.
            </li>
          </ol>
        </div>
      </FaqSection>
      <FaqSection headerTitle={`11. 정보주체의 권익침해에 대한 구제방법`}>
        <div className={`${mode === "Mobile" ? "font-body3" : "font-body3"}`}>
          <ol
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 6,
            }}
          >
            <li>
              회사는 개인정보의 유출 및 권리 침해시 그 피해구제와 권익을
              보장하기 위하여 최선을 다하겠습니다.
            </li>
            <li>
              회사의 자체적인 개인정보 불만처리, 피해구제 결과에 만족하지
              못하시거나 보다 자세한 도움이 필요한 경우, 아래의 기관에 문의하실
              수 있습니다.
              <div style={{ display: "flex", flexDirection: "column", gap: 6 }}>
                <ul className="dashed">
                  <li>
                    개인정보 침해신고센터 (한국인터넷진흥원 운영)
                    <ul
                      style={{
                        listStyleType: "square",
                        listStyleImage: "none",
                      }}
                    >
                      <li>소관업무 : 개인정보 침해사실 신고, 상담 신청</li>
                      <li>홈페이지 : privacy.kisa.or.kr</li>
                      <li>전화 : (국번없이) 118</li>
                      <li>
                        주소 : (58324) 전남 나주시 진흥길 9(빛가람동 301-2) 3층
                        개인정보침해신고센터
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul className="dashed">
                  <li>
                    개인정보 분쟁조정위원회
                    <ul
                      style={{
                        listStyleType: "square",
                        listStyleImage: "none",
                      }}
                    >
                      <li>
                        소관업무 : 개인정보 분쟁조정신청, 집단분쟁조정 (민사적
                        해결)
                      </li>
                      <li>홈페이지 : www.kopico.go.kr</li>
                      <li>전화 : (국번없이) 1833-6972</li>
                      <li>
                        주소 : (03171)서울특별시 종로구 세종대로 209
                        정부서울청사 4층
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul className="dashed">
                  <li>
                    대검찰청 사이버수사과
                    <ul
                      style={{
                        listStyleType: "square",
                        listStyleImage: "none",
                      }}
                    >
                      <li>홈페이지 : www.spo.go.kr</li>
                      <li>전화 : (국번없이) 1301</li>
                    </ul>
                  </li>
                </ul>
                <ul className="dashed">
                  <li>
                    경찰청 사이버안전국
                    <ul
                      style={{
                        listStyleType: "square",
                        listStyleImage: "none",
                      }}
                    >
                      <li>홈페이지 : cyberbureau.police.go.kr</li>
                      <li>전화 : (국번없이) 182</li>
                    </ul>
                  </li>
                </ul>
              </div>
            </li>
          </ol>
        </div>
      </FaqSection>
      <FaqSection headerTitle={`12. 개인정보 처리방침 변경`}>
        <div className={`${mode === "Mobile" ? "font-body3" : "font-body3"}`}>
          <ol
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 6,
            }}
          >
            <li>이 개인정보 처리방침은 2023. 6. 1 부터 적용됩니다.</li>
            <li>
              회사는 개인정보처리방침을 변경하는 경우에 변경 및 시행시기, 변경된
              내용을 지속적으로 공개하고 변경 전･후를 모두 공개하겠습니다.
              <ul className="dashed">
                <li>공고일자 : 2023년 05월 25일</li>
                <li>시행일자 : 2023년 06월 01 일</li>
              </ul>
            </li>
          </ol>
        </div>
      </FaqSection>
    </BodyContainer>
  );
};
const BodyContainer = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  margin-bottom: 160px;

  @media only screen and (max-width: 768px) {
    padding-top: 40px;
    padding-bottom: 40px;
    margin: 0;
  }
`;

export default Policy20230601;
