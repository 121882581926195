import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";

interface Props {
  width: number;
  itemList: DropdownSelectorProps[];
  selectedId: number;
  onChangeItem: (item: DropdownSelectorProps) => void;
}

export interface DropdownSelectorProps {
  id: number;
  text: string;
  data?: any;
}

export const DropdownSelector: React.FC<Props> = (props) => {
  const { width, itemList, selectedId, onChangeItem } = props;

  const rootRef = useRef<HTMLDivElement>(null);
  const searchListRef = useRef<HTMLDivElement>(null);
  const [searchFocus, updateSearchFocus] = useState<boolean>(false);

  const [selectedItem, updateSelectedItem] = useState<
    DropdownSelectorProps | undefined
  >(undefined);

  const mouseHandler = (event: any) => {
    if (rootRef.current && !rootRef.current.contains(event.target)) {
      updateSearchFocus(false);
    }
  };

  useEffect(() => {
    const data = itemList.find((item) => item.id === selectedId);
    updateSelectedItem(data);
  }, [selectedId]);

  useEffect(() => {
    window.addEventListener("mousedown", mouseHandler);
    return () => {
      window.removeEventListener("mousedown", mouseHandler);
    };
  }, []);

  return (
    <Container
      ref={rootRef}
      style={{
        width,
        minWidth: width,
        maxWidth: width,
        borderColor: searchFocus ? "var(--primary)" : "var(--grey-00)",
        cursor: "pointer",
      }}
      onClick={(e) => {
        updateSearchFocus(true);
      }}
    >
      <ItemText className={`font-body4`}>
        {" "}
        {selectedItem ? selectedItem.text : ""}
      </ItemText>

      {searchFocus && (
        <SearchListContainer className="scroll__invisible" ref={searchListRef}>
          {itemList.map((item, index) => {
            return (
              <SearchListItem
                tabIndex={index}
                className={`font-body4 ${
                  item.id === selectedId ? "selected" : ""
                }`}
                onClick={(e) => {
                  e.stopPropagation();
                  onChangeItem(item);
                  updateSearchFocus(false);
                }}
              >
                {item.text}
              </SearchListItem>
            );
          })}
        </SearchListContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  height: auto;
  min-height: 42px;

  display: flex;
  flex-direction: row;
  align-items: center;

  background: #ffffff;
  border: 1px solid var(--grey-00);
  border-radius: 4px;
  transition: all 0.2s ease;
  cursor: text;
`;

const ItemText = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 4px;

  padding: 0 24px;
`;

const SearchListContainer = styled.div`
  position: absolute;
  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: column;
  overflow: scroll;

  top: calc(100% + 10px);
  left: 0;

  padding: 8px 0px;
  max-height: 256px;

  background: #ffffff;
  box-shadow: 2px 2px 10px rgba(100, 103, 109, 0.3);
  border-radius: 4px;

  z-index: 10;
`;

const SearchListItem = styled.div`
  position: relative;
  width: 100%;
  height: 40px;
  min-height: 40px;
  max-height: 40px;
  padding: 9px 16px;

  line-height: 1;
  display: flex;
  flex-direction: row;
  align-items: center;

  transition: all 0.2s ease;

  cursor: pointer;

  &.selected {
    background-color: var(--secondary-3);
  }

  :hover {
    background-color: var(--grey-00);
  }
`;
