import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import Footer from "component/footer/Footer";
import HeaderNavigation from "component/header/HeaderNavigation";
import axios from "axios";
import DisplayType from "utils/displayType";
import Banner from "component/banner/Banner";
import { InsightMenu, NavigationMenu } from "admin/type/type";
import Title from "component/title/Title";
import LastestContents from "component/lastest-contents/insight/LastestContents";
import LastestNews from "component/lastest-news/LastestNews";
import LastestBlueletter from "component/lastest-blueletter/LastestBlueletter";
import { BannerProps } from "admin/router/banner/BannerAdmin";
import Slider from "react-slick";
import { getYMD, updateTitle } from "utils/commonUtil";
import * as api from "../../api/api";
import InsightTabs from "./components/InsightTabs";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Carousel from "./components/Carousel";

const Insight = () => {
  const mode = DisplayType();

  const sliderRef = useRef<Slider>(null);
  const [banners, updateBanners] = useState<BannerProps[]>([]);
  const sliderSettings = {
    dots: true,
    draggable: true,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const getBanner = async () => {
    try {
      const result = await axios.get(api.getBannerList());
      const timeResult = await axios.get(api.getServerTime());

      if (result.status === 200) {
        const filterData = result.data.filter((item: any) => {
          const today = new Date(getYMD(timeResult.data)).getTime();
          const startD = new Date(getYMD(item.openDate)).getTime();
          const endD = new Date(getYMD(item.closeDate)).getTime();

          return item.isActive && startD <= today && endD >= today;
        });
        updateBanners(filterData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBanner();
  }, []);

  useLayoutEffect(() => {
    updateTitle("Insight");
  }, []);

  return (
    <Container>
      {banners.length === 0 ? (
        <Banner bannerImg={""} mode={mode}>
          {mode === "Mobile" && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div className="font-title-en" style={{ color: "var(--white)" }}>
                INSIGHT
              </div>
            </div>
          )}
        </Banner>
      ) : (
        <Slider {...sliderSettings} ref={sliderRef}>
          {banners.map((item) => (
            <a
              key={item.hpBannerId}
              href={item.linkUrl}
              target="_blank"
              rel="noreferrer"
            >
              <Carousel bannerImg={item.thumbNailImgUrl} />
            </a>
          ))}
        </Slider>
      )}

      <InsightTabs selectedTab={InsightMenu.ALL} style={{ marginTop: -6 }} />
      <InsightBodyContainer className="limit__body">
        <Title
          label="Contents"
          link="/insight/contents"
          style={{ marginTop: mode === "Mobile" ? 40 : 100 }}
        >
          <LastestContents />
        </Title>
        <Title
          label="News"
          link="/insight/news"
          style={{ marginTop: mode === "Mobile" ? 100 : 100 }}
        >
          <LastestNews />
        </Title>
        <Title
          label="Blue letter"
          link="/insight/blueletter"
          style={{ marginTop: mode === "Mobile" ? 100 : 80 }}
        >
          <LastestBlueletter />
        </Title>
      </InsightBodyContainer>
      <Footer />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .slick-dots li.slick-active button:before {
    opacity: 0.75;
    color: var(--primary);
  }
  .slick-dots li {
    margin: 0;
  }
  .slick-dots {
    position: absolute;
    bottom: 24px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
  }

  @media only screen and (max-width: 768px) {
    .slick-dots {
      bottom: 12px;
    }
  }
`;
const InsightBodyContainer = styled.div`
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 80px;
`;
export default Insight;
