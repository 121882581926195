import React, { useState } from "react";
import styled from "@emotion/styled";
import { ContentProps } from "admin/router/content";
import Chip from "component/chips/Chip";
import DisplayType from "utils/displayType";
import { getYMD } from "utils/commonUtil";
import Label from "component/label/Label";

interface Props {
  item: ContentProps;
  onClickItem?: () => void;
  callback?: () => void;
}
const ContentsCard: React.FC<Props> = (props) => {
  const mode = DisplayType();
  return (
    <Container onClick={props.onClickItem}>
      <div className="thumbnail__container">
        <img
          className="thumbnail"
          src={props.item.thumbNailImgUrl}
          alt="Thumbnail"
        />

        <Label className="category">{props.item.category}</Label>
      </div>
      <div className="content__container">
        <div
          className={`content__card__title ${
            mode === "Mobile" ? "font-sub-title4" : "font-sub-title2"
          }`}
        >
          {props.item.title}
        </div>
        <p
          className={`content__card__description ${
            mode === "Mobile" ? "font-body4" : "font-body2"
          }`}
        >
          {props.item.description}
        </p>
        <span className="open__date font-body4">
          {getYMD(props.item.openDate)}
        </span>
      </div>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  min-width: calc((1176px / 3) - (48px / 3));
  max-width: calc((1176px / 3) - (48px / 3));
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border: 1px solid var(--grey-00);
  transition: all 0.3s ease;
  cursor: pointer;

  .thumbnail__container {
    position: relative;
    width: 100%;
    height: 200px;
    border-bottom: 1px solid vaR(--grey-00);
    .thumbnail {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .category {
      position: absolute;
      top: 16px;
      left: 16px;
    }
  }

  .content__container {
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 32px;

    .content__card__title {
      height: 100%;
      min-height: 65px;
      max-height: 65px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      color: var(--black);
    }
    .content__card__description {
      height: 100%;
      min-height: 75px;
      max-height: 75px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      margin-top: 24px;
      color: var(--grey-60);
    }

    .open__date {
      margin-top: 32px;
      color: var(--grey-40);
    }
  }

  :hover {
    border-color: var(--primary);
    transform: scale(1.02);
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    min-width: 100%;
    max-width: 100%;

    .content__container {
      padding: 24px;

      .content__card__title {
        height: 100%;
        min-height: 50px;
        max-height: 50px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        color: var(--black);
      }
      .content__card__description {
        height: 100%;
        min-height: 60px;
        max-height: 60px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        margin-top: 10px;
        color: var(--grey-60);
      }

      .open__date {
        margin-top: 16px;
        color: var(--grey-40);
      }
    }
  }
`;

export default ContentsCard;
