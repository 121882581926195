import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import axios from "axios";
import ModalLayout from "admin/component/modal/ModalLayout";
import ConfirmModal from "admin/component/modal/ConfirmModal";
import { ReactComponent as Eye } from "../../../../assets/eye.svg";
import { ReactComponent as Trash } from "../../../../assets/trash_can.svg";
import Toggle from "../../../component/toggle";
import { ContentProps } from "..";
import * as api from "../../../../api/api";

interface Props {
  item: ContentProps;
  onClickItem?: () => void;
  callback?: () => void;
}
const ContentCard: React.FC<Props> = (props) => {
  const [deleteConfirmOpen, updateDeleteConfirmOpen] = useState<boolean>(false);

  const onClickToggle = (changeSate: boolean) => {};

  const deleteContent = async () => {
    try {
      if (props.item.hpContentId === undefined) return;

      const result = await axios.delete(
        api.deleteContent(props.item.hpContentId)
      );
      if (result.status === 200 && props.callback) {
        updateDeleteConfirmOpen(false);
        props.callback();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container onClick={props.onClickItem}>
      <div className="thumbnail__container">
        <img
          className="thumbnail"
          src={props.item.thumbNailImgUrl}
          alt="Thumbnail"
          onLoad={(e) => {
            // console.log(e);
          }}
        />
      </div>
      <div className="content__container">
        <div className="content__wrapper">
          <span className="font-bold-24">{props.item.title}</span>
          <span className="font-bold-12">{props.item.openDate}</span>
          <p className="txt_post font-regular-14">{props.item.description}</p>
        </div>
        <div className="btn__wrapper">
          <div className="group" />
          <div className="group">
            <div
              className="group"
              onClick={(e) => {
                e.stopPropagation();
                updateDeleteConfirmOpen(true);
              }}
              onKeyDown={(e) => {}}
            >
              <Trash className="icon" fill="red" />
              <span>삭제</span>
            </div>
            <Toggle isEditable onChange={onClickToggle} />
          </div>
        </div>
      </div>
      <ModalLayout isOpen={deleteConfirmOpen}>
        <ConfirmModal
          type="confirm"
          message="선택하신 컨텐츠를 삭제 하시겠습니까?"
          onClickNegativeBtn={() => updateDeleteConfirmOpen(false)}
          onClickPositiveBtn={deleteContent}
        />
      </ModalLayout>
    </Container>
  );
};

const Container = styled.li`
  width: 100%;
  max-width: 1023px;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid grey;
  cursor: pointer;

  .thumbnail__container {
    position: relative;
    width: 350px;
    min-width: 350px;
    max-width: 350px;
    aspect-ratio: 3 / 2;

    .thumbnail {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
    }
  }

  .content__container {
    position: relative;
    width: calc(100% - 350px);
    max-height: 300px;
    display: flex;
    flex-direction: column;
    border-left: 1px solid grey;
    .content__wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 16px;
      .txt_post {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5; /* 라인수 */
        -webkit-box-orient: vertical;
        word-wrap: break-word;
        line-height: 1.2em;
        height: calc(
          1.2em * 5
        ); /* line-height 가 1.2em 이고 3라인을 자르기 때문에 height는 1.2em * 3 = 3.6em */
      }
    }

    .btn__wrapper {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 60px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      background-color: white;
      gap: 16px;
      border-top: 1px solid grey;
      padding: 0 16px;
      .group {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
      }
      .icon {
        width: 20px;
        min-width: 20px;
        max-width: 20px;

        height: 20px;
        min-height: 20px;
        max-height: 20px;
      }
    }
  }
`;

const OverSizeLabel = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 24px;
  right: 24px;
  color: var(--white);
  background-color: var(--red);
  transform: translate(20%, -40%);
  width: fit-content;
  height: fit-content;
  padding: 6px 10px 5px 10px;
  border-radius: 20px;
`;

export default ContentCard;
