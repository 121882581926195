import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import axios from "axios";
import { NotionInput } from "admin/component/input/NotionInput";
import DefaultButton from "admin/component/button/DefaultButton";
import UploadImg from "admin/component/upload-img/UploadImg";
import { imgCategory, irArchiveType, IRDataProps } from "admin/type/type";
import { getYMD } from "utils/commonUtil";
import * as api from "../../../../api/api";
import BlueLetterCard from "./IRCard";
import ButtonSelector from "./ButtonSelector";

import { ReactComponent as RadioOn } from "../../../../assets/radio_on.svg";
import { ReactComponent as RadioOff } from "../../../../assets/radio_off.svg";
import { IRDataAdmin } from "../IRArchive";

interface Props {
  totalArchiveData: IRDataAdmin[];
  close?: () => void;
  callback?: () => void;
}

const IRArchiveDetail: React.FC<Props> = (props) => {
  const [selectedType, updateSelectedType] = useState<
    { title: string; type: string } | undefined
  >(undefined);
  const [totalYear, updateTotalYear] = useState<string[]>([]);
  const [year, updateYear] = useState<string>("");
  const [totalQuarter, updateTotalQuarter] = useState<string[]>([]);
  const [quarter, updateQuarter] = useState<string>("");
  const [file, updateFile] = useState<any | undefined>(undefined);

  const isFileCreating = useRef<boolean>(false);

  const validationChecking = (): boolean => {
    if (selectedType?.type === "earningRelease") {
      const archiveData = props.totalArchiveData.find(
        (item) => item.type.type === "earningRelease"
      );
      const existData = archiveData?.data.find(
        (item) => `${item.year}` === year && `${item.quarterNum}` === quarter
      );
      if (existData !== undefined)
        alert(`"${existData.title}" 파일이 이미 존재 합니다.`);
      return existData === undefined;
    }
    if (selectedType?.type === "auditReport") {
      const archiveData = props.totalArchiveData.find(
        (item) => item.type.type === "auditReport"
      );
      const existData = archiveData?.data.find(
        (item) => `${item.year}` === year && `${item.quarterNum}` === quarter
      );
      if (existData !== undefined)
        alert(`"${existData.title}" 파일이 이미 존재 합니다.`);
      return existData === undefined;
    }
    if (selectedType?.type === "businessReport") {
      const archiveData = props.totalArchiveData.find(
        (item) => item.type.type === "businessReport"
      );
      const existData = archiveData?.data.find(
        (item) => `${item.year}` === year
      );
      if (existData !== undefined)
        alert(`"${existData.title}" 파일이 이미 존재 합니다.`);
      return existData === undefined;
    }
    if (selectedType?.type === "annualReport") {
      const archiveData = props.totalArchiveData.find(
        (item) => item.type.type === "annualReport"
      );
      const existData = archiveData?.data.find(
        (item) => `${item.year}` === year
      );
      if (existData !== undefined)
        alert(`"${existData.title}" 파일이 이미 존재 합니다.`);
      return existData === undefined;
    }

    return false;
  };

  const createIrArchive = async () => {
    if (isFileCreating.current) return;
    if (!selectedType) return alert("자료 유형을 선택해주세요.");
    if (!file) return alert("파일을 선택해주세요.");

    if (!validationChecking()) return;

    try {
      isFileCreating.current = true;
      const formData = new FormData();
      let title = "";
      if (year.length > 0) {
        title += `${year}년 `;
      }
      if (
        (selectedType.type === "earningRelease" ||
          selectedType.type === "auditReport") &&
        quarter.length > 0
      ) {
        title += `${quarter}분기 `;
      }
      title += selectedType.title;

      formData.append("file", file);
      formData.append("type", selectedType.type);
      formData.append("year", year);
      formData.append("quarterNum", quarter);
      formData.append("title", title);

      const result = await axios.post(api.createIRArchives(), formData);
      if (result.status === 201 && props.callback) {
        props.callback();
      }
      isFileCreating.current = false;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const selectedYear = parseInt(year, 10);

    console.log(currentYear);
    console.log(selectedYear);

    if (!currentYear) return;
    if (!selectedYear) return;

    if (currentYear > selectedYear) {
      updateTotalQuarter(["4", "3", "2", "1"]);
    } else {
      const quarter = Math.ceil((new Date().getMonth() + 1) / 3);

      const _totalQuarter: string[] = [];
      for (let i = quarter; i > 0; i--) {
        _totalQuarter.push(`${i}`);
      }
      updateTotalQuarter(_totalQuarter);
      updateQuarter(_totalQuarter[0]);
    }
  }, [year]);

  useLayoutEffect(() => {
    const currentYear = new Date().getFullYear();
    const startYear = 2014;
    const _totalYear: string[] = [];
    for (let i = currentYear; i >= startYear; i--) {
      _totalYear.push(`${i}`);
    }
    updateTotalYear(_totalYear);
    updateYear(_totalYear[0]);
  }, []);

  return (
    <Container className="scroll__invisible">
      <div className="ir__archive__detail__header font-bold-18">
        <span>IR자료</span>
        <DefaultButton
          onClick={() => {
            createIrArchive();
          }}
        >
          생성하기
        </DefaultButton>
      </div>
      <div className="ir__archive__detail__body">
        <div className="ir__archive__detail__body__row">
          <IRTypeContainer>
            <div className="font-bold-14">
              <span>{"자료유형"}</span>
            </div>

            <div className="ir__type__items">
              {irArchiveType.map((item: any, index: number) => {
                return (
                  <div
                    key={index}
                    className={`${
                      item.type === selectedType?.type
                        ? "font-bold-14"
                        : "font-regular-14"
                    } ir__type__item`}
                    onClick={() => updateSelectedType(item)}
                    style={{
                      color:
                        item.type === selectedType?.type
                          ? "var(--black)"
                          : "var(--grey-40)",
                    }}
                  >
                    {item.type === selectedType?.type ? (
                      <RadioOn />
                    ) : (
                      <RadioOff />
                    )}
                    {item.title}
                  </div>
                );
              })}
            </div>
          </IRTypeContainer>
        </div>
        {selectedType && (
          <div className="ir__archive__detail__body__row">
            <IRDateContainer>
              <ButtonSelector
                label="연도"
                items={totalYear}
                selectedItem={year}
                onClickItem={(item) => {
                  updateYear(item);
                }}
              />
              {(selectedType.type === "earningRelease" ||
                selectedType.type === "auditReport") && (
                <ButtonSelector
                  label="쿼터"
                  items={totalQuarter}
                  selectedItem={quarter}
                  onClickItem={(item) => {
                    updateQuarter(item);
                  }}
                />
              )}
            </IRDateContainer>
          </div>
        )}
        {selectedType && (
          <div
            className="ir__archive__detail__body__row"
            style={{ marginTop: 60 }}
          >
            <input
              type={"file"}
              style={{ width: "100%" }}
              accept=".pdf"
              onChange={(e) => {
                if (e.target.files && e.target.files?.length > 0) {
                  updateFile(e.target.files[0]);
                }
              }}
            />
          </div>
        )}
      </div>
    </Container>
  );
};

const Container = styled.div`
  width: 100vw;
  max-width: 780px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  .ir__archive__detail__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px 24px;

    border-bottom: 1px solid var(--grey);
  }
  .ir__archive__detail__body {
    width: 100%;

    display: flex;
    flex-direction: column;
    padding: 24px;
    gap: 24px;

    padding-bottom: 80px;

    .ir__archive__detail__body__row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 24px;
    }
  }
`;

const IRTypeContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  .ir__type__title {
    width: 100px;
  }

  .ir__type__items {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;

    .ir__type__item {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 4px;
      cursor: pointer;
    }
  }
`;

const IRDateContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 16px;
`;
export default IRArchiveDetail;
