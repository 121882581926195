import styled from "@emotion/styled";
import axios from "axios";
import Chip from "component/chips/Chip";
import React, { useEffect, useState } from "react";
import DisplayType from "utils/displayType";
import { IndustryTech, PortfolioProps } from "admin/type/type";
import { ReactComponent as FoldDown } from "../../../assets/fold_down.svg";
import * as api from "../../../api/api";

interface Props {
  allPortfolio: PortfolioProps[];
  onChange?: (selectedSector: IndustryTech[]) => void;
}
const SectorList: React.FC<Props> = (props) => {
  const mode = DisplayType();
  const [isAllSelect, updateAllSelect] = useState<boolean>(true);
  const [allSector, setAllSector] = useState<IndustryTech[]>([]);
  const [selectedSector, updateSelectedSector] = useState<IndustryTech[]>([]);
  const [isFold, updateFold] = useState<boolean>(true);

  const onClickAllSelect = () => {
    if (isAllSelect) {
      updateSelectedSector([]);
      if (props.onChange) props.onChange([]);
    } else {
      updateSelectedSector([...allSector]);
      if (props.onChange) props.onChange([...allSector]);
    }
    updateAllSelect(!isAllSelect);
  };

  const onSelectSector = (item: IndustryTech) => {
    if (isAllSelect) {
      updateSelectedSector([item]);
      updateAllSelect(false);
      if (props.onChange) props.onChange([item]);
    } else {
      const index = selectedSector.findIndex(
        (sector) => sector.acIndustrialTechId === item.acIndustrialTechId
      );
      if (index !== -1) {
        selectedSector.splice(index, 1);
        updateSelectedSector([...selectedSector]);
        updateAllSelect(selectedSector.length === allSector.length);
        if (props.onChange) props.onChange([...selectedSector]);
      } else {
        const newSector = [...selectedSector, item];
        updateSelectedSector(newSector);
        updateAllSelect(newSector.length === allSector.length);
        if (props.onChange) props.onChange(newSector);
      }
    }
  };

  const getAllSector = async () => {
    try {
      const portfolioResult = await axios.get(api.getHomepagePortfolioList());
      const result = await axios.get(api.getHompepageIndustrialTech());
      if (result.status === 200 && portfolioResult.status === 200) {
        const sectors = result.data.filter((item: any) => item.isActive);
        const existSector = sectors.filter((item: any) => {
          return (
            portfolioResult.data.filter((portfolio: any) => {
              return portfolio.hpPfIndustrialTech
                .map((data: any) => data.hpIndustrialTech.acIndustrialTechId)
                .includes(item.acIndustrialTechId);
            }).length > 0
          );
        });

        setAllSector([...existSector]);
        updateSelectedSector([...existSector]);
        updateAllSelect(true);
        if (props.onChange) props.onChange([...existSector]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateIndustrialTech = async () => {
    try {
      axios.put(api.updateIndustrialTech()).then((result) => {
        if (result.status === 200) {
          getAllSector();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const existSector = allSector.filter((item) => {
      return (
        props.allPortfolio.filter((portfolio) => {
          console.log(portfolio);
          return portfolio.hpPfIndustrialTech
            .map((data) => data.hpIndustrialTech.acIndustrialTechId)
            .includes(item.acIndustrialTechId);
        }).length > 0
      );
    });

    setAllSector(existSector);
    updateSelectedSector(existSector);
  }, [props.allPortfolio.length]);

  useEffect(() => {
    updateIndustrialTech();
  }, []);

  return (
    <Container isFold={isFold}>
      <span className="sector__list__label font-sub-title4">SECTOR</span>
      <div className="sector__list">
        <Chip isClickable isActive={isAllSelect} onClickChip={onClickAllSelect}>
          All
        </Chip>
        {allSector.map((item) => {
          return (
            <Chip
              key={item.acIndustrialTechId}
              isClickable
              isActive={selectedSector
                .map((item) => item.acIndustrialTechId)
                .includes(item.acIndustrialTechId)}
              onClickChip={() => {
                onSelectSector(item);
              }}
            >
              {item.name}
            </Chip>
          );
        })}
      </div>
      {mode === "Mobile" && (
        <div
          className="fold__controler__container"
          onClick={() => updateFold((prevState) => !prevState)}
        >
          <span className="font-body4">{isFold ? "펼치기" : "접기"}</span>
          <FoldDown className={`fold__icon ${isFold ? "" : "unfold"}`} />
        </div>
      )}
    </Container>
  );
};

const Container = styled.div<{ isFold: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: row;

  .sector__list__label {
    min-width: 104px;
    max-width: 104px;
    color: var(--black);
  }

  .sector__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
  }

  .fold__controler__container {
    width: calc(100% + 48px);
    height: 40px;
    display: flex;
    transform: translateX(-24px);
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: var(--grey-00);
    color: var(--grey-40);
    padding-top: 1px;
    gap: 4px;
    .fold__icon {
      width: 16px;
      height: 16px;
      transform: rotate(0);
      transition: all 0.2s ease;
    }
    .fold__icon.unfold {
      transform: rotate(180deg);
    }
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    .sector__list__label {
      min-width: 100%;
      max-width: 100%;
    }
    .sector__list {
      padding-top: 20px;
      padding-bottom: 20px;
      flex-wrap: ${(props) => (props.isFold ? "nowrap" : "wrap")};
      overflow: scroll;
      ::-webkit-scrollbar {
        display: none;
      }
    }
  }
`;

export default SectorList;
