import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import axios from "axios";
import ModalLayout from "admin/component/modal/ModalLayout";
import ConfirmModal from "admin/component/modal/ConfirmModal";
import { getYMD } from "utils/commonUtil";
import { ReactComponent as Trash } from "../../../../assets/trash_can.svg";
import Toggle from "../../../component/toggle";
import { EventCardProps, EventProps } from "..";
import * as api from "../../../../api/api";

interface Props {
  isViewer?: boolean;
  item: EventProps;
  today?: Date;
  onClickItem?: () => void;
  callback?: () => void;
}

const EventCard: React.FC<Props> = (props) => {
  const [eventItem, updateEventItem] = useState<EventProps>(props.item);
  const [deleteConfirmOpen, updateDeleteConfirmOpen] = useState<boolean>(false);
  const onClickContent = () => {
    if (props.onClickItem) {
      props.onClickItem();
    }
  };
  const getLabel = () => {
    if (!props.today) {
      return null;
    }
    const today = props.today.getTime();
    const openDate = new Date(eventItem.openDate).getTime();
    const eventDate = new Date(eventItem.eventDate).getTime();

    if (!eventItem.isActive) {
      return (
        <span
          className="font-button3-en event__label"
          style={{ backgroundColor: "rgba(0,0,0,0.7)" }}
        >
          Disabled
        </span>
      );
    }

    if (today < openDate) {
      return (
        <span
          className="font-button3-en event__label"
          style={{ backgroundColor: "var(--grey-30)" }}
        >
          Soon
        </span>
      );
    }

    if (today >= openDate && today <= eventDate) {
      return (
        <span
          className="font-button3-en event__label"
          style={{ backgroundColor: "var(--primary)" }}
        >
          Open
        </span>
      );
    }

    if (today >= eventDate) {
      return (
        <span
          className="font-button3-en event__label"
          style={{ backgroundColor: "var(--grey-10)" }}
        >
          Closed
        </span>
      );
    }

    return null;
  };

  const onClickToggle = (changeSate: boolean) => {};
  const deleteEvent = async () => {
    try {
      if (props.isViewer || !props.item.hpEventId) return;

      const result = await axios.delete(api.deleteEvent(props.item.hpEventId));
      if (result.status === 200 && props.callback) {
        updateDeleteConfirmOpen(false);
        props.callback();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    updateEventItem(props.item);
  }, [props.item]);

  if (!eventItem) return null;

  return (
    <Container onClick={onClickContent}>
      <div
        className="thumbnail__container"
        style={{ backgroundImage: `url(${eventItem.thumbNailImgUrl})` }}
      >
        {getLabel()}
      </div>
      <div className="event__content__container">
        <span className="event__title font-sub-title4">{eventItem.title}</span>
        {eventItem.eventDate && (
          <div className="event__row">
            <span className="event__row__title font-body3">일시</span>
            <span className="event__subtitle font-body3">
              {getYMD(eventItem.eventDate)}
            </span>
          </div>
        )}
        {eventItem.eventStartDate && eventItem.eventEndDate && (
          <div className="event__row">
            <span className="event__row__title font-body3">신청일</span>
            <span className="event__subtitle font-body3">
              {getYMD(eventItem.eventStartDate)} -{" "}
              {getYMD(eventItem.eventEndDate)}
            </span>
          </div>
        )}
        {eventItem.place && (
          <div className="event__row">
            <span className="event__row__title font-body3">장소</span>
            <span className="event__subtitle font-body3">
              {eventItem.place}
            </span>
          </div>
        )}
      </div>
      {!props.isViewer && (
        <MenuContainer className="menu__controller">
          <Trash
            className="icon"
            onClick={(e) => {
              e.stopPropagation();
              updateDeleteConfirmOpen(true);
            }}
          />
          <Toggle isEditable />
        </MenuContainer>
      )}
      {!props.isViewer && (
        <ModalLayout isOpen={deleteConfirmOpen}>
          <ConfirmModal
            type="confirm"
            message="선택하신 이벤트를 삭제 하시겠습니까?"
            onClickNegativeBtn={() => updateDeleteConfirmOpen(false)}
            onClickPositiveBtn={deleteEvent}
          />
        </ModalLayout>
      )}
    </Container>
  );
};

const Container = styled.li`
  width: 100%;
  max-width: calc((1176px / 3) - (48px / 3));

  position: relative;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  gap: 19px;

  .thumbnail__container {
    position: relative;
    width: 100%;
    max-width: 100%;
    aspect-ratio: 3 / 4;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .event__label {
      position: absolute;
      width: fit-content;
      height: fit-content;
      padding: 3px 16px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      top: 16px;
      left: 16px;
      color: white;
    }
  }

  .event__content__container {
    width: 100%;
    display: flex;
    flex-direction: column;

    .event__title {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-bottom: 8px;
    }
    .event__row {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      .event__row__title {
        width: 56px;
      }
    }
    .event__subtitle {
      flex: 1;
      color: grey;
    }
  }
  :hover {
    .menu__controller {
      display: flex;
    }
  }
`;

const MenuContainer = styled.div`
  position: absolute;
  min-width: 36px;
  height: 36px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  gap: 8px;
  padding: 0 8px;
  top: 0;
  right: 0;
  transform: translate(20%, -50%);
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid grey;

  .icon {
    width: 20px;
    min-width: 20px;

    height: 20px;
    min-height: 20px;
    max-height: 20px;
    svg {
      fill: white;
    }
  }
`;
export default React.memo(EventCard);
