import styled from "@emotion/styled";

interface Props {
  theme: string;
  maxWidth?: number;
}

export const Logo = styled.button`
  border: none;
  background-color: transparent;
`;

export const HeaderNavigationContainer = styled.div<{
  isFillHeader?: boolean;
  isScrollTop: boolean;
  isOpenMenu?: boolean;
  hasUnderLine?: boolean;
}>`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    !props.isFillHeader && props.isScrollTop ? "transparent" : "var(--white)"};
  border-bottom: 1px solid
    ${(props) =>
      !props.isFillHeader && props.isScrollTop
        ? "transparent"
        : "var(--grey-00)"};
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 10;

  .header__navigation__container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .hide {
    height: 0;
    transition: height 0.3s ease;
    overflow: hidden;
  }
  .show {
    height: 500px;
    transition: height 0.3s ease;
  }

  .main__navigation__container {
    position: relative;
    width: 100%;
    max-width: 1136px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
    padding: 0 20px;
    min-height: 60px;
    height: fit-content;
    background-color: ${(props) => {
      if (props.isOpenMenu) return "var(--white)";
      if (props.isFillHeader) return "var(--white)";
      if (props.isScrollTop) return "transparent";
      return "var(--white)";
    }};
  }
`;

export const HeaderSubNavigationContainer = styled.ul<{
  isScrollTop: boolean;
  isShow: boolean;
}>`
  width: 100%;
  padding-left: 0;
  display: ${(props) => (props.isShow ? "flex" : "none")};
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    props.isScrollTop ? "transparent" : "var(--bg-03)"};
  .header__sub__navigation__container {
    position: relative;
    width: 100%;
    max-width: 1136px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

export const SubNavigationContainer = styled.ul`
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 64px;
  margin: 0;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const SubNavigationItem = styled.li`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: var(--white);
  @media only screen and (max-width: var(--mobile)) {
    color: black;
  }
`;
