import styled from "@emotion/styled";
import React, { useRef } from "react";
import DisplayType from "utils/displayType";

interface Props {
  bannerImg?: string;
  bannerImg1920?: string;
  bannerImg2048?: string;
  aspectRatio?: string;
  style?: React.CSSProperties;
  mode: string;
  withOutOverley?: boolean;
  imgPostiion?: "center" | "left" | "right";
}

interface StyledProps {
  bannerImg?: string;
  mode: string;
  widthRatio: number;
  heightRatio: number;
  imgPostiion?: "center" | "left" | "right";
}
const Banner: React.FC<Props> = (props) => {
  const {
    bannerImg = "",
    aspectRatio = "1440/480",
    mode,
    style = {},
    imgPostiion = "center",
    withOutOverley = false,
  } = props;
  const width = useRef<number>(parseInt(aspectRatio.split("/")[0], 10));
  const hieght = useRef<number>(parseInt(aspectRatio.split("/")[1], 10));

  return (
    <BannerContainer
      style={style}
      widthRatio={width.current}
      heightRatio={hieght.current}
      mode={mode}
      imgPostiion={imgPostiion}
    >
      {!withOutOverley && <Overley />}
      <div className="limit__body banner__content__wrapper">
        <div className="banner__content">{props.children}</div>
      </div>
      {mode !== "Mobile" &&
        (props.bannerImg || props.bannerImg1920 || props.bannerImg2048) && (
          <picture
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              left: 0,
              top: 0,
            }}
          >
            {props.bannerImg1920 && (
              <source
                className="banner__img"
                srcSet={props.bannerImg}
                media="all and (max-width: 1440px)"
              />
            )}
            {props.bannerImg2048 && (
              <source
                className="banner__img"
                srcSet={props.bannerImg1920}
                media="all and (max-width: 1920px)"
              />
            )}
            <img
              className="banner__img"
              src={props.bannerImg2048}
              alt="banner img"
            />
          </picture>
        )}
    </BannerContainer>
  );
};

const BannerContainer = styled.div<StyledProps>`
  position: relative;
  width: 100%;
  height: 480px;
  /* height: ${(props) =>
    `calc(100vw * (${props.heightRatio} / ${props.widthRatio}))`}; */
  background: url(${(prpos) => prpos.bannerImg});
  background-size: cover;
  background-position: center;
  background-color: var(--grey-00);

  .banner__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: ${(props) => props.imgPostiion};
  }

  .banner__content__wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .banner__content {
    display: flex;
    flex-direction: column;
    align-items: ${(props) =>
      props.mode === "Mobile" ? "center" : "flex-start"};
    z-index: 2;
  }

  @media only screen and (max-width: 768px) {
    background: var(--primary);
    width: 100%;
    height: 344px;
    aspect-ratio: none;

    .banner__content__wrapper {
      justify-content: center;
    }

    .banner__content {
      padding: 0 24px;
    }
  }
`;
const Overley = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export default Banner;
