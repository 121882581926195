import React from "react";
import styled from "@emotion/styled";
import DisplayType from "utils/displayType";
import { propTypes } from "react-bootstrap/esm/Image";

interface Props {
  className?: string;
  isClickable?: boolean;
  isActive?: boolean;
  disabled?: boolean;
  chipType?: "main-color" | "green-color";
  children: string;
  onClickChip?: () => void;
}
const Chip: React.FC<Props> = (props) => {
  const mode = DisplayType();
  const { chipType = "main-color" } = props;
  return (
    <Container
      className={`${props.className || ""} ${
        mode === "Mobile" ? "font-button3" : "font-button1"
      }`}
      isActive={props.isActive}
      disabled={props.disabled}
      isClickable={props.isClickable}
      chipType={chipType}
      isMobile={mode === "Mobile"}
      onClick={() => {
        if (props.isClickable && props.onClickChip) props.onClickChip();
      }}
    >
      {props.children}
    </Container>
  );
};

const Container = styled.div<{
  isActive?: boolean;
  disabled?: boolean;
  isClickable?: boolean;
  chipType?: string;
  isMobile: boolean;
}>`
  padding: 5px 16px 4px 16px;
  width: fit-content;
  height: fit-content;

  white-space: nowrap;
  cursor: ${(props) => (props.isClickable ? "pointer" : "default")};
  background-color: ${(props) => {
    if (props.disabled) {
      return "var(--grey-10)";
    }
    if (!props.isClickable) {
      return "var(--bg-03)";
    }
    if (props.isActive) {
      return props.chipType === "main-color"
        ? "var(--primary)"
        : "var(--green)";
    }
    return "var(--bg-03)";
  }};
  transition: all 0.3s ease;
  color: ${(props) => {
    if (props.disabled || props.isActive) {
      return "var(--white)";
    }
    return "var(--grey-30)";
  }};
  ${(props) =>
    props.isClickable &&
    !props.isMobile &&
    `:hover {
      opacity: ${props.isActive ? "1" : "0.4"};
      background-color: ${
        props.isActive && props.chipType === "main-color"
          ? "var(--primary)"
          : props.isActive && props.chipType !== "main-color"
          ? "var(--green)"
          : props.chipType === "main-color"
          ? "var(--blue-01)"
          : "var(--green-01)"
      };
      color: var(--white);
    }`}
`;

export default React.memo(Chip);
