import styled from "@emotion/styled";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  gap: 8px;
  .require__wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 48px;
    height: 24px;
    background: var(--bg-grey);
    border: 1px solid var(--bg-grey);
    padding: 0 3px;
    border-radius: 16px;
    cursor: pointer;
    transition: 0.2s;
  }
  .active {
    background: var(--primary);
  }
  .require__thumb {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: var(--white);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    transition: 0.2s;
  }
  .require {
    transform: translateX(125%);
  }
`;
