import React, { useRef } from "react";
import styled from "@emotion/styled";

interface Props {
  className?: string;
  type?: "submit" | "danger" | "outline" | "text" | "excel" | "fill-button";
  width?: number;
  height?: number;
  disabled?: boolean;
  isHover?: boolean;
  onClick?: () => void;
}
const DefaultButton: React.FC<Props> = (props) => {
  const btnRef = useRef<HTMLButtonElement>(null);
  return (
    <Container
      ref={btnRef}
      // className={`${props.className ? props.className : "font-medium-14"} ${
      //   props.type || "submit"
      // }`}
      className={`${props.className ? props.className : "font-sub-title2"} ${
        props.type || "submit"
      }`}
      disabled={props.disabled || false}
      isHover={props.isHover}
      onClick={(e) => {
        e.stopPropagation();
        if (props.onClick) props.onClick();
      }}
      style={{
        height: props.height || 42,
        width: props.width || "fit-content",
      }}
    >
      {props.children}
    </Container>
  );
};

const Container = styled.button<{ disabled: boolean; isHover?: boolean }>`
  min-width: 120px;
  padding: 0 16px;
  box-sizing: border-box;
  border: 0;

  cursor: pointer;

  &.fill-button {
    ${(props) =>
      props.disabled
        ? `
      background-color: var(--white);
      color: var(--grey-20);
      border: 1px solid var(--grey-20);
    `
        : `
      background-color: var(--white);
      color: var(--primary);
      border: 1px solid var(--white);
      ${
        props.isHover &&
        `
      :hover { 
        background-color: var(--primary);
        color: var(--white);
      }
      `
      }
    `}
  }

  &.submit {
    ${(props) =>
      props.disabled
        ? `
      background-color: var(--grey-20);
      color: var(--white);
    `
        : `
      background-color: var(--primary);
      color: var(--white);
    `}
  }

  &.danger {
    ${(props) =>
      props.disabled
        ? `
      background-color: var(--white);
      color: var(--grey-20);
      border: 1px solid var(--grey-20);
    `
        : `
      background-color: var(--white);
      color: var(--red);
      border: 1px solid var(--red);
    `}
  }

  &.outline {
    ${(props) =>
      props.disabled
        ? `
      background-color: var(--white);
      color: var(--grey-20);
      border: 1px solid var(--grey-20);
    `
        : `
      background-color: var(--white);
      color: var(--primary);
      border: 1px solid var(--primary);
      ${
        props.isHover &&
        `
      :hover { 
        background-color: var(--primary);
        color: var(--white);
      }
      `
      }
    `}
  }

  &.text {
    ${(props) =>
      props.disabled
        ? `
      background-color: transparent;
      color: var(--grey-20);
    `
        : `
      background-color: transparent;
      color: var(--grey-50);
    `}
  }

  &.excel {
    ${(props) =>
      props.disabled
        ? `
      background-color: var(--white);
      color: var(--grey-20);
      border: 1px solid var(--grey-20);
    `
        : `
      background-color: var(--white);
      color: var(--black);
      border: 1px solid #107c41;
    `}
  }
`;
export default DefaultButton;
