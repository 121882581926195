import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";

import axios from "axios";
import { imgCategory } from "admin/type/type";
import * as api from "../../../api/api";
import { ReactComponent as Upload } from "../../../assets/upload.svg";

interface Props {
  width?: number;
  height?: number;
  editable: boolean;
  isError?: boolean;
  errorMessage?: string;
  defaultValue?: any;
  onChangeFile?: (file: any | undefined) => void;
}
const ContactFileUpload: React.FC<Props> = (props) => {
  const [hoverShow, setHoverShow] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(true);
  const reRender = () => setReload((prevState) => !prevState);
  const [file, updateFile] = useState<any>(undefined);
  const inputRef = useRef<HTMLInputElement>(null);

  const resetFile = () => {
    if (!props.editable) return;

    if (inputRef.current) inputRef.current.value = "";
    if (props.onChangeFile) props.onChangeFile(undefined);
  };

  const onChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      if (!e.target.files || e.target.files.length === 0) return;

      updateFile(e.target.files[0]);
      if (props.onChangeFile) props.onChangeFile(e.target.files[0]);
      e.target.value = "";
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    updateFile(props.defaultValue);
  }, [props.defaultValue]);

  return (
    <Container width={props.width || 409} height={props.height || 48}>
      <div
        className={`file__title__container ${props.isError && "input_error"}`}
      >
        {file === undefined ? (
          <span className="font-body3" style={{ color: "var(--grey-30)" }}>
            파일을 업로드해주세요.
          </span>
        ) : (
          <span className="font-regular-16" style={{ color: "var(--grey-60)" }}>
            {file.name}
          </span>
        )}
      </div>
      <div
        className="file__upload__btn font-button3"
        onClick={() => {
          if (inputRef.current) {
            inputRef.current.click();
          }
        }}
      >
        파일 업로드 <Upload />
      </div>
      <input ref={inputRef} type="file" accept=".pdf" onChange={onChangeFile} />
      {props.isError && (
        <div className={`error__msg font-body4`}>
          {props.errorMessage || "파일을 선택해주세요."}
        </div>
      )}
    </Container>
  );
};

const Container = styled.div<{ width: number; height: number }>`
  position: relative;
  width: ${(props) => props.width}px;
  min-width: ${(props) => props.width}px;
  max-width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  min-height: ${(props) => props.height}px;
  max-height: ${(props) => props.height}px;

  display: flex;
  flex-direction: row;
  border-radius: 4px;
  .file__title__container {
    flex-grow: 1;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 16px;
    padding-right: 16px;
    background-color: var(--bg-03);
    border-top: 1px solid var(--grey-00);
    border-left: 1px solid var(--grey-00);
    border-bottom: 1px solid var(--grey-00);
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .file__upload__btn {
    width: 129px;
    min-width: 129px;
    max-width: 129px;
    height: 100%;
    gap: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 0;
    user-select: none;
    background-color: var(--primary);
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    color: var(--white);
    cursor: pointer;
  }

  .input_error {
    border: 1px solid #e02020;
    background-color: #fdeded;
    animation: shake 300ms;
    :focus {
      outline: none;
    }
  }

  .error__msg {
    position: absolute;
    left: 10px;
    bottom: -24px;
    color: #e02020;
    :focus {
      outline: none;
    }
  }

  input {
    display: none;
  }
  .remove {
    position: absolute;
    display: flex;
    width: 16px;
    height: 16px;
    top: 10px;
    right: 10px;

    cursor: pointer;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }
`;
export default ContactFileUpload;
