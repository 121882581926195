import React, { useEffect, useRef, useState } from "react";
import Styled from "@emotion/styled";

import { UserInfo } from "admin/type/type";
import { Tree, NodeModel } from "@minoru/react-dnd-treeview";
import { StructureNode } from "./StructureNode";
import { StructureDragPreview } from "./StructureDragPreview";
import { StructurePlaceholder } from "./StructurePlaceholder";
import { ReactComponent as Plus } from "../../../../assets/plus_structure.svg";

import { DataType } from "..";

interface Props {
  data: NodeModel<DataType>[];
  selectedStruct: NodeModel<DataType> | undefined;
  reloading: () => void;
  onAddStructure: () => void;
  onChangeStructure: () => void;
  deleteStructure: (data: NodeModel<DataType>) => void;
  setData: React.Dispatch<React.SetStateAction<NodeModel<DataType>[]>>;
  setSelectedStruct: React.Dispatch<
    React.SetStateAction<NodeModel<DataType> | undefined>
  >;
  deleteStructureId: number;
  allHpUser: UserInfo[];
}
const Structure: React.FC<Props> = (props) => {
  const treeRef = useRef<any>();
  const containerRef = useRef<HTMLDivElement>(null);
  const [currentEditNodeId, updateCurrentEditNodeid] = useState<
    NodeModel["id"] | undefined
  >(undefined);

  const [showDeleteStructure, setShowDeleteStructure] =
    useState<boolean>(false);

  const handleSelect = (node: NodeModel<DataType>) => {
    props.setSelectedStruct(node);
  };

  const handelOpen = (id: NodeModel["id"]) => treeRef.current.open(id);

  const handleNameChange = (id: NodeModel["id"], value: string) => {
    const newTree = props.data.map((node): NodeModel<DataType> => {
      if (node.id === id) {
        if (node.data && node.data.structInfo) {
          const newData: NodeModel<DataType> = {
            id: node.id,
            parent: node.parent,
            text: value,
            droppable: node.droppable,
            data: {
              isStruct: node.data.isStruct,
              name: value,
              structInfo: {
                isNew: node.data.structInfo.isNew,
                isChange: !node.data.structInfo.isNew,
                isDelete: node.data.structInfo.isDelete,
                hpStructureId: node.data.structInfo.hpStructureId,
                introduction: node.data.structInfo.introduction,
                name: value,
                parent: node.data.structInfo.parent,
              },
            },
          };
          return newData;
        }
      }
      return node;
    });
    props.setData(newTree);
    props.onChangeStructure();
    updateCurrentEditNodeid(undefined);
  };
  const onEditStructure = (id: NodeModel["id"]) => {
    updateCurrentEditNodeid(id);
  };

  const onEditCancelStructure = (id: NodeModel["id"]) => {
    updateCurrentEditNodeid(undefined);
  };

  const existSameValue = (value: string): boolean => {
    const index = props.data.findIndex((node) => {
      return node.text === value;
    });
    return index !== -1;
  };
  const getDetph = (
    tree: NodeModel[],
    target: NodeModel | undefined,
    depth?: number
  ): number => {
    const _depth = depth || 0;

    if (!target) return _depth;

    if (target.parent === 0) return _depth;

    const newTarget = tree.find((item) => item.id === target.parent);
    return getDetph(tree, newTarget, _depth + 1);
  };

  useEffect(() => {
    setShowDeleteStructure(
      props.allHpUser.filter((item) => item.isDelete).length > 0
    );

    if (
      props.selectedStruct?.id === props.deleteStructureId &&
      props.allHpUser.filter((item) => item.isDelete).length === 0
    ) {
      props.setSelectedStruct(undefined);
    }
    // props.reloading();
  }, [props.allHpUser]);

  return (
    <Container ref={containerRef}>
      <Tree
        ref={treeRef}
        tree={props.data.filter((data) => !data.data?.structInfo?.isDelete)}
        classes={{
          draggingSource: "dragging",
          container: "container",
          listItem: "listitem",
          root: "root",
        }}
        rootId={0}
        onDrop={(newTree) => {
          newTree.forEach((item) => {
            if (getDetph(newTree, item) > 2) {
              return null;
              // return toast.error("더 이상 하위 그룹을 만들 수 없습니다", {
              //   position: toast.POSITION.BOTTOM_CENTER,
              // });
            }
          });

          const _newTree = newTree.map((item): NodeModel<DataType> => {
            if (item.droppable) {
              if (
                item.data !== undefined &&
                item.data.structInfo !== undefined
              ) {
                return {
                  id: item.id,
                  parent: item.parent,
                  text: item.text,
                  droppable: item.droppable,
                  data: {
                    isStruct: item.data.isStruct,
                    name: item.data.name,
                    structInfo: {
                      isNew: item.data.structInfo.isNew,
                      isChange: !item.data.structInfo.isNew,
                      isDelete: item.data.structInfo.isDelete,
                      hpStructureId: item.data.structInfo.hpStructureId,
                      name: item.data.structInfo.name,
                      introduction: item.data.structInfo.introduction,
                      parent: item.parent,
                    },
                  },
                };
              }
            }
            return item;
          });
          props.setData([..._newTree]);
        }}
        render={(node, { depth, isOpen, onToggle, hasChild }) => {
          if (node.id === props.deleteStructureId && !showDeleteStructure) {
            return <div />;
          }
          return (
            <StructureNode
              isStructure={node.data?.isStruct}
              node={node}
              depth={depth}
              isOpen={isOpen}
              hasChild={hasChild && node.id !== props.selectedStruct?.id}
              visibleInput={
                node.id === currentEditNodeId &&
                node.id !== props.deleteStructureId
              }
              isSelectedStructure={node.id === props.selectedStruct?.id}
              existSameValue={existSameValue}
              onToggle={onToggle}
              onOpen={handelOpen}
              onSelect={handleSelect}
              onEditStructure={onEditStructure}
              onEditCancelStructure={onEditCancelStructure}
              onTextChange={handleNameChange}
              deleteStructure={props.deleteStructure}
              canDelete={node.id !== props.deleteStructureId}
              isSelectedDeleteStructure={node.id === props.deleteStructureId}
            />
          );
        }}
        dragPreviewRender={(monitorProps) => (
          <StructureDragPreview
            monitorProps={monitorProps}
            isStructure={monitorProps.item.data?.isStruct}
          />
        )}
        sort={false}
        insertDroppableFirst={false}
        canDrop={(
          tree,
          { dragSource, dragSourceId, dropTargetId, dropTarget }
        ) => {
          if (
            (dragSource && dragSource.id === props.deleteStructureId) ||
            (dropTarget && dropTarget.id === props.deleteStructureId)
          ) {
            return false;
          }
          if (dragSource?.parent === dropTargetId) {
            return true;
          }
        }}
        dropTargetOffset={10}
        placeholderRender={(node, { depth }) => {
          return <StructurePlaceholder node={node} depth={depth} />;
        }}
      />
      <CompanyLabel isClicked onClick={props.onAddStructure}>
        <Plus />
        추가하기
      </CompanyLabel>
    </Container>
  );
};
const Container = Styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 100%;
  padding:20px;
  overflow: scroll;
  
  ul{
    list-style: none;
  }
  .root{
  }
  .listitem {
  }
  .container {
    padding:0;
  }
  .dragging { 
    opacity: 0.2;
  }
`;
const CompanyLabel = Styled.div<{ isClicked: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;

    cursor: default;
  
    height: 36px;

    border-radius: 10px;
    padding: 0 12px;

    border: 1px solid ${(props) =>
      props.isClicked ? "var(--primary)" : "var(--grey)"} ;

    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.1px;
    color: ${(props) => (props.isClicked ? "var(--primary)" : "var(--grey)")};
`;
export default Structure;
