import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import ModalLayout from "admin/component/modal/ModalLayout";
import ConfirmModal from "admin/component/modal/ConfirmModal";
import axios from "axios";
import { ReactComponent as Trash } from "../../../../assets/trash_can.svg";
import Toggle from "../../../component/toggle";
import { BlueLetterProps } from "..";
import * as api from "../../../../api/api";

interface Props {
  item: BlueLetterProps;
  showMenu?: boolean;
  onClickItem?: () => void;
  callback?: () => void;
}

const BlueLetterCard: React.FC<Props> = (props) => {
  const [newsItem, updateNewsItem] = useState<BlueLetterProps>(props.item);
  const [deleteConfirmOpen, updateDeleteConfirmOpen] = useState<boolean>(false);
  const onClickContent = () => {
    if (props.onClickItem) {
      props.onClickItem();
    }
  };

  const deleteEvent = async () => {
    try {
      if (!props.item.hpBlueLetterId) return;

      const result = await axios.delete(
        api.deleteBlueLetter(props.item.hpBlueLetterId)
      );
      if (result.status === 200 && props.callback) {
        updateDeleteConfirmOpen(false);
        props.callback();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    updateNewsItem(props.item);
  }, [props.item]);

  if (!newsItem) return null;

  return (
    <Container onClick={onClickContent}>
      <div className="news__content__container">
        <span className="news__title font-regular-14">{newsItem.title}</span>
        <span className="news__description font-regular-12">
          {newsItem.description}
        </span>
        <span className="news__link font-regular-12">{newsItem.linkUrl}</span>
      </div>
      <div
        className="thumbnail__container"
        style={{ backgroundImage: `url(${newsItem?.thumbNail})` }}
      />
      {props.showMenu && (
        <MenuContainer className="menu__controller">
          <Trash
            className="icon"
            onClick={(e) => {
              e.stopPropagation();
              updateDeleteConfirmOpen(true);
            }}
          />
          <Toggle isEditable />
        </MenuContainer>
      )}
      <ModalLayout isOpen={deleteConfirmOpen}>
        <ConfirmModal
          type="confirm"
          message="선택하신 블루레터를 삭제 하시겠습니까?"
          onClickNegativeBtn={() => updateDeleteConfirmOpen(false)}
          onClickPositiveBtn={deleteEvent}
        />
      </ModalLayout>
    </Container>
  );
};

const Container = styled.li`
  width: 100%;
  max-width: 706px;
  height: 106px;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  border: 1px solid rgba(55, 53, 47, 0.16);

  .thumbnail__container {
    height: 100%;
    aspect-ratio: 249 / 106;
    border-left: 1px solid rgba(55, 53, 47, 0.16);
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .news__content__container {
    flex: 4 1 180px;
    display: flex;
    flex-direction: column;
    padding: 12px 14px 14px;
    overflow: hidden;
    text-align: left;
    .news__title {
      line-height: 20px;
      color: rgb(55, 53, 47);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      min-height: 24px;
      margin-bottom: 2px;
    }
    .news__description {
      line-height: 16px;
      color: rgba(55, 53, 47, 0.65);
      height: 32px;
      overflow: hidden;
    }
    .news__link {
      margin-top: 6px;
      line-height: 16px;
      color: rgb(55, 53, 47);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  :hover {
    .menu__controller {
      display: flex;
    }
  }
`;

const MenuContainer = styled.div`
  position: absolute;
  min-width: 36px;
  height: 36px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  gap: 8px;
  padding: 0 8px;
  top: 0;
  right: 0;
  transform: translate(20%, -50%);
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid grey;

  .icon {
    width: 20px;
    min-width: 20px;

    height: 20px;
    min-height: 20px;
    max-height: 20px;
    svg {
      fill: white;
    }
  }
`;
export default React.memo(BlueLetterCard);
