import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import {
  HomepagePortfolioInfoType,
  Mode,
  PortfolioProps,
} from "admin/type/type";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import DisplayType from "utils/displayType";

import { getRangeArray } from "utils/commonUtil";
import { ReactComponent as RollingLeft } from "../../../assets/rolling_left.svg";
import { ReactComponent as RollingRight } from "../../../assets/rolling_right.svg";

interface Props {
  portfolioList: PortfolioProps[];
  onClickPortfolio?: (id: number) => void;
}
const LatestPortfolio: React.FC<Props> = (props) => {
  const [loading, setLoading] = useState(true);
  const sliderRef = useRef<any>(null);
  const mode = DisplayType();
  const limitCount = mode === "Mobile" ? 2 : 6;
  const settings = {
    infinite: true,
    speed: 200,
    slidesToShow: mode === "Mobile" ? 2 : 6,
    slidesToScroll: 1,
  };
  const next = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };
  const prev = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };
  return (
    <Container>
      <div
        className={`header__container ${
          mode === "Mobile" ? "font-sub-title2-en" : "font-title"
        }`}
      >
        <span>Latest {mode === "Mobile" && <br />}Portfolio</span>
        {props.portfolioList.length >= limitCount && (
          <div className="controller">
            <RollingLeft className="rolling__icon" onClick={prev} />
            <RollingRight className="rolling__icon" onClick={next} />
          </div>
        )}
      </div>
      <ProtfolioListContainer>
        {props.portfolioList.length < limitCount ? (
          props.portfolioList.map((item) => {
            console.log(item);
            if (item.companyImgUrl && item.companyImgUrl.length > 0) {
              return (
                <PortfolioItemContainer
                  key={item.portfolioId}
                  src={item.companyImgUrl || ""}
                  alt={"logo"}
                  onClick={() => {
                    if (props.onClickPortfolio)
                      props.onClickPortfolio(item.portfolioId);
                  }}
                />
              );
            }
            return (
              <PortfolioItemTextContainer
                className="font-body2"
                onClick={() => {
                  if (props.onClickPortfolio)
                    props.onClickPortfolio(item.portfolioId);
                }}
              >
                {item.companyName.split(" ").map((item, index) => {
                  return <span key={index}>{item}</span>;
                })}
              </PortfolioItemTextContainer>
            );
          })
        ) : (
          <Slider ref={sliderRef} className="full" {...settings}>
            {props.portfolioList.map((item) => {
              console.log(item);
              if (item && item.companyImgUrl && item.companyImgUrl.length > 0) {
                return (
                  <PortfolioItemContainer
                    key={item.portfolioId}
                    src={item.companyImgUrl || ""}
                    alt={"logo"}
                    onClick={() => {
                      if (props.onClickPortfolio)
                        props.onClickPortfolio(item.portfolioId);
                    }}
                  />
                );
              }
              return (
                <div>
                  <PortfolioItemTextContainer
                    className="font-body2"
                    style={{ display: "flex", fontWeight: 800 }}
                    onClick={() => {
                      if (props.onClickPortfolio)
                        props.onClickPortfolio(item.portfolioId);
                    }}
                  >
                    {item
                      ? item.companyName.split(" ").map((item, index) => {
                          return <span key={index}>{item}</span>;
                        })
                      : ""}
                  </PortfolioItemTextContainer>
                </div>
              );
            })}
          </Slider>
        )}
      </ProtfolioListContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  .full {
    width: 100%;
    overflow: hidden;
  }
  .header__container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-transform: uppercase;
    .controller {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      .rolling__icon {
        width: 32px;
        height: 32px;
        cursor: pointer;

        :hover {
          path {
            stroke: var(--primary);
          }
        }
      }
    }
  }
  @media only screen and (max-width: 768px) {
    gap: 16px;
    .header__container {
      .controller {
        .folling__icon {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
`;

const ProtfolioListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  border-left: 1px solid var(--grey-00);
`;
const PortfolioItemContainer = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: calc(1176px / 6 - 2px);
  max-width: calc(1176px / 6 - 2px);
  min-height: calc(1176px / 6 - 2px);
  max-height: calc(1176px / 6 - 2px);
  background-color: var(--white);
  border-top: 1px solid var(--grey-00);
  border-right: 1px solid var(--grey-00);
  border-bottom: 1px solid var(--grey-00);
  transition: all 0.1s ease;
  cursor: pointer;

  :hover {
    border: 2px solid var(--primary);
  }

  @media only screen and (max-width: 768px) {
    min-width: calc(1176px / 6 - 33px);
    max-width: calc(1176px / 6 - 33px);
    min-height: calc(1176px / 6 - 33px);
    max-height: calc(1176px / 6 - 33px);
    border-left: 1px solid var(--grey-00);
  }
`;

const PortfolioItemTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: calc(1176px / 6 - 2px);
  max-width: calc(1176px / 6 - 2px);
  min-height: calc(1176px / 6 - 2px);
  max-height: calc(1176px / 6 - 2px);
  background-color: var(--white);
  border-top: 1px solid var(--grey-00);
  border-right: 1px solid var(--grey-00);
  border-bottom: 1px solid var(--grey-00);
  transition: all 0.1s ease;
  cursor: pointer;
  color: var(--blue-01);

  :hover {
    border: 2px solid var(--primary);
  }

  @media only screen and (max-width: 768px) {
    min-width: calc(1176px / 6 - 33px);
    max-width: calc(1176px / 6 - 33px);
    min-height: calc(1176px / 6 - 33px);
    max-height: calc(1176px / 6 - 33px);
    border-left: 1px solid var(--grey-00);
  }
`;
export default React.memo(LatestPortfolio);
