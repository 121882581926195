import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { ReactComponent as Arrow } from "../../../../assets/selector_arrow.svg";

interface Props {
  label?: string;
  items: string[];
  selectedItem?: string;
  onClickItem: (item: string) => void;
}

const ButtonSelector: React.FC<Props> = (props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLUListElement>(null);
  const [showItemList, updateShowItemList] = useState<boolean>(false);

  const handlerMouseDown = (e: any) => {
    if (containerRef.current && !containerRef.current.contains(e.target)) {
      updateShowItemList(false);
    }
  };

  useEffect(() => {
    if (!menuRef.current) return;
    if (showItemList) {
      menuRef.current.style.transform = "scaleY(1)";
    } else {
      menuRef.current.style.transform = "scaleY(0)";
    }
  }, [showItemList]);

  useEffect(() => {
    window.addEventListener("mousedown", handlerMouseDown);
    return () => {
      window.removeEventListener("mousedown", handlerMouseDown);
    };
  }, []);
  return (
    <SelectorContainer>
      {props.label && (
        <div className="font-bold-14">
          <span>{props.label}</span>
        </div>
      )}
      <Container
        ref={containerRef}
        className="font-body3"
        style={{ fontWeight: 700 }}
        onClick={() => {
          updateShowItemList((prev) => !prev);
        }}
      >
        <span>{props.selectedItem ? props.selectedItem : "-"}</span>
        <Arrow className={`selector__arrow ${showItemList ? "open" : ""}`} />
        <MenuContainer className="scroll__invisible" ref={menuRef}>
          {props.items.map((item, index) => {
            return (
              <MenuItem
                key={index}
                onClick={(e) => {
                  e.stopPropagation();
                  props.onClickItem(item);
                  updateShowItemList(false);
                }}
              >
                {item}
              </MenuItem>
            );
          })}
        </MenuContainer>
      </Container>
    </SelectorContainer>
  );
};

const SelectorContainer = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Container = styled.div`
  position: relative;
  width: auto;
  min-width: 200px;
  min-height: 59px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 0 13px;

  border-bottom: 1px solid rgba(229, 229, 229, 0.5);
  z-index: 10;

  .selector__arrow {
    transform: rotate(0deg);
    transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
    &.open {
      transform: rotate(180deg);
    }
  }
`;

const MenuContainer = styled.ul`
  position: absolute;
  width: 100%;
  max-height: 183px;
  overflow-y: scroll;
  transform: scaleY(0);
  display: flex;
  flex-direction: column;
  top: 60px;
  left: 0;
  background-color: rgba(229, 229, 229, 0.5);
  gap: 1px;
  margin: 0;
  padding: 0;
  transition: all 0.1s ease;
  transform-origin: top;
`;

const MenuItem = styled.li`
  width: 100%;
  min-height: 59px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 0 21px;
  background-color: var(--bg-03);
`;
export default React.memo(ButtonSelector);
