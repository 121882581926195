import { useEffect, useState } from "react";
import axios from "axios";
import { PortfolioProps } from "admin/type/type";
import styled from "@emotion/styled";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import DefaultButton from "admin/component/button/DefaultButton";
import * as api from "../../../api/api";
import Navigation, { Pages } from "../../component/navigation";
import { PortfolioDragItem } from "./types";
import reorder, { moveBetween } from "./reorder";

const PortfolioHeaderList = () => {
  const [keyword, updateKeyword] = useState<string>("");
  const [allPortfolioList, setAllPortfolioList] = useState<PortfolioDragItem[]>(
    []
  );
  const [portfoliosList, updatePortfolioList] = useState<PortfolioDragItem[]>(
    []
  );
  const [selectedPortfoliosList, updateSelectedPortfolioList] = useState<
    PortfolioDragItem[]
  >([]);

  const getPortflioList = async () => {
    try {
      const result = await axios.get(api.getHomepagePortfolioList());
      const headeResult = await axios.get(api.getHeaderPortfolio());
      if (result.status === 200) {
        const headerPortfolio = headeResult.data.map(
          (item: any) => item.portfolioId
        );
        const portfolios = result.data.map(
          (item: any): PortfolioProps => ({
            ...item,
          })
        );
        const allPortfolioDragItem = portfolios.map(
          (item: any, index: number): PortfolioDragItem => ({
            id: `portfolio_${index}`,
            content: item,
          })
        );

        setAllPortfolioList(allPortfolioDragItem);

        updatePortfolioList(
          allPortfolioDragItem.filter((item: any) => {
            return !headerPortfolio.includes(item.content.portfolioId);
          })
        );
        updateSelectedPortfolioList(
          allPortfolioDragItem.filter((item: any) => {
            return headerPortfolio.includes(item.content.portfolioId);
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onDragEnd = (result: DropResult) => {
    const { destination, source } = result;

    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      if (source.droppableId === "portfolio_list") {
        updatePortfolioList(
          reorder(portfoliosList, source.index, destination.index)
        );
      }
      if (source.droppableId === "selected_portfolio_list") {
        updateSelectedPortfolioList(
          reorder(selectedPortfoliosList, source.index, destination.index)
        );
      }
      return;
    }

    const { list1, list2 } = moveBetween({
      list1: {
        id: "portfolio_list",
        values: portfoliosList.filter((item) =>
          item.content.companyName.includes(keyword)
        ),
      },
      list2: {
        id: "selected_portfolio_list",
        values: selectedPortfoliosList,
      },
      source,
      destination,
    });

    updatePortfolioList(
      allPortfolioList.filter(
        (item) => !list2.values.map((data) => data.id).includes(item.id)
      )
    );
    updateSelectedPortfolioList(list2.values);
  };

  const renderTasks = (
    portfolios: PortfolioDragItem[],
    options: { isDragEnabled: boolean } = { isDragEnabled: true }
  ) => {
    return portfolios.map((portfolio: PortfolioDragItem, index: number) => {
      return (
        <Draggable
          draggableId={portfolio.id}
          index={index}
          key={portfolio.id}
          isDragDisabled={!options.isDragEnabled}
        >
          {(provided, snapshot) => (
            <Item
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              isDragging={snapshot.isDragging}
              ref={provided.innerRef}
            >
              {portfolio.content.companyName}
            </Item>
          )}
        </Draggable>
      );
    });
  };

  const updateHeaderPortfolio = async () => {
    try {
      const selctedPortfolio = selectedPortfoliosList.map((item, index) => ({
        idx: index + 1,
        hpPortfolio: {
          portfolioId: item.content.portfolioId,
        },
      }));
      const result = await axios.put(
        api.updateHeaderPortfolio(),
        selctedPortfolio
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPortflioList();
  }, []);
  return (
    <Navigation activePageId={Pages[2].id}>
      <Wrapper>
        <DragDropContext onDragEnd={onDragEnd}>
          <Container>
            <List>
              <ListTitle>포트폴리오리스트</ListTitle>
              <input
                type={"text"}
                value={keyword}
                onChange={(e) => updateKeyword(e.target.value)}
              />
              <div className="portfolio__list">
                <Droppable droppableId="portfolio_list">
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      style={{ minHeight: 200 }}
                    >
                      {renderTasks(
                        portfoliosList.filter((item) =>
                          item.content.companyName.includes(keyword)
                        ),
                        { isDragEnabled: true }
                      )}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            </List>
            <List>
              <ListTitle>선택된 포트폴리오</ListTitle>
              <div className="portfolio__list">
                <Droppable droppableId="selected_portfolio_list">
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      style={{ minHeight: 200 }}
                    >
                      {renderTasks(selectedPortfoliosList, {
                        isDragEnabled: true,
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            </List>
          </Container>
        </DragDropContext>
        <DefaultButton onClick={updateHeaderPortfolio}>
          업데이트 하기
        </DefaultButton>
      </Wrapper>
    </Navigation>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;
const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: start;
  user-select: none;
  gap: 24px;
`;

const List = styled.div`
  border: 1px solid var(--grey-00);
  margin: 16px;
  text-align: center;
  .portfolio__list {
    max-height: 50vh;
    overflow: scroll;
    padding-bottom: 16px;
  }
`;
const ListTitle = styled.h3`
  padding: 16px;
  width: 250px;
`;

const Item = styled.div<{ isDragging: boolean }>`
  padding: 8px;
  border: 1px solid var(--grey-30);
  background-color: ${(props) =>
    props.isDragging ? "var(--primary)" : "var(--white)"};
  margin-top: 8px;
  margin-left: 8px;
  margin-right: 8px;
`;
export default PortfolioHeaderList;
