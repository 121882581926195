import React, { useEffect } from "react";
import styled from "@emotion/styled";
import DisplayType from "utils/displayType";
import { Link } from "react-router-dom";

interface Props {
  item: ProgramRowProps;
  isReverse: boolean;
}

export interface ProgramRowProps {
  to: string;
  thumbNail: string;
  subTitle: string;
  title: string;
  introduction: string;
  style?: React.CSSProperties;
}

const ProgramRow: React.FC<Props> = (props) => {
  const mode = DisplayType();

  const refreshPage = () => {
    window.scrollTo({ top: 0, behavior: "auto" });
  };

  useEffect(() => {}, []);
  return (
    <Container isReverse={props.isReverse} imgUrl={props.item.thumbNail}>
      <div
        className="program__thumbnail"
        style={{
          ...props.item.style,
        }}
      />
      <div className="program__info">
        <span
          className={`program__info__sub__title ${
            mode === "Mobile" ? "font-bold-16" : "font-sub-title4"
          }`}
        >
          {props.item.subTitle}
        </span>
        <span
          className={`program__info__title ${
            mode === "Mobile" ? "font-bold-26" : "font-title"
          }`}
        >
          {props.item.title}
        </span>
        <p
          className={`program__info__introduction ${
            mode === "Mobile" ? "font-body4" : "font-body2"
          }`}
        >
          {props.item.introduction}
        </p>
        <Link
          to={props.item.to}
          style={{ textDecoration: "none" }}
          onClick={refreshPage}
        >
          <ProgramButton
            className={`${mode === "Mobile" ? "font-button2" : "font-button1"}`}
          >
            자세히 보기
          </ProgramButton>
        </Link>
      </div>
    </Container>
  );
};

const Container = styled.div<{ isReverse: boolean; imgUrl?: string }>`
  width: 100%;
  height: 360px;
  display: flex;
  flex-direction: ${(props) => (props.isReverse ? "row-reverse" : "row")};
  padding-left: 60px;
  padding-right: 60px;
  gap: 80px;

  .program__thumbnail {
    width: 540px;
    min-width: 540px;
    max-width: 540px;
    height: 100%;
    background: url(${(props) => props.imgUrl || ""});
  }

  .program__info {
    flex-grow: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .program__info__sub__title {
      color: var(--grey-50);
    }
    .program__info__title {
      color: var(--black);
    }
    .program__info__introduction {
      color: var(--grey-50);
      margin-top: 24px;
    }
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    height: auto;
    flex-direction: column;
    gap: 0;
    padding-left: 0px;
    padding-right: 0px;
    .program__thumbnail {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      height: 100%;
      min-height: 200px;
      max-height: 200px;
      border: 1px solid var(--grey-00);
      background-size: contain;
      background-position: center;
    }

    .program__info {
      border: 1px solid var(--grey-00);
      border-top: 0px;
      padding: 24px;
      padding-bottom: 48px;
      align-items: center;
      .program__info__sub__title {
        text-align: center;
      }
      .program__info__title {
        text-align: center;
      }
      .program__info__introduction {
        text-align: center;
      }
    }

    .program__info__introduction {
      text-align: center;
      margin-top: 16px;
    }
  }
`;

const ProgramButton = styled.button`
  width: 205px;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  border: 1px solid var(--primary);
  color: var(--primary);
  transition: all 0.3s ease;
  margin-top: 40px;
  :hover {
    background-color: var(--primary);
    color: var(--white);
  }
  @media only screen and (max-width: 768px) {
    width: 132px;
    height: 43px;
    margin-top: 7px;
  }
`;

export default ProgramRow;
