import React, { useEffect, useState } from "react";
import { SearchBarLayout } from "./styles";
import { ReactComponent as SearchIcon } from "../../../assets/search.svg";

interface Props {
  defaultKeyword?: string;
  onChangeKeyword: (keyword: string) => void;
}

const SearchBar: React.FC<Props> = (props) => {
  const [keyword, updateKeyword] = useState<string>("");
  useEffect(() => {
    if (props.defaultKeyword) {
      updateKeyword(props.defaultKeyword);
    }
  }, [props.defaultKeyword]);
  return (
    <SearchBarLayout>
      <input
        value={keyword}
        onChange={(e) => {
          updateKeyword(e.target.value);
          props.onChangeKeyword(e.target.value);
        }}
      />
      <SearchIcon className="search__icon" />
    </SearchBarLayout>
  );
};
export default SearchBar;
