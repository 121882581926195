import React from "react";
import styled from "@emotion/styled";
import DisplayType from "utils/displayType";
import { ReactComponent as SuccessCheck } from "../../assets/success_apply.svg";
import { ReactComponent as CloseBtn } from "../../assets/modal_close.svg";

interface Props {
  close: () => void;
}
const SuccessPartnershipModal: React.FC<Props> = (props) => {
  const mode = DisplayType();
  const message = `보내주신 문의 내용은 블루포인트의\n 담당 팀에 전달되어 면밀히 검토하게 됩니다.\n\n검토 후 추가적인 미팅을 요청드릴 경우에만 담당 팀에서 연락을 드릴 예정이고,\n반려 시 별도의 연락은 드리지 못하고 있는 점 양해 부탁드립니다.\n\n검토 기간은 최대 3주가 소요됩니다.`;
  return (
    <Container>
      <ContentsContainer>
        {mode !== "Mobile" && (
          <CloseBtn className="close__btn" onClick={props.close} />
        )}
        <SuccessCheck />
        <div
          className={`success__apply__title ${
            mode === "Mobile" ? "font-sub-title2" : "font-sub-title"
          }`}
        >
          접수가 완료 되었습니다.
        </div>
        {mode === "Mobile" ? (
          <p className={`success__apply__content font-body4 `}>
            보내주신 문의 내용은 블루포인트의 담당 팀에 전달되어 면밀히 검토하게
            됩니다.
            <br />
            <br />
            검토 후 추가적인 미팅을 요청드릴 경우에만 담당 팀에서 연락을 드릴
            예정이고, 반려 시 별도의 연락은 드리지 못하고 있는 점 양해
            부탁드립니다.
            <br />
            <br />
          </p>
        ) : (
          <pre className={`success__apply__content font-body2`}>{message}</pre>
        )}
      </ContentsContainer>
      {mode === "Mobile" && (
        <div className="mobile__close" onClick={props.close}>
          확인
        </div>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;

  .mobile__close {
    width: 100%;
    height: 56px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--grey-00);
    color: var(--primary);
  }
`;

const ContentsContainer = styled.div`
  position: relative;
  width: 1060px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  padding: 80px;

  .close__btn {
    position: absolute;
    top: 24px;
    right: 24px;
    width: 48px;
    height: 48px;
    cursor: pointer;
    background-color: transparent;
    transition: background-color 0.2s ease-in;
    border-radius: 8px;
    :hover {
      background-color: var(--grey-00);
    }
  }

  .success__apply__title {
    text-align: center;
  }
  .success__apply__content {
    text-align: center;
  }

  @media only screen and (max-width: 768px) {
    width: calc(100vw - 64px);
    padding: 40px 24px;

    .success__apply__title {
      text-align: center;
    }
    .success__apply__content {
      text-align: start;
      width: 100%;
    }
  }
`;

export default React.memo(SuccessPartnershipModal);
