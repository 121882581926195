import axios from "axios";
import { access } from "fs";
import { async } from "q";
import { Cookies, useCookies } from "react-cookie";

// domain
const DOMAIN = "http://192.168.0.58:8000";
// Router
const PRODUCT = "/product";
const RELATED_NEWS = "/relatedNews";
const RELATED_PF = "/relatedPF";
const HOT_PFS = "/hotPFs";
const LATEST_COL = "/latestcolumn";
const LATEST_NEWS = "/latestnews";
const STRUCTURE = "/structure";
const ALL_USER = "/users";
const ALL_PF = "/allPortfolios";
const FILTERS = "/filters";
const COLUMNS = "/columns";
const AUTH = "/auth";
const AUTH_LOGIN = `${AUTH}/login`;

const ACCELERATOR = "/accelerator";
const AC_STRUCTURE_ADMIN = `${ACCELERATOR}/administrator/structure`;

const IR_ARCHIVES = "/ir-archives";
const IR_ANNOUNCEMENT = "/announcement";
const POPUP = "popup";
const STOCK_INFO = "stock-info";

export const setInitAxioSetting = () => {
  axios.defaults.baseURL = process.env.REACT_APP_API_DOMAIN;
  const cookie = new Cookies();
  const accessToken = cookie.get("access_token");

  if (accessToken !== undefined) {
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  }
  interceptor();
};

export const interceptor = () => {
  axios.interceptors.request.use((config) => {
    const cookie = new Cookies();
    const accessToken = cookie.get("access_token");

    console.log(accessToken);

    if (accessToken !== undefined) {
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    }

    config.withCredentials = !config.url?.includes(
      "https://api.odcloud.kr/api/nts-businessman"
    );
    return config;
  });

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const {
        config,
        response: { data },
      } = error;
      if (data.data.statusCode === 401) {
        const cookie = new Cookies();
        const sessing = cookie.get("sessing");
        if (sessing && data.data.message === "Unauthorized") {
          alert("세션이 만료 되었습니다. 다시 로그인 해주시기 바랍니다.");
          Logout();
        }
      }
      return Promise.reject(error);
    }
  );
};

// 쿠키 저장
// expiredays 는 일자 정수 - 365년 1년 쿠키
const setCookie = (key: string, value: string) => {
  document.cookie = `${key}=${value}; path=/;`;
};

const delCookie = (key: string) => {
  document.cookie = `${key}=; path=/;`;
};

export const Logout = () => {
  try {
    delCookie("sessing");
    delCookie("access_token");
    window.location.replace("/");
  } catch (error) {
    console.log(error);
  }
};

export const checkingToken = async () => {
  try {
    const cookie = new Cookies();

    const accessToken = cookie.get("access_token");
    console.log(accessToken);

    if (!accessToken || accessToken.length === 0) {
      alert("잘못된 접근입니다.");
      Logout();
    } else {
      const result = await axios.get(checkToken());
      return result;
    }
  } catch (error) {
    console.log(error);
  }
};

export const login = (): string => `${DOMAIN}${AUTH_LOGIN}`;
export const getProduct = (): string => `${DOMAIN}${PRODUCT}`;
export const getRelatedNews = (): string => `${DOMAIN}${RELATED_NEWS}`;
// export const getRelatedNews = (id:number): string => `${RELATED_NEWS}/${id}`;
export const getRelatedPF = (): string => `${DOMAIN}${RELATED_PF}`;
export const getHotPFs = (): string => `${DOMAIN}${HOT_PFS}`;
export const getLatestCol = (): string => `${DOMAIN}${LATEST_COL}`;
export const getLatestNews = (): string => `${DOMAIN}${LATEST_NEWS}`;
export const getStructure = (): string => `${DOMAIN}${STRUCTURE}`;
export const getAllUser = (): string => `${DOMAIN}${ALL_USER}`;
export const getAllPf = (): string => `${DOMAIN}${ALL_PF}`;
export const getFilters = (): string => `${DOMAIN}${FILTERS}`;
export const getColumns = (): string => `${DOMAIN}${COLUMNS}`;
export const isAuthUser = (): string => `${DOMAIN}${AUTH}`;

export const getAcAdminStructure = (): string => `${AC_STRUCTURE_ADMIN}`;

// admin
const USER = "/user";
const PORTFOLIO = "/portfolio";
const COMMON = "/common";
const EVENT = "/event";
const BLUE_LETTER = "/blueletter";
const CONTENT = "/content";
const NEWS = "/news";
const BANNER = "/banner";

// common
export const uploadAndGetUrl = (): string => `${COMMON}/uploadAndGetUrl`;
export const getHompepageIndustrialTech = (): string =>
  `${COMMON}/industrialTech`;
export const uploadBusinessPlanFile = (): string =>
  `${COMMON}/businessPlanFile`;
export const uploadGoogleBusinessPlanFile = (): string =>
  `${COMMON}/googleFile`;
export const getServerTime = (): string => `${COMMON}/serverTime`;
export const downloadByKey = () => `${COMMON}/file`;
// common

// user
export const upsertAcStructure = (): string => `${USER}/structure`;
export const updateIndustrialTech = (): string => `${USER}/industrialTech`;
export const getAllPosition = (): string => `${USER}/position`;
export const getHomepageStructure = (): string =>
  `${USER}/homepage/allStructure`;
export const getAllHomepageUser = (): string => `${USER}/homepage/allUser`;
export const getHomepageUser = (id: string | number): string =>
  `${USER}/homepage/${id}`;
export const getAllLatticeUser = (): string => `${USER}/lattice/allUser`;
export const adminLogin = (): string => `${USER}/masterUser`;
export const checkToken = (): string => `${USER}/token`;
// user

// portfoio
export const reloadPortfolio = (): string => `${PORTFOLIO}/renewal`;
export const getHomepagePortfolioList = (isNotRandom?: boolean): string =>
  `${PORTFOLIO}${isNotRandom ? "" : "/random"}`;
export const upsertHomepagePortfolio = (): string => PORTFOLIO;
export const patchHomepagePortfolioActiveStatus = (
  portfolioId: number | string
): string => `${PORTFOLIO}/${portfolioId}`;
export const getLatticePortfolio = (id: string | number): string =>
  `${PORTFOLIO}/lattice/${id}`;
export const getHomepagePortfolio = (id: string | number): string =>
  `${PORTFOLIO}/homepage/${id}`;
export const sendApply = (): string => `${PORTFOLIO}/apply`;
export const sendPartnership = (): string => `${PORTFOLIO}/partnership`;
export const updateHeaderPortfolio = (): string => `${PORTFOLIO}/header`;
export const getHeaderPortfolio = (): string => `${PORTFOLIO}/header`;
// portfoio

// event
export const createEvent = (): string => `${EVENT}`;
export const getEventList = (): string => `${EVENT}`;
export const updateEvent = (): string => `${EVENT}`;
export const getEventDetail = (id: string | number): string => `${EVENT}/${id}`;
export const deleteEvent = (id: string | number): string => `${EVENT}/${id}`;
// event

// blueletter
export const createBlueLetter = (): string => `${BLUE_LETTER}`;
export const getBlueLetterList = (): string => `${BLUE_LETTER}`;
export const updateBlueLetter = (): string => `${BLUE_LETTER}`;
export const deleteBlueLetter = (id: string | number): string =>
  `${BLUE_LETTER}/${id}`;
export const subscribeblueLetter = (): string => `${BLUE_LETTER}/subscribe`;
// blueletter

// Content
export const createContent = (): string => `${CONTENT}`;
export const getContentList = (): string => `${CONTENT}`;
export const updateContent = (): string => `${CONTENT}`;
export const getContentDetail = (id: string | number): string =>
  `${CONTENT}/${id}`;
export const deleteContent = (id: string | number): string =>
  `${CONTENT}/${id}`;
// Content

// News
export const createNews = (): string => `${NEWS}`;
export const getNewsList = (): string => `${NEWS}`;
export const updateNews = (): string => `${NEWS}`;
export const deleteNews = (id: string | number): string => `${NEWS}/${id}`;
// News

// Banner
export const createBanner = (): string => `${BANNER}`;
export const getBannerList = (): string => `${BANNER}`;
export const updateBanner = (): string => `${BANNER}`;
export const getBannerDetail = (id: string | number): string =>
  `${BANNER}/${id}`;
export const deleteBanner = (id: string | number): string => `${BANNER}/${id}`;
// Banner

// IR Archive
export const createIRArchives = (): string => `${IR_ARCHIVES}`;
export const deleteIRArchives = (): string => `${IR_ARCHIVES}`;
export const getIRArchives = (): string => `${IR_ARCHIVES}`;
// IR Archive

// IR Announcement
export const getIRAnnouncementUser = (): string => `${IR_ANNOUNCEMENT}/user`;
export const getIRAnnouncementAdmin = (): string => `${IR_ANNOUNCEMENT}/admin`;
export const getIRAnnouncementDetail = (id: number | string): string =>
  `${IR_ANNOUNCEMENT}/user/${id}`;
export const uploadIRAnnouncementFile = (): string => `${IR_ANNOUNCEMENT}/file`;

export const createIRAnnouncement = (): string => `${IR_ANNOUNCEMENT}`;
export const updateIRAnnouncement = (): string => `${IR_ANNOUNCEMENT}`;
export const deleteIRAnnouncement = (id: number | string): string =>
  `${IR_ANNOUNCEMENT}/${id}`;

// IR Announcement

// Popup
export const createPopup = (): string => `${POPUP}`;
export const updatePopup = (): string => `${POPUP}`;
export const getPopupListForClient = (): string => `${POPUP}/show`;
export const getPopupListForAdmin = (): string => `${POPUP}/admin`;
export const updatePopupAll = (): string => `${POPUP}/all`;
export const deletePopup = (id: string | number) => `${POPUP}/${id}`;
// Popup

// StockInfo
export const getStockInfoCurrentValue = (): string =>
  `${STOCK_INFO}/currentValue`;
export const getStockInfoDaily = (): string => `${STOCK_INFO}/daily`;
// StockInfo
