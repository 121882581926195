import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { CompanyProjectType, imgCategory } from "admin/type/type";
import { NotionInput } from "admin/component/input/NotionInput";
import { NotionTextarea } from "admin/component/input/NotionTextarea";
import UploadCompanyImg from "./UploadCompanyImg";
import { ReactComponent as Remove } from "../../../../assets/edit_cancel.svg";

interface Props {
  portfolioId: number;
  editable: boolean;
  project?: CompanyProjectType;
  isViewer?: boolean;
  onClick?: () => void;
  onChangeProjectData?: (project: CompanyProjectType) => void;
  onDeleteProject?: (projectId?: number | string) => void;
}

const ProjectCard: React.FC<Props> = (props) => {
  const { isViewer = false } = props;
  const [projectImgUrl, setProjectImgUrl] = useState<string | undefined | null>(
    undefined
  );

  const [summary, setSummary] = useState<string | undefined>("");
  const [description, setDescription] = useState<string | undefined>("");

  useEffect(() => {
    const project = props.project;
    setProjectImgUrl(project?.projectImgUrl);
    setSummary(project?.summary);
    setDescription(project?.description);
  }, [
    props.project?.projectImgUrl,
    props.project?.summary,
    props.project?.description,
  ]);
  return (
    <Container>
      {props.editable && (
        <Remove
          className="remove__btn"
          width={24}
          height={24}
          onClick={() => {
            if (props.onDeleteProject) {
              props.onDeleteProject(props.project?.pfProjectId);
            }
          }}
        />
      )}

      <div className="project__row">
        <UploadCompanyImg
          editable={props.editable}
          width={150}
          portfolioId={props.portfolioId}
          onChangeFile={(url, key) => {
            if (
              props.project === undefined ||
              props.onChangeProjectData === undefined
            )
              return;

            props.onChangeProjectData({
              ...props.project,
              projectImgKey: key,
              projectImgUrl: url,
            });
          }}
          imgUrl={projectImgUrl}
          category={imgCategory.PROJECT_IMG}
        />
        <div className="project__column">
          <NotionInput
            editable={props.editable}
            hasUnderLine={props.editable}
            textType="text"
            placeholder="프로젝트 요약글을 입력하세요. 최대 30자까지 입력 가능"
            defaultValue={summary}
            onChange={(e) => {
              setSummary(e);
            }}
            onBlur={(e) => {
              if (
                props.project === undefined ||
                props.onChangeProjectData === undefined
              )
                return;

              props.onChangeProjectData({
                ...props.project,
                summary: summary || "",
              });
            }}
          />
          <NotionTextarea
            editable={props.editable}
            placeholder="프로젝트 설명을 입력하세요. 최대 50자까지 입력 가능"
            defaultValue={description}
            onChange={(e) => {
              setDescription(e);
            }}
            onBlur={(e) => {
              if (
                props.project === undefined ||
                props.onChangeProjectData === undefined
              )
                return;

              props.onChangeProjectData({
                ...props.project,
                description: description || "",
              });
            }}
          />
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  background: var(--white);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  padding: 24px;

  .project__row {
    display: flex;
    flex-direction: row;
    gap: 24px;
  }

  .project__column {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .remove__btn {
    position: absolute;
    top: 6px;
    right: 6px;
    width: 24px;
    height: 24px;
    z-index: 2;
    cursor: pointer;
    :hover {
      border-radius: 4px;
      background-color: var(--grey-00);
    }
  }
`;

export default React.memo(ProjectCard);
