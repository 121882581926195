import React from "react";
import styled from "@emotion/styled";

interface Props {
  className?: string;
  children: string;
  onClickChip?: () => void;
}
const RectangleChip: React.FC<Props> = (props) => {
  return (
    <Container
      className={
        props.className !== undefined ? props.className : "font-medium-14"
      }
    >
      {props.children}
    </Container>
  );
};

const Container = styled.div`
  padding: 4px 16px 3px 16px;
  min-width: 58px;
  min-height: 27px;
  border-radius: 30px;
  cursor: default;
  user-select: none;

  background-color: var(--white);
  border: 1px solid var(--primary);
  border-radius: 4px;
  color: var(--primary);
`;

export default React.memo(RectangleChip);
