import React, { useLayoutEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import axios from "axios";
import { NotionInput } from "admin/component/input/NotionInput";
import DefaultButton from "admin/component/button/DefaultButton";
import { SelectData } from "admin/type/type";
import { getYMD } from "utils/commonUtil";
import * as api from "../../../../api/api";
import { BlueLetterProps } from "..";
import BlueLetterCard from "./BlueLetterCard";

interface Props {
  item?: BlueLetterProps;
  close?: () => void;
  callback?: () => void;
}
export interface Portfolio {
  portfolioId: number;
  companyName: string;
}

const BlueLetterDetail: React.FC<Props> = (props) => {
  const [openDate, updateOpenDate] = useState<string | undefined>(
    getYMD(new Date())
  );
  const [linkUrl, updateLinkUrl] = useState<string | undefined>(undefined);

  const hpBlueLetterId = useRef<number | undefined>(undefined);
  const isActive = useRef<boolean>(true);

  const getBlueletterDetail = () => {
    if (props.item === undefined) return;

    isActive.current = props.item.isActive;
    hpBlueLetterId.current = props.item.hpBlueLetterId;
    updateOpenDate(getYMD(props.item.openDate));
    updateLinkUrl(props.item.linkUrl);
  };

  const updateBlueletter = async () => {
    try {
      const param = {
        hpBlueLetterId: hpBlueLetterId.current,
        openDate,
        linkUrl,
        isActive: isActive.current,
      };

      axios.put(api.updateBlueLetter(), param).then((result) => {
        if (result.status === 200 && props.callback) {
          props.callback();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const createBlueletter = async () => {
    try {
      const param = {
        openDate,
        linkUrl,
        isActive: isActive.current,
      };

      axios.post(api.createBlueLetter(), param).then((result) => {
        if (result.status === 201 && props.callback) {
          props.callback();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useLayoutEffect(() => {
    if (props.item !== undefined) {
      getBlueletterDetail();
    }
  }, [props.item]);

  return (
    <Container>
      <div className="blueletter__detail__header font-bold-18">
        <span />
        {props.item ? "블루레터 수정" : "블루레터 추가"}
        <DefaultButton
          disabled={openDate === undefined || linkUrl === undefined}
          onClick={() => {
            if (props.item) {
              // 수정
              updateBlueletter();
            } else {
              // 생성
              createBlueletter();
            }
          }}
        >
          {props.item ? "수정하기" : "생성하기"}
        </DefaultButton>
      </div>
      <div className="blueletter__detail__body">
        <div className="blueletter__detail__body__row">
          <NotionInput
            label="게시일"
            hasUnderLine
            editable
            textType="date"
            max="9999-12-31"
            placeholder="연도. 월. 일"
            defaultValue={openDate}
            onChange={(e) => {
              updateOpenDate(e);
            }}
          />
        </div>
        <div className="blueletter__detail__body__row">
          <NotionInput
            label="링크"
            hasUnderLine
            editable
            textType="text"
            defaultValue={linkUrl}
            onChange={(e) => {
              updateLinkUrl(e);
            }}
          />
        </div>
        {props.item && <BlueLetterCard item={props.item} />}
      </div>
    </Container>
  );
};

const Container = styled.div`
  width: 100vw;
  max-width: 780px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  .blueletter__detail__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px 24px;

    border-bottom: 1px solid var(--grey);
  }
  .blueletter__detail__body {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 24px;
    gap: 10px;

    .blueletter__detail__body__row {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
`;
export default BlueLetterDetail;
