import { useRef, useEffect, useState } from "react";

import HeaderNavigation from "component/header/HeaderNavigation";
import DisplayType from "utils/displayType";
import { NavigationMenu, Structure, UserInfo } from "admin/type/type";
import axios from "axios";
import styled from "@emotion/styled";

import { useLocation, useNavigate } from "react-router-dom";
import { NotionTextarea } from "admin/component/input/NotionTextarea";
import GoList from "component/go-list/GoList";
import { updateTitle } from "utils/commonUtil";
import { ReactComponent as Twitter } from "../../assets/sns_twitter.svg";
import { ReactComponent as Facebook } from "../../assets/sns_facebook.svg";
import { ReactComponent as Instagram } from "../../assets/sns_instagram.svg";
import { ReactComponent as Blog } from "../../assets/sns_blog.svg";

import Footer from "../../component/footer/Footer";

import * as api from "../../api/api";
import RectangleChip from "./components/RectangleChip";
import DetailInvests from "./organism/DetailInvests";

const StaffDetail = () => {
  const mode = DisplayType();
  const { pathname } = useLocation();
  const navigation = useNavigate();
  const userId = useRef<number | undefined>(undefined);
  const [userInfo, updateUserInfo] = useState<UserInfo | undefined>(undefined);

  const getUserInfo = async () => {
    try {
      if (userId.current === undefined) return;

      const result = await axios.get(api.getHomepageUser(userId.current));
      updateUserInfo({ ...result.data });

      updateTitle(result.data.name);
    } catch (error) {
      console.log(error);
    }
  };

  const addProtocol = (url: string) => {
    if (url.includes("http://") || url.includes("http://")) {
      return url;
    }
    return `http://${url}`;
  };

  useEffect(() => {
    if (userId.current === undefined) {
      userId.current = parseInt(pathname.split("/")[2], 10);
    }
    getUserInfo();
  }, []);

  if (userInfo === undefined) return null;

  return (
    <Container>
      <BodyContainer className="limit__body">
        <UserInfoContainer>
          <img
            className="thumbnail"
            src={userInfo.profileImgUrl}
            alt="user profile"
          />
          <div className="infomation__container">
            <div className="title__container">
              <span
                className={`${
                  mode === "Mobile" ? "font-sub-title" : "font-title-lx"
                }`}
                style={{ color: "var(--balck)" }}
              >
                {userInfo.name}
              </span>
              {userInfo.acUserInfo?.acPosition && (
                <>
                  {mode !== "Mobile" && <div className="divider" />}
                  <span
                    className={`staff__position ${
                      mode === "Mobile" ? "font-body3" : "font-body1"
                    }`}
                    style={{ color: "var(--grey-30)" }}
                  >
                    {userInfo.acUserInfo?.acPosition.name}
                  </span>
                </>
              )}
            </div>
            <div className="user__info__container">
              {userInfo.acUserInfo?.acStructure && (
                <span
                  className={`staff__position ${
                    mode === "Mobile" ? "font-body3" : "font-body1"
                  }`}
                >
                  {userInfo.acUserInfo?.acStructure.department &&
                    `${userInfo.acUserInfo?.acStructure.department} `}
                  {userInfo.acUserInfo?.acStructure.group &&
                    `${userInfo.acUserInfo?.acStructure.group} `}
                  {userInfo.acUserInfo?.acStructure.team &&
                    `${userInfo.acUserInfo?.acStructure.team} `}
                </span>
              )}
              {userInfo.acUserInfo?.acResponsibility && (
                <span
                  className={`staff__position ${
                    mode === "Mobile" ? "font-body3" : "font-body1"
                  }`}
                >
                  {userInfo.acUserInfo.acResponsibility.name}
                </span>
              )}
            </div>
            {userInfo.hpUserMajor && userInfo.hpUserMajor.length > 0 && (
              <div className="chips__container">
                {userInfo.hpUserMajor?.map((item) => {
                  return (
                    <RectangleChip
                      key={item.hpIndustrialTech.acIndustrialTechId}
                    >
                      {item.hpIndustrialTech.name}
                    </RectangleChip>
                  );
                })}
              </div>
            )}
            {(userInfo.twitter ||
              userInfo.facebook ||
              userInfo.blog ||
              userInfo.instagram) && (
              <div className="sns__container">
                {userInfo.twitter && (
                  <a
                    className="sns__link"
                    href={addProtocol(userInfo.twitter)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Twitter className="sns" />
                  </a>
                )}
                {userInfo.facebook && (
                  <a
                    className="sns__link"
                    href={addProtocol(userInfo.facebook)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Facebook className="sns" />
                  </a>
                )}
                {userInfo.blog && (
                  <a
                    className="sns__link"
                    href={addProtocol(userInfo.blog)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Blog className="sns" />
                  </a>
                )}
                {userInfo.instagram && (
                  <a
                    className="sns__link"
                    href={addProtocol(userInfo.instagram)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Instagram className="sns" />
                  </a>
                )}
              </div>
            )}
          </div>
        </UserInfoContainer>
        <div
          style={{
            marginTop: mode === "Mobile" ? 24 : 40,
            width: mode === "Mobile" ? "100%" : "100%",
          }}
        >
          <NotionTextarea
            fontColor="var(--grey-60)"
            editable={false}
            defaultValue={userInfo.introduction}
          />
        </div>
        <div className="education__carrer__container">
          {userInfo.careerAndEducation?.education?.map((item, index) => {
            return (
              <div className="education__carrer" key={`carrer-${index}`}>
                <span
                  className={`education__carrer__title ${
                    mode === "Mobile" ? "font-body3" : "font-body2"
                  }`}
                >
                  {item.schoolName}
                </span>
                <span
                  className={`education__carrer__position ${
                    mode === "Mobile" ? "font-button2" : "font-button1"
                  }`}
                >
                  {item.major}
                </span>
              </div>
            );
          })}
          {userInfo.careerAndEducation?.career?.map((item, index) => {
            return (
              <div className="education__carrer" key={`education-${index}`}>
                <span
                  className={`education__carrer__title ${
                    mode === "Mobile" ? "font-body3" : "font-body2"
                  }`}
                >
                  {item.company}
                </span>
                <span
                  className={`education__carrer__position ${
                    mode === "Mobile" ? "font-button2" : "font-button1"
                  }`}
                >
                  {item.position}
                </span>
              </div>
            );
          })}
        </div>
        {userInfo.hpPortfolio && userInfo.hpPortfolio.length > 0 && (
          <DetailInvests portfolioList={userInfo.hpPortfolio} />
        )}
        <StaffDetailFooter>
          <GoList to={"/team"} />
        </StaffDetailFooter>
      </BodyContainer>
      <Footer />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 80px;

  .sns {
    width: 32px;
    min-width: 32px;
    max-width: 32px;
    height: 32px;
    min-height: 32px;
    max-height: 32px;
  }
`;

const BodyContainer = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-top: 100px;

  .user__description {
    color: var(--grey-60);
  }

  .education__carrer__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    gap: 8px;

    .education__carrer {
      width: 100%;
      display: flex;
      flex-direction: row;
      .education__carrer__title {
        width: 100%;
        min-width: 240px;
        max-width: 240px;
      }
      .education__carrer__position {
        flex-grow: 1;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    margin-top: 40px;
    .education__carrer__container {
      gap: 12px;
      .education__carrer {
        flex-direction: column;
        gap: 0px;
      }
    }
  }
`;

const UserInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 56px;
  padding-bottom: 40px;
  border-bottom: 1px solid var(--grey-00);
  .thumbnail {
    width: 220px;
    min-width: 220px;
    max-width: 220px;
    height: 220px;
    min-height: 220px;
    max-height: 220px;
    border: 1px solid var(--grey-00);
  }
  .infomation__container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    .title__container {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 20px;
      margin-top: 40px;
      .divider {
        width: 1px;
        height: 20px;
        background: var(--grey-00);
      }
      .staff__name {
      }
      .staff__position {
      }
    }

    .user__info__container {
      display: flex;
      flex-direction: column;
      span {
        color: #636366;
      }
    }
    .chips__container {
      display: flex;
      flex-direction: row;
      gap: 8px;
      flex-wrap: wrap;
      margin-top: 16px;
    }
    .sns__container {
      display: flex;
      flex-direction: row;
      gap: 8px;
      margin-top: 32px;
      .sns__link {
        height: fit-content;
        background-color: white;
        border-radius: 50%;
        :hover {
          svg {
            opacity: 0.7;
          }
        }
      }
    }
    .container__row {
      display: flex;
      flex-direction: row;
    }
    .name__position {
      margin-top: 40px;
    }
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 16px;
    padding-bottom: 24px;

    .thumbnail {
      width: 167px;
      min-width: 167px;
      max-width: 167px;
      height: 167px;
      min-height: 167px;
      max-height: 167px;
    }

    .infomation__container {
      .title__container {
        flex-direction: column;
        align-items: center;
        margin-top: 0px;
        gap: 4px;
        .staff__position {
          padding-bottom: 0px;
        }
      }
      .user__info__container {
        margin-top: 16px;
      }
      .chips__container {
        gap: 6px;
        justify-content: center;
        margin-top: 24px;
      }
      .sns__container {
        justify-content: center;
        margin-top: 40px;
      }
    }
  }
`;

const StaffDetailFooter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  border-top: 1px solid var(--grey-10);
  margin-top: 40px;
  margin-bottom: 160px;
  padding-top: 16px;

  @media only screen and (max-width: 768px) {
    margin-top: 40px;
    margin-bottom: 24px;
  }
`;
export default StaffDetail;
