import styled from "@emotion/styled";
import { allStage, Stage } from "admin/type/type";
import Chip from "component/chips/Chip";
import React, { useEffect, useState } from "react";

interface Props {
  onChange?: (value: Stage[]) => void;
}
const StageList: React.FC<Props> = (props) => {
  const [isAllSelect, updateAllSelect] = useState<boolean>(true);
  const [stage, setStage] = useState<Stage[]>([]);
  const [selectedStage, updateSelectedStage] = useState<Stage[]>([]);

  const onClickAllSelect = () => {
    if (isAllSelect) {
      updateSelectedStage([]);
      if (props.onChange) props.onChange([]);
    } else {
      updateSelectedStage([...stage]);
      if (props.onChange) props.onChange([...stage]);
    }
    updateAllSelect(!isAllSelect);
  };

  const onSelectStage = (item: Stage) => {
    if (isAllSelect) {
      updateSelectedStage([item]);
      updateAllSelect(false);
      if (props.onChange) props.onChange([item]);
    } else {
      const index = selectedStage.findIndex((stage) => stage.id === item.id);
      if (index !== -1) {
        selectedStage.splice(index, 1);
        updateSelectedStage([...selectedStage]);
        updateAllSelect(selectedStage.length === stage.length);
        if (props.onChange) props.onChange([...selectedStage]);
      } else {
        const newSector = [...selectedStage, item];
        updateSelectedStage(newSector);
        updateAllSelect(newSector.length === stage.length);
        if (props.onChange) props.onChange(newSector);
      }
    }
  };

  const getAllStage = () => {
    setStage([...allStage]);
    updateSelectedStage([...allStage]);
    updateAllSelect(true);
    if (props.onChange) props.onChange([...allStage]);
  };
  useEffect(() => {
    getAllStage();
  }, []);

  return (
    <Container>
      <span className="sector__list__label font-sub-title4">STAGE</span>
      <div className="sector__list">
        <Chip
          isClickable
          isActive={isAllSelect}
          onClickChip={onClickAllSelect}
          chipType="green-color"
        >
          All
        </Chip>
        {stage.map((item) => {
          return (
            <Chip
              key={item.id}
              isClickable
              chipType="green-color"
              isActive={selectedStage
                .map((stage) => stage.id)
                .includes(item.id)}
              onClickChip={() => {
                onSelectStage(item);
              }}
            >
              {item.name}
            </Chip>
          );
        })}
      </div>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  .sector__list__label {
    min-width: 104px;
    max-width: 104px;
    color: var(--black);
  }

  .sector__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    .sector__list__label {
      min-width: 100%;
      max-width: 100%;
    }
    .sector__list {
      padding-top: 20px;
      padding-bottom: 20px;
      overflow: scroll;
    }
  }
`;

export default StageList;
