import React, { useEffect, useRef, useState } from "react";
import ModalLayout from "admin/component/modal/ModalLayout";
import axios from "axios";
import { Button } from "react-bootstrap";
import styled from "@emotion/styled";
import SearchBar from "admin/component/search";
import * as api from "../../../api/api";
import Navigation, { Pages } from "../../component/navigation";
import BannerCard from "./component/BannerCard";
import BannerDetail from "./component/BannerDetail";

export interface BannerProps {
  hpBannerId: number;
  openDate: string;
  closeDate: string;
  linkUrl: string;
  thumbNailImgKey: string;
  thumbNailImgUrl: string;
  isActive: boolean;
}

const BannerAdmin = () => {
  const selectedBanner = useRef<BannerProps | undefined>(undefined);
  const [isOpenBannerDetail, setOpenBannerDetail] = useState<boolean>(false);
  const [banners, updateBanners] = useState<BannerProps[]>([]);
  const [keyword, updateKeyword] = useState<string>("");

  const openBannerDetail = (banner?: BannerProps) => {
    selectedBanner.current = banner;
    setOpenBannerDetail(true);
  };

  const closeBannerDetail = () => {
    setOpenBannerDetail(false);
    getBanner();
  };

  const getBanner = async () => {
    try {
      const result = await axios.get(api.getBannerList());

      if (result.status === 200) {
        updateBanners(result.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const init = async () => {
    try {
      if (await api.checkingToken()) {
        await getBanner();
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    init();
  }, []);
  return (
    <Navigation activePageId={Pages[7].id}>
      <Container>
        <div className="banner__header">
          <SearchBar
            onChangeKeyword={(keyword) => {
              updateKeyword(keyword);
            }}
          />
          <Button
            variant="primary"
            onClick={() => {
              openBannerDetail(undefined);
            }}
          >
            배너 생성하기
          </Button>
        </div>
        <div className="banner__body">
          {banners.map((item) => (
            <BannerCard
              key={item.hpBannerId}
              item={item}
              onClickItem={() => openBannerDetail(item)}
              callback={() => closeBannerDetail()}
            />
          ))}
        </div>
      </Container>

      <ModalLayout
        isOpen={isOpenBannerDetail}
        borderRadius={0}
        toggle={() => setOpenBannerDetail(false)}
      >
        <BannerDetail
          item={selectedBanner.current}
          callback={() => closeBannerDetail()}
        />
      </ModalLayout>
    </Navigation>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;

  .banner__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    min-height: 60px;
    max-height: 60px;
    border-bottom: 1px solid grey;
    padding: 0 24px;
  }

  .banner__body {
    width: 100%;
    height: calc(100vh - 60px);
    display: flex;
    flex-direction: column;
    padding: 40px;
    gap: 30px;
    overflow: scroll;
  }
`;
export default BannerAdmin;
