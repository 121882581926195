import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { UserInfo } from "admin/type/type";
import StaffCard from "contents/staff/StaffCard";
import { Navigate, useNavigate } from "react-router-dom";
import DisplayType from "utils/displayType";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "animate.css/animate.min.css";

export interface TeamSectionProps {
  structureId?: number | string;
  name?: string;
  introduction?: string;
  userList: UserInfo[];
  structureChildren: TeamSectionProps[];
}

interface Props {
  item: TeamSectionProps;
}

const TeamSectionDepth1: React.FC<Props> = (props) => {
  const navigation = useNavigate();
  const mode = DisplayType();
  const [section, setSection] = useState<TeamSectionProps | undefined>(
    undefined
  );

  const getUserList = (structure: TeamSectionProps): UserInfo[] => {
    if (!structure.structureChildren) return [...structure.userList];
    if (structure.structureChildren.length === 0)
      return [...structure.userList];

    const a = structure.structureChildren.map((item) => {
      const _userList = [...item.userList];
      if (!item.structureChildren) return [..._userList];
      if (item.structureChildren.length === 0) return [..._userList];
      return [..._userList, ...getUserList(item)];
    });

    const sumUserList = [...structure.userList];
    for (let i = 0; i < a.length; i++) {
      a[i].forEach((item) => sumUserList.push(item));
    }
    return [...sumUserList];
  };

  useEffect(() => {
    setSection({
      ...props.item,
      userList: getUserList(props.item),
    });
  }, []);

  if (section === undefined) return null;

  return (
    // <AnimationOnScroll animateIn="animate__fadeIn" animateOnce>
    <Container>
      <div className="section__list">
        <Section>
          <div
            className={`team__section__title ${
              mode === "Mobile" ? "font-sub-title2" : "font-sub-title"
            }`}
          >
            {section.name}
          </div>
          <div
            className={`team__section__description ${
              mode === "Mobile" ? "font-body3" : "font-body2"
            }`}
          >
            {section.introduction}
          </div>
          <div className="team__list">
            {section.userList
              .filter((user) => user.profileImgUrl !== null)
              .map((user) => {
                return (
                  <StaffCard
                    key={user.hpUserId}
                    user={user}
                    onClick={() => {
                      navigation(`/staff/${user.hpUserId}`);
                    }}
                  />
                );
              })}
          </div>
        </Section>
      </div>
    </Container>
    // </AnimationOnScroll>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .team__section__title {
    color: var(--black);
    text-transform: uppercase;
  }
  .section__list {
    display: flex;
    flex-direction: column;
    gap: 60px;
    padding-top: 40px;
  }

  @media only screen and (max-width: 768px) {
    .section__list {
      padding-top: 24px;
      gap: 80px;
    }
  }
`;

const Section = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  .section__team__sub__title {
    color: var(--grey-60);
    text-transform: uppercase;
  }
  .team__section__description {
    color: var(--grey-60);
    margin-top: 8px;
  }
  .team__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px;
    margin-top: 24px;
  }

  @media only screen and (max-width: 768px) {
    .team__section__description {
      color: var(--grey-60);
      margin-top: 8px;
    }

    .team__list {
      column-gap: 3px;
      row-gap: 24px;
      margin-top: 24px;
    }
  }
`;

export default React.memo(TeamSectionDepth1);
