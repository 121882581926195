import React from "react";
import styled from "@emotion/styled";

import { Link } from "react-router-dom";
import DisplayType from "../../utils/displayType";
import { ReactComponent as Back } from "../../assets/go_back.svg";

interface Props {
  to: string;
  toText: string;
}

const GoTo: React.FC<Props> = (props) => {
  const mode = DisplayType();
  return (
    <Container>
      <Link className="font-button1 go__list__link" to={props.to}>
        <span>{props.toText}</span>
        <Back style={{ transform: "rotate(180deg)" }} />
      </Link>
    </Container>
  );
};
const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 20px;

  .go__list__link {
    display: flex;
    flex-direction: row;
    text-decoration: none;
    color: var(--primary);
    path {
      fill: var(--primary);
    }

    :hover {
      color: var(--black);
      path {
        fill: var(--black);
      }
    }
  }
`;
export default GoTo;
