import styled from "@emotion/styled";
import React from "react";

import { ReactComponent as Circle } from "../../../assets/main_mobile_circle.svg";

const BallBounceAnim = () => {
  return (
    <Container>
      <Circle />
      {/* <div className="ball" /> */}
      {/* <div className="shadow" /> */}
    </Container>
  );
};

const Container = styled.div`
  height: 200px;
  width: 78px;
  position: relative;
  .ball {
    position: absolute;
    width: 78px;
    height: 78px;
    background-color: var(--primary);
    border-radius: 50%;
    animation: bounce 0.5s cubic-bezier(0.5, 0, 1, 0.5) infinite alternate;
    z-index: 2;
  }
  .shadow {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    position: absolute;
    bottom: -40px;
    left: 0;
    z-index: 1;
    animation: shadows cubic-bezier(0.5, 0, 1, 0.5) infinite alternate;
  }

  @keyframes bounce {
    /* 100% {
      transform: translate3d(0, 100px, 0) scale3d(1.5, 0.2, 1);
    } */
    0% {
      top: 0px;
      transform: scale3d(1, 1);
    }
    80% {
      transform: scale(1, 1);
    }
    100% {
      top: 120px;
      transform: scale(1.2, 0.6);
    }
  }

  @keyframes shadows {
    0% {
      transform: rotateX(90deg) scale3d(0.5, 0.5, 1);
      background-color: rgba(0, 0, 0, 0.1);
    }
    100% {
      transform: rotateX(90deg) scale3d(0.8, 0.8, 1);
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
`;

export default BallBounceAnim;
