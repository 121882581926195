import React, { CSSProperties, useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import {
  AddComma,
  checkLanguageEn,
  checkLanguageKo,
  OnlyNum,
  RegexpBusiness,
  RegexpPhone,
  RemoveComma,
  RemoveHypen,
} from "../../../utils/commonUtil";

interface Props {
  label?: string;
  isRequired?: boolean;
  className?: string;
  hasUnderLine?: boolean;
  isOnlyKorean?: boolean;
  isOnlyEnglish?: boolean;
  defaultValue?: string | null;
  placeholder?: string;
  textType?:
    | "text"
    | "date"
    | "number"
    | "phone"
    | "businessNumber"
    | "numberWithComma";
  min?: string;
  max?: string;
  editable: boolean;
  tabIndex?: number;
  onChange?: (e: string) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

export const NotionInput: React.FC<Props> = (props) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [focus, setFocus] = useState<boolean>(false);
  const [value, setValue] = useState<string>(props.defaultValue || "");

  const [render, setRender] = useState<boolean>(true);
  const reRender = () => setRender((prevState) => !prevState);

  const getBorderStyle = (): CSSProperties => {
    if (props.hasUnderLine) {
      if (focus) {
        return { borderBottom: "1px solid transparent" };
      }
      return { borderBottom: "1px solid var(--grey-20)" };
    }
    return { borderBottom: "1px solid transparent" };
  };

  const onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setFocus(true);
    if (props.textType === "numberWithComma") {
      // if (inputRef.current) {
      // }
      setValue(RemoveComma(e.target.value || ""));
    } else if (props.textType === "text") {
      // if (inputRef.current) {
      // }
      setValue(e.target.value || "");
    } else if (props.textType === "date") {
      if (inputRef.current) {
        inputRef.current.type = "date";
      }
      setValue(e.target.value || "");
    } else if (props.textType === "businessNumber") {
      if (inputRef.current) {
        inputRef.current.maxLength = 10;
      }
      setValue(RemoveHypen(e.target.value || ""));
    } else if (props.textType === "phone") {
      if (inputRef.current) {
        inputRef.current.maxLength = 11;
      }
      setValue(RemoveHypen(e.target.value || ""));
    }
  };
  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setFocus(false);
    if (props.textType === "numberWithComma") {
      // if (inputRef.current) {
      // }
      setValue(AddComma(e.target.value || ""));
    } else if (props.textType === "text") {
      // if (inputRef.current) {
      // }
      setValue(e.target.value || "");
    } else if (props.textType === "date") {
      if (inputRef.current) {
        inputRef.current.type = "text";
      }
      setValue(e.target.value || "");
    } else if (props.textType === "businessNumber") {
      if (inputRef.current) {
        inputRef.current.maxLength = 12;
      }
      setValue(RegexpBusiness(e.target.value || ""));
    } else if (props.textType === "phone") {
      if (inputRef.current) {
        inputRef.current.maxLength = 13;
      }
      setValue(RegexpPhone(e.target.value || ""));
    }

    if (props.onBlur) props.onBlur(e);
  };

  const numberWithComma = (number: string): string => {
    const min = props.min && parseInt(props.min, 10);
    const max = props.max && parseInt(props.max, 10);
    const num = parseInt(number, 10);

    if (min !== undefined && max !== undefined) {
      if (num < min || num > max) {
        return number.slice(0, -1);
      }
    }

    if (min !== undefined) {
      if (num < min) {
        return number.slice(0, -1);
      }
    }

    if (max !== undefined) {
      if (num > max) {
        return number.slice(0, -1);
      }
    }

    if (value.length > 1 && value[0] === "0") {
      return number.slice(1);
    }

    return number;
  };

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    if (
      props.textType === "businessNumber" ||
      props.textType === "numberWithComma" ||
      props.textType === "number"
    ) {
      value = OnlyNum(value);
    }

    if (props.textType === "numberWithComma" || props.textType === "number") {
      value = numberWithComma(value);
    }
    // else if (props.textType === "phone") {
    // }

    if (props.textType === "text") {
      if (props.isOnlyKorean) {
        if (checkLanguageKo(value)) {
          value = value.substring(0, value.length - 1);
        }
      } else if (props.isOnlyEnglish) {
        if (checkLanguageEn(value)) {
          value = value.substring(0, value.length - 1);
        }
      }
    }

    setValue(value);
    if (props.onChange) props.onChange(value);
    reRender();
  };

  useEffect(() => {
    if (focus) return;

    if (props.textType === "numberWithComma") {
      setValue(AddComma(props.defaultValue || ""));
    } else if (props.textType === "text") {
      setValue(props.defaultValue || "");
    } else if (props.textType === "number") {
      setValue(props.defaultValue || "");
    } else if (props.textType === "date") {
      setValue(props.defaultValue || "");
    } else if (props.textType === "businessNumber") {
      setValue(RegexpBusiness(props.defaultValue || ""));
    } else if (props.textType === "phone") {
      setValue(RegexpPhone(props.defaultValue || ""));
    } else {
      setValue(props.defaultValue || "");
    }
  }, [props.defaultValue]);

  useEffect(() => {
    reRender();
  }, [props.min, props.max]);
  return (
    <Container className={props.className || ""}>
      {props.label && (
        <div className="font-bold-14">
          <span>{props.label}</span>
          {props.isRequired && (
            <span
              className="font-regular-14"
              style={{ color: "var(--red)", marginLeft: 8 }}
            >
              *
            </span>
          )}
        </div>
      )}
      <TextContainer
        className="font-bold-14"
        editable={props.editable}
        style={getBorderStyle()}
      >
        <input
          ref={inputRef}
          className="font-regular-14"
          autoComplete="off"
          type="text"
          value={value}
          disabled={!props.editable}
          min={props.min}
          max={props.max}
          placeholder={props.placeholder}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={(e) => onChangeInput(e)}
          onKeyPress={(e) => {
            if (e.key === "Enter" && inputRef.current) {
              inputRef.current.blur();
            }
          }}
        />
      </TextContainer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 4px;
`;

const TextContainer = styled.div<{ editable: boolean }>`
  position: relative;
  width: 100%;

  input {
    width: 100%;
    padding: 5px 10px;
    border-radius: 4px;
    background: white;
    border: 1px solid transparent;

    :hover {
      ${(props) => props.editable && "background-color: var(--grey-10)"};
    }

    :focus {
      outline: none;
      border: 1px solid var(--grey-20);
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
      :hover {
        ${(props) => props.editable && "background-color: var(--white)"};
      }
    }

    ::placeholder {
      color: var(--grey-20);
    }
  }

  input[type="date"] {
    ::placeholder {
      color: var(--grey-20);
    }
    :invalid {
    }
  }
`;
