import styled from "@emotion/styled";

export const LoginContainer = styled.div`
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    clip-path: polygon(60% 0, 100% 0, 100% 100%, 40% 100%);
    background: var(--primary);

    @media screen and (max-width: 768px) {
      clip-path: polygon(0 60%, 100% 40%, 100% 100%, 0 100%);
    }
  }
`;

export const LoginBox = styled.div`
  width: 90%;
  height: 70%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  background: transparent;
  z-index: 10;
  display: flex;
  flex-direction: row;

  .left__container {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .logo {
    width: 300px;
    height: 150px;
  }

  .right__container {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .login__btn {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;
