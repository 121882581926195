import styled from "@emotion/styled";

export const SearchBarLayout = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 300px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid grey;
  padding-right: 6px;

  overflow: hidden;

  input {
    flex-grow: 1;
    height: 100%;
    padding: 8px 10px;
    border: 0;
  }
  .search__icon {
    width: 24px;
    min-width: 24px;
    max-width: 24px;

    height: 24px;
    min-height: 24px;
    max-height: 24px;
  }
`;
