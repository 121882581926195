import React from "react";
import Styled from "@emotion/styled";
import { DragLayerMonitorProps } from "@minoru/react-dnd-treeview";

import { ReactComponent as ArrowRightIcon } from "../../../../assets/arrow_right.svg";

type Props = {
  monitorProps: DragLayerMonitorProps<any>;
  isStructure?: boolean;
};

export const StructureDragPreview: React.FC<Props> = (props) => {
  const { item } = props.monitorProps;
  const { name } = props.monitorProps.item.data;

  return (
    <Container className="tree-node root">
      {item.droppable && (
        <div className="expandIconWrapper">
          <ArrowRightIcon />
        </div>
      )}

      <div className="labelGridItem">{name}</div>
    </Container>
  );
};

const Container = Styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 200px;
  height: 36px;

  border-radius: 10px;
  padding: 0 12px;

  border: 1px solid var(--grey_20);
  margin-bottom: 20px;

  .expandIconWrapper {
    cursor: pointer;
    height: 24px;
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform linear 0.1s;
    transform: rotate(0deg);
  }

  .expandIconWrapper.isOpen {
    transform: rotate(90deg);
  }

  .labelGridItem {
    flex-grow: 1;
    padding-inline-start: 8px;

    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: bold;
    font-size: var(--large);
    letter-spacing: 0.1px;

    color: var(--black1);
  }
`;
