import React, { useEffect, useState } from "react";
import Styled from "@emotion/styled";

import { UserInfo } from "admin/type/type";
import { ReactComponent as DefaultUserImg } from "../../../../assets/default_user_img.svg";
import { ReactComponent as Delete } from "../../../../assets/cancel_red.svg";
import { ReactComponent as Restore } from "../../../../assets/ic_update.svg";
// import { ReactComponent as Warnning } from "../../../../../../assets/image/ic_warning.svg";

interface Props {
  userInfo: UserInfo;
  structureName?: string;
  onClick?: () => void;
  onClickDelete?: (userInfo: UserInfo) => void;
  onClickRestore?: (userInfo: UserInfo) => void;
}

const UserCard: React.FC<Props> = (props) => {
  useEffect(() => {}, []);

  return (
    <Container onClick={props.onClick}>
      <ProfileImageContainer>
        {props.userInfo.profileImgUrl ? (
          <img
            className="profile__img"
            alt="profile"
            src={props.userInfo.profileImgUrl}
          />
        ) : (
          <DefaultUserImg className="profile__img" />
        )}
      </ProfileImageContainer>
      <div className="font-medium-20 name">{props.userInfo.name}</div>
      <div className="position">{props.userInfo.hpPosition?.name}</div>
      <span>{props.structureName}</span>
      {props.userInfo.isNew && (
        <Overlay>
          <div className="warnning font-body4">NEW</div>
        </Overlay>
      )}
      {props.userInfo.isDelete ? (
        <Restore
          className="restore-icon"
          onClick={(event) => {
            event.stopPropagation();
            if (props.onClickRestore) props.onClickRestore(props.userInfo);
          }}
        />
      ) : (
        <Delete
          className="delete-icon"
          onClick={(event) => {
            event.stopPropagation();
            if (props.onClickDelete) props.onClickDelete(props.userInfo);
          }}
        />
      )}
    </Container>
  );
};

const Container = Styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 216px;
    height: 240px;

    background: #ffffff;
    border: 1px solid transparent;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
    border-radius: 10px;

    transition: 0.1s;

    cursor: pointer;

    .name {
      margin-top: 8px;
    }
    .position {
      margin-top: 4px;
    }
    :hover {
      border: 1px solid var(--primary);
      .delete-icon {
        visibility: visible;
        line {
          stroke: var(--grey-30);
        }
        :hover {
          line {
            stroke: var(--red);
          }
        }
      }
      .restore-icon {
        visibility: visible;
        path {
          fill: var(--grey-30);
        }
        :hover {
          path{
            fill: var(--primary);
          }
        }
      }
    }
    .delete-icon, .restore-icon {
      position: absolute;
      top: 10px;
      right: 10px;
      visibility: hidden;
    }
  `;
const Overlay = Styled.div`
    position: absolute;
    padding: 5px 16px;
    left: 10px;
    top: 10px;
    border-radius: 20px;
    background-color: red;
    .warnning {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: white;
    }
  `;
const ProfileImageContainer = Styled.div`
    width: 120px;
    height: 120px;
    background-color: var(--grey_10);
    border-radius: 50%;
    margin-top: 16px;

    display: flex;
    align-items: center;
    justify-content: center;
    .profile__img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  `;

export default React.memo(UserCard);
