import React from "react";
import { UserInfo } from "admin/type/type";
import styled from "@emotion/styled";
import DisplayType from "utils/displayType";

interface Props {
  user: UserInfo;
  onClick: () => void;
}
const StaffCard: React.FC<Props> = (props) => {
  const mode = DisplayType();
  return (
    <Container>
      <div className="thumbnail__container" onClick={props.onClick}>
        <img
          className="thumbnail"
          src={props.user.profileImgUrl}
          alt="profile"
        />
        <div className="overlay__gradient">
          {props.user.keyword
            ?.sort((a, b) => {
              if (a.length > b.length) return 1;
              if (a.length < b.length) return -1;
              return 0;
            })
            .map((item, index) => {
              return (
                <div
                  key={index}
                  className={`user__keyword ${
                    mode === "Mobile" ? "font-bold-12" : "font-sub-title4"
                  }`}
                >
                  {item}
                </div>
              );
            })}
        </div>
      </div>
      <span
        className={`${mode === "Mobile" ? "font-bold-12" : "font-sub-title4"}`}
        style={{ color: "var(--grey-60)" }}
      >
        {props.user.name}
      </span>
    </Container>
  );
};
const Container = styled.div`
  width: calc(1176px / 5 - 24px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  .thumbnail__container {
    position: relative;
    width: calc(1176px / 5 - 24px);
    min-width: calc(1176px / 5 - 24px);
    max-width: calc(1176px / 5 - 24px);

    height: calc(1176px / 5 - 24px);
    min-height: calc(1176px / 5 - 24px);
    max-height: calc(1176px / 5 - 24px);

    border: 1px solid var(--grey-00);
    transition: all 0.3s ease;
    overflow: hidden;
    .thumbnail {
      width: 100%;
      height: 100%;
      object-fit: contain;
      transition: all 0.3s ease;
    }

    .overlay__gradient {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;
      padding: 0 10px 10px 0;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      background: rgba(0, 0, 0, 0.3);
      transition: all 0.3s ease;
      z-index: 2;
      .user__keyword {
        color: var(--white);

        text-align: end;
        animation-duration: 0.3s;
        animation-fill-mode: both;
        line-height: 1.2;
      }
    }
  }
  :hover {
    .thumbnail__container {
      .overlay__gradient {
        opacity: 1;
        .user__keyword {
          animation-name: fadeInBottom;
        }
      }
    }
    .thumbnail {
      transform: scale(1.05);
    }
  }

  @media only screen and (max-width: 768px) {
    width: 107px;
    .thumbnail__container {
      width: 107px;
      min-width: 107px;
      max-width: 107px;

      height: 107px;
      min-height: 107px;
      max-height: 107px;

      border: 1px solid var(--grey-00);
      .thumbnail {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  @keyframes fadeInBottom {
    from {
      opacity: 0;
      transform: translateX(100%);
    }
    to {
      opacity: 1;
    }
  }
`;
export default React.memo(StaffCard);
