import React from "react";
import styled from "@emotion/styled";
import DisplayType from "utils/displayType";
import { Link } from "react-router-dom";
import { ContactMenu, InsightMenu } from "admin/type/type";

interface Props {
  selectedTab: ContactMenu;
}
const ContactTabs: React.FC<Props> = (props) => {
  const mode = DisplayType();
  return (
    <Container>
      <TabContainer className="limit__body">
        <Link to="/contact/apply" style={{ textDecoration: "none" }}>
          <TabItemContainer
            className={
              mode === "Mobile" ? "font-button1-en" : "font-button1-en"
            }
            isSelected={props.selectedTab === ContactMenu.APPLY}
          >
            Apply
          </TabItemContainer>
        </Link>
        <Link to="/contact/partnership" style={{ textDecoration: "none" }}>
          <TabItemContainer
            className={
              mode === "Mobile" ? "font-button1-en" : "font-button1-en"
            }
            isSelected={props.selectedTab === ContactMenu.PARTNERSHIP}
          >
            Partnership
          </TabItemContainer>
        </Link>
        <Link to="/contact/faq" style={{ textDecoration: "none" }}>
          <TabItemContainer
            className={
              mode === "Mobile" ? "font-button1-en" : "font-button1-en"
            }
            isSelected={props.selectedTab === ContactMenu.FAQ}
          >
            FAQ
          </TabItemContainer>
        </Link>
      </TabContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 64px;
  background-color: var(--bg-02);
`;

const TabContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;

  @media only screen and (max-width: 768px) {
    flex-wrap: nowrap;
    overflow: scroll;
    padding: 0;

    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

const TabItemContainer = styled.div<{ isSelected?: boolean }>`
  width: fit-content;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 32px;

  cursor: pointer;
  white-space: nowrap;
  color: ${(props) => (props.isSelected ? "var(--primary)" : "var(--grey-60)")};
  border-bottom: 2px solid
    ${(props) => (props.isSelected ? "var(--primary)" : "transparent")};

  @media only screen and (max-width: 768px) {
    padding: 0 16px;
    gap: auto;
  }
`;

export default React.memo(ContactTabs);
