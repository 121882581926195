import styled from "@emotion/styled";
import Banner from "component/banner/Banner";
import DisplayType from "utils/displayType";
import DefaultButton from "admin/component/button/DefaultButton";
import { useLayoutEffect } from "react";
import { updateTitle } from "utils/commonUtil";
import Footer from "../../component/footer/Footer";
import aboutContents from "../../assets/about_img.png";
import { ReactComponent as Logo } from "../../assets/new_logo.svg";
import aboutBanner from "../../assets/about_1440.jpg";
import aboutBanner1920 from "../../assets/about_1920.jpg";
import aboutBanner2048 from "../../assets/about_2048.jpg";

const AboutBluePoint = () => {
  const mode = DisplayType();

  useLayoutEffect(() => {
    updateTitle("회사소개");
  }, []);

  return (
    <Container>
      <Banner
        bannerImg={aboutBanner}
        bannerImg1920={aboutBanner1920}
        bannerImg2048={aboutBanner2048}
        mode={mode}
        imgPostiion={"right"}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: mode === "Mobile" ? "center" : "flex-start",
          }}
        >
          <div
            className={`${
              mode === "Mobile" ? "font-title-en" : "font-title-en"
            }`}
            style={{ color: "var(--white)", textTransform: "uppercase" }}
          >
            About
          </div>
          <div
            className={mode === "Mobile" ? "font-body3" : "font-body1"}
            style={{
              color: "var(--white)",
              marginTop: 24,
              textAlign: mode === "Mobile" ? "center" : "start",
            }}
          >
            시작하는 사람들을 위한
            <br />
            모든 혁신의 시작점, 블루포인트
          </div>
        </div>
      </Banner>
      <AboutContainer className="limit__body">
        <AboutSection>
          <div
            className={`about__section__title ${
              mode === "Mobile" ? "font-sub-title2" : "font-sub-title"
            }`}
          >
            What We Do
          </div>
          <div
            className={`about__section__sub__title ${
              mode === "Mobile" ? "font-sub-title3" : "font-sub-title"
            } `}
          >
            초기 스타트업을 발굴하고 투자합니다.
          </div>
          <p className={`${mode === "Mobile" ? "font-body3" : "font-body2"}`}>
            좋은 혁신가와 초기 스타트업을 발굴하고 그들의 가치를 알아보는 것은
            생각보다 어려운 일입니다.
            {mode !== "Mobile" && <br />} 창업 초기엔 참고할 수 있는 가시적인
            성과 지표가 많지 않기 때문입니다.{mode === "Mobile" && <br />}
            {mode === "Mobile" && <br />}
            {mode !== "Mobile" && <br />} 따라서 초기 투자는 스타트업의 잠재력과
            진가를 얼마나 잘 예측하는지가 중요하므로 투자자의 전문성과 충분한
            경험을 필요로 합니다.
          </p>
          <p className={`${mode === "Mobile" ? "font-body3" : "font-body2"}`}>
            블루포인트는 기술창업 경험자들을 비롯해 산업계, 연구계 출신
            구성원들의 노하우와 네트워크 그리고 자체 구축한 발굴 시스템으로
            잠재력 있는 혁신가들을 만나고 있습니다.
            <br />
            {mode === "Mobile" && <br />} 예비창업부터 Seed, Pre-Series A,
            Series A 단계에 이르기까지 폭넓은 초기 투자를 지향하면서 스타트업과
            시작을 함께 하고 있습니다.
          </p>
        </AboutSection>
        <AboutSection>
          <div
            className={`about__section__title ${
              mode === "Mobile" ? "font-sub-title2" : "font-sub-title"
            }`}
          >
            Value Add
          </div>
          <div
            className={`about__section__sub__title ${
              mode === "Mobile" ? "font-sub-title3" : "font-sub-title"
            } `}
          >
            초기 스타트업의 성장을 돕습니다.
          </div>

          <p className={`${mode === "Mobile" ? "font-body3" : "font-body2"}`}>
            창업 초기는 가장 도움이 필요한 시기이지만, 아이러니하게 가장
            도움받기 어려운 때이기도 합니다.
            {mode !== "Mobile" && <br />} 하지만 이 시기에 사업을 크게 키울 수
            있는 역량이 형성되기 때문에 성장이란 여정의 방향키를 함께 잡아줄
            좋은 파트너를 만나 시작하는 것이 중요합니다.
            {mode === "Mobile" && <br />}
            {mode === "Mobile" && <br />}
            {mode !== "Mobile" && <br />} 블루포인트는 투자금에 더하여
            스타트업이 보다 대담한 시도를 할 수 있도록 다양한 분야에서 지원하고
            있습니다. {mode !== "Mobile" && <br />}
            {mode !== "Mobile" && <br />}
            스타트업은 연속된 선택과 결정의 산물이라 해도 과언이 아닙니다.{" "}
            {mode !== "Mobile" && <br />}
            결정과 행동의 주체는 창업가들이지만 블루포인트는 그 과정을 함께
            고민하고 최선의 선택지를 같이 찾아가며 불확실성을 줄여갑니다.{" "}
            {mode === "Mobile" && <br />}
            {mode === "Mobile" && <br />}
            {mode !== "Mobile" && <br />}
            블루포인트 패밀리사의 성장을 위해 네트워크 연결, 후속투자유치 지원,
            홍보 및 채용 지원에 이르기까지 다양한 방식으로 스타트업을 도우며{" "}
            {mode !== "Mobile" && <br />}
            그들의 다음 단계로의 도약을 이끌어냅니다.
          </p>

          {/* <BubbleMesesageContainer>
            <BubbleMessage
              className={`bubble__1 ${
                mode === "Mobile" ? "font-small-text" : "font-body3"
              }`}
            >
              내 기술과 시장성을 검증받고 싶은데 누가 해줄 수 있지?
            </BubbleMessage>
            <BubbleMessage
              className={`bubble__2 ${
                mode === "Mobile" ? "font-small-text" : "font-body3"
              }`}
            >
              CFO와 디자이너가 필요한데 안 구해져. 팀원을 어떻게 구하지?
            </BubbleMessage>
            <BubbleMessage
              className={`bubble__3 ${
                mode === "Mobile" ? "font-small-text" : "font-body3"
              }`}
            >
              후속투자 유치를 받아야 하는데 좋은 투자사를 소개받고 싶어!
            </BubbleMessage>
          </BubbleMesesageContainer> */}
        </AboutSection>
        <AboutSection>
          <a
            href="https://bluebox-ac.s3.ap-northeast-2.amazonaws.com/bbox/ci/Bluepoint_brandbook.pdf"
            target={"_blank"}
            rel="noreferrer"
          >
            <DefaultButton className="font-body3" type="outline" height={42}>
              회사소개서 다운로드
            </DefaultButton>
          </a>
        </AboutSection>
      </AboutContainer>
      <AboutImageContainer imgUrl={aboutContents}>
        <div className="overlay" />
        <div className="limit__body contents__container">
          <div
            className={`${
              mode === "Mobile" ? "font-sub-title2-en" : "font-title"
            }`}
            style={{ textTransform: "uppercase" }}
          >
            Philosophy
          </div>
          <div
            className={`${
              mode === "Mobile" ? "font-sub-title4" : "font-bold-28"
            }`}
            style={{ marginTop: mode === "Mobile" ? 32 : 40 }}
          >
            지금 여기, 기술 너머의 의미를 상상하고,
            <br />
            실패의 경험도 큰 성장이라 말하며,
            <br />
            시작을 위한 조언을 아끼지 않는{mode === "Mobile" && <br />} 사람들이
            있습니다.
          </div>
          <div style={{ marginTop: mode === "Mobile" ? 16 : 24 }}>
            <p
              className={`${mode === "Mobile" ? "font-body4" : "font-body2"}`}
              style={{ margin: 0 }}
            >
              블루포인트는 가능성 있는 혁신가들이
              <br />
              자신의 가치를 알아보는 파트너를 만날 때,
              <br />
              함께 모여 서로 배우며 즐겁게 성장할 때
              <br /> 더 큰 변화를 만들 수 있다고 믿습니다.
              <br />
              <br /> 세상을 바꿀 혁신가들의 순수한 이념이 존중받는 사회,
              <br /> 최초의 시도(First-in-Class)가 활발하게 이루어지는 사회를
              꿈꿉니다.
              <br />
              <br /> 모든 혁신의 시작점, 블루포인트
              <br /> Starting point for Starters
            </p>
          </div>
        </div>
      </AboutImageContainer>
      <AboutContainer className="limit__body">
        <AboutSection>
          <div
            className={`about__section__title ${
              mode === "Mobile" ? "font-sub-title2" : "font-sub-title"
            }`}
          >
            Corporate Identity
            <CIDownload>
              <div className="ci__thumbnail__container">
                <Logo width={180} height={90} />
              </div>
              <div className="ci__description__container">
                <div
                  className={`${
                    mode === "Mobile" ? "font-body3" : "font-body2"
                  }`}
                  style={{ color: "var(--grey-60)" }}
                >
                  파란점에서 출발한 블루포인트의 로고는 누구나 시도하고 실험할
                  수 있는 시작점(Starting Point)과 기존에 없던 새로운 룰을
                  만드는 혁신적 관점(Stand Point)을 상징합니다.
                  <br />
                  <div style={{ marginBottom: 7 }} />
                  어디로 튈지 모르는 둥근 공의 역동성과 하나의 중심에서 모든
                  방향으로 뻗어나가는 확장성은 유쾌한 상상력으로 세상을 바꾸는
                  혁신가들을 의미합니다.
                </div>
                <a
                  href="https://bluebox-ac.s3.ap-northeast-2.amazonaws.com/bbox/ci/Bluepoint+BI_RGB.ai"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <DefaultButton
                    className="font-body3"
                    type="outline"
                    height={42}
                  >
                    CI 다운로드
                  </DefaultButton>
                </a>
              </div>
            </CIDownload>
          </div>
        </AboutSection>
      </AboutContainer>
      <Footer />
    </Container>
  );
};
const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 768px) {
  }
`;

const AboutContainer = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const AboutSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  margin-top: 100px;

  .about__section__title {
    color: var(--black);
    text-transform: uppercase;
  }

  .about__section__sub__title {
    color: var(--primary);
    margin-top: 40px;
    margin-bottom: 16px;
  }

  p {
    color: var(--grey-60);
  }

  @media only screen and (max-width: 768px) {
    margin-top: 40px;
    .about__section__sub__title {
      margin-top: 24px;
    }
  }
`;

const BubbleMesesageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 270px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .bubble__1 {
    position: absolute;
    top: 24px;
    left: 0;
    :after {
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      bottom: -10px;
      left: 37px;
      border: 20px solid transparent;
      border-top-width: 0;
      border-bottom-color: var(--secondary-3);
      transform: rotate(-45deg);
    }
  }
  .bubble__2 {
    position: absolute;
    top: 83px;
    right: 36px;
    :after {
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      bottom: -10px;
      right: 37px;
      border: 20px solid transparent;
      border-top-width: 0;
      border-bottom-color: var(--secondary-3);
      transform: rotate(-45deg);
    }
  }
  .bubble__3 {
    position: absolute;
    top: 148px;
    left: 150px;
    :after {
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      bottom: -10px;
      right: 37px;
      border: 20px solid transparent;
      border-top-width: 0;
      border-bottom-color: var(--secondary-3);
      transform: rotate(-45deg);
    }
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
    height: fit-content;
    gap: 24px;
    margin-bottom: 24px;
    .bubble__1 {
      position: relative;
      top: 0;
      left: 0;
      :after {
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        bottom: -10px;
        left: 37px;
        border: 12px solid transparent;
        border-top-width: 0;
        border-bottom-color: var(--secondary-3);
        transform: rotate(-45deg);
      }
    }
    .bubble__2 {
      position: relative;
      top: 0;
      left: 0;
      :after {
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        bottom: -10px;
        right: 37px;
        border: 12px solid transparent;
        border-top-width: 0;
        border-bottom-color: var(--secondary-3);
        transform: rotate(-45deg);
      }
    }
    .bubble__3 {
      position: relative;
      top: 0;
      left: 0;

      :after {
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        bottom: -10px;
        left: 37px;
        border: 12px solid transparent;
        border-top-width: 0;
        border-bottom-color: var(--secondary-3);
        transform: rotate(-45deg);
      }
    }
  }
`;

const BubbleMessage = styled.div`
  padding: 24px 40px;
  border-radius: 20px;
  background-color: var(--secondary-3);
  color: var(--primary);

  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 20px 16px;
    border-radius: 16px;
    text-align: center;
  }
`;

const AboutImageContainer = styled.div<{ imgUrl: string }>`
  position: relative;
  width: 100%;
  height: 800px;
  background-image: url(${(props) => props.imgUrl});
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.3);
  }

  .contents__container {
    display: flex;
    flex-direction: column;
    color: white;
    z-index: 2;
  }
  @media only screen and (max-width: 768px) {
    margin-top: 80px;
    height: fit-content;
    padding-bottom: 20px;
    .contents__container {
      padding-top: 40px;
    }
  }
`;

const CIDownload = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 24px;
  margin-top: 40px;
  margin-bottom: 100px;

  .ci__thumbnail__container {
    width: 352px;
    min-width: 352px;
    max-width: 352px;

    height: 186px;
    min-height: 186px;
    max-height: 186px;

    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--grey-00);
  }

  .ci__description__container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 24px;
    margin-bottom: 100px;

    .ci__thumbnail__container {
      width: 100%;
      min-width: 100%;
      max-width: 100%;

      height: 186px;
      min-height: 186px;
      max-height: 186px;

      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid var(--grey-00);
    }

    .ci__description__container {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
  }
`;
export default AboutBluePoint;
