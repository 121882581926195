import React from "react";
import styled from "@emotion/styled";
import { PortfolioProps } from "admin/type/type";
import PortfolioCard from "../component/PortfolioCard";

interface Props {
  portfolios: PortfolioProps[];
  openPortfolioDetail: (portfolioIds: number[]) => void;
  onPortfolioChangeActive: (portfolioId: number, active: boolean) => void;
}
const PortfolioList: React.FC<Props> = (props) => {
  return (
    <PortfolioListLayout>
      {props.portfolios.map((item) => {
        return (
          <PortfolioCard
            key={item.portfolioId}
            portfolio={item}
            onClick={() => {
              props.openPortfolioDetail([item.portfolioId]);
            }}
            onPortfolioChangeActive={props.onPortfolioChangeActive}
          />
        );
      })}
    </PortfolioListLayout>
  );
};

const PortfolioListLayout = styled.ul`
  width: 100%;
  max-width: calc((376px * 3) + (24px * 2) + 100px);
  padding: 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
  margin: 0 auto;

  transition: background-color 0.2s ease;
  overflow: auto;
`;

export default React.memo(PortfolioList);
