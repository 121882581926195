import styled from "@emotion/styled";
import React, { useRef } from "react";

interface Props {
  bannerImg?: string;
  aspectRatio?: string;
}

interface StyledProps {
  bannerImg?: string;
  widthRatio: number;
  heightRatio: number;
}
const Carousel: React.FC<Props> = (props) => {
  const { bannerImg = "", aspectRatio = "1440/480" } = props;
  const width = useRef<number>(parseInt(aspectRatio.split("/")[0], 10));
  const hieght = useRef<number>(parseInt(aspectRatio.split("/")[1], 10));

  return (
    <BannerContainer
      bannerImg={bannerImg}
      widthRatio={width.current}
      heightRatio={hieght.current}
    >
      <Overley />
    </BannerContainer>
  );
};

const BannerContainer = styled.div<StyledProps>`
  position: relative;
  width: 100%;
  height: 480px;
  /* height: ${(props) =>
    `calc(100vw * (${props.heightRatio} / ${props.widthRatio}))`}; */
  background: url(${(prpos) => prpos.bannerImg});
  background-size: cover;
  background-position: center;
  background-color: var(--grey-00);
  @media only screen and (max-width: 768px) {
    width: 100%;
    height: 344px;
    aspect-ratio: none;
  }
`;
const Overley = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
`;

export default Carousel;
