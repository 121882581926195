import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import DisplayType from "utils/displayType";

import { ReactComponent as CheckOn } from "../../../assets/checkbox_on.svg";
import { ReactComponent as CheckOff } from "../../../assets/checkbox_off.svg";
import ContactInput from "./ContactInput";

interface Props {
  className?: string;
  label?: string;
  isError?: boolean;
  errorMessage?: string;
  checkedItem: number[];
  updateCheckedItem: React.Dispatch<React.SetStateAction<number[]>>;
  extraMsg: string;
  updateExtraMsg: React.Dispatch<React.SetStateAction<string>>;
  onChangeValue?: () => void;
}
const ContactChecked: React.FC<Props> = (props) => {
  const mode = DisplayType();

  const onClickItem = (id: number) => {
    const findIndex = props.checkedItem.findIndex((item) => item === id);
    if (findIndex !== -1) {
      props.checkedItem.splice(findIndex, 1);
      props.updateCheckedItem([...props.checkedItem]);
    } else {
      props.updateCheckedItem((prev) => [...prev, id]);
    }

    if (props.onChangeValue) props.onChangeValue();
  };

  return (
    <Container className={props.className}>
      {props.label && (
        <div
          className={`${
            mode === "Mobile" ? "font-sub-title4" : "font-sub-title4"
          }`}
          style={{ color: "var(--grey-50)" }}
        >
          {props.label}
        </div>
      )}
      <CheckList>
        <CheckItem onClick={() => onClickItem(1)}>
          {props.checkedItem.includes(1) ? (
            <CheckOn className="check__icon" />
          ) : (
            <CheckOff className="check__icon" />
          )}
          <span className="font-sub-title4 check__title">지인소개</span>
        </CheckItem>
        <CheckItem onClick={() => onClickItem(2)}>
          {props.checkedItem.includes(2) ? (
            <CheckOn className="check__icon" />
          ) : (
            <CheckOff className="check__icon" />
          )}
          <span className="font-sub-title4 check__title">언론보도</span>
        </CheckItem>
        <CheckItem onClick={() => onClickItem(3)}>
          {props.checkedItem.includes(3) ? (
            <CheckOn className="check__icon" />
          ) : (
            <CheckOff className="check__icon" />
          )}
          <span className="font-sub-title4 check__title">SNS</span>
          <span className="font-body3" style={{ color: "var(--grey-30)" }}>
            인스타그램, 페이스북, 브런치, 블로그, 유튜브 등
          </span>
        </CheckItem>
        <CheckItem onClick={() => onClickItem(4)}>
          {props.checkedItem.includes(4) ? (
            <CheckOn className="check__icon" />
          ) : (
            <CheckOff className="check__icon" />
          )}
          <span className="font-sub-title4 check__title">인터넷 검색</span>
        </CheckItem>
        <CheckItem onClick={() => onClickItem(5)}>
          {props.checkedItem.includes(5) ? (
            <CheckOn className="check__icon" />
          ) : (
            <CheckOff className="check__icon" />
          )}
          <span className="font-sub-title4 check__title">기타</span>
        </CheckItem>
        {props.checkedItem.includes(5) && (
          <div style={{ width: 400, marginLeft: 29 }}>
            <ContactInput
              inputType="text"
              placeHolder="내용을 입력해주세요."
              onChangeValue={(value) => props.updateExtraMsg(value)}
            />
          </div>
        )}
      </CheckList>

      {props.isError && (
        <div
          className={`error__msg ${
            mode === "Mobile" ? "font-body4" : "font-body4"
          }`}
        >
          알게된 경로를 선택해주세요.
        </div>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .error__msg {
    position: absolute;
    left: 10px;
    bottom: -24px;
    color: #e02020;
    :focus {
      outline: none;
    }
  }

  @media only screen and (max-width: 768px) {
    textarea {
      height: 320px;
      min-height: 320px;
      max-height: 320px;
    }
  }
`;

const CheckList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
const CheckItem = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  .check__icon {
    width: 21px;
    min-width: 21px;
    max-width: 21px;
    height: 21px;
    min-height: 21px;
    max-height: 21px;
  }
  .check__title {
    color: var(--grey-50);
  }
`;

export default React.memo(ContactChecked);
