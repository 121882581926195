import React, { CSSProperties, useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";

import { SelectData } from "admin/type/type";
import { ReactComponent as Arrow } from "../../../assets/select_arrow.svg";
import { ReactComponent as Close } from "../../../assets/edit_cancel.svg";

interface Props {
  hasUnderLine?: boolean;
  className?: string;
  isShowChip?: boolean;
  isRequired?: boolean;
  label?: string;
  type: "single" | "multi";
  dropDirection?: "up" | "down";
  distinguisher?: string;
  data: SelectData<any>[];
  editable?: boolean;
  selectedItem?: SelectData<any>[];
  placeholder?: string;
  maxSelect?: number;
  maxHeight?: number;
  onChange?: (item: any[]) => void;
  onCreate?: (name: string) => void;
}

const NotionInputSelecter: React.FC<Props> = (props) => {
  const { isShowChip = true } = props;

  const dataRefs = useRef<(HTMLSpanElement | null)[]>([]);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const inputWrapperRef = useRef<HTMLDivElement | null>(null);
  const dataWrapperRef = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const listRef = useRef<HTMLDivElement | null>(null);

  const [selectedItem, setSelectedItem] = useState<SelectData<any>[]>([]);
  const [searchList, setSearchList] = useState<SelectData<any>[]>([]);
  const [value, setValue] = useState<string>("");
  const [focus, setFocus] = useState<number>(-1);
  const [isEdit, setEdit] = useState<boolean>(false);

  const getBorderStyle = (): CSSProperties => {
    if (props.hasUnderLine) {
      if (isEdit) {
        return { borderBottom: "1px solid transparent" };
      }
      return { borderBottom: "1px solid var(--grey-20)" };
    }
    return { borderBottom: "1px solid transparent" };
  };

  const onClick = (item: SelectData<any>) => {
    if (props.maxSelect && selectedItem.length === props.maxSelect) return;

    if (props.type === "multi" && inputRef.current) inputRef.current.focus();

    const _selectedItem =
      props.type === "single" ? [item] : [...selectedItem, item];
    setSelectedItem(_selectedItem);

    if (props.onChange) {
      props.onChange(
        _selectedItem.map((data): any | undefined => {
          return data.data;
        })
      );
    }

    if (props.type === "single") closeList();
    if (props.type === "multi") searchItem("");
  };

  const removeItem = (item: SelectData<any>) => {
    const index = selectedItem.findIndex((data) => data.id === item.id);
    selectedItem.splice(index, 1);
    setSelectedItem([...selectedItem]);

    if (props.onChange) {
      props.onChange(
        selectedItem.map((data): any | undefined => {
          return data.data;
        })
      );
    }
  };

  const closeList = () => {
    setEdit(false);
    if (inputRef.current) inputRef.current.blur();
  };

  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target.value;
    setValue(target);
    searchItem(target);
  };

  const searchItem = (target: string) => {
    const _target = target.toUpperCase();
    setFocus(-1);
    dataRefs.current = [];
    const searchList = props.data.filter((item) => {
      const value = item.text.toUpperCase();
      return value.includes(_target);
    });
    setSearchList([...searchList]);
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!isEdit) return;
    if (props.maxSelect && selectedItem.length === props.maxSelect) return;
    if (e.key === "ArrowUp") {
      if (focus === -1) return setFocus(0);
      setFocus((prevState) => prevState - 1);
    } else if (e.key === "ArrowDown") {
      if (focus === -1) return setFocus(0);
      if (focus === props.data.length - 1)
        return setFocus(props.data.length - 1);
      setFocus((prevState) => prevState + 1);
    } else if (e.key === "Tab") {
      if (inputRef.current) inputRef.current.blur();
      setEdit(false);
    } else if (e.key === "Escape") {
      if (props.type === "single" && selectedItem.length > 0)
        setValue(selectedItem[0].text);
      if (inputRef.current) inputRef.current.blur();
      setEdit(false);
    }
  };

  const enterEventTypeMulti = () => {
    if (focus > -1) {
      const target = searchList[focus];
      const isSelected = selectedItem
        .map((item) => item.id)
        .includes(target.id);

      if (isSelected) removeItem(target);
      else onClick(target);

      setValue("");
    } else if (searchList.length === 1) {
      const target = searchList[0];
      if (target.text === value) {
        const isSelected = selectedItem
          .map((item) => item.id)
          .includes(target.id);

        if (isSelected) removeItem(target);
        else onClick(target);
      } else if (props.onCreate) {
        props.onCreate(value);
      } else {
        const isSelected = selectedItem
          .map((item) => item.id)
          .includes(target.id);
        if (isSelected) removeItem(target);
        else onClick(target);
      }
      setValue("");
    } else if (searchList.length === 0) {
      if (
        value.length !== 0 &&
        props.data.findIndex((item) => item.text === value) === -1
      ) {
        if (props.onCreate) props.onCreate(value);
      }
    }
  };

  const enterEventTypeSingle = () => {
    if (focus > -1) {
      const target = searchList[focus];
      onClick(target);
    } else if (searchList.length === 1) {
      const target = searchList[0];
      if (target.text === value) {
        onClick(target);
      } else if (props.onCreate) {
        if (inputRef.current) inputRef.current.blur();
        setEdit(false);
      } else {
        onClick(target);
      }
    } else if (searchList.length === 0) {
      if (inputRef.current) inputRef.current.blur();
      setEdit(false);
    }
  };

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.defaultPrevented) return;

    if (e.key === "Enter") {
      if (props.maxSelect && selectedItem.length === props.maxSelect) return;

      if (props.type === "single") enterEventTypeSingle();
      else enterEventTypeMulti();
    }
  };

  const onBlur = () => {
    if (
      isShowChip &&
      props.type === "multi" &&
      inputRef.current &&
      !isEdit &&
      selectedItem.length > 0
    ) {
      inputRef.current.style.width = "0px";
      inputRef.current.style.height = "0px";
      inputRef.current.style.opacity = "0";
    }

    if (
      value.length !== 0 &&
      props.data.findIndex((item) => item.text === value) === -1
    ) {
      if (props.maxSelect && selectedItem.length === props.maxSelect) return;

      if (props.onCreate) props.onCreate(value);
    }
  };

  const handleResize = () => {
    if (inputWrapperRef.current && containerRef.current) {
      inputWrapperRef.current.style.maxWidth = `${containerRef.current?.clientWidth}px`;
    }
    if (dataWrapperRef.current && dataWrapperRef.current) {
      dataWrapperRef.current.style.maxWidth = `${containerRef.current?.clientWidth}px`;
    }
  };

  const Chips = ({ item }: { item: SelectData<any> }) => {
    return (
      <ChipsContainer className="font-regular-14">
        <span>{item.text}</span>
        <Close
          className="icon"
          onClick={(e) => {
            e.stopPropagation();
            removeItem(item);
          }}
        />
      </ChipsContainer>
    );
  };

  const Item = ({ item, index }: { item: SelectData<any>; index: number }) => {
    const isSelected = selectedItem.map((item) => item.id).includes(item.id);
    return (
      <ItemContainer
        ref={(ref) => {
          dataRefs.current[index] = ref;
        }}
        className={`font-regular-14 ${focus === index && "focus"}`}
        isSelected={isSelected}
        onClick={(e) => {
          e.stopPropagation();
          if (props.type === "multi") {
            if (isSelected) removeItem(item);
            else onClick(item);
          } else {
            onClick(item);
          }
        }}
      >
        {item.text}
      </ItemContainer>
    );
  };

  const handleMouseDown = (event: any) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setEdit(false);
    }
  };

  useEffect(() => {
    window.addEventListener("mousedown", handleMouseDown);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("mousedown", handleMouseDown);
    };
  }, []);

  useEffect(() => {
    setSelectedItem(props.selectedItem || []);

    if (props.type === "single") {
      setSearchList([...props.data]);
      if (props.selectedItem && props.selectedItem.length > 0) {
        setValue(props.selectedItem[0].text);
      } else {
        setValue("");
      }
    } else {
      setSearchList([...props.data]);
      searchItem("");
      setValue("");

      if (
        isShowChip &&
        props.type === "multi" &&
        inputRef.current &&
        !isEdit &&
        selectedItem.length === 0
      ) {
        inputRef.current.style.flexGrow = "1";
        inputRef.current.style.height = "fit-content";
        inputRef.current.style.opacity = "1";
      }
    }
  }, [props.data, props.selectedItem]);

  useEffect(() => {
    if (isEdit && inputRef.current) {
      handleResize();
    }
    if (isEdit && containerRef.current && listRef.current) {
      listRef.current.style.maxWidth = `${props.maxHeight || 500}px`;
    }

    if (isEdit) {
      if (props.type === "single") {
        if (props.selectedItem && props.selectedItem.length > 0) {
          setValue(props.selectedItem[0].text);
        }
      } else {
        setValue("");
      }
    }
    setFocus(-1);
  }, [isEdit]);

  useEffect(() => {
    if (
      dataRefs !== null &&
      dataRefs.current &&
      dataRefs.current.length > 0 &&
      focus !== -1
    ) {
      dataRefs.current[focus]?.focus();
    }
  }, [focus]);
  return (
    <Container ref={containerRef} className={props.className || ""}>
      {props.label && (
        <div className="font-bold-14">
          <span>{props.label}</span>
          {props.isRequired && (
            <span
              className="font-bold-14"
              style={{ color: "var(--red)", marginLeft: 8 }}
            >
              *
            </span>
          )}
        </div>
      )}
      <TextContainer
        editable={props.editable || false}
        isEdit={isEdit}
        style={getBorderStyle()}
        onClick={() => inputRef.current && inputRef.current.focus()}
      >
        <div ref={inputWrapperRef} className="inputWrapper">
          {isShowChip &&
            props.type === "multi" &&
            !isEdit &&
            selectedItem.map((item, index) => (
              <span className="font-regular-14" key={index}>
                {props.distinguisher}
                {item.text}
              </span>
            ))}
          {isShowChip &&
            props.type === "multi" &&
            isEdit &&
            selectedItem.map((item, index) => (
              <Chips key={index} item={item} />
            ))}
          <input
            ref={inputRef}
            className="font-regular-14"
            autoComplete="off"
            aria-autocomplete="none"
            autoCorrect="off"
            type="text"
            disabled={!props.editable}
            placeholder={
              props.type === "multi" && selectedItem.length !== 0 && isShowChip
                ? ""
                : props.placeholder
            }
            value={value}
            onKeyPress={onKeyPress}
            onKeyDown={onKeyDown}
            onFocus={() => {
              if (inputRef.current) {
                inputRef.current.style.flexGrow = "1";
                inputRef.current.style.height = "fit-content";
                inputRef.current.style.opacity = "1";
              }

              setEdit(true);
            }}
            onBlur={onBlur}
            onChange={onChangeSearch}
          />
          {!props.onCreate && isEdit && (
            <div className="arrow">
              <Arrow />
            </div>
          )}
        </div>

        <ListContainer
          ref={listRef}
          className={`selector ${props.editable && isEdit ? "show" : ""}`}
          style={{ maxHeight: isEdit ? props.maxHeight || 500 : 0 }}
          dropDirection={props.dropDirection || "down"}
          maxHeight={props.maxHeight || 500}
        >
          <span
            className="font-regular-14"
            style={{ padding: 11, color: "var(--grey-20)" }}
          >
            선택하기
          </span>
          {searchList.length > 0 ? (
            searchList.map((item, index) => (
              <Item key={index} index={index} item={item} />
            ))
          ) : (
            <div
              style={{
                minHeight: "100px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {" "}
              검색 결과가 없습니다.{" "}
            </div>
          )}
        </ListContainer>
      </TextContainer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 4px;
`;
const TextContainer = styled.div<{ editable: boolean; isEdit: boolean }>`
  position: relative;
  width: 100%;
  .viewer__text {
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }

  .inputWrapper {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    padding: 5px 32px 5px 10px;
    border-radius: 4px;

    gap: 10px;

    background: white;
    border: 1px solid
      ${(props) => (props.isEdit ? "var(--grey-20)" : "transparent")};
    ${(props) => props.isEdit && "box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16)"};

    input {
      flex-grow: 1;
      background: transparent;
      border: 0;
      word-break: break-word;
      :focus {
        outline: none;
      }
    }

    :hover {
      ${(props) =>
        props.editable && !props.isEdit && "background-color: var(--grey-10)"};
    }

    .arrow {
      position: absolute;
      top: 50%;
      right: 4px;
      width: 24px;
      height: 24px;
      transform: translateY(-50%);
    }
  }

  .selector {
    max-height: 0px;
    overflow: hidden;
  }
  .show {
    overflow: auto;

    border-radius: 0.25rem;
    border: 1px solid #e5e5e5;
    background-color: #ffffff;

    box-shadow: 0.5px 3px 3px 0px rgba(0, 0, 0, 0.2),
      -0.5px 0 5px 0px rgba(0, 0, 0, 0.2);
  }
`;
const ListContainer = styled.div<{
  dropDirection: "up" | "down";
  maxHeight: number;
}>`
  width: 350px;
  max-height: ${(props) => props.maxHeight}px;

  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 10;
  overflow: scroll;
  left: 0;
  ${(props) =>
    props.dropDirection === "up"
      ? "bottom: calc(100% + 0.5rem)"
      : "top: calc(100% + 0.5rem)"};

  .focus {
    border: 1px solid var(--BPP_blue);
    border-radius: 4px;
  }
`;
const ItemContainer = styled.span<{ isSelected: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 12px;
  background-color: ${(props) =>
    props.isSelected ? "var(--grey-10)" : "var(--white)"};
  cursor: pointer;
  gap: 12px;

  :hover {
    background-color: #e3f2fd;
  }
`;
const ChipsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 8px;
  border-radius: 15px;
  border: 1px solid var(--grey-20);
  background-color: #ffffff;
  gap: 4px;

  .icon {
  }
`;
export default NotionInputSelecter;
