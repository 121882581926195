import React from "react";
import Styled from "@emotion/styled";
import { NodeModel } from "@minoru/react-dnd-treeview";

type Props = {
  node: NodeModel;
  depth: number;
};

export const StructurePlaceholder: React.FC<Props> = (props) => {
  const left = props.depth * 36 + 20;

  return <Container style={{ left }} />;
};

const Container = Styled.div`
  height: 2px;
  position: absolute;
  background-color: #1967d2;
  right: 20px;
  transform: translateY(-10px);
`;
