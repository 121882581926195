import React, { useEffect, useRef, useState } from "react";

import { Button } from "react-bootstrap";
import { getServerTime } from "utils/commonApi";
import axios from "axios";
import { sortingByOpenDate } from "utils/commonUtil";
import Navigation, { Pages } from "../../component/navigation";
import { EventLayout, EventList } from "./styles";
import SearchBar from "../../component/search";
import ModalLayout from "../../component/modal/ModalLayout";
import EventCard from "./component/EventCard";
import EventDetail from "./component/EventDetail";
import * as api from "../../../api/api";

export interface EventProps {
  hpEventId?: number;
  thumbNailImgKey: string; // 이벤트 썸네일 이미지 키
  thumbNailImgUrl: string; // 이벤트 썸네일 이미지 주소
  openDate: string; // 이벤트가 홈페이지에 노출되는 시기
  eventStartDate: string; // 이벤트 시작일
  eventEndDate: string; // 이벤트 종료일
  title: string; // 이벤트 제목
  place: string; // 이벤트 장소
  linkUrl: string; // 링크 주소
  eventDate: string; // 이벤트 날짜
  content: string; // 이벤트 본문
  isActive: boolean; // show || hide
  createdAt: string; // 생성날짜
}

export interface EventCardProps {
  hpEventId: number;
  thumbNailImgKey: string;
  thumbNailImgUrl: string;
  openDate: string;
  eventDate: string;
  title: string;
  place: string;
  isActive: boolean;
}

const Event = () => {
  const [isEventDetailModal, updateEventDetailModal] = useState<boolean>(false);
  const selectedEventData = useRef<EventProps | undefined>(undefined);
  const [eventData, updateEventData] = useState<EventProps[]>([]);
  const [today, updateToday] = useState<Date | undefined>(undefined);
  const [keyword, updateKeyword] = useState<string>("");

  const onShowEventDetail = (eventData?: EventProps) => {
    selectedEventData.current = eventData;
    updateEventDetailModal(true);
  };
  const setCurrentTime = async () => {
    try {
      const currentTime = await getServerTime();
      updateToday(currentTime);
    } catch (error) {
      console.log(error);
    }
  };

  const getEventList = async () => {
    try {
      const result = await axios.get(api.getEventList());
      if (result.status === 200) {
        updateEventData(
          result.data
            .sort(sortingByOpenDate)
            .map((item: any): EventCardProps => ({ ...item }))
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const init = async () => {
    try {
      if (await api.checkingToken()) {
        await setCurrentTime();
        await getEventList();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    init();
  }, []);
  return (
    <Navigation activePageId={Pages[3].id}>
      <EventLayout>
        <div className="contents__header">
          <SearchBar
            onChangeKeyword={(keyword) => {
              updateKeyword(keyword);
            }}
          />
          <Button
            variant="primary"
            onClick={() => onShowEventDetail(undefined)}
          >
            컨텐츠 생성하기
          </Button>
        </div>
        <div className="contents__body">
          <EventList>
            {eventData
              .filter((item) =>
                item.title.toUpperCase().includes(keyword.toUpperCase())
              )
              .map((item) => {
                return (
                  <EventCard
                    key={item.hpEventId}
                    item={item}
                    today={today}
                    onClickItem={() => onShowEventDetail(item)}
                    callback={() => {
                      getEventList();
                    }}
                  />
                );
              })}
          </EventList>
        </div>
        <ModalLayout
          isOpen={isEventDetailModal}
          toggle={() => updateEventDetailModal(false)}
        >
          <EventDetail
            item={selectedEventData.current}
            callback={() => {
              updateEventDetailModal(false);
              getEventList();
            }}
          />
        </ModalLayout>
      </EventLayout>
    </Navigation>
  );
};
export default Event;
