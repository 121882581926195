import React, { useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { PortfolioProps } from "admin/type/type";
import Chip from "component/chips/Chip";
import Label from "component/label/Label";
import DisplayType from "utils/displayType";
import { propTypes } from "react-bootstrap/esm/Image";
import Toggle from "admin/component/toggle";

interface Props {
  portfolio?: PortfolioProps;
  isViewer?: boolean;
  onClick?: () => void;
  onPortfolioChangeActive?: (portfolioId: number, active: boolean) => void;
}

const PortfolioCard: React.FC<Props> = (props) => {
  const { isViewer = false } = props;
  const mode = DisplayType();

  if (props.portfolio === undefined) return <Container />;

  console.log(props.portfolio);
  return (
    <Container onClick={props.onClick}>
      <div className="portfolio__logo__container">
        {props.portfolio.companyImgUrl ? (
          <img
            className="portfolio__logo"
            src={props.portfolio.companyImgUrl}
            alt="logo"
          />
        ) : (
          <div
            className="font-sub-title2"
            style={{
              color: `var(--blue-01)`,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {props.portfolio.companyName.split(" ").map((item, index) => {
              return <span key={index}>{item}</span>;
            })}
          </div>
        )}
      </div>
      <InfoContainer>
        <span className="font-sub-title4">{props.portfolio?.companyName}</span>
        {mode !== "Mobile" && (
          <div className="chips__container">
            {props.portfolio?.hpPfIndustrialTech.map((item) => {
              return (
                <Label key={item.hpPfIndustrialTechId} labelType="type2">
                  {item.hpIndustrialTech.name}
                </Label>
              );
            })}
          </div>
        )}
        <p
          className={`portfolio__introduction ${
            mode === "Mobile" ? "font-body3" : "font-body3"
          }`}
        >
          {props.portfolio?.introduction}
        </p>
        <p
          className={`portfolio__description ${
            mode === "Mobile" ? "font-body4" : "font-body4"
          }`}
        >
          {props.portfolio?.description}
        </p>
        {mode === "Mobile" && (
          <div className="chips__container">
            {props.portfolio?.hpPfIndustrialTech.map((item) => {
              return (
                <Label key={item.hpPfIndustrialTechId} labelType="type2">
                  {item.hpIndustrialTech.name}
                </Label>
              );
            })}
          </div>
        )}
      </InfoContainer>
      {props.portfolio?.stage && (
        <Label className="stage__label">{props.portfolio?.stage}</Label>
      )}

      {!isViewer &&
        props.portfolio?.preLatticePortfolioUpdatedAt !==
          props.portfolio?.curLatticePortfolioUpdatedAt && (
          <UpdateLabel
            className="font-bold-12"
            isNew={props.portfolio?.preLatticePortfolioUpdatedAt === null}
          >
            {props.portfolio?.preLatticePortfolioUpdatedAt === null
              ? "New"
              : "Update"}
          </UpdateLabel>
        )}
      {!isViewer && (
        <ActiveSwitch>
          <Toggle
            isEditable
            defaultChecked={props.portfolio.active}
            onChange={(check) => {
              if (
                props.onPortfolioChangeActive &&
                props.portfolio &&
                props.portfolio.portfolioId
              ) {
                props.onPortfolioChangeActive(
                  props.portfolio.portfolioId,
                  check
                );
              }
            }}
          />
        </ActiveSwitch>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 376px;
  height: 452px;

  background: #ffffff;
  border: 1px solid var(--grey-00);
  transition: all 0.1s ease;
  cursor: pointer;

  .portfolio__logo__container {
    width: 100%;
    height: 201px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--white);
    border-bottom: 1px solid var(--grey-00);
    .portfolio__logo {
      width: 200px;
      height: 200px;
      object-fit: contain;
    }
  }

  .stage__label {
    position: absolute;
    left: 16px;
    top: 16px;
  }

  :hover {
    border: 1px solid var(--primary);
    transform: scale(1.02);
  }

  @media only screen and (max-width: 768px) {
    width: calc(50% - 7px);
    height: fit-content;
    border: 0;
    .portfolio__logo__container {
      width: 100%;
      aspect-ratio: 1/1;
      height: auto;
      border: 1px solid var(--grey-00);
      overflow: hidden;
      .portfolio__logo {
        width: calc(100%);
        height: auto;
        aspect-ratio: 1/1;
        transform: scale(1.2);
      }
    }
    .stage__label {
      left: 8px;
      top: 8px;
    }
  }
`;

const InfoContainer = styled.div`
  width: 100%;
  flex-grow: 1;
  padding: 32px;
  display: flex;
  flex-direction: column;

  .chips__container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 4px;
    max-height: 64px;
    overflow: hidden;
    margin: 0;
    margin-top: 16px;
  }

  .portfolio__introduction {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    margin: 0;
    margin-top: 24px;
    color: var(--black);
  }

  .portfolio__description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin: 0;
    margin-top: 8px;
    color: var(--grey-40);
  }

  @media only screen and (max-width: 768px) {
    padding: 16px 0px 0px 0px;

    .portfolio__description {
      -webkit-line-clamp: 1;
    }

    .portfolio__introduction {
      display: none;
      -webkit-line-clamp: 2;
    }
  }
`;
const UpdateLabel = styled.div<{ isNew: boolean }>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  color: var(--white);
  background-color: ${(props) =>
    props.isNew ? "var(--primary)" : "var(--red)"};
  transform: translate(20%, -40%);
  width: fit-content;
  height: fit-content;
  padding: 6px 10px 5px 10px;
  border-radius: 20px;
`;

const ActiveSwitch = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
`;
export default React.memo(PortfolioCard);
