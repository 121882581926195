import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { PortfolioProps } from "admin/type/type";
import Chip from "component/chips/Chip";
import { NewsProps } from "admin/router/news";
import DisplayType from "utils/displayType";
import { getYMD } from "utils/commonUtil";

interface Props {
  news: NewsProps;
  isViewer?: boolean;
  onClick?: () => void;
}

const NewsItem: React.FC<Props> = (props) => {
  const { isViewer = false } = props;
  const mode = DisplayType();
  useEffect(() => {}, []);

  return (
    <Container onClick={props.onClick}>
      <img className="thumbNail" src={props.news.thumbNail} alt="logo" />
      <InfoContainer>
        <span
          className={`news__portfolio ${
            mode === "Mobile" ? "font-bold-12" : "font-bold-14"
          }`}
        >
          {props.news.hpPortfolio?.companyName}
        </span>
        <p
          className={`news__title ${
            mode === "Mobile" ? "font-bold-16" : "font-sub-title2"
          }`}
        >
          {props.news.title}
        </p>
        <p
          className={`news__description ${
            mode === "Mobile" ? "font-body4" : "font-body4"
          }`}
        >
          {props.news.description}
        </p>
        <span
          className={`news__date ${
            mode === "Mobile" ? "font-small-text" : "font-bold-12"
          }`}
        >
          {getYMD(props.news.openDate)}
        </span>
      </InfoContainer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 18px;
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--grey-00);

  transition: all 0.1s ease;
  cursor: pointer;

  .thumbNail {
    width: 180px;
    min-width: 180px;
    max-width: 180px;
    height: 116px;
    min-height: 116px;
    max-height: 116px;

    object-fit: cover;
  }

  @media only screen and (max-width: 768px) {
    padding-top: 0px;
    padding-bottom: 0px;
    border-bottom: 1px solid var(--grey-00);

    .thumbNail {
      width: 88px;
      min-width: 88px;
      max-width: 88px;
      height: 80px;
      min-height: 80px;
      max-height: 80px;

      object-fit: cover;
    }
  }
`;

const InfoContainer = styled.div`
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  .news__portfolio {
    color: var(--grey-50);
  }
  .news__title {
    max-height: 27px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    margin: 0;
    margin-top: 4px;
    color: var(--black);
  }

  .news__description {
    height: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    margin: 0;
    margin-top: 4px;
    color: var(--grey-40);
  }
  .news__date {
    margin-top: 8px;
    color: var(--grey-30);
  }

  @media only screen and (max-width: 768px) {
    padding: 16px 10px;
    .news__title {
      max-height: 18px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      margin: 0;
      font-size: 16px;
      color: var(--black);
    }
    .news__description {
      max-height: 18px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      margin: 0;
      margin-top: 2px;
      color: var(--grey-40);
    }
    .news__date {
      margin-top: 6px;
      color: var(--grey-30);
    }
  }
`;
export default React.memo(NewsItem);
