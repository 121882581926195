import styled from "@emotion/styled";

export const TeamLayout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: calc(100vw - 300px);
  min-height: 100vh;
  max-height: 100vh;

  .team__header {
    width: 100%;
    height: 68px;
    min-height: 68px;
    max-height: 68px;
    padding: 0px 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--border-grey);
  }

  .team__body {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    padding: 24px;
    gap: 24px;
  }
`;
