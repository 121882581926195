import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";

import { ReactComponent as CheckOn } from "../../assets/checkbox_on.svg";
import { ReactComponent as CheckOff } from "../../assets/checkbox_off.svg";

interface Props {
  className?: string;
  defaultCheck?: boolean;
  style?: React.CSSProperties;
  isError?: boolean;
  onChangeValue?: (value: boolean) => void;
}

const PrivacyPolicyCheckBox: React.FC<Props> = (props) => {
  const [checked, updateChecked] = useState<boolean>(false);

  useEffect(() => {
    if (props.defaultCheck !== undefined) {
      updateChecked(props.defaultCheck);
    }
  }, [props.defaultCheck]);

  return (
    <Container
      className={`${props.isError && `is__error`}`}
      style={{ ...props.style }}
      onClick={() => {
        updateChecked(!checked);
        if (props.onChangeValue) props.onChangeValue(!checked);
      }}
    >
      {checked ? (
        <CheckOn className="check__icon" width={16} height={16} />
      ) : (
        <CheckOff className="check__icon" width={16} height={16} />
      )}

      {/* <div className="font-body4">
        <span
          style={{
            color: props.isError ? "#e02020" : "var(--grey-50)",
            marginLeft: 8,
            marginRight: 4,
          }}
        >
          개인정보 수집
        </span>
      </div>
      <span
        className="font-body4"
        style={{ color: props.isError ? "#e02020" : "var(--grey-30)" }}
      >
        및 이메일 수신에 동의합니다.
      </span> */}

      <a
        className="font-body4"
        href="/privacy-policy"
        target={"_blank"}
        style={{ textDecoration: "none" }}
        onClick={(e) => e.stopPropagation()}
      >
        <span
          className="link__url"
          style={{
            color: props.isError ? "#e02020" : "var(--grey-50)",
            marginLeft: 8,
            marginRight: 4,
          }}
        >
          개인정보 수집
        </span>
      </a>
      <span
        className="font-body4"
        style={{ color: props.isError ? "#e02020" : "var(--grey-30)" }}
      >
        및 이메일 수신에 동의합니다.
      </span>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  .check__icon {
    margin-bottom: 3px;
  }

  .link__url {
    :hover {
      text-decoration: underline;
    }
  }
`;

export default React.memo(PrivacyPolicyCheckBox);
