import styled from "@emotion/styled";

export const TeamStructureLayout = styled.div`
  position: relative;
  width: 350px;
  min-width: 350px;
  max-width: 350px;
  height: 100%;
  min-height: calc(100vh - 68px - 48px);
  max-height: calc(100vh - 68px - 48px);
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  overflow: scroll;
`;
