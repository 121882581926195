import styled from "@emotion/styled";
import axios from "axios";
import Chip from "component/chips/Chip";
import React, { useEffect, useState } from "react";
import DisplayType from "utils/displayType";
import { IndustryTech } from "admin/type/type";
import { ReactComponent as FoldDown } from "../../../assets/fold_down.svg";
import * as api from "../../../api/api";

interface Props {
  isError?: boolean;
  defaultValue?: IndustryTech[];
  onChange?: (selectedSector: IndustryTech[]) => void;
}
const SectorList: React.FC<Props> = (props) => {
  const mode = DisplayType();
  const [allSector, setAllSector] = useState<IndustryTech[]>([]);
  const [selectedSector, updateSelectedSector] = useState<IndustryTech[]>([]);
  const [isFold, updateFold] = useState<boolean>(true);

  const onSelectSector = (item: IndustryTech) => {
    const index = selectedSector.findIndex(
      (sector) => sector.acIndustrialTechId === item.acIndustrialTechId
    );
    if (index !== -1) {
      selectedSector.splice(index, 1);
      updateSelectedSector([...selectedSector]);
      if (props.onChange) props.onChange([...selectedSector]);
    } else {
      const newSector = [...selectedSector, item];
      updateSelectedSector(newSector);
      if (props.onChange) props.onChange(newSector);
    }
  };

  const getAllSector = async () => {
    try {
      const result = await axios.get(api.getHompepageIndustrialTech());
      if (result.status === 200) {
        setAllSector(result.data.filter((item: any) => item.isActive));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateIndustrialTech = async () => {
    try {
      axios.put(api.updateIndustrialTech()).then((result) => {
        if (result.status === 200) {
          getAllSector();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    updateSelectedSector([...(props.defaultValue || [])]);
  }, [props.defaultValue]);

  useEffect(() => {
    updateIndustrialTech();
  }, []);

  return (
    <Container isFold={isFold}>
      <span
        className={`sector__list__label font-sub-title4 ${
          props.isError && "is__error"
        }`}
      >
        산업기술 분야를 선택해주세요.
      </span>
      <div className="sector__list">
        {allSector.map((item) => {
          return (
            <Chip
              key={item.acIndustrialTechId}
              isClickable
              isActive={selectedSector
                .map((item) => item.acIndustrialTechId)
                .includes(item.acIndustrialTechId)}
              onClickChip={() => {
                onSelectSector(item);
              }}
            >
              {item.name}
            </Chip>
          );
        })}
      </div>
      {mode === "Mobile" && (
        <div
          className="fold__controler__container"
          onClick={() => updateFold((prevState) => !prevState)}
        >
          <span className="font-body4">{isFold ? "펼치기" : "접기"}</span>
          <FoldDown className={`fold__icon ${isFold ? "" : "unfold"}`} />
        </div>
      )}
    </Container>
  );
};

const Container = styled.div<{ isFold: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .sector__list__label {
    color: var(--grey-50);
  }

  .sector__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 8px;
  }

  .is__error {
    color: #e02020;
    animation: shake 300ms;
    :focus {
      outline: none;
    }
  }

  .fold__controler__container {
    width: calc(100% + 48px);
    height: 40px;
    display: flex;
    transform: translateX(-24px);
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: var(--grey-00);
    color: var(--grey-40);
    padding-top: 1px;
    gap: 4px;
    .fold__icon {
      width: 16px;
      height: 16px;
      transform: rotate(0);
      transition: all 0.2s ease;
    }
    .fold__icon.unfold {
      transform: rotate(180deg);
    }
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    .sector__list__label {
      min-width: 100%;
      max-width: 100%;
    }
    .sector__list {
      padding-top: 20px;
      padding-bottom: 20px;
      flex-wrap: ${(props) => (props.isFold ? "nowrap" : "wrap")};
      overflow: scroll;
      ::-webkit-scrollbar {
        display: none;
      }
    }
  }
`;

export default SectorList;
