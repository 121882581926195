import styled from "@emotion/styled";
import DisplayType from "utils/displayType";
import { useLayoutEffect } from "react";
import { updateTitle } from "utils/commonUtil";
import Footer from "../../component/footer/Footer";
import { ReactComponent as Logo } from "../../assets/logo_open_innovation.svg";
import { ReactComponent as Email } from "../../assets/email__icon.svg";

const OpenInnovation = () => {
  const mode = DisplayType();

  useLayoutEffect(() => {
    updateTitle("오픈이노베이션");
  }, []);

  return (
    <Container>
      <HeaderContainer>
        <Logo className="program__logo" />
        <span
          className={`program__title ${
            mode === "Mobile" ? "font-bold-24" : "font-title"
          }`}
        >
          오픈이노베이션
        </span>
        <p
          className={`program__description ${
            mode === "Mobile" ? "font-body3" : "font-body2"
          }`}
        >
          신성장동력을 찾는 대기업/중견기업과{mode === "Mobile" && <br />}{" "}
          산업전문가가 필요한{mode === "Mobile" && <br />} 스타트업을 연결하는
          프로그램입니다 .
          {/* 신성장동력을 찾는 기업과 {mode === "Mobile" && <br />} 산업 전문가가 필요한
          {mode === "Mobile" && <br />} 스타트업을 연결합니다. */}
        </p>
      </HeaderContainer>
      <BodyContainer className="limit__body">
        <ProgramDescription>
          <div
            className={`program__description__title ${
              mode === "Mobile" ? "font-sub-title2" : "font-title"
            }`}
          >
            스타트업, 산업의 노하우를 만나다
          </div>
          <p className={`${mode === "Mobile" ? "font-body3" : "font-body2"}`}>
            산업이 개편되는 속도가 빠른 요즘, 기업들은 신사업 발굴을 위해 내부
            혁신을 넘어 타 회사들과 협력하는 개방형 혁신을 많이 시도하고
            있습니다. 한편 스타트업은 자사의 아이템과 시장을 검증받기 위해 외부
            산업 전문가의 조언을 필요로 합니다.
            <br />
            <br /> 블루포인트는 여기에 착안하여 기업과 스타트업 간 협업 니즈가
            있음을 확인하였고, 액셀러레이팅 프로그램을 통해 서로가 윈윈할 수
            있는 연결 기회를 제공하고 있습니다. 일반적인 배치 프로그램과 다른
            특별한 점은 선발된 스타트업들이 대기업의 산업 전문가, 기술
            전문가들의 집중 멘토링과 그들의 네트워크를 확보할 수 있다는
            것입니다.{mode === "Mobile" && <br />}
            {mode === "Mobile" && <br />} 뿐만 아니라 경우에 따라 대기업이
            보유한 실험실 등의 인프라를 스타트업 제품의 테스트베드로 활용해 볼
            수 있으며, 향후 기업의 전략투자 및 {`M&A`} 기회도 모색해 볼 수
            있습니다.
          </p>
        </ProgramDescription>
        <ProgramProcess>
          <div
            className={`program__process__title ${
              mode === "Mobile" ? "font-bold-22" : "font-sub-title"
            }`}
          >
            프로그램 과정
          </div>
          <div className={`program__process__row`}>
            <span
              className={`${
                mode === "Mobile" ? "font-bold-16" : "font-bold-18"
              }`}
            >
              설명
            </span>
            <span
              className={`${mode === "Mobile" ? "font-body3" : "font-body2"}`}
            >
              대기업과 공동으로 스타트업을 발굴하고 투자 및 지원하는 그룹형
              액셀러레이팅 프로그램
            </span>
          </div>
          <div className={`program__process__row`} style={{ marginTop: 8 }}>
            <span
              className={`${
                mode === "Mobile" ? "font-bold-16" : "font-bold-18"
              }`}
            >
              대상
            </span>
            <span
              className={`${mode === "Mobile" ? "font-body3" : "font-body2"}`}
            >
              기술을 보유한 2인 이상의 초기 스타트업 또는 예비창업자
            </span>
          </div>
          <div className="program__process__list">
            <ProgramProcessItem>
              <div className="program__process__item__column">
                <span
                  className={`${
                    mode === "Mobile" ? "font-sub-title2" : "font-sub-title"
                  }`}
                  style={{ color: "var(--primary)" }}
                >
                  Step. 1
                </span>
              </div>
              <div className="program__process__item__column">
                <span
                  className={`item__title ${
                    mode === "Mobile" ? "font-body3" : "font-body2"
                  }`}
                >
                  지원서 접수
                </span>
              </div>
            </ProgramProcessItem>
            <ProgramProcessItem>
              <div className="program__process__item__column">
                <span
                  className={`${
                    mode === "Mobile" ? "font-sub-title2" : "font-sub-title"
                  }`}
                  style={{ color: "var(--primary)" }}
                >
                  Step. 2
                </span>
              </div>
              <div className="program__process__item__column">
                <span
                  className={`item__title ${
                    mode === "Mobile" ? "font-body3" : "font-body2"
                  }`}
                >
                  인터뷰
                </span>
              </div>
            </ProgramProcessItem>
            <ProgramProcessItem>
              <div className="program__process__item__column">
                <span
                  className={`${
                    mode === "Mobile" ? "font-sub-title2" : "font-sub-title"
                  }`}
                  style={{ color: "var(--primary)" }}
                >
                  Step. 3
                </span>
              </div>
              <div className="program__process__item__column">
                <span
                  className={`item__title ${
                    mode === "Mobile" ? "font-body3" : "font-body2"
                  }`}
                >
                  최종 선발
                </span>
              </div>
            </ProgramProcessItem>
            <ProgramProcessItem>
              <div className="program__process__item__column">
                <span
                  className={`${
                    mode === "Mobile" ? "font-sub-title2" : "font-sub-title"
                  }`}
                  style={{ color: "var(--primary)" }}
                >
                  Step. 4
                </span>
              </div>
              <div className="program__process__item__column">
                <span
                  className={`item__title ${
                    mode === "Mobile" ? "font-body3" : "font-body2"
                  }`}
                >
                  오픈이노베이션 프로그램 참여
                </span>
                <span
                  className={`item__descrition ${
                    mode === "Mobile" ? "font-body3" : "font-body2"
                  }`}
                >
                  단체 교육, 오피스 아워, 네트워킹, 그룹 세션, 스타트업 캠프 등
                  6개월 과정
                </span>
              </div>
            </ProgramProcessItem>
            <ProgramProcessItem>
              <div className="program__process__item__column">
                <span
                  className={`${
                    mode === "Mobile" ? "font-sub-title2" : "font-sub-title"
                  }`}
                  style={{ color: "var(--primary)" }}
                >
                  Step. 5
                </span>
              </div>
              <div className="program__process__item__column">
                <span
                  className={`item__title ${
                    mode === "Mobile" ? "font-body3" : "font-body2"
                  }`}
                >
                  파이널 데모데이
                </span>
                <span
                  className={`item__descrition ${
                    mode === "Mobile" ? "font-body3" : "font-body2"
                  }`}
                >
                  블루포인트 VC 네트워크를 활용한 IR 기회 제공
                </span>
              </div>
            </ProgramProcessItem>
            <ProgramProcessItem>
              <div className="program__process__item__column">
                <span
                  className={`${
                    mode === "Mobile" ? "font-sub-title2" : "font-sub-title"
                  }`}
                  style={{ color: "var(--primary)" }}
                >
                  Step. 6
                </span>
              </div>
              <div className="program__process__item__column">
                <span
                  className={`item__title ${
                    mode === "Mobile" ? "font-body3" : "font-body2"
                  }`}
                >
                  블루포인트 및 대기업의 투자 검토
                </span>
              </div>
            </ProgramProcessItem>
          </div>
        </ProgramProcess>
        <ProgramProcess>
          <div
            className={`program__process__title ${
              mode === "Mobile" ? "font-bold-22" : "font-sub-title"
            }`}
          >
            지원 / 컨택 방법
          </div>
          <span
            className={`item__descrition ${
              mode === "Mobile" ? "font-body3" : "font-body2"
            }`}
          >
            모집시기 홈페이지 공고 예정
          </span>
          <a
            href="mailto:steve@bluepoint.ac"
            style={{ textDecoration: "none" }}
          >
            <EmailContainer>
              <Email className="email__icon" />
              <span
                className={`email__text ${
                  mode === "Mobile" ? "font-regular-16" : "font-regulra-22"
                }`}
              >
                steve@bluepoint.ac
              </span>
            </EmailContainer>
          </a>
        </ProgramProcess>
      </BodyContainer>
      <Footer />
    </Container>
  );
};
const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 80px;

  @media only screen and (max-width: 768px) {
    padding-top: 60px;
  }
`;

const HeaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--bg-02);
  padding-top: 80px;
  padding-bottom: 80px;

  .program__logo {
    width: 240px;
    min-width: 240px;
    max-width: 240px;
    height: 240px;
    min-width: 240px;
    max-width: 240px;
  }
  .program__title {
    color: var(--black);
    margin-top: 24px;
    margin-bottom: 16px;
  }
  .program__description {
    color: var(--grey-40);
    text-align: center;
  }

  @media only screen and (max-width: 768px) {
    padding-bottom: 60px;
    .program__logo {
      width: 117px;
      min-width: 117px;
      max-width: 117px;
      height: 117px;
      min-width: 117px;
      max-width: 117px;
    }
    .program__title {
      color: var(--black);
      margin-top: 24px;
      margin-bottom: 8px;
    }
  }
`;

const BodyContainer = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  margin-bottom: 160px;
`;

const ProgramDescription = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  margin-top: 100px;

  .program__description__title {
    color: var(--primary);
    margin-bottom: 24px;
  }

  @media only screen and (max-width: 768px) {
    margin-top: 40px;
    .program__description__title {
      margin-bottom: 16px;
    }
  }
`;

const ProgramProcess = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  margin-top: 100px;

  .program__process__title {
    color: var(--black);
    margin-bottom: 24px;
  }

  .program__process__row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
  }

  .program__process__list {
    width: 100%;
    border-top: 1px solid var(--grey-00);
    display: flex;
    flex-direction: column;
    margin-top: 24px;
  }
  @media only screen and (max-width: 768px) {
    margin-top: 40px;
    .program__process__title {
      margin-bottom: 16px;
    }
    .program__process__row {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
    }
  }
`;

const ProgramProcessItem = styled.div`
  width: 100%;
  padding: 32px;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid var(--grey-00);
  gap: 312px;
  .program__process__item__column {
    display: flex;
    flex-direction: column;
  }

  .program__process__item__row {
    display: flex;
    flex-direction: row;
    align-items: center;
    .program__process__item__title {
      width: 35px;
    }
  }
  .item__title {
    color: var(--primary);
  }
  .item__descrition {
    color: var(--grey-60);
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    gap: 8px;
    padding: 24px 16px;

    .program__process__item__row {
      .program__process__item__title {
        width: 24px;
      }
    }
  }
`;

const EmailContainer = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  .email__icon {
    width: 32px;
    height: 32px;
  }
  .email__text {
    color: var(--primary);
  }
`;
export default OpenInnovation;
