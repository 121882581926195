import React, { useEffect, useRef, useState } from "react";
import ModalLayout from "admin/component/modal/ModalLayout";
import axios from "axios";
import { Button } from "react-bootstrap";
import styled from "@emotion/styled";
import SearchBar from "admin/component/search";
import { irArchiveType, IRDataProps } from "admin/type/type";
import * as api from "../../../api/api";
import Navigation, { Pages } from "../../component/navigation";
import IRCard from "./component/IRCard";
import IRArchiveDetail from "./component/IRArchiveDetail";

export interface IRDataAdmin {
  type: { title: string; type: string };
  data: IRDataProps[];
}

const IRArchive = () => {
  const [allIRArchives, updateAllIRArchives] = useState<IRDataAdmin[]>([]);
  const [isOpenIrArchiveDetail, setOpenIrArchiveDetail] =
    useState<boolean>(false);

  const openIrArchiveDetail = (irArchiveData?: IRDataProps) => {
    setOpenIrArchiveDetail(true);
  };

  const closeIrArchiveDetail = () => {
    setOpenIrArchiveDetail(false);
    getIRData();
  };

  const getIRData = async () => {
    try {
      const result = await axios.get(api.getIRArchives());
      console.log(result);
      if (result.status === 200) {
        const _data = irArchiveType.map((item) => {
          return {
            type: item,
            data: result.data
              .filter((data: any) => data.type === item.type)
              .sort((a: any, b: any) => {
                if (a.year > b.year) {
                  return -1;
                }
                if (a.year === b.year && a.quarterNum > b.quarterNum) {
                  return -1;
                }
                return 1;
              })
              .map((data: any) => ({ ...data, id: data.hpIrId })),
          };
        });
        updateAllIRArchives(_data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const init = async () => {
    try {
      if (await api.checkingToken()) {
        await getIRData();
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    init();
  }, []);
  return (
    <Navigation activePageId={Pages[9].id}>
      <Container>
        <div className="banner__header">
          <div />
          <Button
            variant="primary"
            onClick={() => {
              openIrArchiveDetail(undefined);
            }}
          >
            IR자료 생성하기
          </Button>
        </div>
        <div className="banner__body">
          {allIRArchives.map((item, index) => (
            <IRCard
              key={index}
              item={item}
              // onClickItem={() => openIrArchiveDetail(item)}
              callback={() => closeIrArchiveDetail()}
            />
          ))}
        </div>
      </Container>

      <ModalLayout
        isOpen={isOpenIrArchiveDetail}
        borderRadius={0}
        toggle={() => setOpenIrArchiveDetail(false)}
      >
        <IRArchiveDetail
          totalArchiveData={allIRArchives}
          callback={() => closeIrArchiveDetail()}
        />
      </ModalLayout>
    </Navigation>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;

  .banner__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    min-height: 60px;
    max-height: 60px;
    border-bottom: 1px solid grey;
    padding: 0 24px;
  }

  .banner__body {
    width: 100%;
    height: calc(100vh - 60px);
    display: flex;
    flex-direction: row;
    padding: 24px;
    gap: 24px;
    border-radius: 16px;
  }
`;
export default IRArchive;
