import React, {
  createRef,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import styled from "@emotion/styled";
import Footer from "component/footer/Footer";
import HeaderNavigation from "component/header/HeaderNavigation";
import DisplayType from "utils/displayType";
import Banner from "component/banner/Banner";
import { ContactMenu, NavigationMenu } from "admin/type/type";
import { updateTitle } from "utils/commonUtil";
import ContactTabs from "./components/ContactTabs";
import bg from "../../assets/contact_bg.png";
import FaqSection from "./components/FaqSection";
import { ReactComponent as Arrow } from "../../assets/faq_arrow.svg";
import { ReactComponent as Graph7 } from "../../assets/faq_7_graph.svg";
import { ReactComponent as MGraph7 } from "../../assets/faq_7_graph_m.svg";

import { ReactComponent as Graph111 } from "../../assets/graph_11_1.svg";
import { ReactComponent as Graph112 } from "../../assets/graph_11_2.svg";
import { ReactComponent as Graph113 } from "../../assets/graph_11_3.svg";

import { ReactComponent as ScrollDown } from "../../assets/go_to_top.svg";

interface ListItemProps {
  id: string;
  text: string;
  ref?: React.RefObject<HTMLDivElement> | null;
}
const Faq = () => {
  const mode = DisplayType();
  const [isGoTopShow, updateGoTopShow] = useState<boolean>(false);

  const faqListItems: ListItemProps[] = [
    {
      id: "section_1",
      text: "어떻게 지원해야 하나요?",
      ref: createRef<HTMLDivElement>(),
    },
    {
      id: "section_2",
      text: "투자 심사 프로세스가 궁금합니다.",
      ref: createRef<HTMLDivElement>(),
    },
    {
      id: "section_3",
      text: "투자까지 얼마나 걸리나요?",
      ref: createRef<HTMLDivElement>(),
    },
    {
      id: "section_4",
      text: "사업소개서에는 어떤 내용들을 담아야 하나요?",
      ref: createRef<HTMLDivElement>(),
    },
    {
      id: "section_5",
      text: "기술 스타트업만 지원 가능한가요?",
      ref: createRef<HTMLDivElement>(),
    },
    {
      id: "section_6",
      text: "그렇다면 투자 검토할 때 가장 중요하게 생각하는 것들은 무엇인가요?",
      ref: createRef<HTMLDivElement>(),
    },
    {
      id: "section_7",
      text: "어떤 분야에 투자하나요? ",
      ref: createRef<HTMLDivElement>(),
    },
    {
      id: "section_8",
      text: "투자 단계와 투자 금액은 어떻게 되나요?",
      ref: createRef<HTMLDivElement>(),
    },
    {
      id: "section_9",
      text: "리드 투자자로만 참여하나요?",
      ref: createRef<HTMLDivElement>(),
    },
    {
      id: "section_10",
      text: "투자금 이외에 무엇을 지원받을 수 있나요?",
      ref: createRef<HTMLDivElement>(),
    },
    {
      id: "section_11",
      text: "포트폴리오사들의 창업자 성별과 나이, 지역 비율은 어떻게 되나요? ",
      ref: createRef<HTMLDivElement>(),
    },
    {
      id: "section_12",
      text: "우리 사업은 새로운 분야인데 이것을 이해할 심사역이 있을까요?",
      ref: createRef<HTMLDivElement>(),
    },
    {
      id: "section_13",
      text: "투자 후에 간섭이 심한가요?",
      ref: createRef<HTMLDivElement>(),
    },
    {
      id: "section_14",
      text: "투자유치 중인 스타트업을 위해 한 마디 해주세요.",
      ref: createRef<HTMLDivElement>(),
    },
  ];
  const faqBodyRef = useRef<HTMLDivElement>(null);
  const ListItem = ({
    item,
    index,
  }: {
    item: ListItemProps;
    index: number;
  }) => {
    return (
      <ListItemContainer
        className={`${
          mode === "Mobile" ? "font-sub-title4" : "font-sub-title4"
        }`}
        onClick={() => {
          if (!item.ref) return;
          if (!item.ref.current) return;
          if (!faqBodyRef.current) return;

          window.scrollTo({
            behavior: "smooth",
            top:
              faqBodyRef.current.offsetTop +
              item.ref.current.offsetTop -
              (mode === "Mobile" ? 65 : 80),
          });
        }}
      >
        <div>
          <span>{index < 10 ? `0${index}.` : `${index}.`}</span>
          <span>{item.text}</span>
        </div>
        <Arrow />
      </ListItemContainer>
    );
  };

  const scrollHandler = () => {
    const isTop = mode === "Mobile" ? 500 : 1000;
    updateGoTopShow(window.scrollY >= isTop);
  };

  useEffect(() => {
    window.addEventListener("scroll", scrollHandler);
    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  });

  useLayoutEffect(() => {
    updateTitle("FAQ");
  }, []);

  return (
    <Container>
      <Banner bannerImg2048={bg} mode={mode}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: mode === "Mobile" ? "center" : "flex-start",
          }}
        >
          <div className="font-title-en" style={{ color: "var(--white)" }}>
            CONTACT
          </div>
        </div>
      </Banner>
      <ContactTabs selectedTab={ContactMenu.FAQ} />
      <FaqBodyContainer ref={faqBodyRef} className="limit__body">
        <FaqList>
          <div
            className={`faq__list__title ${
              mode === "Mobile" ? "font-sub-title2" : "font-sub-title"
            }`}
          >
            질문 전체보기
          </div>
          <div className="faq__list">
            {faqListItems.map((item, index) => {
              return <ListItem key={item.id} item={item} index={index + 1} />;
            })}
          </div>
        </FaqList>

        <FaqSection
          ref={faqListItems[0].ref}
          sectionId={faqListItems[0].id}
          headerTitle={`01. ${faqListItems[0].text}`}
        >
          <div className={`${mode === "Mobile" ? "font-body3" : "font-body3"}`}>
            블루포인트는 크게 수시접수와 배치(Batch) 프로그램을 통한 지원을 받고
            있습니다.
            <br />
            <br />
            <div
              className={`${
                mode === "Mobile" ? "font-bold-16" : "font-bold-16"
              }`}
            >
              1.수시 접수
            </div>
            블루포인트 홈페이지 Apply 메뉴를 통해 지원하실 수 있습니다.
            {mode !== "Mobile" && <br />} 추가로 제출할 자료는 공유 가능한
            드라이브 링크를 남겨주시면 됩니다.{mode !== "Mobile" && <br />}{" "}
            (apply@bluepoint.ac에 자료 접근 권한을 부여해 주셔야 합니다.)
            <br />
            <br />
            <div
              className={`${
                mode === "Mobile" ? "font-bold-16" : "font-bold-16"
              }`}
            >
              2. 프로그램을 통한 접수
            </div>
            블루포인트는 예비창업가를 위주로 하는 ‘동창’, 산업계 전문가들과
            함께하는 ‘오픈이노베이션 프로그램’, 중소기업진흥공단과 공동 운영하는
            ‘청년창업사관학교’ 등 다양한 창업 지원 프로그램을 운영하고 있습니다.
            {mode !== "Mobile" && <br />}
            해당 프로그램이 진행될 때 블루포인트 홈페이지의 모집공고 안내에 따라
            지원하시면 됩니다. {mode !== "Mobile" && <br />}블루포인트
            뉴스레터를 구독하시면 프로그램 모집 공지를 놓치지 않고 받아볼 수
            있습니다.
          </div>
        </FaqSection>

        <FaqSection
          ref={faqListItems[1].ref}
          sectionId={faqListItems[1].id}
          headerTitle={`02. ${faqListItems[1].text}`}
        >
          <div className={`${mode === "Mobile" ? "font-body3" : "font-body3"}`}>
            투자유치는 스타트업이 건강해지는 시간입니다.{" "}
            {mode !== "Mobile" && <br />}투자유치는 자본의 조달과 함께
            스타트업의 역량을 보완하는 과정이기 때문입니다.
            {mode !== "Mobile" && <br />}
            블루포인트는 아래의 프로세스에 따라 투자심사를 진행하고 있습니다.
            <br />
            <br />
            <div
              className={`${
                mode === "Mobile" ? "font-bold-16" : "font-bold-16"
              }`}
            >
              1. 서류 지원/심사
            </div>
            수시접수 채널로 지원하신 경우 심사역이 사전에 미팅 진행 여부를
            결정하는 단계를 거칩니다.{mode !== "Mobile" && <br />} 이 경우 미팅
            제안은 예외적인 경우도 있지만 통상 한 달 내외로 받게 되시며, 미팅
            대상이 아닌 경우엔 따로 연락이 가지는 않습니다.
            <br />
            <br />
            <div
              className={`${
                mode === "Mobile" ? "font-bold-16" : "font-bold-16"
              }`}
            >
              2. 첫 번째 미팅
            </div>
            담당 심사역이 최소 1회 이상의 대면/비대면 미팅을 진행하나, 해당
            미팅을 생략하고 바로 피칭으로 넘어가기도 합니다.
            {mode !== "Mobile" && <br />} 주로 해결하려는 문제와 팀의 역량에
            대해 스타트업의 의견을 듣는 자리로, 심사역이 스타트업 기술과 사업
            전반에 대한 이해를 높이는 시간이기도 합니다.
            <br />
            <br />
            <div
              className={`${
                mode === "Mobile" ? "font-bold-16" : "font-bold-16"
              }`}
            >
              3. 피칭
            </div>
            담당 심사역뿐만 아니라 다른 여러 심사역들이 참석하는 미팅으로
            스타트업이 자사의 사업성, 기술, 팀의 역량에 대한 부분을 중점적으로
            소개하는 시간입니다.{mode !== "Mobile" && <br />} 이후 사업모델
            빌드업을 위해 심사역이 수정 보완이 필요한 부분은 피드백을 드립니다.
            <br />
            <br />
            <div
              className={`${
                mode === "Mobile" ? "font-bold-16" : "font-bold-16"
              }`}
            >
              4. IR
            </div>
            스타트업은 피칭 단계에서 발견된 미흡한 부분을 수정 보완하여 최종
            발표를 하시게 됩니다.{mode !== "Mobile" && <br />} 때에 따라서는
            피칭과 IR이 동시에 진행되기도 합니다.{mode !== "Mobile" && <br />}{" "}
            투자 여부가 결정되기 전 스타트업과 심사역이 만나는 마지막 자리로
            다양한 질의응답이 이루어집니다.
            <br />
            <br />
            <div
              className={`${
                mode === "Mobile" ? "font-bold-16" : "font-bold-16"
              }`}
            >
              5. 투자심사위원회
            </div>
            담당 심사역이 투자심사보고서를 작성하여 투자심사위원회에 투자안을
            상정합니다.{mode !== "Mobile" && <br />} 기술 분야와 사업의 특성에
            따라 전문가로 구성된 투자심사위원회에서 투자 여부를 결정합니다.
            <br />
            <br />
            <div
              className={`${
                mode === "Mobile" ? "font-bold-16" : "font-bold-16"
              }`}
            >
              6. Term sheet 협의 및 투자 계약 체결
            </div>
            투자 조건을 협의하고 투자 계약을 체결합니다.
          </div>
        </FaqSection>
        <FaqSection
          ref={faqListItems[2].ref}
          sectionId={faqListItems[2].id}
          headerTitle={`03. ${faqListItems[2].text}`}
        >
          <div className={`${mode === "Mobile" ? "font-body3" : "font-body3"}`}>
            첫 번째 미팅으로부터 Term Sheet을 제안하는 데까지 보통 4~6주 정도가
            소요되나, 이보다 길어질 수도 있으며 상황에 따라 유동적입니다.
          </div>
        </FaqSection>
        <FaqSection
          ref={faqListItems[3].ref}
          sectionId={faqListItems[3].id}
          headerTitle={`04. ${faqListItems[3].text}`}
        >
          <div className={`${mode === "Mobile" ? "font-body3" : "font-body3"}`}>
            정답이 있는 것은 아니지만 아래와 같은 컨텐츠들이 담기면 심사역이
            보다 명료하게 사업내용을 파악할 수 있습니다.
            <br />
            <br />
            <ul>
              <li
                className={`${
                  mode === "Mobile" ? "font-bold-16" : "font-bold-16"
                }`}
              >
                해결할 가치가 있는 흥미로운 문제
              </li>
              <li
                className={`${
                  mode === "Mobile" ? "font-bold-16" : "font-bold-16"
                }`}
              >
                솔루션
              </li>
              <li
                className={`${
                  mode === "Mobile" ? "font-bold-16" : "font-bold-16"
                }`}
              >
                초기 진입 시장
              </li>
              <li
                className={`${
                  mode === "Mobile" ? "font-bold-16" : "font-bold-16"
                }`}
              >
                Go-To-Market 전략
              </li>
              <li
                className={`${
                  mode === "Mobile" ? "font-bold-16" : "font-bold-16"
                }`}
              >
                수익모델
              </li>
              <li
                className={`${
                  mode === "Mobile" ? "font-bold-16" : "font-bold-16"
                }`}
              >
                지금까지의 성과 또는 확인한 가설
              </li>
              <li
                className={`${
                  mode === "Mobile" ? "font-bold-16" : "font-bold-16"
                }`}
              >
                문제를 함께 해결해 나갈 팀원들
              </li>
            </ul>
          </div>
        </FaqSection>
        <FaqSection
          ref={faqListItems[4].ref}
          sectionId={faqListItems[4].id}
          headerTitle={`05. ${faqListItems[4].text}`}
        >
          <div className={`${mode === "Mobile" ? "font-body3" : "font-body3"}`}>
            블루포인트는 세상의 큰 문제를 풀고자 하는 모든 혁신가들을
            환영합니다.{mode !== "Mobile" && <br />} 기술은 문제를 해결하기 위한
            차별화된 수단으로서 어필이 될 수는 있으나 지원 자격의 필수 요건은
            아닙니다.
          </div>
        </FaqSection>
        <FaqSection
          ref={faqListItems[5].ref}
          sectionId={faqListItems[5].id}
          headerTitle={`06. ${faqListItems[5].text}`}
        >
          <div className={`${mode === "Mobile" ? "font-body3" : "font-body3"}`}>
            그동안의 투자 경험으로 봤을 때 기술과 산업, 시장은 계속해서 변해
            왔습니다.
            <br />
            그런데 유일하게 바뀌지 않는 것이 있었는데, 바로 ‘사람’이었습니다.
            <br />
            우리는 유쾌한 혁신가(Playful New-rule Maker)들을 찾고 있습니다.
            <br />
            <br />
            남들과는 다른 길을 가고 참신함과 도전 정신으로 새로운 기준을
            제시하는 사람,
            <br />
            세상의 변화를 유쾌하게 받아들이고, 변화에 유연하게 대처하며 성장하는
            사람,
            <br />
            기존의 것을 개선하는 사람보다는 시장과 고객을 새롭게 발견하는 사람을
            우리는 유쾌한 혁신가라고 부릅니다.
            <br />
            <br />
            또한 사업을 하는 취지와 목적의식이 세상의 큰 문제를 해결한다는 데서
            시작하는 스타트업을 환영합니다.
            <br />
            기술과 아이템 자체에 몰두하기보다는 항상 문제 중심적 사고를 하며,
            <br />이 문제를 겪는 고객의 페인포인트(Pain Point)가 얼마나 큰지,
            이를 해결했을 때 우리가 얻는 혜택(Benefit)이 얼마나 큰지를 고민하는
            창업가 말이죠.
            <br />
            <br />
            즉, 고객의 페인포인트가 곧 기업이 존재하는 이유인 곳을 찾고
            있습니다.
            <br />
            블루포인트는 이러한 부분들을 종합적으로 검토하며 창업팀들을 만나고
            있습니다.
          </div>
        </FaqSection>
        <FaqSection
          ref={faqListItems[6].ref}
          sectionId={faqListItems[6].id}
          headerTitle={`07. ${faqListItems[6].text}`}
        >
          <div className={`${mode === "Mobile" ? "font-body3" : "font-body3"}`}>
            블루포인트는 산업 분야를 특정하지 않고 다양하고 균형 있게 투자하고
            있습니다.
            <br />
            최근에는 IT 기술 및 서비스 (SaaS 모델, AI, Crypto, Entertainment,
            Fintech 등), 지속가능성 기술(환경, 에너지, Smart Farm 등),
            Bio/Medical/Healthcare 분야, New Industry (배터리, 우주항공,
            Mobility 등) 분야에도 관심을 기울이고 있습니다.
            <br />
            <br />
            2021년 9월 말 기준으로 다음과 같습니다.
            <br />
            <br />
            <div
              className={`${
                mode === "Mobile" ? "font-bold-16" : "font-bold-16"
              }`}
            >
              블루포인트 투자 분야
            </div>
            {mode === "Mobile" ? (
              <MGraph7 style={{ width: "100%" }} />
            ) : (
              <Graph7 style={{ width: "100%" }} />
            )}
          </div>
        </FaqSection>
        <FaqSection
          ref={faqListItems[7].ref}
          sectionId={faqListItems[7].id}
          headerTitle={`08. ${faqListItems[7].text}`}
        >
          <div className={`${mode === "Mobile" ? "font-body3" : "font-body3"}`}>
            3년 미만의 초기 스타트업에 주로 투자하고 있습니다.
            {mode !== "Mobile" && <br />} Seed 투자를 주로 하고 있지만, 최근에는
            다양한 펀드를 운용하면서 Pre-Series A 또는 Series A 단계의
            스타트업에도 투자하고 있습니다.
            <br />
            <br />
            <div
              className={`${mode === "Mobile" ? "font-body3" : "font-bold-16"}`}
            >
              1. Seed
            </div>
            스타트업이 아이디어나 기술을 시장에서 검증하는 단계입니다.
            {mode !== "Mobile" && <br />} 이 과정에서 블루포인트는 1~5억 원의
            투자금과 제품의 시장 적합성을 높이기 위한 전문적인 멘토링을 제공해
            드리고 있습니다.
            <br />
            <br />
            <div
              className={`${mode === "Mobile" ? "font-body3" : "font-bold-16"}`}
            >
              2. Series A
            </div>
            시장 검증을 거친 뒤 시제품이 나오고 서비스를 본격적으로 출시하는
            시점입니다.{mode !== "Mobile" && <br />} 블루포인트는 과거 Seed
            투자를 진행한 패밀리 기업들을 대상으로 Pre-Series A 또는 Series A
            라운드에 5~10억 원을 후속투자하여 스타트업의 성공적인 시장 진입을
            돕고 있습니다.
          </div>
        </FaqSection>
        <FaqSection
          ref={faqListItems[8].ref}
          sectionId={faqListItems[8].id}
          headerTitle={`09. ${faqListItems[8].text}`}
        >
          <div className={`${mode === "Mobile" ? "font-body3" : "font-body3"}`}>
            Seed 투자는 주로 단독 또는 리드 투자를 하고 Series A 단계에서는 리드
            투자하지 않는 것을 원칙으로 합니다.
          </div>
        </FaqSection>
        <FaqSection
          ref={faqListItems[9].ref}
          sectionId={faqListItems[9].id}
          headerTitle={`10. ${faqListItems[9].text}`}
        >
          <div className={`${mode === "Mobile" ? "font-body3" : "font-body3"}`}>
            <div
              className={`${
                mode === "Mobile" ? "font-bold-16" : "font-bold-16"
              }`}
            >
              1. 네트워크
            </div>
            <ul>
              <li
                className={`${mode === "Mobile" ? "font-body3" : "font-body3"}`}
              >
                전문가 : 블루포인트는 내부 전문가는 물론 대기업, 정부출연연구소,
                대학교 등 외부 기관들과 협력관계를 유지하며 탄탄한 전문가
                네트워크를 보유하고 있습니다. 따라서 산업 전문가 또는 기술
                전문가의 자문을 필요하실 때 연결해 드릴 수 있습니다.
              </li>
              <li
                className={`${mode === "Mobile" ? "font-body3" : "font-body3"}`}
              >
                동문(Alumni) : 블루포인트 자체 배치 프로그램에 참여하시면 동료
                간 학습(Peer Learning) 기회를 얻어 갈 수 있으며,
                포트폴리오사들을 위한 다양한 네트워킹 이벤트를 통해 고민을 나눌
                수 있는 선후배 창업가들을 만날 수 있습니다.
              </li>
            </ul>
            <br />
            <div
              className={`${
                mode === "Mobile" ? "font-bold-16" : "font-bold-16"
              }`}
            >
              2. 성장 프로그램
            </div>
            <ul>
              <li
                className={`${mode === "Mobile" ? "font-body3" : "font-body3"}`}
              >
                Blue School : 스타트업의 핵심 성장지표를 향상할 수 있도록
                블루포인트가 집중 멘토링을 제공합니다.
              </li>
              <li
                className={`${mode === "Mobile" ? "font-body3" : "font-body3"}`}
              >
                동창, 오픈이노베이션 등 배치 프로그램 : 선발되면
                6개월~12개월간의 단체 액셀러레이팅 교육과정에 참여하실 수
                있습니다.
              </li>
            </ul>
            <br />
            <div
              className={`${
                mode === "Mobile" ? "font-bold-16" : "font-bold-16"
              }`}
            >
              3. 홍보
            </div>
            <ul>
              <li
                className={`${mode === "Mobile" ? "font-body3" : "font-body3"}`}
              >
                블루포인트 뉴스레터(Blue Letter) : 스타트업 생태계 관계자들이
                구독하고 있는 블루포인트 뉴스레터에 포트폴리오사들의 다양한
                소식을 정기적으로 홍보해드리고 있습니다.
              </li>
              <li
                className={`${mode === "Mobile" ? "font-body3" : "font-body3"}`}
              >
                보도자료 배포, 홍보 콘텐츠 및 영상 제작 지원 : 포트폴리오사가
                홍보 니즈가 있을 때 블루포인트에서 콘텐츠 기획/제작/배포를
                도와드립니다.
              </li>
              <li
                className={`${mode === "Mobile" ? "font-body3" : "font-body3"}`}
              >
                데모데이 : 블루패밀리를 대중에게 선보이는 쇼케이스입니다. 이
                자리에 투자기관, 잠재고객, 미래의 팀원, 예비창업자, 대기업,
                미디어 등 창업 생태계 관계자 1천여 명이 청중으로 참석합니다.
                블루포인트 데모데이는 국내에서 가장 흥행력 있는 스타트업 데뷔
                무대로 알려져 있어 발표팀은 큰 홍보 효과를 누릴 수 있습니다.
              </li>
            </ul>
            <br />
            <div
              className={`${
                mode === "Mobile" ? "font-bold-16" : "font-bold-16"
              }`}
            >
              4. 후속투자 유치 지원
            </div>
            <ul>
              <li
                className={`${mode === "Mobile" ? "font-body3" : "font-body3"}`}
              >
                인베스터데이 : 후속투자 유치를 원하는 포트폴리오사와 VC, CVC 등
                외부 투자기관들을 만나는 자리를 마련해 드립니다.
              </li>
              <li
                className={`${mode === "Mobile" ? "font-body3" : "font-body3"}`}
              >
                블루포인트 자체 후속투자 : 포트폴리오사들을 대상으로 심사를 통해
                프리 시리즈 A 또는 시리즈 A 단계의 후속투자를 집행합니다.
              </li>
            </ul>
          </div>
        </FaqSection>
        <FaqSection
          ref={faqListItems[10].ref}
          sectionId={faqListItems[10].id}
          headerTitle={`11. ${faqListItems[10].text}`}
        >
          <div className={`${mode === "Mobile" ? "font-body3" : "font-body3"}`}>
            2022년 3월 기준으로 다음과 같습니다.
            <br />
            <br />
            <div
              style={{
                display: "flex",
                flexDirection: mode === "Mobile" ? "column" : "row",
                gap: "40px",
              }}
            >
              <div
                style={{ display: "flex", flexDirection: "column", gap: "8px" }}
              >
                <div
                  className={`${
                    mode === "Mobile" ? "font-bold-16" : "font-bold-16"
                  }`}
                >
                  창업자 연령대
                </div>
                <Graph111 />
              </div>
              <div
                style={{ display: "flex", flexDirection: "column", gap: "8px" }}
              >
                <div
                  className={`${
                    mode === "Mobile" ? "font-bold-16" : "font-bold-16"
                  }`}
                >
                  창업자 남여비율
                </div>
                <Graph112 />
              </div>
            </div>
            <br />
            <div
              style={{ display: "flex", flexDirection: "column", gap: "8px" }}
            >
              <div
                className={`${
                  mode === "Mobile" ? "font-bold-16" : "font-bold-16"
                }`}
              >
                지역별 투자비율
              </div>
              <Graph113 />
            </div>
            <br />
            <div
              className={`${
                mode === "Mobile" ? "font-bold-16" : "font-bold-16"
              }`}
            >
              1. 나이
            </div>
            포트폴리오사 창업자들의 평균 나이는 40세입니다.{" "}
            {mode !== "Mobile" && <br />}아무래도 30~40대 대표님들이 많은
            편입니다만 20대 31명, 50대 30명, 60대 이상 6명으로 다양한 나이대의
            대표님들이 활발하게 활동하고 계십니다.
            <br />
            <br />
            <div
              className={`${
                mode === "Mobile" ? "font-bold-16" : "font-bold-16"
              }`}
            >
              2. 성별
            </div>
            창업자 성비는 아직 남녀 9:1 비율이지만, 여성 대표님들이 있는
            블루패밀리 숫자는 꾸준히 늘고 있습니다.
            <br />
            <br />
            <div
              className={`${
                mode === "Mobile" ? "font-bold-16" : "font-bold-16"
              }`}
            >
              3. 지역
            </div>
            블루포인트는 대전과 서울에 오피스를 두고 있기 때문에 국내 초기
            투자사 중에서는 서울 이외 지역에도 투자를 가장 많이 하는 하우스로
            손에 꼽힙니다. {mode !== "Mobile" && <br />}하지만 스타트업들이
            서울, 대전, 경기에 많이 몰려 있다보니 다른 지방 투자 비중이 낮은
            것은 아쉽게 생각하고 있습니다.{mode !== "Mobile" && <br />} 앞으로
            다양한 지역에서 잠재력 있는 스타트업들을 많이 발굴하도록
            노력하겠습니다.
          </div>
        </FaqSection>
        <FaqSection
          ref={faqListItems[11].ref}
          sectionId={faqListItems[11].id}
          headerTitle={`12. ${faqListItems[11].text}`}
        >
          <div className={`${mode === "Mobile" ? "font-body3" : "font-body3"}`}>
            해당 사업분야의 전문성은 창업팀이 가장 높다고 생각합니다.
            <br /> 산업과 기술 발전 속도가 빨라서 심사역이 모든 것을 100% 이해할
            수 있다고 단언할 수는 없기 때문이죠.
            <br /> 하지만 우리는 산업 전문가 이기 보다는 초기 스타트업의 문제를
            잘 이해하는 시작 전문가입니다.
            <br /> 블루포인트는 창업 경험자, 엔지니어, 산업계, 금융업계,
            스타트업 출신 전문 인력들로 구성되어 있어 기술과 그 가치를 이해할
            배경지식이 있고,
            <br /> 비즈니스 관점에서 어떻게 시작해야 하는지 도움을 드릴 수
            있습니다.
          </div>
        </FaqSection>
        <FaqSection
          ref={faqListItems[12].ref}
          sectionId={faqListItems[12].id}
          headerTitle={`13. ${faqListItems[12].text}`}
        >
          <div className={`${mode === "Mobile" ? "font-body3" : "font-body3"}`}>
            저희는 관심이라고 말합니다!{mode !== "Mobile" && <br />}{" "}
            원칙적으로는 스타트업의 요청에 의한 상담과 지원을 하며 경영과
            의사결정에 직접 관여하지 않습니다.
            <br /> (단, 의사결정에 참여할 필요가 생기면 이사회에 참여하는
            방식으로 진행합니다.)
            <br /> 또한 투자사의 관리 의무로써 필요한 자료는 정기적으로
            요청합니다.
          </div>
        </FaqSection>
        <FaqSection
          ref={faqListItems[13].ref}
          sectionId={faqListItems[13].id}
          headerTitle={`14. ${faqListItems[13].text}`}
        >
          <div className={`${mode === "Mobile" ? "font-body3" : "font-body3"}`}>
            멀리 가려면 누구와 함께 가는지가 중요합니다.
            <br />
            블루포인트라면 조금 더 유쾌하고 든든할 겁니다!
          </div>
        </FaqSection>
        {isGoTopShow && (
          <GoToTop>
            <div className="arrow_container">
              <ScrollDown
                onClick={() => {
                  window.scrollTo({ behavior: "smooth", top: 0 });
                }}
              />
            </div>
          </GoToTop>
        )}
      </FaqBodyContainer>
      <Footer />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 768px) {
  }
`;
const FaqBodyContainer = styled.div`
  position: relative;
  flex-grow: 1;
  width: 100%;
  display: flex;
  padding-top: 100px;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 768px) {
    padding-top: 40px;
    padding-bottom: 40px;
    .news__list {
      margin-top: 40px;
    }
  }
`;

const FaqList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
  border-bottom: 1px solid var(--grey-60);

  .faq__list__title {
    color: var(--grey-60);
    padding-bottom: 16px;
    border-bottom: 1px solid var(--grey-00);
  }

  .faq__list {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 30px;
  }

  @media only screen and (max-width: 768px) {
    padding-bottom: 40px;
  }
`;

const ListItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  color: var(--grey-50);
  transition: color 0.3s ease;
  cursor: pointer;

  :hover {
    color: var(--primary);
  }
  @media only screen and (max-width: 768px) {
    justify-content: space-between;
  }
`;

const GoToTop = styled.div`
  position: sticky;
  width: 100%;
  height: 40px;
  bottom: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  .arrow_container {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--grey-00);
    cursor: pointer;
    background-color: var(--white);
    opacity: 0.8;

    path {
      fill: var(--grey-40);
    }

    :hover {
      path {
        fill: var(--primary);
      }
    }
  }
`;

export default Faq;
