import { useEffect, useLayoutEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import Footer from "component/footer/Footer";
import axios from "axios";
import DisplayType from "utils/displayType";
import Banner from "component/banner/Banner";
import { ContactMenu } from "admin/type/type";
import DefaultButton from "admin/component/button/DefaultButton";
import {
  checkEmailForm,
  updateTitle as updateBrowserTitle,
} from "utils/commonUtil";
import ModalLayout from "admin/component/modal/ModalLayout";
import SuccessPartnershipModal from "component/success-modal/SuccessPartnershipModal";
import PrivacyPolicyCheckBox from "component/agree-checkbox/PrivacyPolicyCheckBox";
import { Link } from "react-router-dom";
import Spinner from "component/Spinner";
import { ReactComponent as Arrow } from "../../assets/title_arrow.svg";
import * as api from "../../api/api";
import ContactTabs from "./components/ContactTabs";
import ContactInput from "./components/ContactInput";
import ContactTextArea from "./components/ContactTextArea";
import bg from "../../assets/contact_bg.png";
import ContactChecked from "./components/ContactChecked";

const Partnership = () => {
  const mode = DisplayType();

  const bodyRef = useRef<HTMLDivElement>(null);
  const section1 = useRef<HTMLDivElement>(null);
  const section2 = useRef<HTMLDivElement>(null);
  const section3 = useRef<HTMLDivElement>(null);
  const section4 = useRef<HTMLDivElement>(null);
  const section5 = useRef<HTMLDivElement>(null);
  const section6 = useRef<HTMLDivElement>(null);

  const [isSuccessPartnershipModal, updateSuccessPartnershipModal] =
    useState<boolean>(false);
  const [isProgress, updateProgress] = useState<boolean>(false);

  const [ceoNameError, updateCeoNameError] = useState<boolean>(false);
  const [companyNameError, updateCompanyNameError] = useState<boolean>(false);
  const [phoneNumberError, updatePhoneNumberError] = useState<boolean>(false);
  const [ceoEmailError, updateCeoEmailError] = useState<boolean>(false);
  const [titleError, updateTitleError] = useState<boolean>(false);
  const [contentsError, updateContentsError] = useState<boolean>(false);
  const [ApplyPathError, updateApplyPathError] = useState<boolean>(false);
  const [checkedError, updateCheckedError] = useState<boolean>(false);

  const [ceoName, updateCeoName] = useState<string>("");
  const [companyName, updateCompanyName] = useState<string>("");
  const [phoneNumber, updatePhoneNumber] = useState<string>("");
  const [ceoEmail, updateCeoEmail] = useState<string>("");
  const [title, updateTitle] = useState<string>("");
  const [contents, updateContents] = useState<string>("");

  const [applyPath, updateApplyPath] = useState<number[]>([]);
  const [applyExtraMsg, updateApplyExtraMsg] = useState<string>("");
  const [checked, updateChecked] = useState<boolean>(false);
  const [validationChecked, updateValidationChekced] = useState(false);

  const initValue = () => {
    updateCeoNameError(false);
    updateCompanyNameError(false);
    updatePhoneNumberError(false);
    updateCeoEmailError(false);
    updateTitleError(false);
    updateContentsError(false);

    updateCeoName("");
    updateCompanyName("");
    updatePhoneNumber("");
    updateCeoEmail("");
    updateTitle("");
    updateContents("");
    updateChecked(false);
    updateApplyPath([]);
    updateApplyExtraMsg("");
  };

  const validationChecking = () => {
    const isValidCeoName = ceoName.length === 0;
    const isValidCompanyName = companyName.length === 0;
    const isValidPhoneNumber = phoneNumber.length === 0;
    const isValidCeoEmail = ceoEmail.length === 0 || !checkEmailForm(ceoEmail);
    const isValidTitle = title.length === 0;
    const isValidContents = contents.length === 0;
    const isValidApplyPath = applyPath.length === 0;
    const isValidChecked = !checked;

    updateCeoNameError(isValidCeoName);
    updateCompanyNameError(isValidCompanyName);
    updatePhoneNumberError(isValidPhoneNumber);
    updateCeoEmailError(isValidCeoEmail);
    updateTitleError(isValidTitle);
    updateContentsError(isValidContents);
    updateApplyPathError(isValidApplyPath);
    updateCheckedError(isValidChecked);

    updateValidationChekced((prev) => !prev);
    return (
      !isValidCeoName &&
      !isValidCompanyName &&
      !isValidPhoneNumber &&
      !isValidCeoEmail &&
      !isValidTitle &&
      !isValidContents &&
      !isValidApplyPath &&
      !isValidChecked
    );
  };
  const sendPartnershipInfo = async () => {
    const getPath = (id: number) => {
      switch (id) {
        case 1:
          return { id, name: "지인 소개" };
        case 2:
          return { id, name: "언론보도" };
        case 3:
          return { id, name: "SNS" };
        case 4:
          return { id, name: "인터넷 검색" };
        default:
          return { id, name: "기타" };
      }
    };
    try {
      if (validationChecking()) {
        updateProgress(true);
        const data = {
          ceoName,
          companyName,
          phoneNumber,
          ceoEmail,
          title,
          contents,
          applyPathList: applyPath.map((item) => ({
            applyPath: getPath(item),
            extra: item === 5 ? applyExtraMsg : undefined,
          })),
        };

        const result = await axios.post(api.sendPartnership(), data);
        if (result.status === 201) {
          updateSuccessPartnershipModal(true);
          updateProgress(false);
          initValue();
        }
      }
    } catch (error) {
      console.log(error);
      updateProgress(false);
    }
  };

  useEffect(() => {
    if (
      !section1.current ||
      !section2.current ||
      !section3.current ||
      !section4.current ||
      !section5.current ||
      !section6.current
    ) {
      return;
    }
    // let scrollTop = bodyRef.current.offsetTop - (mode === "Mobile" ? 60 : 80);
    let scrollTop = mode === "Mobile" ? -60 : -80;
    switch (true) {
      case ceoNameError || companyNameError:
        scrollTop += section1.current.offsetTop;
        window.scrollTo({ top: scrollTop, behavior: "smooth" });
        break;
      case phoneNumberError || ceoEmailError:
        scrollTop += section2.current.offsetTop;
        window.scrollTo({ top: scrollTop, behavior: "smooth" });
        break;
      case titleError:
        scrollTop += section3.current.offsetTop;
        window.scrollTo({ top: scrollTop, behavior: "smooth" });
        break;
      case contentsError:
        scrollTop += section4.current.offsetTop;
        window.scrollTo({ top: scrollTop, behavior: "smooth" });
        break;
      case ApplyPathError:
        scrollTop += section5.current.offsetTop;
        window.scrollTo({ top: scrollTop, behavior: "smooth" });
        break;
      case checkedError:
        scrollTop += section6.current.offsetTop;
        window.scrollTo({ top: scrollTop, behavior: "smooth" });
        break;
      default: {
        console.log("");
      }
    }
  }, [validationChecked]);

  useLayoutEffect(() => {
    updateBrowserTitle("Partnership");
  }, []);

  return (
    <Container>
      <Banner bannerImg2048={bg} mode={mode}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: mode === "Mobile" ? "center" : "flex-start",
          }}
        >
          <div className="font-title-en" style={{ color: "var(--white)" }}>
            CONTACT
          </div>
        </div>
      </Banner>
      <ContactTabs selectedTab={ContactMenu.PARTNERSHIP} />
      <PartnershipBodyContainer className="limit__body">
        {mode === "Mobile" ? (
          <p className="font-body1">
            제휴 및 협력, 채용 등
            <br />
            블루포인트에 궁금한 점을
            <br />
            보내주세요!
          </p>
        ) : (
          <p className="font-body">
            제휴 및 협력, 채용 등 블루포인트에
            <br />
            궁금한 점을 보내주세요!
          </p>
        )}
        <Link to={`/contact/faq`} style={{ textDecoration: "none" }}>
          <span className="font-body3 qna" style={{ color: "var(--primary)" }}>
            자주 묻는 질문
            <Arrow className="qna__arrow" width={16} height={16} />
          </span>
        </Link>

        <PartnershipSection style={{ marginTop: mode === "Mobile" ? 80 : 100 }}>
          <div className="section__title__container">
            <span
              className={`section__title ${
                mode === "Mobile" ? "font-sub-title2" : "font-sub-title"
              }`}
            >
              기본 정보
            </span>
            <span
              className={`section__description ${
                mode === "Mobile" ? "font-body4" : "font-body3"
              }`}
            >
              기본 정보를 입력해주세요.
            </span>
          </div>
          <div className="section__row" ref={section1}>
            <ContactInput
              className="section__flex2"
              label="이름"
              inputType="text"
              placeHolder="대표자 성함을 입력해주세요"
              isError={ceoNameError}
              defaultValue={ceoName}
              onFocus={() => updateCeoNameError(false)}
              onChangeValue={(value) => updateCeoName(value)}
            />
            <ContactInput
              className="section__flex4"
              label="기업명"
              inputType="text"
              placeHolder="기업명을 입력해주세요."
              isError={companyNameError}
              defaultValue={companyName}
              onFocus={() => updateCompanyNameError(false)}
              onChangeValue={(value) => updateCompanyName(value)}
            />
          </div>
          <div className="section__row" ref={section2}>
            <ContactInput
              className="section__flex2"
              label="전화번호"
              inputType="phone"
              placeHolder="대표자 전화번호를 입력해주세요."
              isError={phoneNumberError}
              defaultValue={phoneNumber}
              onFocus={() => updatePhoneNumberError(false)}
              onChangeValue={(value) => updatePhoneNumber(value)}
            />
            <ContactInput
              className="section__flex4"
              label="이메일"
              inputType="text"
              placeHolder="대표자 이메일을 입력해주세요"
              isError={ceoEmailError}
              defaultValue={ceoEmail}
              onFocus={() => updateCeoEmailError(false)}
              onChangeValue={(value) => updateCeoEmail(value)}
            />
          </div>
        </PartnershipSection>

        <PartnershipSection style={{ marginTop: mode === "Mobile" ? 40 : 100 }}>
          <div className="section__title__container">
            <span
              className={`section__title ${
                mode === "Mobile" ? "font-sub-title2" : "font-sub-title"
              }`}
            >
              문의 내용
            </span>
          </div>
          <div className="section__row" ref={section3}>
            <ContactInput
              className="section__flex1"
              label="제목"
              inputType="text"
              placeHolder="내용을 입력해주세요."
              isError={titleError}
              defaultValue={title}
              onFocus={() => updateTitleError(false)}
              onChangeValue={(value) => updateTitle(value)}
            />
          </div>
          <div className="section__row" ref={section4}>
            <ContactTextArea
              label="문의 내용을 입력해주세요. (500자이내)"
              maxLength={500}
              isError={contentsError}
              defaultValue={contents}
              onFocus={() => updateContentsError(false)}
              onChangeValue={(value) => {
                updateContents(value);
              }}
            />
          </div>
        </PartnershipSection>

        <PartnershipSection style={{ marginTop: mode === "Mobile" ? 40 : 100 }}>
          <div className="section__title__container">
            <span
              className={`section__title ${
                mode === "Mobile" ? "font-sub-title2" : "font-sub-title"
              }`}
            >
              추가 정보
            </span>
          </div>
          <div className="section__row" ref={section5}>
            <ContactChecked
              label="블루포인트를 알게 된 경로"
              isError={ApplyPathError}
              checkedItem={applyPath}
              updateCheckedItem={updateApplyPath}
              extraMsg={applyExtraMsg}
              updateExtraMsg={updateApplyExtraMsg}
              onChangeValue={() => updateApplyPathError(false)}
            />
          </div>
        </PartnershipSection>

        <div ref={section6}>
          <PrivacyPolicyCheckBox
            defaultCheck={checked}
            style={{ marginTop: 50 }}
            isError={checkedError}
            onChangeValue={(value) => {
              updateChecked(value);
              updateCheckedError(false);
            }}
          />
        </div>
        <SubmitButtonContainer>
          <DefaultButton
            className="font-sub-title4"
            type="submit"
            onClick={sendPartnershipInfo}
            width={194}
            height={60}
          >
            제출하기
          </DefaultButton>
        </SubmitButtonContainer>

        <ModalLayout
          isOpen={isSuccessPartnershipModal}
          borderRadius={0}
          toggle={() => {
            updateSuccessPartnershipModal(false);
          }}
        >
          <SuccessPartnershipModal
            close={() => {
              updateSuccessPartnershipModal(false);
            }}
          />
        </ModalLayout>
        <ModalLayout isOpen={isProgress} borderRadius={0} isProgress>
          <Spinner loading={isProgress} size={80} />
        </ModalLayout>
      </PartnershipBodyContainer>
      <Footer />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 768px) {
  }
`;
const PartnershipBodyContainer = styled.div`
  flex-grow: 1;
  width: 100%;
  display: flex;
  padding-top: 100px;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;

  .qna {
    cursor: pointer;
    .qna__arrow {
      transform: rotate(-45deg);
      path {
        fill: var(--primary);
      }
    }
  }

  .is__error {
    animation: shake 300ms;
  }
  @media only screen and (max-width: 768px) {
    padding-top: 40px;
    padding-bottom: 40px;
    .news__list {
      margin-top: 40px;
    }
  }
`;

const PartnershipSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  .section__title__container {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: flex-end;

    border-bottom: 1px solid var(--grey-00);
    padding-bottom: 16px;

    .section__title {
      color: var(--grey-60);
    }

    .section__description {
      padding-bottom: 6px;
      color: var(--grey-30);
    }
  }

  .section__row {
    display: flex;
    flex-direction: row;
    gap: 24px;

    .section__flex1 {
      flex: 1;
    }
    .section__flex2 {
      flex: 2;
    }
    .section__flex3 {
      flex: 3;
    }
    .section__flex4 {
      flex: 4;
    }
  }

  @media only screen and (max-width: 768px) {
    .section__title__container {
      flex-direction: column;
      align-items: flex-start;
      gap: 0px;
    }

    .section__row {
      flex-direction: column;
      gap: 40px;
    }
  }
`;

const SubmitButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 160px;
  @media only screen and (max-width: 768px) {
    align-items: center;
    justify-content: center;
    margin-top: 80px;
  }
`;
export default Partnership;
