import styled from "@emotion/styled";

export const EmptyView = styled.div`
  flex-grow: 1;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
  border-radius: 10px;

  text-align: center;

  color: var(--grey-30);
`;

export const TeamDetailLayout = styled.div`
  width: 100%;
  max-width: calc(100vw - 350px -48px);
  display: flex;
  flex-direction: row;
  height: 100%;
  min-height: calc(100vh - 68px - 48px);
  max-height: calc(100vh - 68px - 48px);
  gap: 24px;

  .structure__reduce {
    height: 100%;
    min-height: calc(100vh - 68px - 48px);
    max-height: calc(100vh - 68px - 48px);
    width: calc(100% - 300px);
    max-width: calc(100% - 300px);
    overflow-y: scroll;
  }

  .alluser__increse {
    height: 100%;
    min-height: calc(100vh - 68px - 48px);
    max-height: calc(100vh - 68px - 48px);
    width: 300px;
    max-width: 300px;
    overflow-y: scroll;
  }
`;

export const StructureContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 68px - 48px);
  max-height: calc(100vh - 68px - 48px);
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  transition: width 0.35s ease;

  .structure__users__header {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    .structure__users__header__row {
      width: 100%;

      display: flex;
      padding: 24px 24px 0 24px;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
    .structure__description {
      padding: 0px 24px;
    }
  }
`;

export const StructureUserList = styled.ul`
  width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
  transition: background-color 0.2s ease;
  overflow-y: scroll;
`;

export const AllUserContainer = styled.div`
  width: 0px;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: var(--white);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  transition: width 0.35s ease;

  .alluser__header {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 24px;
    gap: 10px;
    .alluser__header__header {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .alluser__header__header__row {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 16px;
      }
    }

    .selected__user__container {
      width: 100%;
      height: 0px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      overflow-x: scroll;
      transition: height 0.35s ease;
    }
  }
`;

export const AllUserList = styled.ul`
  width: 100%;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  transition: background-color 0.2s ease;
  overflow-y: scroll;

  .alluser__search {
    width: 100%;
    height: 30px;
    min-height: 30px;
    max-height: 30px;
    border-radius: 8px;
    background-color: var(--grey-00);
    padding: 0 10px;
    border: 0;
    :focus {
      outline: none;
    }
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--grey-00);
`;
